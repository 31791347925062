import { NextFont } from 'next/dist/compiled/@next/font';
import localFont from 'next/font/local';

export type FontTypes = 'text' | 'headlines';

const headlineFontLoader = localFont({
  src: '../assets/fonts/officina.woff2',
  adjustFontFallback: 'Arial',
  weight: '500',
});

const textFontLoader = localFont({
  src: [
    { path: '../assets/fonts/Inter-roman.var-subset.woff2', weight: '400', style: 'normal' },
    { path: '../assets/fonts/Inter-italic.var-subset.woff2', weight: '400', style: 'italic' },
    { path: '../assets/fonts/Inter-roman.var-subset.woff2', weight: '600', style: 'normal' },
    { path: '../assets/fonts/Inter-italic.var-subset.woff2', weight: '600', style: 'italic' },
  ],
});

export const fontStyles: Record<FontTypes, NextFont> = {
  headlines: headlineFontLoader,
  text: textFontLoader,
};
