export enum BorderRadius {
  Xxs = '0.25rem',
  Xs = '0.375rem',
  S = '0.5rem',
  M = '1rem',
}

export enum BorderWidth {
  Input = '2px',
}
