import { ChangeEventHandler, useRef, forwardRef, useState, useEffect, useCallback } from 'react';

import CrossCircleXs from '@lichtblick/icons/svg/cross-circle/cross-circle-xs';
import { SearchIconXs } from '@lichtblick/icons/svg/search/search-xs';

import { IconWrapper, InputRow, Input, LabelWrapper, Slot, Wrapper } from './FilterSelect.styles';

import { DropDownHandle } from '../../atoms/DropDown/DropDown';
import { doesValueMatchSearch } from '../ComboBoxInput/ComboBoxInput.helpers';
import { FieldFooter } from '../FieldFooter/FieldFooter';
import { Label } from '../Label/Label';
import { SelectInputOption } from '../SelectInput/SelectInput';
import { TextfieldProps } from '../Textfield/Textfield';

export type FilterSelectOption = SelectInputOption & {
  keywords?: string[];
};

export type FilterSelectProps = TextfieldProps & {
  options: FilterSelectOption[];
};

export const FilterSelect = forwardRef<HTMLInputElement, FilterSelectProps>(
  (
    {
      className,
      'data-testid': testId,
      icon,
      id,
      isDisabled,
      label,
      name,
      onChange,
      options,
      statusLabel,
      statusType,
      value,
      ...props
    },
    ref,
  ) => {
    const [searchValue, setSearchValue] = useState('');
    const inputRef = useRef<HTMLInputElement | null>(null);
    const dropdownRef = useRef<DropDownHandle | null>(null);
    const inputId = id || name;
    const labelId = inputId && `${inputId}_label`;
    const statusId = inputId && `${inputId}_validationMessage`;
    const filteredOptions = options.filter(
      ({ keywords, label }) =>
        doesValueMatchSearch(label, searchValue) || (keywords && doesValueMatchSearch(keywords.join(), searchValue)),
    );

    const updateSearchValue = useCallback(
      (searchValue: typeof value) =>
        setSearchValue(options.find((option) => option.value === searchValue)?.label ?? ''),
      [options],
    );

    useEffect(() => updateSearchValue(value), [updateSearchValue, value]);

    const setValue: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
      const exactOption = options.find(({ label }) => label === target.value);

      dropdownRef.current?.open();
      setSearchValue(target.value);

      if (exactOption) {
        dispatchChange(exactOption.value);
      } else if (!target.value) {
        dispatchChange('');
      }
    };

    const focusInput = () => inputRef.current?.focus();

    const dispatchChange = (value: string) => {
      onChange?.({ target: { value, name } } as any);
      updateSearchValue(value); // needed in case current value was selected again after search value was altered
      dropdownRef.current?.close();
    };

    const openDropdown = () => {
      focusInput();
      dropdownRef.current?.open();
    };

    const clearInput = () => {
      dispatchChange('');
      setSearchValue('');
      focusInput();
      dropdownRef.current?.close();
    };

    return (
      <Wrapper className={className}>
        <LabelWrapper>
          {Boolean(label) && (
            <Label htmlFor={inputId} id={labelId} isBold>
              {label}
            </Label>
          )}
        </LabelWrapper>
        <InputRow
          $isDisabled={isDisabled}
          $statusType={statusType}
          onClose={focusInput}
          onOptionSelect={dispatchChange}
          options={filteredOptions}
          ref={dropdownRef}
        >
          <input name={name} type="hidden" value={value} />
          <Input
            {...props}
            aria-describedby={statusId}
            aria-invalid={statusType === 'error'}
            aria-labelledby={labelId}
            data-testid={testId || inputId}
            disabled={isDisabled}
            id={inputId}
            onChange={setValue}
            ref={(instance) => {
              if (typeof ref === 'function') {
                ref(instance);
              } else if (ref) {
                ref.current = instance;
              }

              inputRef.current = instance;
            }}
            value={searchValue}
          />
          {!isDisabled && (
            <Slot>
              {Boolean(searchValue) && (
                <IconWrapper onClick={clearInput}>
                  <CrossCircleXs />
                </IconWrapper>
              )}
              <IconWrapper onClick={openDropdown}>
                <SearchIconXs />
              </IconWrapper>
            </Slot>
          )}
        </InputRow>
        {Boolean(statusLabel) && (
          <FieldFooter fieldFooterLabel={statusLabel} fieldFooterType={statusType} icon={icon} id={statusId} />
        )}
      </Wrapper>
    );
  },
);
