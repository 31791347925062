import { FC } from 'react';

import { Ring, Sun, Svg } from './Loader.styles';

export const Loader: FC<{ className?: string; isWhite?: boolean }> = ({ className, isWhite }) => (
  <Svg className={className} fill="none" viewBox="0 0 100 90" xmlns="http://www.w3.org/2000/svg">
    <Sun cx="40" cy="50" fill="#FCBC67" r="40" />
    <Ring
      $isWhite={isWhite}
      clipRule="evenodd"
      d="M60 72C77.6731 72 92 57.6731 92 40C92 22.3269 77.6731 8 60 8C42.3269 8 28 22.3269 28 40C28 57.6731 42.3269 72 60 72ZM60 80C82.0914 80 100 62.0914 100 40C100 17.9086 82.0914 0 60 0C37.9086 0 20 17.9086 20 40C20 62.0914 37.9086 80 60 80Z"
      fillRule="evenodd"
    />
  </Svg>
);
