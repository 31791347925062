import styled from '@lichtblick/styled';
import { MediaSmallAndAbove, Spacing } from '@lichtblick/theme';
import { Headline, Ul } from '@lichtblick/ui-components';

import { AText } from '../AText';

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${Spacing.Xxs};

  ${MediaSmallAndAbove} {
    margin-bottom: ${Spacing.Xs};
  }
`;

export const StyledUl = styled(Ul)`
  :not(:last-child) {
    margin-bottom: ${Spacing.Xs};
  }
`;

export const StyledText = styled(AText)`
  :not(:last-child) {
    margin-bottom: ${Spacing.Xs};
  }
`;
