import styled, { css } from '@lichtblick/styled';
import { BorderRadius, Colors, darkColors, MediaMediumAndAbove, Spacing } from '@lichtblick/theme';

import { StyledChip } from '../../atoms/Chip/Chip.styles';
import { StyledHeadline } from '../../atoms/Headline';
import { focusRing, linkUnderlineHover } from '../../atoms/shared';
import { StyledText } from '../../atoms/Text';
import { StyledTextCTA } from '../../atoms/TextCTA/TextCTA.styles';
import { Link } from '../../helpers/Link';

const getContrastColor = (backgroundColor: Colors) =>
  darkColors.includes(backgroundColor) ? Colors.White : Colors.Black;

export const Media = styled.div<{ $backgroundColor: Colors; $isTextOutside: boolean }>`
  position: relative;
  border-radius: ${({ $isTextOutside }) => ($isTextOutside ? BorderRadius.S : 0)};
  overflow: hidden;
  width: 100%;

  img {
    width: 100%;
  }

  &::after {
    content: '';
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.1s;
    background: color-mix(
      in srgb,
      ${({ $backgroundColor, $isTextOutside }) => ($isTextOutside ? Colors.Black : getContrastColor($backgroundColor))}
        11%,
      transparent
    );
  }
`;

export const Container = styled.div<{ $backgroundColor: Colors; $isTextOutside: boolean }>`
  border-radius: ${BorderRadius.S};
  position: relative;
  transition: background-color 0.1s;
  background: ${({ $backgroundColor, $isTextOutside }) => ($isTextOutside ? 'none' : $backgroundColor)};
`;

const activeStyles = css<{ $backgroundColor: Colors }>`
  ${Container} {
    background: color-mix(
      in srgb,
      ${({ $backgroundColor }) => getContrastColor($backgroundColor)} 11%,
      ${({ $backgroundColor }) => $backgroundColor}
    );
  }

  ${Media}::after {
    opacity: 1;
  }

  ${StyledTextCTA} > span {
    ${linkUnderlineHover};
  }
`;

export const StyledLink = styled(Link).attrs({ tabIndex: 0 })<{ $backgroundColor: Colors; $isTextOutside: boolean }>`
  color: ${({ $backgroundColor, $isTextOutside }) => ($isTextOutside ? 'inherit' : getContrastColor($backgroundColor))};
  display: block;
  position: relative;
  overflow: hidden;
  outline: none;
  border-radius: ${BorderRadius.S};
  transition: transform 0.1s;

  &:focus-visible {
    ${focusRing};
  }

  @media (hover: hover) {
    &:hover {
      ${activeStyles}
    }
  }

  &:active {
    outline: none;
    transform: scale(0.98);
    ${activeStyles}
  }

  ${StyledText} {
    &:not(:last-child) {
      margin-bottom: ${Spacing.Xs};
    }
  }

  ${StyledHeadline} {
    margin-bottom: ${Spacing.Xxs};

    ${MediaMediumAndAbove} {
      margin-bottom: ${Spacing.Xs};
    }
  }

  ${StyledChip} {
    margin-bottom: ${Spacing.Xs};
  }
`;

export const Content = styled.div<{ $isTextOutside: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ $isTextOutside }) => ($isTextOutside ? Spacing.S : 0)};
  padding: ${({ $isTextOutside }) => ($isTextOutside ? 0 : Spacing.S)};
`;
