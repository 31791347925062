import { TextCTA, Text } from '@lichtblick/ui-components';

const marketingLegalTextBlocks = [
  'Ja, ich willige ein, dass mich die LichtBlick Energy-as-a-Service GmbH sowie mit der LichtBlick Energy-as-a-Service GmbH ',
  'verbundene Unternehmen',
  ' über energienahe Produkte und Dienstleistungen (z.B. Smartmeter, Wallboxen, alternative Energieanlagen oder Energieberatung) sowie zu Marktforschungszwecken und zur Qualitätssicherung per E-Mail kontaktieren und informieren.',
  'Ich habe die ',
  'Datenschutzhinweise',
  ' zur Kenntnis genommen und bin darüber informiert, dass die vorstehenden, freiwilligen Einwilligungen jederzeit ohne Angabe von Gründen mit Wirkung für die Zukunft (per Post an LichtBlick SE, Klostertor 1, 20097 Hamburg, per E-Mail an ',
  'datenschutz@lichtblick.de',
  ' oder per Telefon unter ',
  '+49 40 80803050',
  ') widerrufen werden können.',
];

export const marketingLegalText = marketingLegalTextBlocks.join('');

export const MarketingLegalText = () => (
  <Text>
    {marketingLegalTextBlocks[0]}
    <TextCTA as="a" href="https://www.lichtblick.de/lichtblick-gruppe/" isInlineLink target="_blank">
      {marketingLegalTextBlocks[1]}
    </TextCTA>
    {marketingLegalTextBlocks[2]}
  </Text>
);
