import { FC } from 'react';

import { useTracker } from '@lichtblick/tracker';

import { StyledHeaderMetaNavigationLink, StyledHeaderMetaNavigationList } from './MetaNavigationItem.styles';
import { NavigationItemIcons } from './NavigationItemIcon';

import { Text } from '../../atoms/Text/Text';
import { NavigationItemProps } from '../../types';

export const HeaderMetaNavigationItem: FC<NavigationItemProps> = ({ href, icon, isActive, text, tracking }) => {
  const { trackCustomEvent } = useTracker();

  return (
    <StyledHeaderMetaNavigationList>
      <StyledHeaderMetaNavigationLink
        $isActive={!!isActive}
        aria-label={text}
        href={href}
        onClick={() => {
          trackCustomEvent(tracking);
        }}
      >
        {icon && <NavigationItemIcons name={icon} />}

        <Text isBold={isActive} size="L">
          {text}
        </Text>
      </StyledHeaderMetaNavigationLink>
    </StyledHeaderMetaNavigationList>
  );
};
