import { FC, ReactNode, useEffect, useRef, useState } from 'react';

import { NavigationItem } from './NavigationItem';
import {
  Icon,
  Section,
  NavigationToggle,
  Title,
  Root,
  Navigation,
  ButtonWrapper,
  Overlay,
} from './SecondaryNavigation.styles';

import { NavigationItemProps } from '../../types';

export type SecondaryNavigationProps = {
  children: ReactNode;
  isButtonIntroOnDesktop?: boolean;
  secondaryNavigation: NavigationItemProps[];
  title: string;
};

export const SecondaryNavigation: FC<SecondaryNavigationProps> = ({
  children,
  isButtonIntroOnDesktop = false,
  secondaryNavigation,
  title,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const navigation = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) =>
      entries.forEach((entry) => setIsButtonVisible(!entry.isIntersecting)),
    );
    const sibling = navigation.current?.nextElementSibling;

    if (sibling) {
      observer.observe(sibling);

      return () => observer.unobserve(sibling);
    }
  }, []);

  return (
    <Root data-testid="secondary-navigation" ref={navigation}>
      <Section>
        <Title size="M">{title}</Title>
        {Boolean(secondaryNavigation?.length) && (
          <>
            <NavigationToggle onClick={() => setIsOpen(!isOpen)}>
              <Icon $isToggled={isOpen} />
            </NavigationToggle>
            <Navigation $isOpen={isOpen}>
              {secondaryNavigation.map((item) => (
                <NavigationItem key={item.href} {...item} />
              ))}
            </Navigation>
            <Overlay $isVisible={isOpen} onClick={() => setIsOpen(false)} />
          </>
        )}
        {Boolean(children) && (
          <ButtonWrapper $isButtonIntroOnDesktop={isButtonIntroOnDesktop} $isVisible={isButtonVisible}>
            {children}
          </ButtonWrapper>
        )}
      </Section>
    </Root>
  );
};
