import styled, { css } from '@lichtblick/styled';
import { MediaMediumAndAbove } from '@lichtblick/theme';

import { Picture } from './Picture';

const backgroundStyles = css`
  height: 100%;
  width: 100%;
`;

export const PictureMobile = styled(Picture)<{ $isHiddenOnDesktop: boolean; isBackground?: boolean }>`
  ${({ isBackground }) => isBackground && backgroundStyles}
  ${({ $isHiddenOnDesktop }) =>
    $isHiddenOnDesktop &&
    css`
      ${MediaMediumAndAbove} {
        display: none;
      }
    `}
`;

export const PictureDesktop = styled(Picture)<{ isBackground?: boolean }>`
  display: none;
  ${({ isBackground }) => isBackground && backgroundStyles}

  ${MediaMediumAndAbove} {
    display: block;
  }
`;
