import { ElementType, FC } from 'react';

import { IconWrapper, StyledFieldFooter } from './FieldFooter.styles';

export type FieldFooterType = 'default' | 'error' | 'success';

export type FieldFooterProps = {
  fieldFooterLabel?: string;
  fieldFooterType?: FieldFooterType;
  icon?: ElementType;
  id?: string;
};

export const FieldFooter: FC<FieldFooterProps> = ({ fieldFooterLabel, fieldFooterType, icon: Icon, id }) =>
  !fieldFooterLabel ? null : (
    <StyledFieldFooter $fieldFooterType={fieldFooterType} id={id}>
      {Icon && (
        <IconWrapper>
          <Icon />
        </IconWrapper>
      )}
      <span>{fieldFooterLabel}</span>
    </StyledFieldFooter>
  );
