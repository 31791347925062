import React from 'react';

import { StyledSvgIcon } from './SvgIcon.styles';

import { SvgIconType } from '../../types/storyblok';

export const SvgIcon: React.FC<SvgIconType> = ({ height, svg, width }) => (
  <StyledSvgIcon
    alt={`${svg.alt}`}
    height={height ? Number(height) : undefined}
    src={svg.filename}
    width={width ? Number(width) : undefined}
  />
);
