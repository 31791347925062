import { FC, PropsWithChildren, ReactNode } from 'react';

import { Colors } from '@lichtblick/theme';
import { Text } from '@lichtblick/ui-components';

import { Steps as StyledSteps, Step as StyledStep, StepContent } from './Steps.styles';

export type StepType = {
  /** defaults to `Colors.White` */
  backgroundColor?: Colors;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  text: ReactNode;
};

export const Step: FC<StepType> = ({ backgroundColor = Colors.White, icon: Icon, text }) => (
  <StyledStep>
    <StepContent $backgroundColor={backgroundColor}>
      <Icon />
      <Text isBold size="S">
        {text}
      </Text>
    </StepContent>
  </StyledStep>
);

export const Steps: FC<PropsWithChildren> = ({ children }) => <StyledSteps>{children}</StyledSteps>;
