import { FC } from 'react';

import { ExtendedTemplates, TEMPLATE_MAP, TemplateIds } from '../components/templates';
import { StoryblokStoryType } from '../types';

export type ParentPage = {
  breadcrumbTitle?: string | null;
  slug: string;
};

export type WithMetaData<T> = T & { parentPages: ParentPage[]; slug: string };

const isMappedTemplate = (contentTypeId: string): contentTypeId is TemplateIds => contentTypeId in TEMPLATE_MAP;

export const templateMapper = <T extends WithMetaData<StoryblokStoryType<ExtendedTemplates>>>(template: T) => {
  const templateId = template.content.component;

  if (!isMappedTemplate(templateId)) {
    throw new Error(`Missing template for content type ${templateId}`);
  }

  return { Component: TEMPLATE_MAP[templateId] as FC<T>, props: template as T };
};
