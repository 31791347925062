import { FC } from 'react';

import { buildUrl, componentMapper } from '../../helpers';
import { MLogoItemType } from '../../types/storyblok';

export const MLogoItem: FC<MLogoItemType> = ({
  image,
  linkAsset,
  linkEntry,
  linkUrl,
  openAsDownload: shouldOpenAsDownload,
  openInNewTab: shouldOpenInNewTab,
}) => {
  const url = linkUrl || (linkEntry && buildUrl(linkEntry)) || linkAsset?.filename;
  const logo = image[0] ? componentMapper(image[0]) : null;

  if (!url) {
    return logo;
  }

  return (
    <a download={shouldOpenAsDownload} href={url} rel="noreferrer" target={shouldOpenInNewTab ? '_blank' : '_self'}>
      {logo}
    </a>
  );
};
