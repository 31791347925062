import styled, { css } from '@lichtblick/styled';
import { MediaMediumAndAbove, MediaSmallAndAbove, MediaBelowSmall, Spacing } from '@lichtblick/theme';

import { StyledBoxCTA } from '../../atoms/BoxCTA/BoxCTA.styles';
import { Chip } from '../../atoms/Chip/Chip';
import { Headline } from '../../atoms/Headline/Headline';
import { Text } from '../../atoms/Text/Text';
import { StyledTextCTA } from '../../atoms/TextCTA/TextCTA.styles';

export const StyledTextIntro = styled.div<{ $isCenteredDesktop?: boolean; $isCenteredMobile?: boolean }>`
  ${({ $isCenteredMobile }) =>
    $isCenteredMobile &&
    css`
      ${MediaBelowSmall} {
        text-align: center;
      }
    `}

  ${({ $isCenteredDesktop }) =>
    $isCenteredDesktop &&
    css`
      ${MediaSmallAndAbove} {
        text-align: center;
      }
    `}

  ${StyledBoxCTA}, ${StyledTextCTA} {
    margin-top: ${Spacing.Xs};
  }
`;

export const TextIntroChip = styled(Chip)`
  margin-bottom: ${Spacing.Xxs};

  @media ${MediaMediumAndAbove} {
    margin-bottom: ${Spacing.Xs};
  }
`;

export const TextIntroHeadline = styled(Headline)`
  &:not(:only-child) {
    margin-bottom: ${Spacing.Xxs};
  }
`;

export const TextIntroText = styled(Text)<{ $isOStageSplit?: boolean }>`
  ${MediaMediumAndAbove} {
    ${({ $isOStageSplit }) =>
      $isOStageSplit &&
      css`
        max-width: calc(${(100 / 12) * 11}% - 2rem);
      `}
  }
`;
