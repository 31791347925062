export enum Spacing {
  /** 4px */
  '3Xs' = '0.25rem',
  /** 8px */
  Xxs = '0.5rem',
  /** 16px */
  Xs = '1rem',
  /** 24px */
  S = '1.5rem',
  /** 32px */
  M = '2rem',
  /** 48px */
  L = '3rem',
  /** 64px */
  Xl = '4rem',
  /** 96px */
  Xxl = '6rem',
  /** 128px */
  '3Xl' = '8rem',
}
