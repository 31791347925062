import { LichtBlickTypes } from '../../types';
import { MProductOverviewCardType } from '../../types/storyblok';

export type ProductOverviewCardWithPrice = MProductOverviewCardType & {
  price: number | null;
  prices: number[];
  teaserProductPrice: number | null;
  teaserProductsPrices: number[];
};

export const addProductPricing = ({
  cardProducts,
  dataProducts,
}: {
  cardProducts: MProductOverviewCardType[];
  dataProducts: LichtBlickTypes.GetNonCommodityProductsQuery['nonCommodityProductsByCampaign'];
}) => {
  const mainProducts: LichtBlickTypes.GetNonCommodityProductsQuery['nonCommodityProductsByCampaign'] = [];
  const teaserProducts: LichtBlickTypes.GetNonCommodityProductsQuery['nonCommodityProductsByCampaign'] = [];
  const finalProducts: ProductOverviewCardWithPrice[] = [];

  // sort between main and teaser products
  cardProducts.forEach((cardProduct) => {
    dataProducts.forEach((dataProduct) => {
      const isMainProduct = cardProduct.productCodes?.value?.includes(dataProduct.productCode);
      const isTeaserProduct = cardProduct.teaserProductCodes?.value?.includes(dataProduct.productCode);

      if (isMainProduct) {
        mainProducts.push(dataProduct);
      }

      if (isTeaserProduct) {
        teaserProducts.push(dataProduct);
      }
    });
  });

  // add the prices on the main products
  cardProducts.forEach((cardProduct) => {
    const product = {
      ...cardProduct,
      price: null,
      prices: [] as number[],
      teaserProductPrice: null,
      teaserProductsPrices: [],
    };

    mainProducts.forEach((mainProduct) => {
      if (cardProduct.productCodes?.value?.includes(mainProduct.productCode)) {
        const minPrice = Math.min(...mainProduct.prices.map((price) => price.salesPriceGross));

        product.prices.push(minPrice);
      }
    });

    finalProducts.push(product);
  });

  // add the prices on the teaser products
  finalProducts.forEach((finalProduct) => {
    teaserProducts.forEach((teaserProduct) => {
      if (finalProduct.teaserProductCodes?.value?.includes(teaserProduct.productCode)) {
        const minPrice = Math.min(...teaserProduct.prices.map((price) => price.salesPriceGross));

        finalProduct.teaserProductsPrices.push(minPrice);
      }
    });
  });

  // get the lowest price on the main & teaser products
  finalProducts.forEach((finalProduct) => {
    const mainProductMinPrice = finalProduct.prices.length > 0 ? Math.min(...finalProduct.prices) : null;
    const teaserProductMinPrice =
      finalProduct.teaserProductsPrices.length > 0 ? Math.min(...finalProduct.teaserProductsPrices) : null;

    finalProduct.price = mainProductMinPrice;
    finalProduct.teaserProductPrice = teaserProductMinPrice;
  });

  return finalProducts;
};
