import styled from '@lichtblick/styled';
import { Colors, MediaMediumAndAbove, Spacing } from '@lichtblick/theme';

export const Hr = styled.hr`
  border: 1px solid ${Colors.LightGray};
  margin: ${Spacing.S} ${Spacing.M};

  ${MediaMediumAndAbove} {
    margin: ${Spacing.S} 0;
  }
`;
