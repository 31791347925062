import { FC, useEffect, useMemo, useState } from 'react';

import { Text } from '@lichtblick/ui-components/atoms/Text/Text';

import {
  StyledORoadToZeroBar,
  StyledORoadToZeroCounter,
  StyledORoadToZeroCounterWrapper,
  StyledORoadToZeroWrapper,
} from './ORoadToZero.styles';

import { ORoadToZeroType } from '../../types';
import { colorMap } from '../../utils';
import { MRoadToZeroItem } from '../MRoadToZeroItem/MRoadToZeroItem';

const ANIMATION_DIVIDER = 200;

const formatNumber = (n: number): string => {
  const str = String(n * 10);

  return `${str.slice(0, -1) || 0},${str.slice(-1)}`;
};

const ORoadToZeroCounter: React.FC<{ progressValue: number; unit1?: string; unit2?: string }> = ({
  progressValue,
  unit1,
  unit2,
}) => {
  const [animatedValue, setAnimatedValue] = useState(progressValue);

  useEffect(() => {
    const previousValue = animatedValue;
    const sign = previousValue < progressValue ? 1 : -1;
    const start = performance.now();
    const end = start + Math.abs(previousValue - progressValue) * ANIMATION_DIVIDER;

    const animate = (now: number) => {
      const elapsed = now - start;

      setAnimatedValue(Math.round((previousValue + (sign * elapsed) / ANIMATION_DIVIDER) * 10) / 10);

      if (now > end) {
        setAnimatedValue(progressValue);

        return;
      }

      requestAnimationFrame(animate);
    };

    animate(start);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressValue]);

  return (
    <StyledORoadToZeroCounter>
      <Text isBold size="L">
        {formatNumber(animatedValue)}
        {unit1}
      </Text>
      <Text isBold size="L">
        {unit2}
      </Text>
    </StyledORoadToZeroCounter>
  );
};

export const ORoadToZero: FC<ORoadToZeroType> = ({ items, unit1, unit2 }) => {
  const [progressValue, setProgressValue] = useState(Number(items[0]?.progressValue));

  const colorSteps = useMemo(
    () => items.map(({ accentColor }) => [colorMap[accentColor], colorMap[accentColor]].join(', ')).join(', '),
    [items],
  );

  return (
    <StyledORoadToZeroWrapper>
      {items.map((item) => (
        <MRoadToZeroItem key={item._uid} {...item} setProgressValue={setProgressValue} />
      ))}
      <StyledORoadToZeroBar $colorSteps={colorSteps} />
      <StyledORoadToZeroCounterWrapper>
        <ORoadToZeroCounter progressValue={progressValue} unit1={unit1} unit2={unit2} />
      </StyledORoadToZeroCounterWrapper>
    </StyledORoadToZeroWrapper>
  );
};
