import {
  ChangeEventHandler,
  ComponentProps,
  ElementType,
  ForwardedRef,
  forwardRef,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { ChevronDownIconXs } from '@lichtblick/icons/svg/chevron-down/chevron-down-xs';

import { ArrowWrapper, IconWrapper, InputRow, Select, StyledSelectInput } from './SelectInput.styles';

import { FieldFooter, FieldFooterType } from '../FieldFooter/FieldFooter';
import { Label } from '../Label/Label';

export type SelectInputOption<T = string> = {
  isHidden?: boolean;
  label: string;
  value: T;
};

export type SelectInputProps<T> = Omit<ComponentProps<'select'>, 'disabled'> & {
  allowEmptySelection?: boolean;
  fieldIcon?: ElementType;
  icon?: ElementType;
  isDisabled?: boolean;
  label?: string;
  name: string;
  options: SelectInputOption<T>[];
  placeholder?: string;
  statusLabel?: string;
  statusType?: FieldFooterType;
  value?: T | '';
};

const SelectInputInner = <T extends number | string>(
  {
    allowEmptySelection,
    className,
    fieldIcon: FieldIcon,
    icon,
    id,
    isDisabled,
    label,
    name,
    onChange,
    options,
    placeholder,
    statusLabel,
    statusType,
    value,
    ...props
  }: SelectInputProps<T>,
  ref: ForwardedRef<HTMLSelectElement>,
) => {
  const iconRef = useRef<HTMLDivElement | null>(null);
  const [iconWidth, setIconWidth] = useState<number>();
  const inputId = id || name;
  const labelId = `${inputId}_label`;
  const statusId = `${inputId}_validationMessage`;

  useLayoutEffect(() => {
    const updateIconWidth = () => setIconWidth(iconRef.current?.getBoundingClientRect().width);

    updateIconWidth();
    window.addEventListener('resize', updateIconWidth);

    return () => window.removeEventListener('resize', updateIconWidth);
  }, []);

  const handleChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    onChange?.(event);
  };

  return (
    <StyledSelectInput className={className}>
      {Boolean(label) && (
        <Label htmlFor={inputId} id={labelId} isBold>
          {label}
        </Label>
      )}
      <InputRow $isDisabled={isDisabled} $statusType={statusType}>
        {FieldIcon && (
          <IconWrapper ref={iconRef}>
            <FieldIcon />
          </IconWrapper>
        )}
        <Select
          {...props}
          $paddingLeft={iconWidth}
          aria-describedby={statusId}
          aria-invalid={statusType === 'error'}
          aria-labelledby={labelId}
          disabled={isDisabled}
          id={inputId}
          name={name}
          onChange={handleChange}
          ref={(instance) => {
            if (typeof ref === 'function') {
              ref(instance);
            } else if (ref) {
              ref.current = instance;
            }
          }}
          value={value}
        >
          {/* selects don't have a placeholder in HTML so this hidden option will create the same effect */}
          <option hidden={!allowEmptySelection} value="">
            {placeholder}
          </option>
          {/* the actual options will be mapped as normal, the hidden one cannot be selected */}
          {options.map(({ isHidden, label, value: optionValue }) => (
            <option hidden={isHidden} key={`${optionValue}_${label}`} value={optionValue}>
              {label}
            </option>
          ))}
        </Select>
        {!isDisabled && (
          <ArrowWrapper>
            <ChevronDownIconXs />
          </ArrowWrapper>
        )}
      </InputRow>
      {Boolean(statusLabel) && (
        <FieldFooter fieldFooterLabel={statusLabel} fieldFooterType={statusType} icon={icon} id={statusId} />
      )}
    </StyledSelectInput>
  );
};

export const SelectInput = forwardRef(SelectInputInner);
