import styled from '@lichtblick/styled';
import { Colors, MediaMediumAndAbove } from '@lichtblick/theme';
import { focusRing } from '@lichtblick/ui-components/atoms/shared/styles';

import { AImage } from '../AImage';

export const Image = styled(AImage)<{ $isBackground?: boolean }>`
  &,
  & > img {
    display: block;
    height: ${({ $isBackground }) => ($isBackground ? '100%' : 'auto')};
    object-fit: cover;
    width: 100%;
  }

  ${MediaMediumAndAbove} {
    display: none;
  }
`;

export const StyledPlaceholder = styled.button`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 1.75rem);
    left: calc(50% - 1.75rem);
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 1.75rem;
    background: ${Colors.Orange};
  }
  &:after {
    content: '';
    position: absolute;
    top: calc(50% - 0.6rem);
    left: calc(50% - 0.35rem);
    border-left: solid 1rem ${Colors.White};
    border-top: solid 0.6rem transparent;
    border-bottom: solid 0.6rem transparent;
  }

  &:hover {
    &:before,
    &:after {
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 11%), rgba(0, 0, 0, 11%));
    }
  }

  &:active {
    &:before,
    &:after {
      transform: scale(0.95);
    }
  }

  &:focus-visible {
    ${focusRing};
  }
`;

export const Video = styled.video<{ $isBackground?: boolean; $isVisible?: boolean }>`
  aspect-ratio: ${({ height, width }) => `${width} / ${height}`};
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  height: ${({ $isBackground }) => ($isBackground ? '100%' : 'auto')};
  object-fit: cover;
  width: 100%;
`;
