import { IconName } from '@lichtblick/icons';
import { BarGraphIconXs } from '@lichtblick/icons/svg/bar-graph/bar-graph-xs';
import { ChargingStationIconXs } from '@lichtblick/icons/svg/charging-station/charging-station-xs';
import { ClimateElectionIconXs } from '@lichtblick/icons/svg/climate-election/climate-election-xs';
import { ClimateReportIconXs } from '@lichtblick/icons/svg/climate-report/climate-report-xs';
import { CrowdfundingIconXs } from '@lichtblick/icons/svg/crowdfunding/crowdfunding-xs';
import { ElectricityIconXs } from '@lichtblick/icons/svg/electricity/electricity-xs';
import { GasIconXs } from '@lichtblick/icons/svg/gas/gas-xs';
import { GridIconXs } from '@lichtblick/icons/svg/grid/grid-xs';
import { HeartIconXs } from '@lichtblick/icons/svg/heart/heart-xs';
import { HeatPumpIconXs } from '@lichtblick/icons/svg/heat-pump/heat-pump-xs';
import { HeatingEnergyIconXs } from '@lichtblick/icons/svg/heating-energy/heating-energy-xs';
import { LineGraphIconXs } from '@lichtblick/icons/svg/line-graph/line-graph-xs';
import { LogoIconIconXs } from '@lichtblick/icons/svg/logo-icon/logo-icon-xs';
import { PodcastIconXs } from '@lichtblick/icons/svg/podcast/podcast-xs';
import { PressIconXs } from '@lichtblick/icons/svg/press/press-xs';
import { SolarIconXs } from '@lichtblick/icons/svg/solar/solar-xs';
import { SpeechBubbleIconXs } from '@lichtblick/icons/svg/speech-bubble/speech-bubble-xs';
import { StorageHeaterIconXs } from '@lichtblick/icons/svg/storage-heater/storage-heater-xs';
import { TreeLandscapeIconXs } from '@lichtblick/icons/svg/tree-landscape/tree-landscape-xs';
import { UserIconXs } from '@lichtblick/icons/svg/user/user-xs';
import { WallboxIconXs } from '@lichtblick/icons/svg/wallbox/wallbox-xs';
import { WorldIconXs } from '@lichtblick/icons/svg/world/world-xs';

export const navigationItemIcons: Partial<Record<IconName, React.FC>> = {
  LogoIconIconXs: LogoIconIconXs,
  SpeechBubbleIconXs: SpeechBubbleIconXs,
  UserIconXs: UserIconXs,
  ElectricityIconXs: ElectricityIconXs,
  GasIconXs: GasIconXs,
  HeatingEnergyIconXs: HeatingEnergyIconXs,
  SolarIconXs: SolarIconXs,
  WallboxIconXs: WallboxIconXs,
  ChargingStationIconXs: ChargingStationIconXs,
  LineGraphIconXs: LineGraphIconXs,
  BarGraphIconXs: BarGraphIconXs,
  TreeLandscapeIconXs: TreeLandscapeIconXs,
  PressIconXs: PressIconXs,
  CrowdfundingIconXs: CrowdfundingIconXs,
  HeartIconXs: HeartIconXs,
  StorageHeaterIconXs: StorageHeaterIconXs,
  HeatPumpIconXs: HeatPumpIconXs,
  ClimateReportIconXs: ClimateReportIconXs,
  ClimateElectionIconXs: ClimateElectionIconXs,
  GridIconXs: GridIconXs,
  WorldIconXs: WorldIconXs,
  PodcastIconXs: PodcastIconXs,
};

export const NavigationItemIcons: React.FC<{ name: keyof typeof navigationItemIcons }> = ({ name }) => {
  const Icon = navigationItemIcons[name];

  return Icon ? <Icon /> : null;
};
