import styled, { PickTransient } from '@lichtblick/styled';
import { Breakpoints, Colors, MediaMediumAndAbove, Spacing } from '@lichtblick/theme';
import { Container } from '@lichtblick/ui-components';

import { CalloutBadgeType } from '../../../../../types';

export const minCalloutBadgeHeight = 700;

export const CalloutBadgeContainer = styled(Container).attrs({ as: 'div' })`
  position: relative;
`;

export const SolarCalloutBadge = styled.div<PickTransient<CalloutBadgeType, 'diameter'>>`
  z-index: 2;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $diameter }) => ($diameter ? `${$diameter}px` : '94px')};
  height: ${({ $diameter }) => ($diameter ? `${$diameter}px` : '94px')};
  top: ${Spacing.Xs};
  left: ${Spacing.Xs};
  right: ${Spacing.Xs};
  background: ${Colors.Orange};
  border-radius: 50%;
  /* stylelint-disable-next-line meowtec/no-px */
  font-size: 10px;
  hyphenate-character: '';
  text-align: center;
  /* Hide when vertical screen hight becomes to narrow */
  @media (max-height: ${minCalloutBadgeHeight - 1}px) and (max-width: ${Breakpoints.Medium - 1}px) {
    display: none;
  }
  ${MediaMediumAndAbove} {
    left: auto;
    right: ${Spacing.Xs};
    top: ${Spacing.Xl};
    transform: translateX(-50%);
  }
`;

export const SolarCalloutBadgeText = styled.p`
  line-height: 175%;
  font-feature-settings: 'zero' off;
`;

export const SolarCalloutBadgeTextBold = styled.b`
  display: block;
  /* stylelint-disable-next-line meowtec/no-px */
  font-size: 26px;
  font-weight: bold;
  line-height: 110%;
`;
