import { forwardRef, ReactNode } from 'react';

import { Checkbox, CheckboxLabel, Wrapper } from './Checkbox.styles';

import { CheckboxElementProps } from '../../atoms/Checkbox/Checkbox';
import { FieldFooter } from '../FieldFooter';

type BaseProps = Omit<CheckboxElementProps, 'value' | 'disabled'> & {
  'data-testid'?: string;
  hasBoldLabel?: boolean;
  hasError?: boolean;
  isDisabled?: boolean;
  statusLabel?: string;
  value?: string | number | readonly string[];
};

export type CheckboxInputProps =
  | (BaseProps & { label: string; labelSlot?: never })
  | (BaseProps & { label?: never; labelSlot: ReactNode });

export const CheckboxInput = forwardRef<HTMLInputElement, CheckboxInputProps>(
  (
    {
      'data-testid': dataTestId,
      hasBoldLabel,
      hasError,
      isDisabled,
      label,
      labelSlot,
      name,
      statusLabel,
      value,
      ...props
    },
    ref,
  ) => {
    const id = value ? `${name}_${value}` : name;

    return (
      <Wrapper data-testid={dataTestId}>
        <Checkbox
          disabled={isDisabled}
          id={id}
          name={name}
          value={value}
          {...props}
          $state={hasError ? 'error' : isDisabled ? 'disabled' : 'default'}
          ref={ref}
        />
        <CheckboxLabel $isDisabled={isDisabled} htmlFor={id} isBold={hasBoldLabel}>
          {label ?? labelSlot}
          <FieldFooter fieldFooterLabel={statusLabel} fieldFooterType={hasError ? 'error' : 'default'} />
        </CheckboxLabel>
      </Wrapper>
    );
  },
);
