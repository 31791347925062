import styled, { keyframes, css } from '@lichtblick/styled';
import { Spacing } from '@lichtblick/theme';

const ANIMATION_HEIGHT = '250px';
const ANIMATION_SPEED = '150ms';

const openContent = keyframes`
    0% { max-height: 0; }
   99% { max-height: ${ANIMATION_HEIGHT}; }
  100% { max-height: none; }
`;

export const Root = styled.li`
  list-style: none;
`;

export const Content = styled.div<{ $isOpen: boolean }>`
  max-height: 0;

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          visibility: visible;
          animation: ${openContent} ${ANIMATION_SPEED} ease-in forwards;
        `
      : css`
          visibility: hidden;
        `};

  overflow: hidden;
`;

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: ${Spacing.Xs};
`;
