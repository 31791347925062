import { FC } from 'react';

import { Number1IconXl } from '@lichtblick/icons/svg/number-1/number-1-xl';
import { Number2IconXl } from '@lichtblick/icons/svg/number-2/number-2-xl';
import { Number3IconXl } from '@lichtblick/icons/svg/number-3/number-3-xl';
import { Number4IconXl } from '@lichtblick/icons/svg/number-4/number-4-xl';
import { Spacing } from '@lichtblick/theme';
import { Headline, Box } from '@lichtblick/ui-components';

import { Steps, Step } from './Steps';

import { richTextMapper } from '../../../helpers';
import { FourStepsType } from '../../../types';
import { getColor } from '../../../utils';
import { AButton } from '../../AButton';
import { Section, Container } from '../shared';

const icons = [Number1IconXl, Number2IconXl, Number3IconXl, Number4IconXl];

export const FourSteps: FC<FourStepsType> = ({ button, steps, theme = 'white', title }) => (
  <Section backgroundColor={getColor(theme)}>
    <Container maxWidth={600} textAlign="center">
      {title &&
        richTextMapper(title, {
          p: ({ children }) => (
            <Headline renderAs="h2" size="M">
              {children}
            </Headline>
          ),
        })}
    </Container>
    <Container>
      {steps &&
        richTextMapper(steps, {
          ol: ({ children }) => <Steps>{children}</Steps>,
          li: ({ children, i }) => {
            return children ? <Step backgroundColor={getColor(theme)} icon={icons[i]} text={children} /> : null;
          },
        })}
      {Boolean(button?.length) && (
        <Box display="flex" justifyContent="center" py={Spacing.S}>
          <AButton {...button[0]} />
        </Box>
      )}
    </Container>
  </Section>
);
