import styled from '@lichtblick/styled';
import { alpha, Colors, Opacities, Spacing } from '@lichtblick/theme';

import { Checkbox as CheckboxBase } from '../../atoms/Checkbox/Checkbox';
import { Label } from '../Label';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Checkbox = styled(CheckboxBase)`
  margin-top: 0.35rem;
`;

export const CheckboxLabel = styled(Label)<{ $isDisabled?: boolean }>`
  align-items: flex-start;
  color: ${({ $isDisabled }) => alpha(Colors.Black, $isDisabled ? Opacities.Disabled : 1)};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding-left: ${Spacing.Xxs};
`;
