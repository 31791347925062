import { Colors } from './colors';

export type ColorMode = 'default' | 'light' | 'dark' | 'faded';
type Palette = Record<ColorMode, Colors>;

export const ColorPalette: Record<string, Palette> = {
  Aqua: {
    default: Colors.Aqua,
    dark: Colors.DarkAqua,
    light: Colors.LightAqua,
    faded: Colors.FadedAqua,
  },
  Berry: {
    default: Colors.Berry,
    dark: Colors.DarkBerry,
    faded: Colors.FadedBerry,
    light: Colors.LightBerry,
  },
  Gray: {
    default: Colors.Gray,
    dark: Colors.DarkGray,
    faded: Colors.FadedGray,
    light: Colors.LightGray,
  },
  Green: {
    default: Colors.Green,
    dark: Colors.DarkGreen,
    faded: Colors.FadedGreen,
    light: Colors.LightGreen,
  },
  Moss: {
    default: Colors.Moss,
    dark: Colors.DarkMoss,
    faded: Colors.FadedMoss,
    light: Colors.LightMoss,
  },
  Red: {
    default: Colors.Red,
    dark: Colors.DarkRed,
    faded: Colors.FadedRed,
    light: Colors.LightRed,
  },
  Smoke: {
    default: Colors.Smoke,
    dark: Colors.DarkSmoke,
    faded: Colors.FadedSmoke,
    light: Colors.LightSmoke,
  },
};
