import { FC, useState } from 'react';

import { Icon } from '@lichtblick/icons';
import { AccentText, Text } from '@lichtblick/ui-components';

import { Caption, Card, CloseIcon, Description, Media, PlusIcon, Toggle } from './MFactItem.styles';

import { componentMapper } from '../../helpers/componentMapper';
import { ElementMappers, richTextMapper } from '../../helpers/richTextMapper';
import { MFactItemType } from '../../types/storyblok';
import { ColorKey, colorMap } from '../../utils';

const elementMappers: Partial<ElementMappers> = {
  p: ({ children }) => <span>{children}</span>,
  b: ({ children }) => <AccentText size="L">{children}</AccentText>,
};

export const MFactItem: FC<MFactItemType & { backgroundColor: ColorKey }> = ({
  backgroundColor,
  caption,
  description,
  media,
}) => {
  const [isToggled, setIsToggled] = useState(false);

  return (
    <div data-testid="fact-item">
      <Card $backgroundColor={colorMap[backgroundColor]} $isToggled={isToggled}>
        <Toggle aria-label="Beschreibung anzeigen" onClick={() => setIsToggled(!isToggled)} role="button" />

        <PlusIcon>
          <Icon name="OpenIconS" />
        </PlusIcon>

        <Media $isToggled={isToggled}>{media?.[0] && componentMapper(media[0])}</Media>
        <Description $backgroundColor={colorMap[backgroundColor]} $isToggled={isToggled}>
          <CloseIcon>
            <Icon name="CloseIconS" />
          </CloseIcon>
          <Text>{description}</Text>
        </Description>
      </Card>
      <Caption size="M">{richTextMapper(caption, elementMappers)}</Caption>
    </div>
  );
};
