import Script from 'next/script';
import { FC } from 'react';

import { Colors, Spacing } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

import { CarCalculatorApp, LoaderContainer, StyledLoader } from './OCarCalculator.styles';
import { OCarCalculatorConfig } from './OCarCalculatorConfig';

import { OCarCalculatorType } from '../../types/storyblok';
import { getSpacing } from '../../utils';
import { GridContainer, GridRow } from '../shared';

export const OCarCalculator: FC<OCarCalculatorType> = ({
  _uid,
  anchorId,
  paddingBottomDesktop,
  paddingBottomMobile,
  paddingTopDesktop,
  paddingTopMobile,
}) => {
  const uid = `car-calculator-${_uid}`;
  const configId = `config_${uid}`;

  return (
    <>
      {/* temporary solution until car calculator is rewritten for NextJS */}
      <Script async src="https://static.lichtblick.de/web-components/base.4a4201e56a0f4b1a9061bab7b83e9c09.js" />
      <Script
        async
        src="https://static.lichtblick.de/web-components/modal_dialog.e3184c013c275b9ee2b0088fd78846fe.js"
      />
      <OCarCalculatorConfig configId={configId} />
      <Box
        backgroundColor={Colors.DarkSmoke}
        data-testid="car-calculator"
        id={anchorId ?? undefined}
        pbd={getSpacing(paddingBottomDesktop) ?? Spacing.M}
        pbm={getSpacing(paddingBottomMobile) ?? Spacing.Xs}
        ptd={getSpacing(paddingTopDesktop) ?? Spacing.M}
        ptm={getSpacing(paddingTopMobile) ?? Spacing.Xs}
      >
        <GridContainer>
          <GridRow>
            <CarCalculatorApp data-config-id={configId} id={uid}>
              <LoaderContainer>
                <StyledLoader />
              </LoaderContainer>
            </CarCalculatorApp>
          </GridRow>
        </GridContainer>
      </Box>
    </>
  );
};
