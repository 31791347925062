/**
 * Brand 2.0 color shades
 */
export enum Shades {
  Orange100 = '#FFF5EA',
  Orange200 = '#FFDDBA',
  Orange300 = '#FCBC67',
  Orange400 = '#FFA137',
  Orange500 = '#DB7922',
  Orange600 = '#A14D0F',
  Orange700 = '#783608',
  Orange800 = '#512508',
  Orange900 = '#391A06',
  Red100 = '#FFF2F2',
  Red200 = '#FCC8C6',
  Red300 = '#F89D99',
  Red400 = '#F47771',
  Red500 = '#EA3B32',
  Red600 = '#B9312A',
  Red700 = '#982B23',
  Red800 = '#661A12',
  Red900 = '#47120D',
  Berry100 = '#FCF6FA',
  Berry200 = '#F9E3EA',
  Berry300 = '#F0BACA',
  Berry400 = '#E398AC',
  Berry500 = '#E17895',
  Berry600 = '#B84163',
  Berry700 = '#9C2D4D',
  Berry800 = '#820E38',
  Berry900 = '#5B0A27',
  Aqua100 = '#ECFCFF',
  Aqua200 = '#CEF0F8',
  Aqua300 = '#8DD7EA',
  Aqua400 = '#60BAD3',
  Aqua500 = '#4B97B6',
  Aqua600 = '#2A6D8F',
  Aqua700 = '#084465',
  Aqua800 = '#012d4E',
  Aqua900 = '#012037',
  Smoke100 = '#F4F6F9',
  Smoke200 = '#DCE3EB',
  Smoke300 = '#B9C8D7',
  Smoke400 = '#96ACC3',
  Smoke500 = '#758EA5',
  Smoke600 = '#4C6278',
  Smoke700 = '#2D3D4B',
  Smoke800 = '#19212A',
  Smoke900 = '#11181D',
  Gray100 = '#F6F6F6',
  Gray200 = '#E2E2E2',
  Gray300 = '#C6C6C6',
  Gray400 = '#ABABAB',
  Gray500 = '#888888',
  Gray600 = '#616161',
  Gray700 = '#3B3B3B',
  Gray800 = '#181818',
  Gray900 = '#111111',
  Green100 = '#EEFAF4',
  Green200 = '#C5EDD8',
  Green300 = '#8BDBB0',
  Green400 = '#65C293',
  Green500 = '#529D77',
  Green600 = '#367056',
  Green700 = '#1A4837',
  Green800 = '#142A20',
  Green900 = '#0E1D16',
  Moss100 = '#F1F7F5',
  Moss200 = '#D6E5E1',
  Moss300 = '#ACCCC4',
  Moss400 = '#85B4A9',
  Moss500 = '#61847F',
  Moss600 = '#496463',
  Moss700 = '#334747',
  Moss800 = '#1C2723',
  Moss900 = '#141C18',
  Sand100 = '#F8F6F2',
  Sand200 = '#E9E2D7',
  Sand300 = '#D2C5B1',
  Sand400 = '#B7AA95',
  Sand500 = '#968A7A',
  Sand600 = '#686256',
  Sand700 = '#504B43',
  Sand800 = '#2C2A25',
  Sand900 = '#201D1A',
  White = '#FFFFFF',
  Black = '#000000',
}

export class ColorShades {
  readonly 100: Shades;
  readonly 200: Shades;
  readonly 300: Shades;
  readonly 400: Shades;
  readonly 500: Shades;
  readonly 600: Shades;
  readonly 700: Shades;
  readonly 800: Shades;
  readonly 900: Shades;
  readonly contrast?: Shades;
  readonly default?: Shades;

  constructor(
    private defaultShade: keyof typeof Shades,
    contrastShade?: keyof typeof Shades,
  ) {
    const color = defaultShade.toString().replace(/\d+$/, '');
    const keys = [100, 200, 300, 400, 500, 600, 700, 800, 900] as const;

    for (const key of keys) {
      this[key] = Shades[`${color}${key}` as keyof typeof Shades] ?? Shades[defaultShade];
    }

    this.default = contrastShade && Shades[defaultShade];
    this.contrast = contrastShade && Shades[contrastShade];
  }

  toString() {
    return Shades[this.defaultShade];
  }
}

/**
 * Brand 2.0 color set
 */
export const BrandColors = {
  Orange: new ColorShades('Orange500'),
  Red: new ColorShades('Red500'),
  Berry: new ColorShades('Berry500'),
  Aqua: new ColorShades('Aqua500'),
  Smoke: new ColorShades('Smoke500'),
  Gray: new ColorShades('Gray500'),
  Green: new ColorShades('Green500'),
  Moss: new ColorShades('Moss500'),
  Sand: new ColorShades('Sand500'),
  White: new ColorShades('White', 'Black'),
  Black: new ColorShades('Black', 'White'),
} as const;
