import { ComponentPropsWithRef, forwardRef } from 'react';

import { RadioButton, RadioLabel, Wrapper } from './RadioButton.styles';

import { FieldFooter } from '../FieldFooter';

export type RadioInputProps = Omit<ComponentPropsWithRef<'input'>, 'value' | 'name' | 'disabled'> & {
  hasBoldLabel?: boolean;
  hasError?: boolean;
  isDisabled?: boolean;
  label: string;
  name: string;
  statusLabel?: string;
  value: boolean | number | string;
};

export const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  ({ className, hasBoldLabel, hasError, isDisabled, label, name, statusLabel, value, ...props }, ref) => (
    <Wrapper className={className}>
      <RadioButton
        disabled={isDisabled}
        id={`${name}-${value}`}
        name={name}
        type="radio"
        value={value.toString()}
        {...props}
        $state={hasError ? 'error' : isDisabled ? 'disabled' : 'default'}
        ref={ref}
      />
      <RadioLabel $isDisabled={isDisabled} htmlFor={`${name}-${value}`} isBold={hasBoldLabel}>
        {label}
        <FieldFooter fieldFooterLabel={statusLabel} fieldFooterType={hasError ? 'error' : 'default'} />
      </RadioLabel>
    </Wrapper>
  ),
);
