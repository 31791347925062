import { ReactNode } from 'react';

import { Text } from '@lichtblick/ui-components';

import { StyledHeadline } from './MTextIntro.styles';

import { richTextMapper } from '../../helpers';
import { RichTextDocType } from '../../types/storyblok';

export const buildSubline = (json: RichTextDocType): ReactNode =>
  richTextMapper(json, {
    h1: ({ children }) => (
      <StyledHeadline renderAs="h1" size="S">
        {children}
      </StyledHeadline>
    ),
    h3: ({ children }) => (
      <StyledHeadline renderAs="h3" size="S">
        {children}
      </StyledHeadline>
    ),
    p: ({ children }) => (
      <Text renderAs="p" size="M">
        {children}
      </Text>
    ),
  });
