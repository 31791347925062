import { FC } from 'react';

import { useTracker } from '@lichtblick/tracker';

import { AppleBadge, GoogleBadge, Wrapper } from './AppBadges.styles';
import playstoreBadge from './google-play-badge.png';

export type AppBadgesProps = {
  appStoreUrl?: string;
  className?: string;
  playStoreUrl?: string;
};

type AppStore = 'Android' | 'IOS';

export const AppBadges: FC<AppBadgesProps> = ({ appStoreUrl, className, playStoreUrl }) => {
  const { trackCustomEvent } = useTracker();

  const trackEvent = (appStore: AppStore) => {
    trackCustomEvent(
      {
        eventName: 'app_link',
        customProps: {
          link_type: appStore,
        },
      },
      undefined,
      false,
    );
  };

  return (
    <Wrapper className={className}>
      {appStoreUrl && (
        <a
          aria-label="Im Apple App Store"
          href={appStoreUrl}
          onClick={() => trackEvent('IOS')}
          rel="noopener noreferrer"
          target="_blank"
        >
          <AppleBadge />
        </a>
      )}
      {playStoreUrl && (
        <a
          aria-label="Im Google Play Store ansehen"
          href={playStoreUrl}
          onClick={() => trackEvent('Android')}
          rel="noopener noreferrer"
          target="_blank"
        >
          <GoogleBadge alt="Google Play Store Badge" loading="lazy" src={playstoreBadge.src} />
        </a>
      )}
    </Wrapper>
  );
};
