import styled, { css } from '@lichtblick/styled';
import { BorderRadius, Colors, Spacing } from '@lichtblick/theme';

import { Text } from '../../atoms/Text/Text';

export const Wrapper = styled.div`
  border: 1px solid ${Colors.LightGray};
  border-radius: ${BorderRadius.S};
  display: flex;
  flex-direction: column;
  padding: ${Spacing.Xs};
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const Titles = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Rows = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing['3Xs']};
`;

export const Row = styled.div<{ $isHeadline?: boolean }>`
  display: flex;
  justify-content: space-between;
  ${({ $isHeadline }) =>
    !$isHeadline &&
    css`
      &:before {
        background-image: linear-gradient(to right, ${Colors.Gray} 1px, ${Colors.White} 1px);
        background-position: top;
        background-repeat: repeat-x;
        background-size: ${Spacing['3Xs']} 1px;
        bottom: 0.5em;
        content: '';
        height: 1px;
        position: absolute;
        width: 100%;
      }
    `}
  overflow: auto;
  position: relative;
`;

export const RowHeadline = styled(Text).attrs({ isBold: true, size: 'S' })`
  margin-top: ${Spacing.Xs};
`;

const sharedTextStyles = css`
  background-color: ${Colors.White};
  z-index: 1;
`;

export const Label = styled(Text).attrs({ size: 'S' })`
  ${sharedTextStyles};
  padding-right: ${Spacing['3Xs']};
`;

export const Value = styled(Text).attrs({ size: 'S' })`
  color: ${Colors.DarkGray};
  ${sharedTextStyles};
  padding-left: ${Spacing['3Xs']};
`;
