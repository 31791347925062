import { ElementType, FC, ReactNode } from 'react';

import { StyledHeadline } from './Headline.styles';

import type { Property } from 'csstype';

/**
 * Responsive size classes:
 * https://www.figma.com/file/A0m8tFZk3yHpbiCtmlCXfb/%5BPhoton%5D-Typography-(Web)?node-id=2%3A32&t=jtIHhBKNgXKstYE0-1
 */
export type HeadlineSizeClass = 'M' | 'L' | 'S';

export type HeadlineProps = {
  align?: Property.TextAlign;
  children?: ReactNode;
  className?: string;
  renderAs?: ElementType;
  /**
   * L = mobile 32px | desktop 64px;
   * M = mobile 26px | desktop 36px;
   * S = mobile 20px | desktop 24px;
   */
  size?: HeadlineSizeClass;
};

export const Headline: FC<HeadlineProps> = ({ align, children, className, renderAs, size }) => (
  <StyledHeadline $align={align} $size={size} as={renderAs} className={className}>
    {children}
  </StyledHeadline>
);
