import Script from 'next/script';
import { FC } from 'react';

import { Spacing } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

import { LoaderContainer, StyledLoader, StyledTextIntro } from './OForm.styles';
import { OFormConfig } from './OFormConfig';
import { FormType, getIdFromFormType, useMaintenanceMode } from './OFormHelper';
import { OFormIcons } from './OFormIcons';

import { OFormType } from '../../types/storyblok';
import { getColor } from '../../utils';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

const themeClassNames = { white: 'theme--white', 'faded-gray': 'theme--faded-gray' } as const;

export const OForm: FC<OFormType> = ({ _uid, anchorId, dynamicsCampaignId, formType, textIntro, theme }) => {
  const configId = `config_${_uid}`;
  const selectorId = getIdFromFormType(formType, _uid);
  const { scope: maintenanceModeScope, textIntro: maintenanceModeTextIntro } = useMaintenanceMode(formType);

  if (maintenanceModeScope === 'all') {
    return (
      <Box
        backgroundColor={getColor('faded-gray')}
        data-testid="form"
        pbd={Spacing['3Xl']}
        pbm={Spacing.Xxl}
        ptd={Spacing['3Xl']}
        ptm={Spacing.Xxl}
      >
        {Boolean(maintenanceModeTextIntro) && (
          <GridContainer>
            <GridRow>
              <GridColumn $push={2} $width={8}>
                <StyledTextIntro {...maintenanceModeTextIntro} isCenteredDesktop isCenteredMobile />
              </GridColumn>
            </GridRow>
          </GridContainer>
        )}
      </Box>
    );
  }

  const textIntroProps = maintenanceModeTextIntro || textIntro?.[0];

  return (
    <>
      {/* temporary solution until permission center is rewritten for NextJS */}
      {formType === FormType.PermissionCenter && (
        <>
          <Script async src="https://static.lichtblick.de/web-components/base.4a4201e56a0f4b1a9061bab7b83e9c09.js" />
          <Script
            async
            src="https://static.lichtblick.de/web-components/toggle_switch.af4e12a42eaae4d46b497883a8cba60c.js"
          />
        </>
      )}
      {formType === FormType.ContractCancellation && (
        <>
          <Script async src="https://static.lichtblick.de/web-components/base.4a4201e56a0f4b1a9061bab7b83e9c09.js" />
          <Script
            async
            src="https://static.lichtblick.de/web-components/modal_dialog.e3184c013c275b9ee2b0088fd78846fe.js"
          />
        </>
      )}
      <OFormConfig configId={configId} />
      <OFormIcons />
      <Box
        backgroundColor={getColor(theme)}
        className={themeClassNames[theme]}
        data-testid="form"
        pbd={Spacing.Xl}
        pbm={Spacing.M}
        ptd={Spacing.Xl}
        ptm={Spacing.M}
      >
        {anchorId && <AnchorMark anchorId={anchorId} />}

        <GridContainer>
          <>
            {textIntroProps && (
              <GridRow>
                <GridColumn $push={2} $width={8}>
                  <StyledTextIntro {...textIntroProps} isCenteredDesktop isCenteredMobile />
                </GridColumn>
              </GridRow>
            )}
          </>

          {formType && (
            <GridRow>
              <GridColumn $push={2} $width={8}>
                <div
                  data-boxed="true"
                  data-campaign-id={dynamicsCampaignId ?? undefined}
                  data-config-id={configId}
                  data-form-id={formType}
                  id={selectorId}
                >
                  <LoaderContainer>
                    <StyledLoader />
                  </LoaderContainer>
                </div>
              </GridColumn>
            </GridRow>
          )}
        </GridContainer>
      </Box>
    </>
  );
};
