import { Icon } from '@lichtblick/icons';
import styled from '@lichtblick/styled';
import { MediaMediumAndAbove, MediaBelowMedium, Spacing } from '@lichtblick/theme';
import { Headline } from '@lichtblick/ui-components';

import { AText } from '../AText';
import { GridColumn } from '../shared';

export const StyledGridColumn = styled(GridColumn)`
  display: flex;
  justify-content: center;

  ${MediaBelowMedium} {
    justify-content: flex-start;
    padding-bottom: ${Spacing.Xs};
  }
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${Spacing.Xxs};

  ${MediaMediumAndAbove} {
    margin-bottom: ${Spacing.Xs};
  }
`;

export const StyledText = styled(AText)`
  margin-bottom: ${Spacing.Xs};
`;

export const StyledIcon = styled(Icon)<{ $color: string }>`
  color: ${({ $color }) => $color};
`;
