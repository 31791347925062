'use client';
import { UcDomains } from '@usercentrics/cmp-browser-sdk/dist/src/interfaces';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { ConsentManager } from './ConsentManager';
import { ConsentManagerType } from './ConsentManager.types';

type ConsentContext = Pick<ConsentManagerType, 'getConsentStatus' | 'getControllerId' | 'isUcInitialized'>;

const ConsentManagerContext = createContext<ConsentContext | null>(null);

export const useConsentManager = () => {
  const context = useContext(ConsentManagerContext);

  if (!context) {
    throw new Error('No consent context provided.');
  }

  return context;
};

export const ConsentManagerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [_, setIsInitialized] = useState(false);
  const [{ getConsentStatus, getControllerId, initPromise, isUcInitialized }] = useState(() => new ConsentManager());

  useEffect(() => {
    window.UC_UI_DOMAINS = {
      crossDomainConsentSharingIFrame: 'https://www.lichtblick.de/cross-domain-bridge.html',
    } as UcDomains;

    const updateOnInitialization = async () => {
      await initPromise;

      setIsInitialized(true);
    };

    updateOnInitialization();
  }, [initPromise]);

  const context: ConsentContext = useMemo(
    () => ({
      isUcInitialized: isUcInitialized,
      getConsentStatus: getConsentStatus,
      getControllerId: getControllerId,
    }),
    [getConsentStatus, getControllerId, isUcInitialized],
  );

  return <ConsentManagerContext.Provider value={context}>{children}</ConsentManagerContext.Provider>;
};
