import styled from '@lichtblick/styled';
import { BorderRadius, MediaMediumAndAbove, Spacing } from '@lichtblick/theme';
import { Headline } from '@lichtblick/ui-components';

import { GridContainer, GridRow } from '../shared';

export const StyledGridRow = styled(GridRow)`
  &:only-child {
    margin: 0;
  }

  border-radius: ${BorderRadius.M};

  ${GridContainer} > &:only-child {
    padding: ${Spacing.M} ${Spacing.Xs};

    ${MediaMediumAndAbove} {
      padding: ${Spacing.M} 0;
    }
  }

  padding: ${Spacing.M} ${Spacing.Xs};

  ${MediaMediumAndAbove} {
    padding: ${Spacing.M} 0;
  }
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${Spacing.Xs};
`;
