import { Breakpoints } from '@lichtblick/theme';

const BREAKPOINTS = [Breakpoints.Max, Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, 480, 360];

export const isSvgImage = (filename: string): boolean => filename.endsWith('.svg');

export const getImageDimensions = (filename: string): [number, number] | undefined =>
  /\/(\d+)x(\d+)\//g.exec(filename)?.splice(1, 2).map(Number) as [number, number];

export const buildImageSrcSet = (imageUrl: string, imageWidth: number, params?: Record<string, string>) => {
  const ratio1Url = `${imageUrl}/m/${imageWidth}x0?${new URLSearchParams(params)}`;
  const ratio2Url = `${imageUrl}/m/${
    1.5 * BREAKPOINTS[BREAKPOINTS.findIndex((i) => i === imageWidth) + 1] || imageWidth
  }x0?${new URLSearchParams(params)}`;

  return `${ratio1Url} 1x, ${ratio2Url} 2x`;
};

export const generateImageSources = (
  imageUrl: string,
  maxWidth: number,
  params?: Record<string, string>,
): JSX.Element[] => {
  const sourceElements: JSX.Element[] = [];

  // Generates for all breakpoints smaller than imageWidth the image sources
  // with a min-width media query according to the current breakpoint
  // and an image width of the next larger breakpoint.
  BREAKPOINTS.forEach((breakpoint, index) => {
    if (maxWidth > breakpoint) {
      const width = Math.min(maxWidth, BREAKPOINTS[index ? index - 1 : 0]);
      const url = buildImageSrcSet(imageUrl, width, params);
      const webPUrl = buildImageSrcSet(imageUrl, width, params);
      const mediaQuery = `(min-width: ${breakpoint}px)`;
      const sourceWebP = <source key={`${breakpoint}_webp`} media={mediaQuery} srcSet={webPUrl} type="image/webp" />;
      const source = <source key={breakpoint} media={mediaQuery} srcSet={url} />;

      sourceElements.push(sourceWebP, source);
    }
  });

  return sourceElements;
};
