import NextLink from 'next/link';
import { AnchorHTMLAttributes, FC, PropsWithChildren, useContext } from 'react';

import { ExternalPathsContext } from './externalPathsContext';
import { isExternalPath } from './isExternalPath';

type LinkProps = PropsWithChildren &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> & { className?: string; href: string };

export const Link: FC<LinkProps> = ({ children, href, ...props }) => {
  const externalPaths = useContext(ExternalPathsContext);

  if (
    !externalPaths ||
    (!isExternalPath(`${href.split('?')[0].replace(/^https:\/\/www\.lichtblick\.de/, '')}`, externalPaths) &&
      !isExternalPath(`${href.split('#')[0].replace(/^https:\/\/www\.lichtblick\.de/, '')}`, externalPaths))
  ) {
    return (
      <NextLink {...props} href={href} prefetch={false}>
        {children}
      </NextLink>
    );
  }

  return (
    <a {...props} href={href}>
      {children}
    </a>
  );
};
