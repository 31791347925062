import { FC, PropsWithChildren } from 'react';

import { HomeLink, RichTextContainer, Root } from './BrandText.styles';

import { Logo } from '../../atoms/Logo/Logo';

type BrandTextProps = PropsWithChildren;

export const BrandText: FC<BrandTextProps> = ({ children }) => (
  <Root>
    <HomeLink aria-label="Zur Startseite" href="/">
      <Logo />
    </HomeLink>
    <RichTextContainer>{children}</RichTextContainer>
  </Root>
);
