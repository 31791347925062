import { useRouter } from 'next/router';
import { FC, FormEventHandler, useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { AlertIconXs } from '@lichtblick/icons/svg/alert/alert-xs';
import { Colors } from '@lichtblick/theme';
import { useTracker } from '@lichtblick/tracker';
import { FieldFooter } from '@lichtblick/ui-components';
import { BoxCTA } from '@lichtblick/ui-components/atoms/BoxCTA/BoxCTA';
import { CheckboxInput } from '@lichtblick/ui-components/molecules/Checkbox/CheckboxInput';
import { Textfield } from '@lichtblick/ui-components/molecules/Textfield/Textfield';
import { CheckGroup } from '@lichtblick/ui-components/organisms/CheckGroup/CheckGroup';

import { Form, StyledGridContainer } from './LeadForm.styles';
import { MarketingLegalText, marketingLegalText } from './LegalText';

import { LeadFormType } from '../../../types';
import { MarketingLeadInput } from '../../../types/generated.lichtblick';
import { AImage } from '../../AImage/AImage';
import { MTextIntro } from '../../MTextIntro/MTextIntro';
import { GridColumn, GridRow } from '../../shared/GridContainer/GridContainer';
import { Section } from '../shared/Section';

export const REQUEST_ID = uuidv4();
const DEFAULT_REQUIRED_ERROR_MESSAGE = 'Hier fehlt noch eine Angabe';
const DEFAULT_INVALID_ERROR_MESSAGE = 'Bitte überprüfe deine Angabe';

export const SERVER_URL = process.env.NEXT_PUBLIC_GRAPHQL_SERVER || '';

export const LeadForm: FC<LeadFormType> = ({ dynamicsID, image, keepImageAspectRatio, target, textIntro }) => {
  const { query } = useRouter();
  const webCampaignId = Array.isArray(query?.web_campaign_id) ? query.web_campaign_id[0] : query.web_campaign_id;
  const [hasSubmitError, setHasSubmitError] = useState(false);
  const [isLoadingMarketingLead, setIsLoadingMarketingLead] = useState(false);
  const [missingOptin, setMissingOptin] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string | undefined>(undefined);

  const { trackCustomEvent } = useTracker();

  const handleError = () => {
    setHasSubmitError(true);
    setIsLoadingMarketingLead(false);
  };
  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();

      const email = new FormData(event.currentTarget).get('email')?.toString();

      if (!target.cached_url) {
        return;
      }

      if (!email) {
        setEmailErrorMessage(DEFAULT_REQUIRED_ERROR_MESSAGE);

        return;
      }

      if (!/^([\w.%+-]+)@([\w-]+\.)+(\w{2,})$/.test(email)) {
        setEmailErrorMessage(DEFAULT_INVALID_ERROR_MESSAGE);

        return;
      }

      const lead: MarketingLeadInput = {
        email,
        contentId: dynamicsID,
        legalText: marketingLegalText,
        requestId: REQUEST_ID,
        target: target.cached_url,
        webCampaignId,
      };

      setIsLoadingMarketingLead(true);
      setHasSubmitError(false);

      import('../../../api/lichtblick/graphqlClient').then(({ sdk }) =>
        sdk
          .eaasCreateMarketingLead({ input: { lead } })
          .then((response) => {
            if (!response.eaasCreateMarketingLead.isSent) {
              handleError();

              return;
            }

            trackCustomEvent({
              eventName: 'form_submit_solar_ratgeber',
              customProps: { unique_id: REQUEST_ID, partner: true },
            });
            location.assign('/solar-ratgeber-danke/');
          })
          .catch(handleError),
      );
    },
    [dynamicsID, target.cached_url, webCampaignId, trackCustomEvent],
  );

  return (
    <Section>
      <StyledGridContainer $backgroundColor={Colors.FadedMoss}>
        <GridRow>
          <GridColumn $isAlignedMiddle={false} $width={6}>
            {image?.[0] && <AImage {...image[0]} isBackground={!keepImageAspectRatio} />}
          </GridColumn>
          <GridColumn $isAlignedMiddle={true} $width={6}>
            {textIntro?.[0] && <MTextIntro {...textIntro[0]} />}
            <Form onSubmit={onSubmit}>
              <Textfield
                label="Bitte gib deine E-Mail Adresse ein"
                name="email"
                onChange={(event) =>
                  setEmailErrorMessage(event.target.value ? undefined : DEFAULT_REQUIRED_ERROR_MESSAGE)
                }
                onInvalid={(e) => {
                  e.preventDefault();
                  setEmailErrorMessage(
                    e.currentTarget.value ? DEFAULT_INVALID_ERROR_MESSAGE : DEFAULT_REQUIRED_ERROR_MESSAGE,
                  );
                }}
                required
                statusLabel={emailErrorMessage}
                statusType={emailErrorMessage ? 'error' : 'default'}
                type="email"
              />
              <CheckGroup>
                <CheckboxInput
                  hasError={missingOptin}
                  labelSlot={<MarketingLegalText />}
                  name="optInMarketing"
                  onChange={(e) => setMissingOptin(!e.currentTarget.validity.valid)}
                  onInvalid={(e) => {
                    e.preventDefault();
                    setMissingOptin(!e.currentTarget.validity.valid);
                  }}
                  required
                  statusLabel={missingOptin ? DEFAULT_REQUIRED_ERROR_MESSAGE : undefined}
                />
              </CheckGroup>
              <BoxCTA as="button" isDisabled={isLoadingMarketingLead} isLoading={isLoadingMarketingLead} type="submit">
                Senden
              </BoxCTA>
              {Boolean(hasSubmitError) && (
                <FieldFooter
                  fieldFooterLabel="Beim Senden der Anfrage ist ein unerwarteter Fehler aufgetreten. Bitte versuche es später noch einmal."
                  fieldFooterType="error"
                  icon={AlertIconXs}
                />
              )}
            </Form>
          </GridColumn>
        </GridRow>
      </StyledGridContainer>
    </Section>
  );
};
