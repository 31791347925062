/* stylelint-disable meowtec/no-px */
import styled from '@lichtblick/styled';
import { BorderWidth, SmallAndAbove, Spacing } from '@lichtblick/theme';
import { Colors, StateColors } from '@lichtblick/theme/constants/colors';
import { Input } from '@lichtblick/ui-components/molecules/Textfield/Textfield.styles';

export const StyledCalculatorInputRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Spacing.Xxs};
  margin: ${Spacing.Xxs} 0 0 0;
  align-items: center;

  @media ${SmallAndAbove} {
    margin: ${Spacing.Xs} 0 0 0;
  }
`;

export const StyledCalculatorUsageInput = styled(Input).attrs({ type: 'number' })<{ $hasError?: boolean }>`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
  }

  appearance: none;
  border-radius: 4px;
  margin: 0 4px;
  padding: 0;
  height: 1.25rem;
  text-align: center;
  background: ${Colors.FadedGray};
  font-weight: bold;
  font-size: 0.75rem;
  min-width: 6ch !important;
  max-width: 8ch !important;

  &:focus-visible {
    border: ${BorderWidth.Input} solid ${StateColors.Focus};
  }

  ${({ $hasError }) => $hasError && `border: ${BorderWidth.Input} solid ${StateColors.Error};`}
`;
