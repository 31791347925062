import styled, { keyframes } from '@lichtblick/styled';
import { MediaSmallAndAbove, Colors } from '@lichtblick/theme';

const sunAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0) rotate(0.1deg);
  }
  50% {
    transform: translate3d(10%, -5%, 0) rotate(0.1deg);
  }
  100% {
    transform: translate3d(0, 0, 0) rotate(0.1deg);
  }
`;

export const Svg = styled.svg`
  width: 2.25rem;

  ${MediaSmallAndAbove} {
    width: 6.25rem;
  }
`;

export const Sun = styled.circle`
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: ${sunAnimation};
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  fill: ${Colors.Orange};
`;

export const Ring = styled.path<{ $isWhite?: boolean }>`
  fill: ${({ $isWhite }) => ($isWhite ? Colors.White : Colors.Black)};
`;
