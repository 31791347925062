import styled, { css } from '@lichtblick/styled';
import { MediaMediumAndAbove, Spacing } from '@lichtblick/theme';
import { Headline } from '@lichtblick/ui-components';

import { OStoryPageType } from '../../types';
import { ColorKey, getTheme } from '../../utils';

export const TextLayer = styled.div<{ $layout: Exclude<OStoryPageType['layout'], undefined>; $theme: ColorKey }>`
  color: ${({ $theme }) => getTheme($theme).primary};
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ $layout }) => {
    switch ($layout) {
      case 'lower-third':
        return css`
          justify-content: flex-end;
        `;
      case 'middle-third':
        return css`
          justify-content: center;
        `;
      default:
        return css`
          justify-content: flex-start;
        `;
    }
  }};
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${Spacing.Xxs};

  ${MediaMediumAndAbove} {
    margin-bottom: ${Spacing.Xs};
  }
`;
