import { ChangeEventHandler, useRef, forwardRef, useState, FocusEventHandler, useMemo, ReactNode } from 'react';

import { CrossCircleIconXs } from '@lichtblick/icons/svg/cross-circle/cross-circle-xs';
import { SearchIconXs } from '@lichtblick/icons/svg/search/search-xs';

import {
  IconWrapper,
  InputRow,
  Input,
  LabelWrapper,
  Slot,
  Wrapper,
  OptionContent,
  OptionLabel,
} from './FilterMultiSelect.styles';

import { Checkbox } from '../../atoms/Checkbox/Checkbox';
import { DropDownHandle } from '../../atoms/DropDown/DropDown';
import { doesValueMatchSearch } from '../ComboBoxInput/ComboBoxInput.helpers';
import { FieldFooter } from '../FieldFooter/FieldFooter';
import { FilterSelectOption } from '../FilterSelect/FilterSelect';
import { Label } from '../Label/Label';
import { TextfieldProps } from '../Textfield/Textfield';

export type FilterMultiSelectOption = Required<Omit<FilterSelectOption, 'label' | 'isHidden'>> & {
  label: ReactNode;
};

export type FilterMultiSelectProps = Omit<TextfieldProps, 'onChange' | 'value'> & {
  onChange?: ChangeEventHandler<HTMLInputElement & { value: string[] }>;
  options: FilterMultiSelectOption[];
  values?: string[];
};

/**
 * Displays the `placeholder` when not focussed. Use this to display a meaningful summary of the selected values.
 */
export const FilterMultiSelect = forwardRef<HTMLInputElement, FilterMultiSelectProps>(
  (
    {
      className,
      'data-testid': testId,
      icon,
      id,
      isDisabled,
      label,
      name,
      onChange,
      onFocus,
      options,
      statusLabel,
      statusType,
      values,
      ...props
    },
    ref,
  ) => {
    const [searchValue, setSearchValue] = useState('');
    const inputRef = useRef<HTMLInputElement | null>(null);
    const dropdownRef = useRef<DropDownHandle | null>(null);
    const inputId = id || name;
    const labelId = inputId && `${inputId}_label`;
    const statusId = inputId && `${inputId}_validationMessage`;

    const filteredOptions = useMemo(
      () =>
        options
          .filter(({ keywords }) => doesValueMatchSearch(keywords.join(), searchValue))
          .map(({ label, value }) => ({
            label: (
              <OptionContent>
                <Checkbox checked={values?.includes(value)} tabIndex={-1} />
                <OptionLabel>{label}</OptionLabel>
              </OptionContent>
            ),
            value,
          })),
      [options, searchValue, values],
    );

    const setValue: ChangeEventHandler<HTMLInputElement> = ({ target }) => setSearchValue(target.value);

    const clearValue: FocusEventHandler<HTMLInputElement> = (event) => {
      if (!event.currentTarget.contains(event.relatedTarget)) {
        setSearchValue('');
      }
    };

    const openDropdown: FocusEventHandler<HTMLInputElement> = (event) => {
      dropdownRef.current?.open();
      onFocus?.(event);
    };

    const focusInput = () => inputRef.current?.focus();

    const dispatchChange = (value: string) => {
      const valuesSet = new Set(values);

      valuesSet.delete(value) || valuesSet.add(value);
      onChange?.({ target: { value: Array.from(valuesSet), name } } as any);
    };

    const clearInput = () => {
      setSearchValue('');
      focusInput();
    };

    return (
      <Wrapper className={className}>
        <LabelWrapper>
          {Boolean(label) && (
            <Label htmlFor={inputId} id={labelId} isBold>
              {label}
            </Label>
          )}
        </LabelWrapper>
        <InputRow
          $isDisabled={isDisabled}
          $statusType={statusType}
          onBlur={clearValue}
          onClose={focusInput}
          onOptionSelect={dispatchChange}
          options={filteredOptions}
          ref={dropdownRef}
        >
          <input name={name} type="hidden" value={values} />
          <Input
            {...props}
            aria-describedby={statusId}
            aria-invalid={statusType === 'error'}
            aria-labelledby={labelId}
            data-testid={testId || inputId}
            disabled={isDisabled}
            id={inputId}
            onChange={setValue}
            onFocus={openDropdown}
            ref={(instance) => {
              if (typeof ref === 'function') {
                ref(instance);
              } else if (ref) {
                ref.current = instance;
              }

              inputRef.current = instance;
            }}
            value={searchValue}
          />
          {!isDisabled && (
            <Slot>
              {Boolean(searchValue) && (
                <IconWrapper onClick={clearInput}>
                  <CrossCircleIconXs />
                </IconWrapper>
              )}
              <IconWrapper onClick={focusInput}>
                <SearchIconXs />
              </IconWrapper>
            </Slot>
          )}
        </InputRow>
        {Boolean(statusLabel) && (
          <FieldFooter fieldFooterLabel={statusLabel} fieldFooterType={statusType} icon={icon} id={statusId} />
        )}
      </Wrapper>
    );
  },
);
