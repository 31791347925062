import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useTracker } from '@lichtblick/tracker';

export type TemplateTrackerProps = {
  pageArea: string;
  pageTitle: string;
  pageType: string;
};

export const useTemplateTracker = ({ pageArea, pageTitle, pageType }: TemplateTrackerProps) => {
  const router = useRouter();
  const { trackPageMetaEvent, trackVirtPathEvent } = useTracker();

  useEffect(() => {
    trackVirtPathEvent({
      virtPagePath: window.location.pathname,
      virtPageTitle: pageTitle,
    });
  }, [trackVirtPathEvent, router.asPath, pageTitle]);

  useEffect(() => {
    trackPageMetaEvent({
      pageArea,
      pageType,
    });
  }, [pageArea, pageType, trackPageMetaEvent, router.asPath]);
};
