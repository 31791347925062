import { Icon as BaseIcon } from '@lichtblick/icons';
import styled from '@lichtblick/styled';
import { Colors, MediaMediumAndAbove, Spacing } from '@lichtblick/theme';
import { StyledHeadline, StyledBoxCTA, Text } from '@lichtblick/ui-components';

import { GridColumn, GridContainer } from '../shared';

export const StyledGridContainer = styled(GridContainer)`
  background-color: ${Colors.LightGray};
`;

export const StyledText = styled(Text)`
  margin-bottom: ${Spacing.Xxs};
`;

export const StyledGridColumn = styled(GridColumn)`
  padding: ${Spacing.Xs} 0 0;

  ${MediaMediumAndAbove} {
    padding: 0 ${Spacing.Xl} 0 ${Spacing.Xs};
  }

  ${StyledHeadline} {
    margin-bottom: ${Spacing.Xxs};
  }
`;

export const StyledStars = styled(Text)`
  margin: ${Spacing.L} 0 ${Spacing.Xs};
`;

export const StyledRatingBox = styled.div`
  ${StyledText} {
    margin: ${Spacing.Xs} auto;
  }
`;

export const StyledCTAWrapper = styled(Text)`
  display: flex;
  justify-content: center;
`;

export const StyledLowerBox = styled.div`
  padding-bottom: ${Spacing.L};
  background-color: ${Colors.FadedGray};

  ${StyledHeadline} {
    margin-bottom: ${Spacing.Xxs};
  }

  ${StyledBoxCTA} {
    margin: 0 ${Spacing.Xs};
  }
`;

export const StyledList = styled.ul`
  margin-top: ${Spacing.Xs};
  width: 100%;
  & > :not(:last-of-type) {
    margin-bottom: ${Spacing.Xs};
  }
`;

export const StyledListItem = styled.li`
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  width: 100%;
`;

const Icon = styled(BaseIcon)``;

export const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  margin-right: ${Spacing.Xs};
  margin-top: 0.3125rem;
`;
