import styled from '@lichtblick/styled';
import { BorderRadius, Colors, MediaMediumAndAbove, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';
import { Container } from '@lichtblick/ui-components';

import { CardOrientation } from './CompressedProductBundle';

import { MTextIntro } from '../../MTextIntro';
import { HalfSizedColumn } from '../shared';

export const StyledSection = styled.section<{ $backgroundColor: Colors }>`
  padding-block: ${Spacing.M};
  background: ${({ $backgroundColor }) => $backgroundColor};
  ${MediaSmallAndAbove} {
    padding-block: ${Spacing.L};
  }
`;
export const StyledTextIntroContainer = styled(Container)`
  margin-bottom: ${Spacing.S};
  ${MediaSmallAndAbove} {
    margin-bottom: ${Spacing.M};
  }
`;

export const StyledMTextIntro = styled(MTextIntro)`
  ${MediaMediumAndAbove} {
    max-width: 50%;
  }
`;

export const BundleRow = styled(HalfSizedColumn)`
  display: flex;
  flex-direction: column;
`;

const layout: Record<CardOrientation, string> = {
  horizontal: `
    justify-content: space-between;
  `,
  vertical: `
    flex-direction: column;
    gap: ${Spacing.Xs};
    & > picture {
      align-self: center;
    }
  `,
};

export const Card = styled.figure<{ $backgroundColor: Colors; orientation: CardOrientation }>`
  ${({ orientation }) => layout[orientation]};
  flex-grow: 1;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${BorderRadius.S};
  display: flex;
  flex-wrap: wrap;
  gap: ${Spacing.S};
  justify-content: space-between;
  padding: ${Spacing.Xs};
  &:first-of-type {
    margin-top: ${Spacing.M};
  }
  margin-bottom: ${Spacing.Xs};
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const Figcaption = styled.figcaption`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${Spacing.Xs};
`;

export const ImageContainer = styled.div<{ orientation?: CardOrientation }>`
  flex: 1;
  display: flex;
  justify-content: ${({ orientation }) => (orientation === 'horizontal' ? 'flex-end' : 'center')};
  position: relative;
  /* stylelint-disable-next-line meowtec/no-px */
  min-height: ${({ orientation }) => (orientation === 'horizontal' ? '100px' : '400px')};
`;

// Note: Why I chose img over AImage & Next/Image:
// - AImage is too complex to style in this scenario (mostly due to the picture tag and it's nested internals)
// - Next/Image requires width and height to be known, which we don't.
export const StyledImage = styled.img<{ orientation?: CardOrientation }>`
  position: absolute;
  max-height: 100%;
  max-width: 100%;
`;

export const CTAContainer = styled(Container)`
  display: flex;
  justify-content: center;
  margin-top: ${Spacing.S};
  ${MediaSmallAndAbove} {
    margin-top: ${Spacing.M};
  }
`;
