import { AnimatePresence } from 'framer-motion';
import { FC, PropsWithChildren } from 'react';

import { Duration, EasingValues } from '@lichtblick/theme';

import { Content } from './Collapsible.styles';

// TODO: simply use https://css-tricks.com/css-grid-can-do-auto-height-transitions/ instead?
/**
 * If the collapsible animation is "chopped off" and you use it within a flexed container,
 * you may need to add a div around `CollapsibleWrapper` to persist `gap`.
 */
export const CollapsibleContent: FC<PropsWithChildren> = ({ children }) => (
  <Content
    animate={{
      height: 'auto',
      opacity: 1,
      transition: {
        duration: Duration.LargeIntro / 1000,
        ease: EasingValues.Intro,
        type: 'tween',
      },
    }}
    exit={{
      height: 0,
      opacity: 0,
      transition: {
        duration: Duration.LargeOutro / 1000,
        ease: EasingValues.Outro,
        type: 'tween',
      },
    }}
    initial={{ height: 0, opacity: 0 }}
    key="collapsible-content"
    layout
  >
    {children}
  </Content>
);

export const CollapsibleWrapper: FC<PropsWithChildren> = ({ children }) => (
  <AnimatePresence>{children}</AnimatePresence>
);
