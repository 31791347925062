import styled from '@lichtblick/styled';
import { Colors, Spacing } from '@lichtblick/theme';

export const StyledCalculatorMaintenanceContent = styled.div`
  background: ${Colors.Orange};
  display: flex;
  flex-direction: column;
  gap: ${Spacing.S};
  width: 100%;
  padding: ${Spacing.Xs} ${Spacing.S} ${Spacing.S};
  border-radius: 0 0 0.375rem 0.375rem;
`;
