import { StyledIcon as Icon } from '@lichtblick/icons';
import styled from '@lichtblick/styled';
import { BorderRadius, Colors, MediaDesktop, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';
import { ContainerStyle } from '@lichtblick/ui-components';

export const BenefitCardsRoot = styled.section<{ $backgroundColor: Colors }>`
  background: ${({ $backgroundColor }) => $backgroundColor};
  display: flex;
  flex-direction: column;
  gap: ${Spacing.M};
  padding-bottom: ${Spacing.Xl};
  padding-top: ${Spacing.Xl};
`;

export const TwoCardRow = styled.div`
  display: grid;
  gap: ${Spacing.Xs};
  grid-template-columns: 1fr;
  ${ContainerStyle}

  ${MediaSmallAndAbove} {
    grid-template-columns: 1fr 1fr;
  }
`;
export const Card = styled.div<{ $backgroundColor: Colors }>`
  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${BorderRadius.M};
  display: flex;
  flex-direction: column;
  gap: ${Spacing.S};
  padding: ${Spacing.S};

  ${MediaDesktop} {
    padding-bottom: ${Spacing.M};
    padding-top: ${Spacing.M};
  }
`;

export const Headline = styled.h3`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${Spacing.Xxs};

  ${MediaSmallAndAbove} {
    align-items: center;
    flex-direction: row;
  }
`;

export const StyledIcon = styled(Icon)`
  padding: ${Spacing.Xxs};
`;
