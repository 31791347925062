import { RefObject, useCallback, useEffect, useState } from 'react';

import { InfoIconXs } from '@lichtblick/icons/svg/info/info-xs';
import { useTracker } from '@lichtblick/tracker';
import { Headline, Modal, Stepper, Text, TextCTA } from '@lichtblick/ui-components';

import { StyledModalContent } from './CalculatorLayout.styles';
import { StyledCalculatorInputRow, StyledCalculatorUsageInput } from './CalculatorUsageInput.styles';

type GasUsageInputProps = {
  children: JSX.Element;
  onChange: (value: string, livingSpace: number) => void;
  usageRef: RefObject<HTMLInputElement>;
};

export const GasUsageInput: React.FC<GasUsageInputProps> = ({ children, onChange, usageRef }) => {
  const { trackCustomEvent } = useTracker();
  const [livingSpace, setLivingSpace] = useState<number>(30);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleStepperChange = useCallback(
    (livingSpace: number | undefined) => {
      if (livingSpace) {
        setLivingSpace(livingSpace);

        if (usageRef.current) {
          usageRef.current.value = (livingSpace * 150).toString();
          onChange(usageRef.current.value, livingSpace);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setLivingSpace],
  );

  useEffect(() => {
    handleStepperChange(30);
  }, [handleStepperChange]);

  return (
    <div>
      <Stepper
        label="Wohnfläche in m²"
        max={300}
        min={30}
        name="livingSpace"
        onChange={handleStepperChange}
        onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
        step={5}
        value={livingSpace}
      />
      <StyledCalculatorInputRow>
        <Text size="Xs">
          Dein Verbrauch{' '}
          <StyledCalculatorUsageInput
            $hasError={usageRef.current?.checkValidity() === false}
            aria-invalid={usageRef.current?.checkValidity() === false}
            aria-label="Verbrauch in kWh/Jahr"
            name="gas"
            onChange={(event) => onChange(event.target.value, livingSpace)}
            onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
            onInvalid={(e) => e.preventDefault()}
            ref={usageRef}
            required
          />{' '}
          kWh/Jahr
        </Text>
        <TextCTA
          ariaLabel="Informationen zur Berechnung des Gasverbrauchs zeigen"
          as="button"
          icon={<InfoIconXs />}
          onClick={() => setIsInfoModalOpen(true)}
          type="button"
        />
      </StyledCalculatorInputRow>

      <Modal
        footerContent={
          <TextCTA as="button" data-testid="button-modal-close" onClick={() => setIsInfoModalOpen(false)}>
            Ok, verstanden
          </TextCTA>
        }
        isOpen={isInfoModalOpen}
        newApi
        onPointerDownOutside={() => setIsInfoModalOpen(false)}
      >
        <StyledModalContent>
          <Headline renderAs={'h2'} size="S">
            So berechnet LichtBlick den Gasverbrauch
          </Headline>
          <Text renderAs={'p'}>
            Natürlich wisst nur ihr genau, was ihr verbraucht und könnt es deswegen auch direkt eintragen. Aus langer
            Erfahrung als Ökogasanbieter kann LichtBlick aber auch ziemlich gut schätzen: Im Schnitt werden pro
            Quadratmeter 150 kWh im Jahr verbraucht. Dabei ist natürlich miteingerechnet, dass im Sommer
            durchschnittlich deutlich weniger verbraucht wird als im Winter. Wenn zusätzlich mit Gas gekocht wird,
            kommen nochmal 1.000 kWh dazu. Diese müsstet ihr dann nur noch im Kalkulator hinzufügen, damit die Rechnung
            aufgeht. Doch weil auch wir bei der Berechnung nur mit Wasser kochen, könnt ihr den errechneten Wert
            jederzeit anpassen.
          </Text>
        </StyledModalContent>
      </Modal>
      {children}
    </div>
  );
};
