import styled from '@lichtblick/styled';
import {
  Colors,
  MediaBelowMedium,
  MediaBelowSmall,
  MediaDesktop,
  MediaSmallAndAbove,
  Spacing,
} from '@lichtblick/theme';
import { Headline } from '@lichtblick/ui-components';

export const Header = styled.div<{ $theme?: string }>`
  background-color: ${({ $theme }) => $theme};
  height: 20rem;

  ${MediaDesktop} {
    height: 30rem;
  }
`;

export const StyledHeadline = styled(Headline)`
  color: ${Colors.White};
  margin: ${Spacing.Xl} 0;
`;

export const StickyNav = styled.div<{ $theme?: string }>`
  background-color: ${({ $theme }) => $theme};
  display: block;
  height: 4.688rem;
  margin-top: -10rem;

  position: sticky;
  top: 0;
  z-index: 10;

  ${MediaSmallAndAbove} {
    height: 6.25rem;
    margin-top: -14rem;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 6rem;
  width: 100%;

  & > * {
    ${MediaBelowMedium} {
      display: flex;
      width: 33.333%;
    }

    ${MediaBelowSmall} {
      display: none;

      &[data-order='0'],
      &[data-order='1'] {
        display: block;
        width: 50%;
      }
    }
  }
`;

export const Card = styled.div<{ order: number }>`
  order: ${({ order }) => order};
`;
