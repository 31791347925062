'use client';

import { useState, useEffect } from 'react';

import { logger } from '@lichtblick/logger';

import { useConsentManager } from '../ConsentManager/ConsentManagerProvider';

export const useUsercentricsControllerId = (): string | undefined => {
  const { getControllerId, isUcInitialized } = useConsentManager();
  const [controllerId, setControllerId] = useState<string>();

  useEffect(() => {
    if (!isUcInitialized) {
      return;
    }

    getControllerId()
      ?.then((id) => setControllerId(id))
      .catch((error) => logger.warn('Unable to retrieve usercentrics controller id', error));
  }, [getControllerId, isUcInitialized]);

  return controllerId;
};
