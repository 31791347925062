import styled from '@lichtblick/styled';
import { BorderWidth, Colors, Spacing } from '@lichtblick/theme';

import * as Base from './base.variant';

export const DropDownContainer = styled(Base.DropDownContainer)`
  border: inherit;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: none;
  margin-top: 0;
`;

export const DropDownRoot = Base.DropDownRoot;

export const DropDownContent = styled(Base.DropDownContent)`
  &::before {
    border: none;
    border-bottom: ${BorderWidth.Input} solid ${Colors.LightGray};
    content: '';
    display: block;
    margin: 0 ${Spacing.Xs};
  }
`;

export const Options = styled(Base.Options)`
  mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 1) 15%, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0));
  max-height: 13.5rem;
`;

export const Option = styled(Base.Option)`
  margin: 0 ${Spacing.Xxs} !important;

  &::before {
    content: none !important;
  }
`;

export const NoResult = styled(Base.NoResult)``;
