import { PropsWithChildren, forwardRef } from 'react';

import { Wrapper, Legend } from './CheckGroup.styles';

export type CheckGroupProps = PropsWithChildren<{
  className?: string;
  /* If children are collapsible, control the current active `CheckGroupItem` value */
  collapsibleValue?: string;
  'data-testid'?: string;
  label?: string;
}>;

export const CheckGroup = forwardRef<HTMLDivElement, CheckGroupProps>(
  ({ children, className, collapsibleValue, 'data-testid': dataTestId, label }, ref) => (
    <Wrapper
      className={className}
      collapsible
      data-testid={dataTestId}
      ref={ref}
      type="single"
      value={collapsibleValue}
    >
      {!!label && <Legend as="legend">{label}</Legend>}
      {children}
    </Wrapper>
  ),
);

CheckGroup.displayName = 'CheckGroup';
