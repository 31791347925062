import { FC } from 'react';

import { StyledAnchorMark } from './AnchorMark.styles';

type AnchorMarkProps = {
  anchorId: string;
  offset?: number;
};

export const AnchorMark: FC<AnchorMarkProps> = ({ anchorId, offset }) => (
  <StyledAnchorMark $offset={offset} id={anchorId} />
);
