import { FC, useContext } from 'react';

import { Colors } from '@lichtblick/theme';
import { AccordionRoot, Box } from '@lichtblick/ui-components';

import { CalculatorLayout } from './CalculatorLayout';
import { CalculatorMaintenance } from './CalculatorMaintenace';
import { HeatingCalculator } from './HeatingCalculator';
import { HomeChargingCalculator } from './HomeChargingCalculator';
import { HouseholdCalculator } from './HouseholdCalculator';
import { getMaintenanceText, mapCalculatorToProduct } from './OAllInOneCalculator.helpers';
import { SolarTeaser } from './SolarTeaser';

import { SettingsContext } from '../../helpers/settingsContext';
import { OAllInOneCalculatorType } from '../../types/storyblok';
import { getSpacing } from '../../utils';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

export type BaseCalculatorProps = Pick<CalculatorLayout, 'isActionTriggerDisabled'>;

export const OAllInOneCalculator: FC<OAllInOneCalculatorType> = ({
  activeIndex = '0',
  anchorId,
  calculators,
  paddingBottomDesktop,
  paddingBottomMobile,
  paddingTopDesktop,
  paddingTopMobile,
}) => {
  const { maintenanceProducts } = useContext(SettingsContext);
  const isSingleCalculator = calculators.length === 1;
  const accordionValue = isSingleCalculator ? calculators[0]._uid : undefined;

  return (
    <Box
      backgroundColor={Colors.FadedGray}
      data-testid="all-in-one-calculator"
      pbd={getSpacing(paddingBottomDesktop ?? 'xl')}
      pbm={getSpacing(paddingBottomMobile ?? 'm')}
      ptd={getSpacing(paddingTopDesktop) ?? 0}
      ptm={getSpacing(paddingTopMobile) ?? 0}
    >
      {anchorId && <AnchorMark anchorId={anchorId} />}
      <GridContainer>
        <GridRow>
          <GridColumn $push={2} $width={8}>
            <AccordionRoot
              defaultValue={calculators[Number(activeIndex) - 1]?._uid}
              type="single"
              value={accordionValue}
            >
              {calculators.map((calculator) => {
                const maintenanceText = getMaintenanceText(mapCalculatorToProduct(calculator), maintenanceProducts);

                if (maintenanceText) {
                  return (
                    <CalculatorMaintenance
                      headline={calculator.headline}
                      id={calculator._uid}
                      isActionTriggerDisabled={isSingleCalculator}
                      key={calculator._uid}
                      maintenanceText={maintenanceText}
                    />
                  );
                }

                switch (calculator.component) {
                  case 'heating-calculator':
                    return (
                      <HeatingCalculator
                        {...calculator}
                        isActionTriggerDisabled={isSingleCalculator}
                        key={calculator._uid}
                      />
                    );
                  case 'household-calculator':
                    return (
                      <HouseholdCalculator
                        {...calculator}
                        isActionTriggerDisabled={isSingleCalculator}
                        key={calculator._uid}
                        productBType={
                          calculator.productBType && getMaintenanceText(calculator.productBType, maintenanceProducts)
                            ? undefined
                            : calculator.productBType
                        }
                      />
                    );
                  case 'home-charging-calculator':
                    return (
                      <HomeChargingCalculator
                        {...calculator}
                        isActionTriggerDisabled={isSingleCalculator}
                        key={calculator._uid}
                      />
                    );
                  case 'solar-teaser':
                    return (
                      <SolarTeaser {...calculator} isActionTriggerDisabled={isSingleCalculator} key={calculator._uid} />
                    );
                }
              })}
            </AccordionRoot>
          </GridColumn>
        </GridRow>
      </GridContainer>
    </Box>
  );
};
