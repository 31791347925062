import { FC } from 'react';

import { StarEmptyIconXs } from '@lichtblick/icons/svg/star-full/star-empty-xs';
import { StarFullIconXs } from '@lichtblick/icons/svg/star-full/star-full-xs';
import { StarHalfIconXs } from '@lichtblick/icons/svg/star-half/star-half-xs';
import { Colors } from '@lichtblick/theme';
import { Text } from '@lichtblick/ui-components';

import {
  Avatar,
  Card,
  DateIndicator,
  InlineText,
  InlineTextLink,
  PersonTextInfoWrapper,
  PersonWrapper,
  RatingWrapper,
  StarsWrapper,
} from './MCardReview.styles';

import { SolarSliderSlideType } from '../../../../types';

export const MCardReview: FC<SolarSliderSlideType & { backgroundColor: Colors }> = ({
  backgroundColor,
  link,
  name,
  profilePictureLink,
  rating,
  relativeTimeDescription,
  text,
}) => {
  const starCount = Number.parseFloat(rating);

  // Render nothing in case of incorrect data
  if (Number.isNaN(starCount) || typeof starCount !== 'number' || starCount < 0 || starCount > 5) {
    return null;
  }

  return (
    <Card $backgroundColor={backgroundColor}>
      <PersonWrapper>
        <Avatar src={profilePictureLink} />
        <PersonTextInfoWrapper>
          <Text isBold size="M">
            {name}
          </Text>
        </PersonTextInfoWrapper>
      </PersonWrapper>
      <RatingWrapper>
        <StarsWrapper>
          {Array(Math.floor(starCount))
            .fill(undefined)
            .map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <StarFullIconXs color={Colors.DarkOrange} key={`${name}-star-full-${index}`} />
            ))}
          {Boolean(!Number.isInteger(starCount)) && <StarHalfIconXs color={Colors.DarkOrange} />}
          {Array((!Number.isInteger(starCount) ? 4 : 5) - Math.floor(starCount))
            .fill(undefined)
            .map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <StarEmptyIconXs color={Colors.DarkOrange} key={`${name}-star-empty-${index}`} />
            ))}
        </StarsWrapper>
        <DateIndicator size="M">{relativeTimeDescription}</DateIndicator>
      </RatingWrapper>
      <p>
        <InlineText isItalic>{text}</InlineText>
        <InlineTextLink href={link} isItalic target="_blank">
          {' Mehr\u2026'}
        </InlineTextLink>
      </p>
    </Card>
  );
};
