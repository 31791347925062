import { FC, PropsWithChildren, ReactNode } from 'react';

import {
  BottomShadowCurtain,
  ModalContent,
  ModalContentWrapper,
  ModalFooter,
  ModalOverlay,
  ModalPortal,
  ModalRoot,
  ModalSurface,
  ModalTrigger,
} from './Modal.styles';

import { BoxCTA } from '../../atoms/BoxCTA/BoxCTA';
import { TextCTA } from '../../atoms/TextCTA/TextCTA';

export type LegacyModalProps = PropsWithChildren<{
  /** @deprecated Set prop newApi. */
  className?: string;
  isOpen?: boolean;
  /** @deprecated Set prop newApi and use prop primaryButton instead. */
  isPrimaryButtonDisabled?: boolean;
  /** @deprecated Set prop newApi. */
  onOpenChange?: (open: boolean) => void;
  /** @deprecated Set prop newApi and use prop primaryButton instead. */
  onPrimaryButtonClick?: () => void;
  /** @deprecated Set prop newApi and use prop secondaryButton instead. */
  onSecondaryButtonClick: () => void;
  /** @deprecated Set prop newApi and use prop primaryButton instead. */
  primaryButtonLabel?: string;
  /** @deprecated Set prop newApi and use prop secondaryButton instead. */
  secondaryButtonLabel?: string;
  /** @deprecated Set prop newApi. */
  triggerComponent?: ReactNode;
}>;

type NewModalProps = PropsWithChildren<{
  footerContent?: ReactNode;
  isOpen?: boolean;
  newApi: true;
  onPointerDownOutside?: () => void;
}>;

export type ModalProps = LegacyModalProps | NewModalProps;

export const Modal: FC<ModalProps> = ({ children, isOpen, ...props }) => (
  <ModalRoot onOpenChange={'newApi' in props ? undefined : props.onOpenChange} open={isOpen}>
    {!('newApi' in props) && Boolean(props.triggerComponent) && (
      <ModalTrigger asChild>{props.triggerComponent}</ModalTrigger>
    )}
    <ModalPortal>
      <ModalOverlay />
      <ModalSurface
        onPointerDownOutside={'newApi' in props ? props.onPointerDownOutside : props.onSecondaryButtonClick}
      >
        <ModalContentWrapper>
          <ModalContent className={'newApi' in props ? undefined : props.className}>{children}</ModalContent>
          <BottomShadowCurtain />
        </ModalContentWrapper>
        <ModalFooter>
          {'newApi' in props ? (
            props.footerContent
          ) : (
            <>
              {Boolean(props.onPrimaryButtonClick && props.primaryButtonLabel) && (
                <BoxCTA as="button" isDisabled={props.isPrimaryButtonDisabled} onClick={props.onPrimaryButtonClick}>
                  {props.primaryButtonLabel}
                </BoxCTA>
              )}
              <TextCTA as="button" data-testid="button-modal-close" onClick={props.onSecondaryButtonClick}>
                {props.secondaryButtonLabel}
              </TextCTA>
            </>
          )}
        </ModalFooter>
      </ModalSurface>
    </ModalPortal>
  </ModalRoot>
);
