import styled from '@lichtblick/styled';
import { BorderRadius, Colors, MediaSmallAndAbove, MediaDesktop, Spacing } from '@lichtblick/theme';

export const ProductListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.S};

  ${MediaSmallAndAbove} {
    :not(:nth-child(2n)) {
      border-right: 1px solid ${Colors.LightGray};
      padding-right: ${Spacing.Xs};
    }
    :not(:nth-child(2n-1)) {
      padding-left: ${Spacing.Xs};
    }
  }
  ${MediaDesktop} {
    :not(:nth-child(4n)) {
      border-right: 1px solid ${Colors.LightGray};
      padding-right: ${Spacing.Xs};
    }
    :not(:nth-child(4n-3)) {
      padding-left: ${Spacing.Xs};
    }
  }
`;

export const MProductItemListRoot = styled.div<{ $backgroundColor: Colors }>`
  align-items: center;
  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${BorderRadius.S};
  display: flex;
  flex-direction: column;
  gap: ${Spacing.M};
  padding: ${Spacing.S};
  width: 100%;
`;

export const ProductItemList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: ${Spacing.Xs};
  width: 100%;

  ${MediaSmallAndAbove} {
    grid-template-columns: 1fr 1fr;
  }
  ${MediaDesktop} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const ImageWrapper = styled.div`
  img {
    /* stylelint-disable-next-line meowtec/no-px */
    height: 160px;
    width: auto;
  }
`;
