import Image, { ImageProps } from 'next/image';
import { ComponentProps, FC, PropsWithChildren } from 'react';

import { IconName } from '@lichtblick/icons';

import { MediaCardRoot, MediaCardGroupRoot, Icon } from './MediaCard.styles';

import { FieldFooter } from '../FieldFooter';

export type ResponsiveColumnProps = {
  desktopColumns?: 2 | 3;
  mobileColumns?: 1 | 2;
};

export type MediaCardGroupProps = ResponsiveColumnProps &
  PropsWithChildren<{
    errorMessage?: string;
  }>;

/**
 * MediaCardGroups contain a list of MediaCards and handle the grid alignment.
 */
export const MediaCardGroup: FC<MediaCardGroupProps> = ({ children, desktopColumns, errorMessage, mobileColumns }) => (
  <>
    <MediaCardGroupRoot $desktopColumns={desktopColumns} $mobileColumns={mobileColumns}>
      {children}
    </MediaCardGroupRoot>
    {Boolean(errorMessage) && <FieldFooter fieldFooterLabel={errorMessage} fieldFooterType={'error'} />}
  </>
);

export type IconOrImageData =
  | {
      iconName?: never;
      imageData: ImageProps;
    }
  | {
      iconName?: IconName;
      imageData?: never;
    };

export type MediaCardProps = Partial<Pick<ComponentProps<'label'>, 'htmlFor'>> &
  PropsWithChildren<{
    isActive?: boolean;
    isDisabled?: boolean;
    mediaProperties: IconOrImageData;
  }>;

/**
 * MediaCards are input containers that house a media component (image or icon).
 * An input element can be passed inside the inputSlot property.
 */
export const MediaCard: FC<MediaCardProps> = ({
  children,
  isActive,
  isDisabled,
  mediaProperties: { iconName, imageData },
  ...props
}) => (
  <MediaCardRoot {...props} $isActive={isActive} aria-disabled={isDisabled} as="label">
    {imageData && <Image {...imageData} />}
    {iconName && <Icon name={iconName} />}
    {children}
  </MediaCardRoot>
);
