import styled from '@lichtblick/styled';
import { MediaSmallAndAbove, Spacing } from '@lichtblick/theme';

export const ImageWrapper = styled.div`
  border-radius: 625rem;
  height: 2.4rem;
  overflow: hidden;
  position: relative;
  width: 2.4rem;
`;

export const InfoLineContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const InfoLines = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.Xs};
  align-items: center;

  ${MediaSmallAndAbove} {
    flex-direction: row;
    justify-content: center;
    gap: ${Spacing.S};
    width: 100%;
  }
`;

export const InfoLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Spacing['3Xs']};
`;

export const ImageInfoLine = styled(InfoLine)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Spacing['Xxs']};
`;
