import styled from '@lichtblick/styled';
import { MediaMediumAndAbove, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';

export const TextIntroWrapper = styled.div`
  margin-bottom: ${Spacing.M};

  ${MediaMediumAndAbove} {
    margin-bottom: ${Spacing.L};
  }
`;

export const Grid = styled.div<{ $columns?: number }>`
  margin: 0 -${Spacing.Xs} -${Spacing.M};
  column-gap: 0;
  column-count: 1;

  ${MediaSmallAndAbove} {
    column-count: ${(props) => props.$columns ?? 3};
  }
`;

export const GridItem = styled.div`
  margin-bottom: ${Spacing.M};
  padding: 0 ${Spacing.Xs};
  break-inside: avoid;
`;
