import { ServiceData, ServicesConsent } from './ConsentManager.types';

export const hasNonEssentialConsentBeenWithdrawn = (
  prevServicesConsent: ServicesConsent,
  currServicesConsent: ServicesConsent,
): boolean =>
  Object.entries(prevServicesConsent).some(
    ([id, service]) => !service.essential && service.consent && !currServicesConsent?.[id]?.consent,
  );

export const collectServicesConsent = (servicesData: Record<string, ServiceData> | undefined): ServicesConsent =>
  Object.values(servicesData || {}).reduce<ServicesConsent>((services, { consent, essential, name }) => {
    services[name] = { consent: consent?.given, essential: essential };

    return services;
  }, {});
