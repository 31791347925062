const parsePercent = (percent = '') => (parseFloat(percent) || 0) / 100;

const getCssColorFunction = (colorValue: string) => {
  const color = colorValue.trim();
  const result = {
    functionName: 'rgb',
    components: ['0', '0', '0'],
    opacity: 1,
  };

  if (color.startsWith('#')) {
    const isShorthand = color.length < 7;
    const parts = isShorthand
      ? color
          .slice(1)
          .split('')
          .map((part) => part.repeat(2))
      : (color.slice(1).match(/../g) ?? []);
    const values = parts.map((part) => parseInt(part, 16));

    result.components = values.slice(0, 3).map(String);
    result.opacity = (values[3] ?? 255) / 255;
  } else {
    const [, functionName, args] = color.match(/(\w+)\(([^)]+)\)/) ?? [];
    const values = args.split(/[\s,/]+/);
    const opacity = values[3] ?? '1';

    result.functionName = functionName.toLowerCase();
    result.components = values.slice(0, 3);
    result.opacity = opacity.endsWith('%') ? parsePercent(opacity) : parseFloat(opacity);
  }

  return result;
};

export const alpha = (color: string, opacity: number) => {
  // eslint-disable-next-line prefer-const
  let { components, functionName, opacity: currentOpacity } = getCssColorFunction(color);

  functionName += functionName.endsWith('a') || functionName === 'hwb' ? '' : 'a';
  currentOpacity *= opacity;

  return `${functionName}(${components.join(',')},${currentOpacity.toFixed(4).replace(/\.?0+$/, '')})`;
};

/**
 * NOTE: Ignores opacity.
 */
export const luminance = (color: string) => {
  const { components, functionName } = getCssColorFunction(color);

  switch (functionName.slice(0, 3)) {
    case 'rgb':
      return (
        [0.299, 0.587, 0.114]
          .map((weight, index) => {
            const channel = components[index] ?? '0';
            const value = channel.endsWith('%') ? parsePercent(channel) : parseInt(channel) / 255;

            return weight * value ** 2;
          })
          .reduce((a, b) => a + b) ** 0.5
      );
    case 'hsl':
      return parsePercent(components[2]);
    case 'hwb':
      return (1 + parsePercent(components[1]) - parsePercent(components[2])) / 2;
    case 'lab':
    case 'lch':
    case 'okl':
      return parsePercent(components[0]);
    default:
      return 0;
  }
};
