import { RefObject, useCallback, useEffect, useState } from 'react';

import { InfoIconXs } from '@lichtblick/icons/svg/info/info-xs';
import { useTracker } from '@lichtblick/tracker';
import { Headline, Modal, Stepper, Text, TextCTA } from '@lichtblick/ui-components';

import { StyledModalContent } from './CalculatorLayout.styles';
import { StyledCalculatorInputRow, StyledCalculatorUsageInput } from './CalculatorUsageInput.styles';

const ElectricityByPerson = [0, 1400, 2300, 3000, 3700, 4400, 5100, 5800, 6500, 7200, 7900] as const;

type ElectrictyUsageInputProps = {
  children: JSX.Element;
  onChange: (value: string, peopleCount: number) => void;
  usageRef: RefObject<HTMLInputElement>;
};

export const ElectrictyUsageInput: React.FC<ElectrictyUsageInputProps> = ({ children, onChange, usageRef }) => {
  const { trackCustomEvent } = useTracker();
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [numberOfPeople, setNumberOfPeople] = useState<number>(1);

  const handleStepperChange = useCallback(
    (people: number | undefined) => {
      if (people) {
        setNumberOfPeople(Math.min(people, 10));

        if (usageRef.current) {
          usageRef.current.value = ElectricityByPerson[Math.min(people, 10)].toString();
          onChange(usageRef.current.value, numberOfPeople);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setNumberOfPeople],
  );

  useEffect(() => {
    handleStepperChange(1);
  }, [handleStepperChange]);

  return (
    <div>
      <Stepper
        label="Anzahl der Personen"
        max={10}
        min={1}
        name="people"
        onChange={handleStepperChange}
        onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
        value={numberOfPeople}
      />
      <StyledCalculatorInputRow>
        <Text size="Xs">
          Dein Verbrauch{' '}
          <StyledCalculatorUsageInput
            $hasError={usageRef.current?.checkValidity() === false}
            aria-invalid={usageRef.current?.checkValidity() === false}
            aria-label="Verbrauch in kWh/Jahr"
            name="strom"
            onChange={(event) => onChange(event.target.value, numberOfPeople)}
            onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
            onInvalid={(e) => e.preventDefault()}
            ref={usageRef}
            required
          />{' '}
          kWh/Jahr
        </Text>
        <TextCTA
          ariaLabel="Informationen zur Berechnung des Stromverbrauchs zeigen"
          as="button"
          icon={<InfoIconXs />}
          onClick={() => setIsInfoModalOpen(true)}
          type="button"
        />
      </StyledCalculatorInputRow>
      <Modal
        footerContent={
          <TextCTA as="button" data-testid="button-modal-close" onClick={() => setIsInfoModalOpen(false)}>
            Ok, verstanden
          </TextCTA>
        }
        isOpen={isInfoModalOpen}
        newApi
        onPointerDownOutside={() => setIsInfoModalOpen(false)}
      >
        <StyledModalContent>
          <Headline renderAs={'h2'} size="S">
            So berechnet LichtBlick den Stromverbrauch
          </Headline>
          <Text renderAs={'p'}>
            Natürlich wisst nur ihr genau, was ihr verbraucht und könnt es deswegen auch direkt eintragen. Aus über 20
            Jahren Erfahrung als Ökostromanbieter kann LichtBlick aber auch ziemlich gut schätzen: Im Schnitt braucht
            eine Person ca. 1.400 kWh. Bei 2 Personen kommt man auf 2.300 kWh – jede weitere Person erhöht den Verbrauch
            jährlich um 700 kWh. Diese Werte werden also verwendet, wenn ihr die Personenzahl im Haushalt angebt.
          </Text>
        </StyledModalContent>
      </Modal>
      {children}
    </div>
  );
};
