import { FC } from 'react';

import { toIconName } from '@lichtblick/icons';
import { Text } from '@lichtblick/ui-components';

import {
  StyledIcon,
  StyledItem,
  StyledPairedItem,
  StyledPairedItemDottedLine,
  StyledPairedItemText,
} from './AListItem.styles';

import { richTextMapper } from '../../helpers/richTextMapper';
import { AListItemType } from '../../types/storyblok';
import { ColorKey } from '../../utils';

export type AListItemProps = AListItemType & {
  className?: string;
  colorOverride?: ColorKey;
  textSizeOverride?: 'M' | 'S';
};

const iconColorMap: Record<Exclude<AListItemType['color'], undefined>, ColorKey> = {
  red: 'light-red',
  green: 'light-moss',
  black: 'black',
};

export const AListItem: FC<AListItemProps> = ({
  className,
  color = 'green',
  colorOverride,
  icon,
  isBold,
  richText,
  textSizeOverride = 'M',
  title,
  value,
}) => {
  const content =
    richText &&
    richTextMapper(richText, {
      p: ({ children }) => (
        <Text renderAs="p" size={textSizeOverride}>
          {children}
        </Text>
      ),
    });

  if (!content) {
    return null;
  }

  return title && value ? (
    <StyledPairedItem key={value}>
      <StyledPairedItemText isBold={isBold}>{title}</StyledPairedItemText>
      <StyledPairedItemDottedLine />
      <StyledPairedItemText isBold={isBold}>{value}</StyledPairedItemText>
    </StyledPairedItem>
  ) : (
    <StyledItem className={className}>
      {icon && (
        <StyledIcon
          $color={colorOverride ?? iconColorMap[color]}
          $textSize={textSizeOverride}
          name={toIconName(icon)}
        />
      )}
      <Text size={textSizeOverride}>{content}</Text>
    </StyledItem>
  );
};
