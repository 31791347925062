import { FC } from 'react';

import { transient } from '@lichtblick/styled';
import { Colors } from '@lichtblick/theme';

import { Wrapper, Frame, StrokeLeft, StrokeRight, Progress, ProgressContainer } from './LoadingBar.styles';

export type LoadingBarProps = {
  color?:
    | Colors.LightGreen
    | Colors.LightSmoke
    | Colors.LightMoss
    | Colors.LightAqua
    | Colors.LightRed
    | Colors.LightBerry
    | Colors.LightGray
    | Colors.Aqua
    | Colors.DarkAqua
    | Colors.Red
    | Colors.Berry
    | Colors.Gray
    | Colors.FadedGray;
  percentage: number;
};

export const LoadingBar: FC<LoadingBarProps> = ({ color = Colors.LightGreen, percentage }) => (
  <Wrapper>
    <Frame />
    <StrokeLeft />
    <StrokeRight />
    <ProgressContainer>
      <Progress {...transient({ color, percentage })} data-testid="loading-bar-progress" />
    </ProgressContainer>
  </Wrapper>
);
