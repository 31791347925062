import { ElementType, FC, ReactNode } from 'react';

import { Wrapper } from './AccentText.styles';

/**
 * Responsive size classes:
 * https://www.figma.com/file/A0m8tFZk3yHpbiCtmlCXfb/%5BPhoton%5D-Typography-(Web)?node-id=2%3A32&t=jtIHhBKNgXKstYE0-1
 */
export type AccentTextSizeClass = 'M' | 'L';

export type AccentTextProps = {
  children?: ReactNode;
  className?: string;
  renderAs?: ElementType | keyof JSX.IntrinsicElements;
  /**
   * M = mobile 18px | desktop 20px;
   * L = mobile 25px | desktop 28px;
   */
  size?: AccentTextSizeClass;
};

export const AccentText: FC<AccentTextProps> = ({ children, renderAs, size, ...props }) => (
  <Wrapper {...props} $size={size} as={renderAs}>
    {children}
  </Wrapper>
);
