import styled from '@lichtblick/styled';
import { Colors, BorderRadius, Shadows, Spacing, DurationAndEasing, ZIndex } from '@lichtblick/theme';

import { focusRing } from '../../atoms/shared/styles';

export const TooltipContent = styled.span<{ $offset: number }>`
  background-color: ${Colors.White};
  border-radius: ${BorderRadius.S};
  box-shadow: ${Shadows.Medium};
  left: 0;
  max-height: calc(50vh - ${({ $offset }) => `${$offset}px`});
  /* stylelint-disable-next-line meowtec/no-px */
  max-width: calc(min(50vw, 300px) - ${({ $offset }) => `${$offset}px`});
  opacity: 0;
  padding: ${Spacing.Xs};
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity ${DurationAndEasing.SmallIntro};
  white-space: normal;
  z-index: ${ZIndex.Tooltip};
`;

export const TooltipTrigger = styled.span.attrs({ tabIndex: 0 })<{ $isVisible: boolean }>`
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};

  &:focus-visible {
    ${focusRing}
  }

  /* stylelint-disable-next-line selector-type-no-unknown */
  &:hover + ${TooltipContent}, &:focus-visible + ${TooltipContent} {
    opacity: 1;
    transition: opacity ${DurationAndEasing.SmallOutro};
  }
`;
