import { Stage } from '@lichtblick/contentful/components/OStageFull/OStageFull.styles';
import styled from '@lichtblick/styled';
import { BorderRadius, Colors, MediaMediumAndAbove, Spacing } from '@lichtblick/theme';
import { Container } from '@lichtblick/ui-components';

import { minCalloutBadgeHeight } from './components/CalloutBadge.styles';

export const Root = styled.div`
  position: relative;
  ${Container} {
    position: relative;
  }
  ${Stage} {
    align-items: flex-start;
    min-height: 70svh;
    padding-bottom: ${Spacing.Xxl};
    @media (min-height: ${minCalloutBadgeHeight}px) {
      align-items: flex-end;
    }
    ${MediaMediumAndAbove} {
      align-items: flex-start;
      /* stylelint-disable-next-line meowtec/no-px */
      max-height: 600px;
      min-height: auto;
    }
  }
`;

export const SellingPointsSection = styled.section<{ $backgroundColor?: Colors }>`
  background: ${({ $backgroundColor }) => $backgroundColor || Colors.FadedGray};
`;

export const SellingPointsContainer = styled(Container).attrs({ as: 'div' })<{
  $height?: { medium: string; small: string };
}>`
  height: ${({ $height }) => $height?.small ?? '5.5rem'};
  ${MediaMediumAndAbove} {
    height: ${({ $height }) => $height?.medium ?? '3rem'};
  }
`;

export const SellingPointList = styled.ul`
  background: ${Colors.White};
  border-radius: ${BorderRadius.S};
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-block: ${Spacing.Xxs};
  transform: translateY(-50%);

  ${MediaMediumAndAbove} {
    flex-direction: row;
  }
`;

export const SellingPointItem = styled.li`
  align-items: center;
  display: flex;
  * {
    font-size: 0.75rem;
    ${MediaMediumAndAbove} {
      font-size: 1rem;
    }
  }
  gap: ${Spacing.Xs};
  padding-block: ${Spacing.Xxs};
  padding-inline: ${Spacing.Xs};
  ${MediaMediumAndAbove} {
    justify-content: center;
    width: calc(100% / 3);
  }
  & > svg {
    flex-shrink: 0;
  }
`;
