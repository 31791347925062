import styled, { css } from '@lichtblick/styled';
import {
  Colors,
  Duration,
  LinkUnderlineWidth,
  MediaBelowMedium,
  MediaBelowSmall,
  MediaMediumAndAbove,
  MediaSmallAndAbove,
  Spacing,
} from '@lichtblick/theme';

import { focusRing } from '../../atoms/shared/styles';
import { StyledText } from '../../atoms/Text/Text.styles';
import { Link } from '../../helpers/Link';

export const StyledHeaderMetaNavigationList = styled.li`
  display: flex;
  flex-direction: column;
  list-style: none;
  pointer-events: all;

  svg {
    display: block;
    margin: 0;
    transform: scale(1.25);

    ${MediaSmallAndAbove} {
      transform: none;
    }

    ${MediaMediumAndAbove} {
      margin: 0 ${Spacing['3Xs']} 0 0;
    }
  }
`;

export const StyledHeaderMetaNavigationLink = styled(Link)<{ $isActive: boolean }>`
  align-items: center;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 0;
  outline: none;
  padding: ${Spacing.Xs} ${Spacing.Xs};
  position: relative;

  ${MediaSmallAndAbove} {
    padding: ${Spacing.Xs} 0;
  }

  ${MediaMediumAndAbove} {
    padding: 1.575rem 0;
  }

  &:focus-visible > * {
    ${focusRing}
  }

  /* Animated overline */
  &::before,
  &::after {
    border-top: ${LinkUnderlineWidth.Default} solid;
    bottom: auto;
    content: '';
    inset: 0;
    position: absolute;
    transform: scaleX(0);
    transform-origin: left;
    transition-duration: ${Duration.LargeOutro}ms;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out;
  }

  &::after {
    border-color: ${Colors.Orange};
  }

  &:hover {
    &::before {
      transform: scaleX(1);
    }

    &::after {
      transform: scaleX(1);
      transition-delay: 300ms;
    }
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      &::before {
        z-index: 1;
      }

      &::after {
        transform: scaleX(1);
        transition-duration: 0;
      }
    `}

  ${StyledText} {
    white-space: nowrap;

    a:focus-visible > & {
      ${focusRing}
    }

    /* stylelint-disable-next-line no-descending-specificity */
    ${MediaBelowSmall} {
      font-size: 0.875rem;
    }

    ${MediaBelowMedium} {
      white-space: normal;
    }

    ${MediaBelowMedium} {
      white-space: normal;
      display: none;
    }
  }
`;
