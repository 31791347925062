import StoryblokClient from 'storyblok-js-client';

export const storyblokSpaceId = '278372';

export const storyblokClient = new StoryblokClient({
  accessToken: process.env.STORYBLOK_CDA_PUBLIC_TOKEN,
  cache: {
    clear: 'auto',
    type: 'memory',
  },
  maxRetries: 10,
});
