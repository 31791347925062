import { FC } from 'react';

import { Spacing } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

import { OStageNarrowType } from '../../types/storyblok';
import { getColor, getTheme } from '../../utils';
import { MTextIntro } from '../MTextIntro';
import { GridColumn, GridContainer, GridRow } from '../shared';

export const OStageNarrow: FC<OStageNarrowType> = ({ defineSubheadlineAsH1: hasSublineAsH1, textIntro, theme }) => (
  <Box backgroundColor={getColor(theme)} id="stage-narrow" pyd={Spacing.Xl} pym={Spacing.Xs}>
    <GridContainer>
      <GridRow>
        <GridColumn $push={2} $width={8}>
          <MTextIntro
            {...textIntro[0]}
            accentColor={getTheme(theme).contrast}
            buttonVariant="primary"
            headlineProps={{
              renderAs: hasSublineAsH1 ? 'div' : 'h1',
              size: 'L',
            }}
            textProps={{
              align: 'left',
              renderAs: 'div',
            }}
          />
        </GridColumn>
      </GridRow>
    </GridContainer>
  </Box>
);
