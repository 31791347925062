import styled, { StyledComponent } from '@lichtblick/styled';
import { Colors, BorderRadius, Spacing } from '@lichtblick/theme';
import { Text, TextProps } from '@lichtblick/ui-components';

export const Card = styled.div<{ $backgroundColor: Colors }>`
  align-items: flex-start;
  align-self: stretch;
  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${BorderRadius.S};
  display: flex;
  flex-direction: column;
  gap: ${Spacing.Xs};
  height: 100%;
  padding: ${Spacing.S};
`;

export const Avatar = styled.img`
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
`;

export const PersonTextInfoWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
`;

export const PersonWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: ${Spacing.Xs};
`;

export const StarsWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  gap: ${Spacing['3Xs']};
`;

export const RatingWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
`;

export const DateIndicator = styled(Text)`
  color: ${Colors.DarkGray};
`;

export const InlineText = styled(Text)`
  display: inline;
`;

export const InlineTextLink = styled(Text).attrs({ renderAs: 'a' })`
  color: ${Colors.DarkAqua};
  display: inline;
` as StyledComponent<'a', any, TextProps>;
