import { FC } from 'react';

import {
  CalloutBadgeContainer,
  SolarCalloutBadge,
  SolarCalloutBadgeText,
  SolarCalloutBadgeTextBold,
} from './CalloutBadge.styles';

import { richTextMapper } from '../../../../../helpers';
import { RichTextBlockType, RichTextDocType, SolarStageType } from '../../../../../types';

const hasContent = (rtDoc?: RichTextDocType): rtDoc is RichTextDocType & { content: RichTextBlockType[] } =>
  Boolean(rtDoc?.content?.some((richtText) => (richtText?.content || richtText?.text)?.length > 0));

export const CalloutBadge: FC<Pick<SolarStageType, 'calloutBadge'>> = ({ calloutBadge }) => {
  if (!calloutBadge?.length) {
    return null;
  }

  const { diameter, text } = calloutBadge[0];

  if (!hasContent(text)) {
    return null;
  }

  return (
    <CalloutBadgeContainer>
      <SolarCalloutBadge $diameter={diameter} data-testid="CalloutBadge">
        {richTextMapper(text, {
          p: SolarCalloutBadgeText,
          b: SolarCalloutBadgeTextBold,
        })}
      </SolarCalloutBadge>
    </CalloutBadgeContainer>
  );
};
