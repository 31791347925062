'use client';

import { useState, useEffect } from 'react';

import { logger } from '@lichtblick/logger';

import { CONSENT_STATUS_CHANGE, UCConsentEvent, UserCentricsServiceName } from '../ConsentManager';
import { useConsentManager } from '../ConsentManager/ConsentManagerProvider';

export const useConsent = (serviceName: UserCentricsServiceName): boolean => {
  const [isConsentGiven, setIsConsentGiven] = useState(false);
  const { getConsentStatus, isUcInitialized } = useConsentManager();

  useEffect(() => {
    const fetchInitialConsent = async () => {
      if (!isUcInitialized) {
        return;
      }

      try {
        const isConsentGiven = await getConsentStatus(serviceName);

        setIsConsentGiven(isConsentGiven ?? false);
      } catch (error) {
        logger.warn(`Unable to retrieve consent for service ${serviceName}`);
      }
    };

    const handleConsentChange = (event: UCConsentEvent) => {
      const service = Object.values(event.detail.services)?.find((service) => service.name === serviceName);

      setIsConsentGiven(Boolean(service?.consent?.given));
    };

    fetchInitialConsent();
    window.addEventListener(CONSENT_STATUS_CHANGE, handleConsentChange);

    return () => removeEventListener(CONSENT_STATUS_CHANGE, handleConsentChange);
  }, [serviceName, getConsentStatus, isUcInitialized]);

  return Boolean(isConsentGiven);
};
