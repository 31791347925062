import { FC, useMemo, useState } from 'react';

import { SelectInput } from '@lichtblick/ui-components';

import { StyledSelectInputWrapper } from './OTeaserGridArticles.styles';

import { MTeaserItemType, OTeaserGridArticlesType, RichTextDocType, TArticleType } from '../../types/storyblok';
import { MTeaserItemProps } from '../MTeaserItem';
import { OTeaserGrid } from '../OTeaserGrid/OTeaserGrid';

type Category = Exclude<TArticleType['categories'], undefined>[number];

export type UnmappedMTeaserItemProps = Omit<MTeaserItemProps, 'text'> &
  Pick<TArticleType, 'categories'> & {
    text: MTeaserItemType['text'] | RichTextDocType;
  };

export type TeaserGridArticlesProps = OTeaserGridArticlesType & {
  articles?: UnmappedMTeaserItemProps[];
  currentPageId?: string | null;
};

export const OTeaserGridArticles: FC<TeaserGridArticlesProps> = ({
  _uid,
  accent,
  anchorId,
  articles,
  currentPageId,
  limit,
  textIntro,
  theme,
}) => {
  const [category, setCategory] = useState<Category | ''>('');

  const listedArticles = useMemo(() => {
    if (!articles) {
      return [];
    }

    let listed = articles.filter((article) => article._uid !== currentPageId);

    if (limit) {
      listed = listed.slice(0, parseInt(limit, 10));
    }

    return listed;
  }, [articles, currentPageId, limit]);

  const options = useMemo(() => {
    const usedCategories = listedArticles
      .flatMap((article) => article.categories)
      .filter((value) => typeof value === 'string');

    return usedCategories
      .filter((value, index, array) => {
        return array.indexOf(value) === index;
      })
      .sort()
      .map((category) => ({
        value: category,
        label: category,
      }));
  }, [listedArticles]);

  const filteredArticles = useMemo(
    () => listedArticles.filter((article) => category === '' || article.categories?.includes(category)),
    [category, listedArticles],
  );

  if (!articles) {
    return null;
  }

  return (
    <>
      {!currentPageId && options.length > 0 && (
        <StyledSelectInputWrapper>
          <SelectInput
            allowEmptySelection
            name="filter"
            onChange={(e) => setCategory(e.target.value as Category)}
            options={options}
            placeholder="Kategorie"
            value={category}
          />
        </StyledSelectInputWrapper>
      )}
      <OTeaserGrid
        _uid={_uid}
        accent={accent}
        anchorId={anchorId}
        columns={listedArticles.length > 2 ? '3' : '2'}
        component="o-teaser-grid"
        items={filteredArticles}
        textIntro={textIntro}
        theme={theme}
      />
    </>
  );
};
