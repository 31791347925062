import styled from '@lichtblick/styled';

import { Li } from './Li';

export const Ul = styled.ul`
  list-style: none;

  ${Li} {
    &::before {
      content: '\\2022';
    }
  }

  ${Li} ${Li} {
    &::before {
      content: '\\25e6';
    }
  }

  ${Li} ${Li} ${Li} {
    &::before {
      content: '\\2043';
    }
  }

  ${Li} ${Li} ${Li} ${Li} {
    &::before {
      content: '\\2023';
    }
  }
`;
