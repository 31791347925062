import { renderToStaticMarkup } from 'react-dom/server';

import { logger } from '@lichtblick/logger';

import { richTextMapper } from './richTextMapper';

import { SettingsType } from '../types/storyblok';

export type MaintenanceContent = {
  buttonLink?: string | null;
  buttonText?: string | null;
  headline?: string | null;
  text?: string | null;
};

type MaintenanceProductContent = {
  text: MaintenanceContent;
};

export type MaintenanceProductsConfig = Partial<Record<string, MaintenanceProductContent | null>>;

// Contentful delivers the keys separated by a hyphen, but the AllInOneCalculator and the Forms expect camel case.
const maintenanceProductMap: Record<string, string> = {
  'form-b2b-electricity': 'formB2bElectricity',
  'form-b2b-gas': 'formB2bGas',
  'home-charging': 'homeCharging',
  'on-road-charging': 'onRoadCharging',
  'service-view': 'serviceView',
  'tariff-change': 'tarifChange',
};

// Contentful delivers the key "home-charging", but the AllInOneCalculator expects "homeCharging".
const mapProductName = (product: string) =>
  product in maintenanceProductMap ? maintenanceProductMap[product] : product;

export const createMaintenanceProductsConfig = (
  products: SettingsType['maintenanceProducts'],
): MaintenanceProductsConfig =>
  (products ?? []).reduce((products, collectionItem) => {
    const { maintenanceText, product } = collectionItem || {};
    const { button, text } = maintenanceText?.[0] || {};

    if (!product) {
      logger.warn(`Unable to create maintenance config for product ${product}.`);

      return products;
    }

    const markup = text ? richTextMapper(text) : <></>;

    products[mapProductName(product)] = {
      text: {
        buttonText: button?.[0]?.text,
        buttonLink: button?.[0]?.linkUrl,
        text: renderToStaticMarkup(<>{markup}</>),
      },
    };

    return products;
  }, {} as MaintenanceProductsConfig);
