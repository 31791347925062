import { FC, PropsWithChildren } from 'react';

import { Legend, Wrapper } from './RadioGroup.styles';

import { FieldFooter } from '../FieldFooter/FieldFooter';

export type RadioGroupProps = PropsWithChildren<{
  className?: string;
  'data-testid'?: string;
  errorMessage?: string;
  label?: string;
}>;

export const RadioGroup: FC<RadioGroupProps> = ({ children, errorMessage, label, ...wrapperProps }) => (
  <Wrapper {...wrapperProps}>
    {Boolean(label) && <Legend as="legend">{label}</Legend>}
    {children}
    {Boolean(errorMessage) && <FieldFooter fieldFooterLabel={errorMessage} fieldFooterType="error" />}
  </Wrapper>
);
