import { alpha } from '../utils/colors';

export enum Colors {
  Aqua = '#0BA3C1',
  BackgroundGray = '#f5f5f5',
  Berry = '#D4496A',
  Black = '#000000',
  DarkAqua = '#087A91',
  DarkBerry = '#952741',
  DarkGray = '#767676',
  DarkGreen = '#054D2E',
  DarkMoss = '#3A5047',
  DarkOrange = '#FA9105',
  DarkRed = '#EA180F',
  DarkSmoke = '#313C49',
  FadedAqua = '#F1F9FA',
  FadedBerry = '#FDF6F8',
  FadedGray = '#F5F5F5',
  FadedGreen = '#F2F9F5',
  FadedMoss = '#F5F8F7',
  FadedRed = '#FEF3F3',
  FadedSmoke = '#F1F4F6',
  Gray = '#B2B2B2',
  Green = '#077144',
  LightAqua = '#75C1D0',
  LightBerry = '#E8A6B6',
  LightGray = '#E6E6E6',
  LightGreen = '#7CC199',
  LightMoss = '#9EB8AE',
  LightRed = '#F78782',
  LightSmoke = '#7390A1',
  Moss = '#658A7C',
  Orange = '#FCBC67',
  Red = '#F45750',
  Smoke = '#465B68',
  White = '#FFFFFF',
}

export const StateColors = {
  AlternativeFocus: Colors.DarkOrange,
  Error: Colors.Red,
  Focus: Colors.Orange,
  InactiveLight: alpha(Colors.Black, 0.05),
  Success: Colors.Green,
};
