/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';

import { SolarTeaserType } from '../../types/storyblok';

// TODO: dummy
export const SolarTeaser: FC<SolarTeaserType> = ({ buttonLink, buttonText, chip, headline, text }) => (
  <div data-testid="heating-calculator">
    <h1>{headline}</h1>
    <span>{chip}</span>
    <dfn>{text}</dfn>
    <dfn>{buttonText}</dfn>
    <dfn>{JSON.stringify(buttonLink)}</dfn>
  </div>
);
