import styled from '@lichtblick/styled';
import { MediaMediumAndAbove, Spacing } from '@lichtblick/theme';
import { AccentText, Chip, Headline, StyledBoxCTA, StyledTextCTA } from '@lichtblick/ui-components';

import { AText } from '../AText';
import { getColumnWidth } from '../shared';

export const Wrapper = styled.div`
  ${MediaMediumAndAbove} {
    display: flex;
    justify-content: space-between;
  }
`;

export const StyledChip = styled(Chip)`
  margin-bottom: ${Spacing.Xs};
`;

export const Content = styled.div`
  ${MediaMediumAndAbove} {
    width: ${getColumnWidth(8)};
  }
`;

export const Price = styled.div`
  ${MediaMediumAndAbove} {
    text-align: right;
    width: calc(${getColumnWidth(4)} - ${Spacing.Xs});
  }

  ${StyledBoxCTA}, ${StyledTextCTA} {
    margin-top: ${Spacing.Xxs};
  }
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${Spacing.Xxs};
`;

export const StyledAccentText = styled(AccentText)`
  display: block;
  margin-bottom: ${Spacing.Xxs};
`;

export const StyledText = styled(AText)`
  margin-bottom: ${Spacing.Xxs};
`;
