import { ColorMode, ColorPalette, Colors } from '../constants';

const modes: ColorMode[] = ['faded', 'light', 'default', 'dark'];

export const getDestructuredColor = (color: Colors) => {
  const [name = ''] = Object.entries(Colors).find(([, value]) => value === color) ?? [];
  const mode: ColorMode = modes.find((mode) => name.toLowerCase().startsWith(mode)) ?? 'default';
  const base = name.replace(new RegExp(`^${mode}`, 'i'), '') as keyof typeof Colors;

  return { base, mode };
};

export const getColorVariant = (color: Colors, variant: 'lighter' | 'darker' = 'lighter') => {
  const { base, mode } = getDestructuredColor(color);

  if (!ColorPalette[base]) {
    return color;
  }

  const newModeIndex = modes.indexOf(mode) + (variant === 'lighter' ? -1 : 1);

  if (newModeIndex < 0) {
    return Colors.White;
  } else if (newModeIndex >= modes.length) {
    return Colors.Black;
  } else {
    return ColorPalette[base][modes[newModeIndex]];
  }
};
