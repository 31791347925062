import styled from '@lichtblick/styled';

import { Li } from './Li';

export const Ol = styled.ol`
  list-style: none;
  counter-reset: item;

  ${Li} {
    counter-increment: item;
    padding: 0.5rem 0 0.5rem 1.5rem;

    &::before {
      font-weight: 600;
      content: counter(item) '.';
      display: inline-block;
      font-size: 1rem;
      position: absolute;
      right: calc(100% - 0.625rem);
      top: 0.5rem;
    }
  }
`;
