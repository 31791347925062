import { FC } from 'react';

import { Text } from '@lichtblick/ui-components';

import { StyledHeadline, TextLayer } from './OStoryPage.styles';

import { AImageType, AVideoType, OStoryPageType } from '../../types/storyblok';

const AUTO_ADVANCE_AFTER = '7s';

export type OSToryPageProps = OStoryPageType & {
  shouldAutoPlay?: boolean | null;
};

type MediaProps = {
  isAudioEnabled: boolean | undefined;
  media: [AImageType | AVideoType];
  videoId: string;
};

const Media: FC<MediaProps> = ({ isAudioEnabled, media, videoId }) => {
  const mediaItem = media[0];

  if (mediaItem.component === 'a-image') {
    return (
      <amp-img
        alt={mediaItem.imageMobile.alt}
        height="960"
        layout="responsive"
        src={`${mediaItem.imageMobile.filename}?w=720&h=960&q=80`}
        width="720"
      ></amp-img>
    );
  }

  const mp4URL = mediaItem.videoSourceMobile?.[0].mp4?.filename;
  const webMURL = mediaItem.videoSourceMobile?.[0].webM?.filename;

  return (
    <amp-video
      autoplay
      height="960"
      id={videoId}
      layout="responsive"
      loop
      noaudio={!isAudioEnabled}
      poster={`${mediaItem.videoSourceMobile?.[0].poster?.filename}?w=720&h=960&q=50`}
      width="720"
    >
      <source src={typeof mp4URL === 'string' ? mp4URL : ''} type="video/mp4" />
      {mediaItem.videoSourceMobile?.[0].webM && (
        <source src={typeof webMURL === 'string' ? webMURL : ''} type="video/webm" />
      )}
    </amp-video>
  );
};

export const OStoryPage: FC<OSToryPageProps> = ({
  _uid,
  enableAudio: isAudioEnbaled,
  headline,
  layout,
  media,
  paragraph,
  shouldAutoPlay,
  theme,
}) => {
  const videoId = `${_uid}-video`;
  const mediaItem = media?.[0];
  const autoAdvanceAfter = mediaItem?.component === 'a-video' ? videoId : AUTO_ADVANCE_AFTER;

  return (
    <amp-story-page auto-advance-after={shouldAutoPlay ? autoAdvanceAfter : undefined} id={_uid}>
      {media?.length === 1 && (
        <amp-story-grid-layer template="fill">
          <Media isAudioEnabled={isAudioEnbaled} media={media} videoId={videoId} />
        </amp-story-grid-layer>
      )}
      <amp-story-grid-layer template="thirds">
        {/* eslint-disable-next-line react/no-unknown-property */}
        <div grid-area={layout ?? 'lower-third'}>
          <TextLayer $layout={layout ?? 'lower-third'} $theme={theme ?? 'white'}>
            {headline && <StyledHeadline size="M">{headline}</StyledHeadline>}
            {paragraph && <Text size="M">{paragraph}</Text>}
          </TextLayer>
        </div>
      </amp-story-grid-layer>
    </amp-story-page>
  );
};
