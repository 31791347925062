import { KeyboardEvent, SyntheticEvent, forwardRef } from 'react';

import { Option } from './ColorOption.styles';

export type ColorSelectorOption = {
  color: string;
  isDisabled?: boolean;
  textContent?: string;
};

export type ColorOptionProps = ColorSelectorOption & {
  className?: string;
  isSelected: boolean;
  name: string;
  onChange: (value: string) => void;
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>) => void;
};

export const ColorOption = forwardRef<HTMLInputElement, ColorOptionProps>(
  ({ className, color, isDisabled = false, isSelected = false, name, onChange, onKeyDown, textContent }, ref) => {
    const handleChangeEvent = (event: SyntheticEvent<HTMLInputElement>) => {
      event.preventDefault();

      if (isDisabled || isSelected) {
        return;
      }

      onChange(color);
    };

    return (
      <Option
        aria-label={textContent}
        checked={isSelected}
        className={className}
        color={color}
        disabled={isDisabled}
        name={name}
        onChange={handleChangeEvent}
        onClick={handleChangeEvent}
        onFocus={handleChangeEvent}
        onKeyDown={onKeyDown}
        ref={ref}
        title={textContent}
        type="radio"
        value={color}
      />
    );
  },
);
