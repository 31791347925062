import { ComponentType, FC } from 'react';

import { Countdown } from './Countdown';
import { Eaas } from './eaas';
import { Equota } from './Equota';
import { Gapminder } from './Gapminder';

import { AppConfigType, OSnowflakeType } from '../../types/storyblok';
import { AnchorMark } from '../shared';

const SnowflakeMap: Record<string, ComponentType<AppConfigType>> = {
  countdown: Countdown,
  eaas: Eaas,
  equota: Equota,
  gapminder: Gapminder,
};

export const OSnowflake: FC<OSnowflakeType> = ({ anchorId, appConfig, name }) => {
  const Snowflake = SnowflakeMap[name];

  if (!Snowflake || !appConfig?.[0]) {
    return null;
  }

  return (
    <div data-testid="snowflake">
      {anchorId && <AnchorMark anchorId={anchorId} />}
      <Snowflake {...appConfig[0]} />
    </div>
  );
};
