import { FC } from 'react';

import { Colors } from '@lichtblick/theme';
import { Chip, Headline, Text } from '@lichtblick/ui-components';

import { ProductCardOptionalRoot } from './MProductCardOptional.styles';
import { ProductInformation } from './shared';

import { richTextMapper } from '../../../../helpers';
import { ProductListItemType } from '../../../../types';
import { AButton } from '../../../AButton';
import { AImage } from '../../../AImage';
import { StyledItem, StyledIcon } from '../../../AListItem/AListItem.styles';
import { StyledMList } from '../../../MList';

export const MProductCardOptional: FC<{ backgroundColor: Colors; item?: ProductListItemType }> = ({
  backgroundColor,
  item,
}) => {
  const itemIsDefined = (item?: ProductListItemType): item is ProductListItemType => Boolean(item?.component);

  const isDefined = itemIsDefined(item);

  if (!isDefined) {
    return null;
  }

  const { image, sellingPoints, subtitle, textCTA, title } = item;

  return (
    <ProductCardOptionalRoot $backgroundColor={backgroundColor}>
      <Chip backgroundColor={Colors.Gray} label="Optional" />
      {image && <AImage {...image?.[0]} />}
      <ProductInformation>
        <Text size="Xs">{subtitle}</Text>
        <Headline renderAs="h3" size="S">
          {title}
        </Headline>
        {sellingPoints &&
          richTextMapper(sellingPoints, {
            ul: ({ children }) => <StyledMList>{children}</StyledMList>,
            li: ({ children }) => (
              <StyledItem>
                <StyledIcon $color="light-moss" $textSize="S" name={'CheckIconXs'} />
                {children}
              </StyledItem>
            ),
          })}
        {textCTA?.[0] && <AButton {...textCTA?.[0]} />}
      </ProductInformation>
    </ProductCardOptionalRoot>
  );
};
