import { FC } from 'react';

import { SolarStage as SolarStageBaseline } from './Baseline/SolarStage';
import { SolarStageExperiment } from './Experiment/SolarStageExperiment';

import { SolarStageType } from '../../../types';

export const SolarStage: FC<SolarStageType> = (props) => (
  <>
    <SolarStageBaseline {...props} />
    <SolarStageExperiment {...props} />
    <SolarStageExperiment {...props} hasSoftFunnelEntry />
  </>
);
