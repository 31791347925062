import { FC } from 'react';

import { Spacing } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

import {
  Banner,
  Badge,
  Headline,
  Description,
  StyledColumn,
  ComparisonCards,
  GridContainerCards,
} from './OEAutoTariffComparison.styles';

import { OEautoTariffComparisonType } from '../../types/storyblok';
import { MEAutoTariffCard } from '../MEAutoTariffCard';
import { AnchorMark, GridRow } from '../shared';

export const OEAutoTariffComparison: FC<OEautoTariffComparisonType> = ({
  anchorId,
  badge,
  cards,
  description,
  headline,
}) => {
  return (
    <Box pyd={Spacing.Xl} pym={Spacing.M}>
      {anchorId && <AnchorMark anchorId={anchorId} />}
      <GridContainerCards>
        <Banner>
          <StyledColumn $width={7}>
            <GridRow $isAlignedCenter>
              <Badge>{badge}</Badge>
            </GridRow>

            <GridRow>
              <Headline>{headline}</Headline>
            </GridRow>

            <GridRow>
              <Description>{description}</Description>
            </GridRow>
          </StyledColumn>
        </Banner>

        <ComparisonCards>{cards?.map((item) => <MEAutoTariffCard {...item} key={item._uid} />)}</ComparisonCards>
      </GridContainerCards>
    </Box>
  );
};
