import { ChangeEventHandler, MouseEventHandler, forwardRef, useCallback } from 'react';

import { CounterMinusIconS } from '@lichtblick/icons/svg/counter-minus/counter-minus-s';
import { CounterPlusIconS } from '@lichtblick/icons/svg/counter-plus/counter-plus-s';

import { StyledStepper, StepperButton } from './Stepper.styles';

import { TextfieldProps } from '../Textfield/Textfield';

export type StepperProps = Omit<
  TextfieldProps,
  'isBold' | 'isCentered' | 'defaultValue' | 'leftSlot' | 'rightSlot' | 'suffixText' | 'value' | 'onChange'
> & {
  defaultValue?: number;
  max?: number;
  min?: number;
  onChange: (value?: number) => void;
  step?: number;
  value?: number;
};

/**
 * ! Controlled state only
 * Handles min/max via button but also via text input.
 * `onCountChange` is only called with a valid number or an empty string.
 */
export const Stepper = forwardRef<HTMLInputElement, StepperProps>(
  (
    { ['data-testid']: testid = 'counter', isDisabled, max = Infinity, min = 0, onChange, step = 1, value, ...props },
    ref,
  ) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value: input } }) => {
      const parsedInput = parseFloat(input);

      if (input === '') {
        onChange(undefined);
      } else if (!isNaN(parsedInput) && isFinite(parsedInput)) {
        onChange(parsedInput);
      }
    };

    const handleCountDown: MouseEventHandler<HTMLButtonElement> = (event) => {
      event.preventDefault();

      if (typeof value === 'number') {
        onChange(Math.max(value - step, min));
      }
    };

    const handleCountUp: MouseEventHandler<HTMLButtonElement> = (event) => {
      event.preventDefault();

      if (typeof value === 'number') {
        onChange(Math.min(value + step, max ?? Infinity));
      } else {
        onChange(min);
      }
    };

    return (
      <StyledStepper
        {...props}
        data-testid={testid}
        inputMode="numeric"
        isBold
        isCentered
        isDisabled={isDisabled}
        leftSlot={
          <StepperButton
            aria-label={`um ${step} verringern`}
            data-testid={`${testid}_minus`}
            disabled={isDisabled || value === undefined || value <= min}
            onClick={handleCountDown}
            type="button"
          >
            <CounterMinusIconS />
          </StepperButton>
        }
        max={max}
        min={min}
        onChange={useCallback(handleChange, [onChange])}
        ref={ref}
        rightSlot={
          <StepperButton
            aria-label={`um ${step} erhöhen`}
            data-testid={`${testid}_plus`}
            disabled={isDisabled || (value !== undefined && value >= max)}
            onClick={handleCountUp}
            type="button"
          >
            <CounterPlusIconS />
          </StepperButton>
        }
        type="number"
        value={value ?? ''}
      />
    );
  },
);
