import styled, { css } from '@lichtblick/styled';
import { BorderRadius, Breakpoints, Colors, MediaMediumAndAbove, Spacing, themeColors } from '@lichtblick/theme';
import { Container } from '@lichtblick/ui-components';

export const getColumnWidth = (numberOfColumns: number): string => `${(100 / 12) * numberOfColumns}%`;

export const getContainerStyle = (style?: string | null): ContainerStyle | undefined =>
  style === 'full-boxed' || style === 'bottom-boxed' ? style : undefined;

type ContainerStyle = 'full-boxed' | 'bottom-boxed';

type GridCount = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

// TODO remove this usage and use Box from ui-components with GridRow
export const Section = styled(Container)`
  display: flex;
  justify-content: center;
`;

// TODO remove this usage and use Box from ui-components with GridRow
// Naming: Root is actually not the best naming here, we will have to find a better one
// it should replace the former "col-8 push-2" class, cause grid is something we do not need here.
// For other contentful components we will have to create a replacement for the "col-10 push-1" and "col-12" class too.
export const Root = styled.div`
  width: 100%;
  ${MediaMediumAndAbove} {
    width: 66.66%;
  }
`;

export const GridContainer = styled.div<{ $backgroundColor?: Colors; $containerStyle?: ContainerStyle }>`
  margin: 0 auto;
  max-width: ${Breakpoints.Large}px;
  width: 100%;

  ${({ $backgroundColor, $containerStyle }) => {
    if ($containerStyle === 'full-boxed') {
      return css`
        & > ${GridRow} {
          background: ${$backgroundColor};
          color: ${$backgroundColor && themeColors($backgroundColor).primary};

          ${MediaMediumAndAbove} {
            margin: 0 ${Spacing.Xl};
          }
        }

        & > ${GridRow}:only-child {
          padding: ${Spacing.Xs};
          border-radius: ${BorderRadius.S};

          ${MediaMediumAndAbove} {
            padding: ${Spacing.M} ${Spacing.S};
          }
        }

        & > ${GridRow}:nth-last-child(2) {
          padding: ${Spacing.Xs} 0;
          border-radius: ${BorderRadius.S} ${BorderRadius.S} 0 0;

          ${MediaMediumAndAbove} {
            padding: ${Spacing.M} ${Spacing.S} 0;
          }
        }

        & > ${GridRow}:nth-last-child(2) + ${GridRow}:nth-child(2) {
          padding: ${Spacing.Xs};
          border-radius: 0 0 ${BorderRadius.S} ${BorderRadius.S};

          ${MediaMediumAndAbove} {
            padding: 0 ${Spacing.M} ${Spacing.S};
          }
        }
      `;
    } else if ($containerStyle === 'bottom-boxed') {
      return css`
        & > ${GridRow}:nth-child(2),
        & > ${GridRow}:only-child {
          background: ${$backgroundColor};
          color: ${$backgroundColor && themeColors($backgroundColor).primary};

          padding: ${Spacing.Xs};
          border-radius: ${BorderRadius.S};

          ${MediaMediumAndAbove} {
            padding: ${Spacing.M} ${Spacing.S};
            margin: 0 ${Spacing.Xl};
          }
        }
      `;
    }
  }}
`;

export const GridRow = styled.div<{ $isAlignedCenter?: boolean; $isReversed?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0 ${Spacing.Xs};

  ${MediaMediumAndAbove} {
    flex-direction: row;
    margin: 0 3.5rem;

    ${({ $isAlignedCenter }) =>
      $isAlignedCenter &&
      css`
        align-items: center;
      `}

    ${({ $isReversed }) =>
      $isReversed &&
      css`
        flex-direction: row-reverse;
      `}
  }
`;

export const GridColumn = styled.div<{ $isAlignedMiddle?: boolean; $push?: GridCount; $width: GridCount }>`
  ${MediaMediumAndAbove} {
    padding: 0 ${Spacing.Xxs};
    width: ${({ $width }) => getColumnWidth($width)};

    ${({ $push }) =>
      $push &&
      css`
        margin-left: ${getColumnWidth($push)};
      `}

    ${({ $isAlignedMiddle }) =>
      $isAlignedMiddle &&
      css`
        margin-top: auto;
        margin-bottom: auto;
      `}
  }
`;
