import styled from '@lichtblick/styled';

import { Text } from '../../atoms/Text/Text';
import { AccordionRoot } from '../Accordion';

export const Wrapper = styled(AccordionRoot)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Legend = styled(Text)`
  font-weight: bold;
  margin-bottom: 0.75rem;
`;
