import styled from '@lichtblick/styled';
import { Colors, MediaMediumAndAbove, Spacing } from '@lichtblick/theme';
import { Headline, LegacyButton } from '@lichtblick/ui-components';

import { AText } from '../AText';
import { GridColumn, GridContainer } from '../shared';

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${Spacing.Xxs};
`;

export const StyledTagline = styled(AText)`
  color: ${Colors.Gray};
  margin-bottom: ${Spacing.Xxs};
`;

export const StyledButton = styled(LegacyButton)`
  margin-top: ${Spacing.Xxs};
`;

export const GridContainerExtend = styled(GridContainer)`
  align-items: center;
`;

export const WrapperList = styled.div`
  margin-bottom: ${Spacing.Xxs};
`;

export const StyledText = styled(AText)`
  margin-bottom: ${Spacing.Xxs};
`;

export const GridColumnsExtend = styled(GridColumn)<{ $isLeftAligned?: boolean | null }>`
  padding: ${Spacing.Xs} 0 0;

  ${MediaMediumAndAbove} {
    padding: ${({ $isLeftAligned }) =>
      $isLeftAligned ? `0 ${Spacing.M} 0 ${Spacing.Xs}` : `0 ${Spacing.Xs} 0 ${Spacing.M}`};
  }
`;

export const Price = styled.div`
  margin-bottom: ${Spacing.Xxs};
  margin-top: ${Spacing.Xl};
`;

export const StyledPriceSmallText = styled(AText)`
  opacity: 0.6;
`;
