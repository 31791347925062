import styled from '@lichtblick/styled';
import { MediaMediumAndAbove, Spacing } from '@lichtblick/theme';

export const TextIntroWrapper = styled.div<{ $isFullWidth: boolean }>`
  margin-bottom: ${Spacing.M};

  ${MediaMediumAndAbove} {
    margin-bottom: ${({ $isFullWidth }) => ($isFullWidth ? Spacing.L : 0)};
  }
`;
