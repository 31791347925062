import styled, { css } from '@lichtblick/styled';
import { BorderRadius, Colors, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';

import { AText } from '../AText';

export const Root = styled.div`
  background-color: ${Colors.White};
  border: 2px solid ${Colors.LightGray};
  border-radius: ${BorderRadius.S};
  overflow-x: scroll;
  overflow-y: hidden;
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
`;

const tabelHeaderAndRowStyles = css`
  border-bottom: 2px solid ${Colors.LightGray};
  padding: ${Spacing.Xs} ${Spacing.Xxs};

  ${MediaSmallAndAbove} {
    padding: ${Spacing.S} ${Spacing.Xs};
  }

  &:first-of-type {
    padding-left: ${Spacing.Xs};
    ${MediaSmallAndAbove} {
      padding-left: ${Spacing.S};
    }
  }

  &:last-of-type {
    padding-right: ${Spacing.Xs};
    ${MediaSmallAndAbove} {
      padding-right: ${Spacing.S};
    }
  }

  vertical-align: baseline;
`;

export const TableHeader = styled.th`
  background-color: ${Colors.FadedGray};
  text-align: left;
  ${tabelHeaderAndRowStyles}
`;

export const TableData = styled.td`
  ${tabelHeaderAndRowStyles}
`;

export const TableRow = styled.tr`
  height: 3rem;

  &:last-child {
    ${TableHeader},
    ${TableData} {
      border-bottom: 0;
    }
  }
`;

export const StyledText = styled(AText)`
  white-space: pre-line;
`;
