import { FC } from 'react';

import { PictureMobile, PictureDesktop } from './AImage.styles';

import { AImageType } from '../../types';

export type AImageProps = Omit<AImageType, 'component' | '_uid'> & {
  _uid?: AImageType['_uid'];
  className?: string;
  component?: AImageType['component'];
  isBackground?: boolean;
  loading?: 'eager' | 'lazy';
  maxWidth?: number;
  params?: Record<string, string>;
};

export const AImage: FC<AImageProps> = ({
  altText,
  fullWidth: isFullWidth,
  imageDesktop,
  imageMobile,
  isBackground,
  loading,
  ...props
}) => {
  const description = altText ?? '';

  return (
    <>
      <PictureMobile
        $isHiddenOnDesktop={Boolean(imageDesktop?.filename)}
        asset={imageMobile}
        className={props.className}
        data-testid="image-mobile"
        description={description}
        isBackground={isBackground}
        isFullWidth={isFullWidth}
        loading={loading}
        maxWidth={props.maxWidth}
      />
      <PictureDesktop
        asset={imageDesktop}
        className={props.className}
        data-testid="image-desktop"
        description={description}
        isBackground={isBackground}
        isFullWidth={isFullWidth}
        loading={loading}
        maxWidth={props.maxWidth}
      />
    </>
  );
};
