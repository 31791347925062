import { FC } from 'react';

type CarCalculatorConfigProps = {
  configId: string;
};

export const OCarCalculatorConfig: FC<CarCalculatorConfigProps> = ({ configId }) => {
  const configData = {
    graphql: {
      graphql_server: process.env.NEXT_PUBLIC_GRAPHQL_SERVER,
    },
  };

  return (
    <div id={configId} style={{ display: 'none' }}>
      {JSON.stringify(configData)}
    </div>
  );
};
