import styled from '@lichtblick/styled';
import { BorderRadius, Colors, MediaMediumAndAbove, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';

export const ListContainer = styled.div`
  padding-bottom: ${Spacing.M};
  padding-top: ${Spacing.M};

  ${MediaMediumAndAbove} {
    padding-bottom: ${Spacing.Xl};
    padding-top: ${Spacing.Xl};
  }
`;

export const List = styled.div<{ $theme: string | null | undefined }>`
  background-color: ${({ $theme }) => ($theme === 'white' ? Colors.FadedGray : Colors.White)};
  border-radius: ${BorderRadius.S};
  margin-top: ${Spacing.M};
  padding: ${Spacing.M} ${Spacing.Xs};

  ${MediaSmallAndAbove} {
    padding: ${Spacing.M};
  }
  ${MediaMediumAndAbove} {
    margin-top: 0;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${Spacing.M};
  text-align: center;
`;
