import styled from '@lichtblick/styled';
import { Spacing } from '@lichtblick/theme';

export const FormContainer = styled.div`
  padding-bottom: ${Spacing.Xl};
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 4rem 0;
`;
