import { FC, KeyboardEvent, useRef } from 'react';

import { ColorOption, ColorSelectorOption } from './ColorOption';
import { Wrapper } from './ColorSelector.styles';

export type ColorSelectorProps = {
  ariaLabel?: string;
  isDisabled?: boolean;
  name: string;
  onChange: (value: string) => void;
  options: ColorSelectorOption[];
  value: string;
};

export const ColorSelector: FC<ColorSelectorProps> = ({
  ariaLabel,
  isDisabled = false,
  name,
  onChange,
  options,
  value,
}) => {
  const enabledColorOptionRefs = useRef<HTMLInputElement[]>([]);

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    const options = enabledColorOptionRefs.current;
    const currentIndex = options.findIndex((option) => value === option.value);
    let offset = 0;

    if (options.length === 0 || currentIndex < 0) {
      return;
    }

    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowLeft': {
        offset = -1;
        break;
      }
      case 'ArrowDown':
      case 'ArrowRight': {
        offset = 1;
        break;
      }
      default: {
        break;
      }
    }

    if (offset) {
      const nextIndex = (options.length + currentIndex + offset) % options.length;
      const nextOption = options[nextIndex];

      event.preventDefault();
      nextOption.focus();
      onChange(nextOption.value);
    }
  };

  return (
    <Wrapper aria-label={ariaLabel ?? undefined}>
      {options.map(({ color, isDisabled: isOptionDisabled, textContent }) => (
        <ColorOption
          color={color}
          isDisabled={isDisabled || isOptionDisabled}
          isSelected={color === value}
          key={color}
          name={name}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          ref={(ref) => {
            if (ref && !isDisabled) {
              enabledColorOptionRefs.current.push(ref);
            }
          }}
          textContent={textContent}
        />
      ))}
    </Wrapper>
  );
};
