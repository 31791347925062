import { FC, useState } from 'react';

import { formatPrice } from '@lichtblick/localization';
import { SmallAndAbove, Colors } from '@lichtblick/theme';
import { AccentText, Chip, Headline } from '@lichtblick/ui-components';

import {
  Button,
  Card,
  ChipWrapper,
  Curtain,
  Image,
  ImageContent,
  List,
  PriceWrapper,
  StyledPriceSubline,
  Teaser,
} from './MProductOverviewCard.styles';

import { componentMapper } from '../../helpers/componentMapper';
import { useMediaQuery } from '../../hooks';
import { MProductOverviewCardType } from '../../types/storyblok';
import { ColorKey, getColor } from '../../utils';
import { MList } from '../MList';
import { MProductCardTeaser } from '../MProductCardTeaser';

export type ProductOverviewCardProps = MProductOverviewCardType & {
  isLoading?: boolean;
  price?: number | null;
  teaserProductPrice?: number | null;
  theme?: ColorKey | null;
};

export const MProductOverviewCard: FC<ProductOverviewCardProps> = ({
  button,
  campaign,
  chip,
  headline,
  highlighted: isHighlighted,
  list,
  media,
  price,
  productCodes,
  teaser,
  teaserProductCodes,
  teaserProductPrice,
  theme,
}) => {
  const isMatch = useMediaQuery(SmallAndAbove);
  const hasTeaserCodesAndPrice = Boolean(teaserProductCodes && teaserProductCodes.value?.length && teaserProductPrice);

  const [isToggleEnabled, setisToggleEnabled] = useState(false);

  const onToggleClick = () => {
    setisToggleEnabled(!isToggleEnabled);
  };

  const getPrice = () => {
    const suffix = hasTeaserCodesAndPrice ? '*' : '';

    if (isToggleEnabled) {
      return `ab ${formatPrice(teaserProductPrice, '€', 2)}${suffix}`;
    }

    return `ab ${formatPrice(price, '€', 2)}${suffix}`;
  };

  const getSubline = () => {
    const PRICE_WITHOUT_DISCOUNT = '*ohne Kombi-Rabatt';
    const PRICE_WITH_DISCOUNT = '*mit Kombi-Rabatt';

    if (isToggleEnabled) {
      return `${PRICE_WITHOUT_DISCOUNT} ${formatPrice(price, '€', 2)}`;
    }

    return `${PRICE_WITH_DISCOUNT} ${formatPrice(teaserProductPrice, '€', 2)}`;
  };

  return (
    <Card
      data-campaign={campaign}
      data-product-codes={productCodes?.value}
      data-teaser-product-codes={teaserProductCodes?.value}
      data-testid="product-overview-card"
    >
      {chip && (
        <ChipWrapper>
          <Chip backgroundColor={Colors.Black} label={chip} />
        </ChipWrapper>
      )}
      <Curtain $theme={getColor(theme) ?? Colors.Moss}>
        <Headline renderAs="h3" size="S">
          {headline}
        </Headline>
      </Curtain>
      <Image>
        <ImageContent>{media?.length === 1 && componentMapper(media[0], media[0]._uid)}</ImageContent>
      </Image>
      <PriceWrapper $isHighlighted={isHighlighted ?? false} $isLoading={!price}>
        <AccentText size={isHighlighted && isMatch ? 'L' : 'M'}>{getPrice()}</AccentText>
      </PriceWrapper>
      {hasTeaserCodesAndPrice && (
        <StyledPriceSubline renderAs="span" size="Xs">
          {getSubline()}
        </StyledPriceSubline>
      )}
      {list?.length === 1 && (
        <List $isHighlighted={isHighlighted ?? false}>
          <MList
            {...list[0]}
            items={
              list[0]?.items?.filter(Boolean).map((item) => (item ? { ...item, textSizeOverride: 'S' } : item)) ?? []
            }
          />
        </List>
      )}
      {hasTeaserCodesAndPrice && teaser?.length === 1 && (
        <Teaser>
          <MProductCardTeaser {...teaser[0]} isToggleEnabled={isToggleEnabled} onToggleClick={onToggleClick} />
        </Teaser>
      )}
      {button?.length === 1 && (
        <Button $isHighlighted={isHighlighted ?? false}>{componentMapper(button[0], button[0]._uid)}</Button>
      )}
    </Card>
  );
};
