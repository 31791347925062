import { logger } from '@lichtblick/logger';

export const isSolarPartnerAvailable = async (zipCode: string): Promise<boolean> => {
  if (!zipCode) {
    const error = 'No zip code provided';

    logger.error(error);

    throw new Error(error);
  }

  const { sdk } = await import('../../graphqlClient');

  const data = await sdk.isSolarPartnerAvailable({ zipCode });

  return data?.validateZipCodeForSystemInstallation.isValid;
};
