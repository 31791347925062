import { FC, KeyboardEventHandler, MouseEventHandler, useRef, useState } from 'react';

import { generateUuid } from '@lichtblick/localization';
import { Spacing } from '@lichtblick/theme';
import { Box, Headline } from '@lichtblick/ui-components';

import {
  Control,
  Controls,
  NextIcon,
  PreviousIcon,
  SkipLink,
  SlideItem,
  Slider,
  Slides,
  StyledTeaserItem,
  StyledTextIntro,
} from './OCarousel.styles';

import { OCarouselType } from '../../types/storyblok';
import { getColor, getSpacing, getTheme } from '../../utils';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

export const OCarousel: FC<OCarouselType> = ({
  anchorId,
  items,
  paddingBottomDesktop,
  paddingBottomMobile,
  paddingTopDesktop,
  paddingTopMobile,
  textIntro,
  theme,
}) => {
  const [isPreviousControlEnabled, setIsPreviousControlEnabled] = useState(false);
  const [isNextControlEnabled, setIsNextControlEnabled] = useState(true);
  const skipCarouselAnchorMarkId = generateUuid().toString();
  const previousControlRef = useRef<HTMLDivElement>(null);
  const nextControlRef = useRef<HTMLDivElement>(null);
  const slidesRef = useRef<HTMLDivElement>(null);
  const slideItemsRef = useRef<HTMLDivElement[]>([]);

  const handleClick: MouseEventHandler = (event) => {
    const itemWidth = slideItemsRef.current[0]?.clientWidth ?? 0;

    slidesRef?.current?.scrollBy({
      left: event.target === nextControlRef.current ? itemWidth : -itemWidth,
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (previousControlRef.current === event?.target) {
        previousControlRef.current?.click();
      } else {
        nextControlRef.current?.click();
      }
    }
  };

  const enableControls = () => {
    if (slidesRef.current?.scrollLeft === 0) {
      setIsPreviousControlEnabled(false);
    } else {
      setIsPreviousControlEnabled(true);
    }

    if (
      slidesRef.current?.clientWidth ===
      (slidesRef.current?.scrollWidth ?? 0) - (slidesRef.current?.scrollLeft ?? 0)
    ) {
      setIsNextControlEnabled(false);
    } else {
      setIsNextControlEnabled(true);
    }
  };

  return (
    <Box
      backgroundColor={getColor(theme)}
      data-testid="o-carousel"
      pbd={getSpacing(paddingBottomDesktop) ?? Spacing.Xl}
      pbm={getSpacing(paddingBottomMobile) ?? Spacing.L}
      ptd={getSpacing(paddingTopDesktop) ?? Spacing.Xl}
      ptm={getSpacing(paddingTopMobile) ?? Spacing.L}
    >
      {anchorId && <AnchorMark anchorId={anchorId} />}
      {textIntro?.length === 1 && (
        <GridContainer>
          <GridRow>
            <GridColumn $width={8}>
              <StyledTextIntro {...textIntro[0]} accentColor={getTheme(theme).primary} buttonVariant="link" />
            </GridColumn>
          </GridRow>
        </GridContainer>
      )}
      <Slider>
        <SkipLink href={`#${skipCarouselAnchorMarkId}`} variant="plain">
          <Headline size="M">Carousel überspringen</Headline>
        </SkipLink>
        {items.length > 4 && (
          <GridContainer>
            <GridRow>
              <GridColumn $width={12}>
                <Controls>
                  <Control
                    aria-disabled={!isPreviousControlEnabled}
                    aria-label="Pfeil links"
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                    ref={previousControlRef}
                    tabIndex={0}
                  >
                    <PreviousIcon />
                  </Control>
                  <Control
                    aria-disabled={!isNextControlEnabled}
                    aria-label="Pfeil rechts"
                    onClick={handleClick}
                    onKeyDown={handleKeyDown}
                    ref={nextControlRef}
                    tabIndex={0}
                  >
                    <NextIcon />
                  </Control>
                </Controls>
              </GridColumn>
            </GridRow>
          </GridContainer>
        )}
        <Slides onScroll={enableControls} ref={slidesRef}>
          {items.map((item, index) => (
            <SlideItem
              key={item._uid}
              ref={(element) => {
                if (element) {
                  slideItemsRef.current[index] = element;
                }
              }}
            >
              <StyledTeaserItem {...item} accentColor={'black'} isTextOutside />
            </SlideItem>
          ))}
        </Slides>
      </Slider>
      <AnchorMark anchorId={skipCarouselAnchorMarkId} />
    </Box>
  );
};
