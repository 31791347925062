import { CalculatorsType, MaintenanceProductType, MaintenanceTextType } from '../../types';

type CalculatorProduct = Extract<
  MaintenanceProductType['product'],
  'gas' | 'electricity' | 'heating' | 'home-charging' | 'solar'
>;

export const getMaintenanceText = (
  product?: CalculatorProduct,
  maintenanceProducts?: MaintenanceProductType[],
): MaintenanceTextType | undefined => {
  const maintenanceAllProducts = maintenanceProducts?.find((mode) => mode.product === 'all')?.maintenanceText?.[0];
  const maintenanceSingleProduct = maintenanceProducts?.find((mode) => mode.product === product)?.maintenanceText?.[0];

  return maintenanceAllProducts ?? maintenanceSingleProduct;
};

export const mapCalculatorToProduct = (calculator: CalculatorsType): CalculatorProduct => {
  switch (calculator.component) {
    case 'household-calculator':
      return calculator.productAType;
    case 'home-charging-calculator':
      return 'home-charging';
    case 'heating-calculator':
      return 'heating';
    case 'solar-teaser':
      return 'solar';
  }
};
