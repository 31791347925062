import styled, { css } from '@lichtblick/styled';
import { MediaMediumAndAbove, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';
import { MasonryGrid, StyledMasonryGridItem } from '@lichtblick/ui-components';

import { MDownloadItem } from '../MDownloadItem';
import { GridColumn, GridRow } from '../shared';

export const TextColumn = styled(GridColumn)`
  margin-bottom: ${Spacing.M};

  ${MediaMediumAndAbove} {
    margin-bottom: ${Spacing.L};
  }
`;
export const ArchiveRow = styled(GridRow)`
  margin-top: ${Spacing.Xs};

  ${MediaSmallAndAbove} {
    margin-top: ${Spacing.M};
  }
`;

export const StyledMasonryGrid = styled(MasonryGrid)<{ $hasOnlyOneItem?: boolean }>`
  ${MediaSmallAndAbove} {
    gap: ${Spacing.Xxs};
  }

  ${StyledMasonryGridItem} {
    ${({ $hasOnlyOneItem }) =>
      $hasOnlyOneItem &&
      css`
        width: 100% !important;
      `}

    ${MediaSmallAndAbove} {
      width: calc(100% - ${Spacing.Xxs});
    }
  }
`;

export const DownloadItem = styled(MDownloadItem)`
  margin-bottom: ${Spacing.Xs};
`;
