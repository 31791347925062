import { FC } from 'react';

import { TextSizeClass } from '@lichtblick/ui-components';

import { Wrapper } from './MBulletList.styles';

import { ABulletItem } from '../ABulletItem';

export type BulletListProps = {
  className?: string;
  items: {
    richText: any;
    size?: TextSizeClass;
  }[];
};

export const MBulletList: FC<BulletListProps> = ({ className, items }) => (
  <Wrapper className={className}>
    {items.map(({ richText, size }) => (
      <ABulletItem key={richText} richText={richText} size={size}></ABulletItem>
    ))}
  </Wrapper>
);
