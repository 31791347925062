import styled from '@lichtblick/styled';
import { MediaMediumAndAbove, Spacing } from '@lichtblick/theme';

import { MTextIntro } from '../MTextIntro';

export const StyledTextIntro = styled(MTextIntro)`
  margin-bottom: ${Spacing.M};

  ${MediaMediumAndAbove} {
    margin-bottom: ${Spacing.L};
  }
`;

export const ColumnItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: -${Spacing.M};

  ${MediaMediumAndAbove} {
    margin: 0 -${Spacing.Xxs} -${Spacing.M};
  }
`;
