import { FC, useEffect, useState } from 'react';

import { BelowSmall, Spacing } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

import { ScrollContainer, StyledMTable, StyledMTextIntro } from './OTable.styles';

import { OTableType } from '../../types/storyblok';
import { getColor } from '../../utils';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

export const OTable: FC<OTableType> = ({ anchorId, table, textIntro, theme }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQueryObserver = window.matchMedia(BelowSmall);
    const handleMediaQueryChange = () => setIsMobile(mediaQueryObserver.matches);

    mediaQueryObserver.addEventListener('change', handleMediaQueryChange);
    handleMediaQueryChange();

    return () => {
      mediaQueryObserver.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <Box backgroundColor={getColor(theme)} data-testid="table" pyd={Spacing.Xl} pym={Spacing.Xs}>
      {anchorId && <AnchorMark anchorId={anchorId} />}
      {textIntro?.[0] && (
        <GridContainer>
          <GridRow>
            <GridColumn $push={2} $width={8}>
              <StyledMTextIntro {...textIntro[0]} buttonVariant="link" isCenteredDesktop isCenteredMobile />
            </GridColumn>
          </GridRow>
        </GridContainer>
      )}
      {table?.[0] &&
        (isMobile ? (
          <ScrollContainer>
            <StyledMTable {...table[0]} />
          </ScrollContainer>
        ) : (
          <GridContainer>
            <GridRow $isAlignedCenter>
              <GridColumn $isAlignedMiddle $width={12}>
                <StyledMTable {...table[0]} />
              </GridColumn>
            </GridRow>
          </GridContainer>
        ))}
    </Box>
  );
};
