import styled from '@lichtblick/styled';
import { BorderRadius, Colors, FontWeights, MediaMediumAndAbove, Spacing, fontStyles } from '@lichtblick/theme';
import { StyledHeadline } from '@lichtblick/ui-components';
import { focusRing } from '@lichtblick/ui-components/atoms/shared/styles';

export const StyledCard = styled.div`
  background-color: ${Colors.White};
  border-radius: ${BorderRadius.S};
  padding: ${Spacing.Xs};
  text-align: center;
  width: 40%;
  min-width: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: -4rem;

  &:first-child {
    margin-bottom: 4rem;
  }

  ${MediaMediumAndAbove} {
    align-self: stretch;
    width: 18rem;
    padding: ${Spacing['3Xs']} ${Spacing.S} ${Spacing.Xxs};
    &:first-child {
      margin-bottom: 0;
    }
  }
`;

export const StyledHeader = styled.div`
  padding: ${Spacing.Xs} 0;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  height: 100%;

  & > ${StyledHeadline} {
    margin-bottom: ${Spacing.Xxs};

    &.subheadline {
      color: ${Colors.Moss};
      font-size: 16px;
    }
  }
`;

export const StyledTariffIcon = styled.div`
  height: 2rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledCustomerSwitchWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  gap: 0.5rem;
  border-radius: ${BorderRadius.S};
  background-color: ${Colors.FadedMoss};
  padding: 16px 0;
  margin-bottom: 1rem;

  & > .customer-switch-label {
    color: ${Colors.Moss};
  }
`;

export const StyledCustomerSwitchInputWrapper = styled.div<{ $rightActive: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 2px;
  background-color: ${Colors.White};
  border-radius: 10rem;
  border: 1px solid ${Colors.Moss};

  &::before {
    display: block;
    content: '';
    position: absolute;
    background-color: ${Colors.Moss};
    width: 4rem;
    height: 2rem;
    border-radius: 10rem;
    z-index: 0;
    transform: ${({ $rightActive }) => ($rightActive ? `translateX(calc(100% + 2px))` : 'none')};
    transition: transform 0.3s;
  }
`;

export const StyledCustomerSwitchButton = styled.button<{ $active: boolean }>`
  width: 4rem;
  height: 2rem;
  border-radius: 4rem;
  background-color: transparent;
  color: ${({ $active }) => ($active ? Colors.White : Colors.Moss)};
  z-index: 10;
  font-size: small;
  font-weight: ${FontWeights.Bold};
  ${fontStyles.text.style};
  transition:
    color 0.3s,
    background-color 0.1s;

  &:hover {
    background-color: ${({ $active }) => ($active ? 'transparent' : Colors.LightGray)};
  }

  &:focus {
    background-color: ${({ $active }) => ($active ? 'transparent' : Colors.LightGray)};
    ${focusRing}
  }
`;

export const DataListContainer = styled.div`
  padding: 0 ${Spacing.Xs};

  li span {
    font-size: 0.75rem;
  }
`;

export const DataList = styled.ul`
  list-style: none;
`;

export const StyledInfoDialogLink = styled.div`
  margin-bottom: ${Spacing.Xs};
  margin-top: ${Spacing.Xs};
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  align-items: stretch;
  padding: ${Spacing.M} 0;
`;
