import { FC } from 'react';

import { Colors } from '@lichtblick/theme';
import { Text } from '@lichtblick/ui-components';

import { StyledContainer, StyledSection } from './Promo.styles';

import { richTextMapper } from '../../../helpers';
import { InstallionPromoType } from '../../../types';
import { getColor } from '../../../utils';
import { AImage } from '../../AImage';
import { MTextIntro } from '../../MTextIntro';
import { Tagline } from '../shared';

export type CaptionProps = {
  content: string;
  title?: string;
};

export const Promo: FC<InstallionPromoType> = ({ caption, image, tagline, textIntro, theme }) => (
  <StyledSection $backgroundColor={getColor(theme) || Colors.White}>
    <StyledContainer>
      <div>
        <figure>
          {image?.[0] && <AImage {...image[0]} />}
          <figcaption>
            {caption && richTextMapper(caption, { p: ({ children }) => <Text size="S">{children}</Text> })}
          </figcaption>
        </figure>
      </div>
      <main>
        {tagline && <Tagline>{tagline}</Tagline>}
        {textIntro?.[0] && <MTextIntro {...textIntro[0]} />}
      </main>
    </StyledContainer>
  </StyledSection>
);
