import { forwardRef } from 'react';

import { Checkbox, CheckboxLabel, Wrapper } from './Checkbox.styles';

import { CheckboxElementProps } from '../../atoms/Checkbox/Checkbox';
import { FieldFooter } from '../FieldFooter';
import { IconOrImageData, MediaCard } from '../MediaCard';

export type CheckboxCardProps = Omit<CheckboxElementProps, 'disabled'> & {
  // ! Replaces :has selector, which is not avilable for FireFox yet. See: https://caniuse.com/?search=%3Ahas
  activeValues?: boolean | CheckboxElementProps['value'][];
  hasError?: boolean;
  label: string;
  mediaProperties: IconOrImageData;
  statusMessage?: string;
};

export const CheckboxCard = forwardRef<HTMLInputElement, CheckboxCardProps>(
  ({ activeValues, hasError, label, mediaProperties, name, statusMessage, value, ...props }, ref) => {
    const id = value ? `${name}_${value}` : name;

    return (
      <MediaCard
        htmlFor={id}
        isActive={typeof activeValues === 'boolean' ? activeValues : activeValues?.includes(value)}
        mediaProperties={mediaProperties}
      >
        <Wrapper>
          <Checkbox id={id} name={name} value={value} {...props} $state={hasError ? 'error' : 'default'} ref={ref} />
          <CheckboxLabel isBold renderAs={'span'}>
            {label}
            <FieldFooter fieldFooterLabel={statusMessage} />
          </CheckboxLabel>
        </Wrapper>
      </MediaCard>
    );
  },
);
