import { ComponentProps, FC, Key } from 'react';

import { logger } from '@lichtblick/logger';

import { COMPONENTS_MAP } from '../components/components';

const Fallback: FC<{ contentType?: string }> = ({ contentType }) => (
  <pre style={{ color: 'red' }}>Missing component for content type {contentType}</pre>
);

export const componentMapper = <T extends keyof typeof COMPONENTS_MAP>(
  data: ComponentProps<(typeof COMPONENTS_MAP)[T]>,
  key?: Key,
  options?: Partial<ComponentProps<(typeof COMPONENTS_MAP)[T]>>,
  index?: number,
) => {
  const contentType = data?.component;

  if (!contentType) {
    logger.error('Unable to map content missing content type');

    return null;
  }

  if (COMPONENTS_MAP[contentType]) {
    const Component = COMPONENTS_MAP[contentType] as FC<ComponentProps<(typeof COMPONENTS_MAP)[T]>>;

    return <Component key={key} {...{ index: index, ...data }} {...options} />;
  }

  return <Fallback contentType={contentType} />;
};
