import { Breakpoints } from '../constants';

export const BelowSmall = `(max-width: ${Breakpoints.Small - 1}px)`;
export const BelowMedium = `(max-width: ${Breakpoints.Medium - 1}px)`;
export const BelowLarge = `(max-width: ${Breakpoints.Large - 1}px)`;

export const SmallAndAbove = `(min-width: ${Breakpoints.Small}px)`;
export const MediumAndAbove = `(min-width: ${Breakpoints.Medium}px)`;
export const LargeAndAbove = `(min-width: ${Breakpoints.Large}px)`;

export const MediaBelowSmall = `@media ${BelowSmall}`;
export const MediaBelowMedium = `@media ${BelowMedium}`;
export const MediaBelowLarge = `@media ${BelowLarge}`;

export const MediaSmallAndAbove = `@media ${SmallAndAbove}`;
export const MediaMediumAndAbove = `@media ${MediumAndAbove}`;
export const MediaLargeAndAbove = `@media ${LargeAndAbove}`;
export const MediaDesktop = MediaMediumAndAbove; // just an alias
