import React, { FC } from 'react';

import { Colors } from '@lichtblick/theme';
import { Label } from '@lichtblick/ui-components';

import { Divider, SecondRow, Rows, Value, Row } from './Listing.styles';

import { CaseStudyType } from '../../../../types';

type ListingDetailsRow = NonNullable<CaseStudyType['details']>[0];

export type ListingProps = { backgroundColor?: Colors; 'data-testid'?: string; rows?: ListingDetailsRow[] };

const SingleRow: FC<Pick<ListingDetailsRow, 'title' | 'value' | 'valueColor'>> = ({ title, value, valueColor }) => (
  <Row>
    <Label isBold size="S">
      {title}
    </Label>
    <Divider />
    <Value $color={valueColor} size="S">
      {value}
    </Value>
  </Row>
);

const DoubleRow: FC<Pick<ListingDetailsRow, 'title' | 'subtitle' | 'value' | 'valueColor' | 'subValue'>> = ({
  subValue,
  subtitle,
  title,
  value,
  valueColor,
}) => (
  <span>
    <Row>
      <Label isBold size="S">
        {title}
      </Label>
      <Divider />
      <Value $color={valueColor} size="S">
        {value}
      </Value>
    </Row>
    <SecondRow>
      <Label isBold size="S">
        {subtitle}
      </Label>
      {/* FIXME: can be bold! */}
      <Value $color={valueColor} size="S">
        {subValue}
      </Value>
    </SecondRow>
  </span>
);

const toRowElementNew = (row: ListingDetailsRow) => {
  const key = `${row.title}-${row.value}`;

  return row.subtitle || row.subValue ? <DoubleRow {...row} key={key} /> : <SingleRow {...row} key={key} />;
};

export const Listing: FC<ListingProps> = ({ backgroundColor, 'data-testid': testId, rows }) => (
  <Rows $backgroundColor={backgroundColor} data-testid={testId}>
    {rows && rows.map(toRowElementNew)}
  </Rows>
);
