import styled from '@lichtblick/styled';
import { MediaSmallAndAbove, Spacing } from '@lichtblick/theme';

import { MTable } from '../MTable';
import { MTextIntro } from '../MTextIntro';

export const StyledMTextIntro = styled(MTextIntro)`
  margin-bottom: ${Spacing.L};
`;

export const ScrollContainer = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
`;

export const StyledMTable = styled(MTable)`
  display: inline-block;
  margin: 0 ${Spacing.Xs};
  min-width: calc(100% - ${Spacing.M});

  ${MediaSmallAndAbove} {
    display: block;
    margin: 0;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;
