import styled from '@lichtblick/styled';
import { Colors, MediaMediumAndAbove, Spacing } from '@lichtblick/theme';
import { Headline } from '@lichtblick/ui-components';

import { StyledPairedItemText } from '../AListItem/AListItem.styles';
import { AText } from '../AText';

export const StyledHeadline = styled(Headline)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${Spacing.Xxs};

  ${MediaMediumAndAbove} {
    margin-bottom: ${Spacing.Xs};
  }
`;

export const StyledHeadlineIcon = styled.div`
  width: 3.5rem;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const StyledText = styled(AText)`
  margin-bottom: ${Spacing.Xxs};

  ${MediaMediumAndAbove} {
    margin-bottom: ${Spacing.Xs};
  }
`;

export const StyledH6Headline = styled(Headline)`
  color: ${Colors.DarkGray};
  font-size: 0.75rem;
  margin: 0.5rem 0 0;
`;

export const ContentWrapper = styled.div<{ isTextCentered?: boolean | null }>`
  text-align: ${({ isTextCentered }) => (isTextCentered ? 'center' : 'left')};

  ${StyledPairedItemText} {
    font-size: 0.75rem;
  }
`;
