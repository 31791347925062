import { css } from '@lichtblick/styled';
import { Colors, LinkUnderlineWidth, DurationAndEasing, MediaMediumAndAbove } from '@lichtblick/theme';

const linkUnderlineBase = css`
  --link-underline-width: ${LinkUnderlineWidth.Thin};
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size:
    0% var(--link-underline-width),
    100% var(--link-underline-width);
  padding-bottom: calc(1.5 * var(--link-underline-width));
  transition: background-size ${DurationAndEasing.SmallIntro};

  ${MediaMediumAndAbove} {
    --link-underline-width: ${LinkUnderlineWidth.Default};
  }
`;

export const linkUnderlineNone = css`
  background-image: none;
`;

export const linkUnderline = css`
  background-image: linear-gradient(${Colors.Orange} 0, ${Colors.Orange} 100%),
    linear-gradient(currentColor 0, currentColor 100%);
  ${linkUnderlineBase}
`;

export const linkUnderlineThin = css`
  ${linkUnderline}

  ${MediaMediumAndAbove} {
    --link-underline-width: ${LinkUnderlineWidth.Thin};
  }
`;

export const linkUnderlineBlack = css`
  background-image: linear-gradient(${Colors.Black} 0, ${Colors.Black} 100%),
    linear-gradient(transparent 0, transparent 100%);
  ${linkUnderlineBase}
`;

export const linkUnderlineHover = css`
  background-size: 100% var(--link-underline-width);
`;
