import { FC } from 'react';

import { Spacing } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

import { ImageWrapper, StyledAppBadges, StyledHeadline, StyledText } from './OStandoutText.styles';

import { componentMapper } from '../../helpers';
import { OStandoutTextType } from '../../types/storyblok';
import { getButtonVariant } from '../../utils/button';
import { getColor } from '../../utils/colors';
import { getSpacing } from '../../utils/spacing';
import { getHeadlineSize } from '../../utils/text';
import { AButton } from '../AButton';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

export const OStandoutText: FC<OStandoutTextType> = ({
  anchorId,
  appStoreUrl,
  button,
  buttonType,
  headline,
  headlineSize,
  media,
  paddingBottomDesktop,
  paddingBottomMobile,
  paddingTopDesktop,
  paddingTopMobile,
  playStoreUrl,
  text,
  theme,
}) => {
  const hasBadge = Boolean(button?.length !== 1 && appStoreUrl && playStoreUrl);

  return (
    <Box
      backgroundColor={getColor(theme)}
      data-testid="standout-text"
      pbd={getSpacing(paddingBottomDesktop) ?? Spacing['3Xl']}
      pbm={getSpacing(paddingBottomMobile) ?? Spacing.Xxl}
      ptd={getSpacing(paddingTopDesktop) ?? Spacing['3Xl']}
      ptm={getSpacing(paddingTopMobile) ?? Spacing.Xxl}
      textAlign="center"
    >
      {anchorId && <AnchorMark anchorId={anchorId} />}
      <GridContainer>
        <GridRow>
          <GridColumn $push={2} $width={8}>
            {media?.length === 1 && <ImageWrapper>{componentMapper(media[0])}</ImageWrapper>}
            {Boolean(headline) && (
              <StyledHeadline renderAs="h2" size={getHeadlineSize(headlineSize)}>
                {headline}
              </StyledHeadline>
            )}
            {Boolean(text) && (
              <StyledText align="center" renderAs="p">
                <span>{text}</span>
              </StyledText>
            )}
            {button?.length === 1 && <AButton {...button[0]} variant={getButtonVariant(buttonType) ?? 'secondary'} />}
            {hasBadge && <StyledAppBadges appStoreUrl={appStoreUrl ?? ''} playStoreUrl={playStoreUrl ?? ''} />}
          </GridColumn>
        </GridRow>
      </GridContainer>
    </Box>
  );
};
