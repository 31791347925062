import { FC, ReactNode } from 'react';

import { IconName } from '@lichtblick/icons';
import { Colors } from '@lichtblick/theme';

import { CollapsibleContent, Icon, ItemContent, Slot, Wrapper } from './CheckGroupItem.styles';

import { Text } from '../../atoms/Text/Text';
import { AccordionContent, AccordionHeader, AccordionItem, AccordionTrigger } from '../Accordion/Accordion.styles';

type IconOrText =
  | {
      iconColor?: never;
      iconName?: never;
      text?: string;
    }
  | {
      iconColor?: Colors;
      iconName?: IconName;
      text?: never;
    };

type OptionalCollapsibleProps =
  | { collapsibleContentSlot: ReactNode; collapsibleValue: string }
  | { collapsibleContentSlot?: never; collapsibleValue?: never };

export type CheckGroupItemProps = {
  className?: string;
  'data-testid'?: string;
  isActive?: boolean;
  leftSlot: ReactNode;
  onItemClick: () => void;
} & IconOrText &
  OptionalCollapsibleProps;

export const CheckGroupItem: FC<CheckGroupItemProps> = ({
  className,
  /* Must be used together with `collapsibleValue` for proper controlling in `CheckGroup` */
  collapsibleContentSlot,
  /* Must be used together with `collapsibleContentSlot` for proper controlling in `CheckGroup` */
  collapsibleValue,
  'data-testid': dataTestId,
  iconColor,
  iconName,
  isActive,
  leftSlot,
  onItemClick,
  text,
}) => (
  <Wrapper $isCollapsible={Boolean(collapsibleContentSlot)} $isHighlighted={isActive} data-testid={dataTestId}>
    {collapsibleContentSlot ? (
      <AccordionItem value={collapsibleValue}>
        <AccordionHeader>
          <AccordionTrigger onClick={onItemClick}>
            <ItemContent className={className}>
              <Slot>{leftSlot}</Slot>
              <Slot>
                {iconName && <Icon name={iconName} shiftColor={iconColor} />}
                {Boolean(text) && <Text isBold>{text}</Text>}
              </Slot>
            </ItemContent>
          </AccordionTrigger>
        </AccordionHeader>
        <AccordionContent>
          <CollapsibleContent>{collapsibleContentSlot}</CollapsibleContent>
        </AccordionContent>
      </AccordionItem>
    ) : (
      <ItemContent onClick={onItemClick}>
        <Slot>{leftSlot}</Slot>
        <Slot>
          {iconName && <Icon name={iconName} shiftColor={iconColor} />}
          {Boolean(text) && <Text isBold>{text}</Text>}
        </Slot>
      </ItemContent>
    )}
  </Wrapper>
);
