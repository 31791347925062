import { FC } from 'react';

import { componentMapper } from '../../helpers/componentMapper';
import { useTemplateTracker } from '../../hooks';
import { StoryblokStoryType, THomeType } from '../../types/storyblok';
import { Layout } from '../Layout';
import { MNavigationSecondary } from '../MNavigationSecondary';
import { OFootnotes } from '../OFootnotes';

export const THome: FC<StoryblokStoryType<THomeType>> = ({
  content: { footnotes, metaTitle, partials, secondaryNavigation, stage },
}) => {
  useTemplateTracker({
    pageArea: 'Home',
    pageType: 'home',
    pageTitle: metaTitle ?? '',
  });

  return (
    <Layout>
      {secondaryNavigation?.length === 1 && <MNavigationSecondary {...secondaryNavigation[0]} />}
      {stage?.length === 1 && componentMapper(stage[0])}
      {partials?.map((item) => item && componentMapper(item, item._uid))}
      {Boolean(footnotes?.length) && <OFootnotes footnotes={footnotes} />}
    </Layout>
  );
};
