import { FC, PropsWithChildren } from 'react';

import { Colors, Spacing } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

export const Section: FC<PropsWithChildren & { backgroundColor?: Colors }> = ({
  backgroundColor = Colors.White,
  children,
}) => (
  <Box as="section" backgroundColor={backgroundColor} pyd={Spacing.Xl} pym={Spacing.L}>
    {children}
  </Box>
);
