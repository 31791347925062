import dynamic from 'next/dynamic';
import { FC, MouseEventHandler, Suspense } from 'react';

import styled, { PickTransient } from '@lichtblick/styled';
import { Colors, Shades } from '@lichtblick/theme';

export type IconProps = {
  className?: string;
  onClick?: MouseEventHandler<SVGSVGElement>;
  shiftColor?: Colors | Shades | 'transparent';
};

export const LazySvgMap = {
  AddDocumentIconL: dynamic(() => import('../svg/add-document/add-document-l'), { ssr: true }),
  AddDocumentIconM: dynamic(() => import('../svg/add-document/add-document-m'), { ssr: true }),
  AddDocumentIconS: dynamic(() => import('../svg/add-document/add-document-s'), { ssr: true }),
  AddDocumentIconXl: dynamic(() => import('../svg/add-document/add-document-xl'), { ssr: true }),
  AddDocumentIconXs: dynamic(() => import('../svg/add-document/add-document-xs'), { ssr: true }),
  AddDocumentFilledIconS: dynamic(() => import('../svg/add-document-filled/add-document-filled-s'), { ssr: true }),
  AddDocumentFilledIconXs: dynamic(() => import('../svg/add-document-filled/add-document-filled-xs'), { ssr: true }),
  AlertIconL: dynamic(() => import('../svg/alert/alert-l'), { ssr: true }),
  AlertIconM: dynamic(() => import('../svg/alert/alert-m'), { ssr: true }),
  AlertIconS: dynamic(() => import('../svg/alert/alert-s'), { ssr: true }),
  AlertIconXs: dynamic(() => import('../svg/alert/alert-xs'), { ssr: true }),
  AppDownloadIconXl: dynamic(() => import('../svg/app-download/app-download-xl'), { ssr: true }),
  ArrowDownCircleIconS: dynamic(() => import('../svg/arrow-down-circle/arrow-down-circle-s'), { ssr: true }),
  ArrowLeftIconM: dynamic(() => import('../svg/arrow-left/arrow-left-m'), { ssr: true }),
  ArrowLeftIconS: dynamic(() => import('../svg/arrow-left/arrow-left-s'), { ssr: true }),
  ArrowLeftIconXs: dynamic(() => import('../svg/arrow-left/arrow-left-xs'), { ssr: true }),
  ArrowRightIconM: dynamic(() => import('../svg/arrow-right/arrow-right-m'), { ssr: true }),
  ArrowRightIconS: dynamic(() => import('../svg/arrow-right/arrow-right-s'), { ssr: true }),
  ArrowRightIconXs: dynamic(() => import('../svg/arrow-right/arrow-right-xs'), { ssr: true }),
  ArrowRightUpIconS: dynamic(() => import('../svg/arrow-right-up/arrow-right-up-s'), { ssr: true }),
  ArrowRightUpIconXs: dynamic(() => import('../svg/arrow-right-up/arrow-right-up-xs'), { ssr: true }),
  ArrowUpIconS: dynamic(() => import('../svg/arrow-up/arrow-up-s'), { ssr: true }),
  BadgeAppIconStore: dynamic(() => import('../svg/badge-app-store/badge-app-store'), { ssr: true }),
  BarGraphIconXl: dynamic(() => import('../svg/bar-graph/bar-graph-xl'), { ssr: true }),
  BarGraphIconXs: dynamic(() => import('../svg/bar-graph/bar-graph-xs'), { ssr: true }),
  BarGraphIconXxl: dynamic(() => import('../svg/bar-graph/bar-graph-xxl'), { ssr: true }),
  BatteryIconM: dynamic(() => import('../svg/battery/battery-m'), { ssr: true }),
  BatteryIconXl: dynamic(() => import('../svg/battery/battery-xl'), { ssr: true }),
  BinIconXs: dynamic(() => import('../svg/bin/bin-xs'), { ssr: true }),
  BonusIconXl: dynamic(() => import('../svg/bonus/bonus-xl'), { ssr: true }),
  BonusIconXs: dynamic(() => import('../svg/bonus/bonus-xs'), { ssr: true }),
  CalendarIconS: dynamic(() => import('../svg/calendar/calendar-s'), { ssr: true }),
  CarIconL: dynamic(() => import('../svg/car/car-l'), { ssr: true }),
  CardIconS: dynamic(() => import('../svg/card/card-s'), { ssr: true }),
  ChargingStationIconS: dynamic(() => import('../svg/charging-station/charging-station-s'), { ssr: true }),
  ChargingStationIconXl: dynamic(() => import('../svg/charging-station/charging-station-xl'), { ssr: true }),
  ChargingStationIconXs: dynamic(() => import('../svg/charging-station/charging-station-xs'), { ssr: true }),
  ChargingStationIconXxl: dynamic(() => import('../svg/charging-station/charging-station-xxl'), { ssr: true }),
  CheckIconL: dynamic(() => import('../svg/check/check-l'), { ssr: true }),
  CheckIconS: dynamic(() => import('../svg/check/check-s'), { ssr: true }),
  CheckIconXl: dynamic(() => import('../svg/check/check-xl'), { ssr: true }),
  CheckIconXs: dynamic(() => import('../svg/check/check-xs'), { ssr: true }),
  CheckIconXxl: dynamic(() => import('../svg/check/check-xxl'), { ssr: true }),
  CheckmarkIconXs: dynamic(() => import('../svg/checkmark/checkmark-xs'), { ssr: true }),
  CheckmarkCircleIconL: dynamic(() => import('../svg/checkmark-circle/checkmark-circle-l'), { ssr: true }),
  ChevronDownIconS: dynamic(() => import('../svg/chevron-down/chevron-down-s'), { ssr: true }),
  ChevronDownIconXs: dynamic(() => import('../svg/chevron-down/chevron-down-xs'), { ssr: true }),
  ChevronLeftIconS: dynamic(() => import('../svg/chevron-left/chevron-left-s'), { ssr: true }),
  ChevronLeftIconXs: dynamic(() => import('../svg/chevron-left/chevron-left-xs'), { ssr: true }),
  ChevronRightIconS: dynamic(() => import('../svg/chevron-right/chevron-right-s'), { ssr: true }),
  ChevronRightIconXs: dynamic(() => import('../svg/chevron-right/chevron-right-xs'), { ssr: true }),
  ChevronUpIconS: dynamic(() => import('../svg/chevron-up/chevron-up-s'), { ssr: true }),
  ChevronUpIconXs: dynamic(() => import('../svg/chevron-up/chevron-up-xs'), { ssr: true }),
  CircleIconXs: dynamic(() => import('../svg/circle/circle-xs'), { ssr: true }),
  ClimateElectionIconXs: dynamic(() => import('../svg/climate-election/climate-election-xs'), { ssr: true }),
  ClimateReportIconXs: dynamic(() => import('../svg/climate-report/climate-report-xs'), { ssr: true }),
  CloseIconS: dynamic(() => import('../svg/close/close-s'), { ssr: true }),
  CloseIconXs: dynamic(() => import('../svg/close/close-xs'), { ssr: true }),
  CloseCircledIconS: dynamic(() => import('../svg/close-circled/close-circled-s'), { ssr: true }),
  CoTwoIconM: dynamic(() => import('../svg/co-two/co-two-m'), { ssr: true }),
  CogwheelIconL: dynamic(() => import('../svg/cogwheel/cogwheel-l'), { ssr: true }),
  CogwheelIconM: dynamic(() => import('../svg/cogwheel/cogwheel-m'), { ssr: true }),
  CogwheelIconS: dynamic(() => import('../svg/cogwheel/cogwheel-s'), { ssr: true }),
  CogwheelIconXs: dynamic(() => import('../svg/cogwheel/cogwheel-xs'), { ssr: true }),
  CogwheelFilledIconS: dynamic(() => import('../svg/cogwheel-filled/cogwheel-filled-s'), { ssr: true }),
  CogwheelFilledIconXs: dynamic(() => import('../svg/cogwheel-filled/cogwheel-filled-xs'), { ssr: true }),
  ContractIconS: dynamic(() => import('../svg/contract/contract-s'), { ssr: true }),
  CookingIconXs: dynamic(() => import('../svg/cooking/cooking-xs'), { ssr: true }),
  CookingIconXxl: dynamic(() => import('../svg/cooking/cooking-xxl'), { ssr: true }),
  CopyIconXs: dynamic(() => import('../svg/copy/copy-xs'), { ssr: true }),
  CounterMinusIconS: dynamic(() => import('../svg/counter-minus/counter-minus-s'), { ssr: true }),
  CounterPlusIconS: dynamic(() => import('../svg/counter-plus/counter-plus-s'), { ssr: true }),
  CounterReadingIconL: dynamic(() => import('../svg/counter-reading/counter-reading-l'), { ssr: true }),
  CounterReadingIconS: dynamic(() => import('../svg/counter-reading/counter-reading-s'), { ssr: true }),
  CounterReadingIconXxl: dynamic(() => import('../svg/counter-reading/counter-reading-xxl'), { ssr: true }),
  CreditTransferIconM: dynamic(() => import('../svg/credit-transfer/credit-transfer-m'), { ssr: true }),
  CrossCircleIconS: dynamic(() => import('../svg/cross-circle/cross-circle-s'), { ssr: true }),
  CrossCircleIconXs: dynamic(() => import('../svg/cross-circle/cross-circle-xs'), { ssr: true }),
  CrowdfundingIconXl: dynamic(() => import('../svg/crowdfunding/crowdfunding-xl'), { ssr: true }),
  CrowdfundingIconXs: dynamic(() => import('../svg/crowdfunding/crowdfunding-xs'), { ssr: true }),
  CustomerRecommendationIconL: dynamic(() => import('../svg/customer-recommendation/customer-recommendation-l'), {
    ssr: true,
  }),
  DebitIconM: dynamic(() => import('../svg/debit/debit-m'), { ssr: true }),
  ActiveIcon: dynamic(() => import('../svg/delivery-status/active'), { ssr: true }),
  CanceledIcon: dynamic(() => import('../svg/delivery-status/canceled'), { ssr: true }),
  PendingIcon: dynamic(() => import('../svg/delivery-status/pending'), { ssr: true }),
  ProcessingIcon: dynamic(() => import('../svg/delivery-status/processing'), { ssr: true }),
  TerminatedIcon: dynamic(() => import('../svg/delivery-status/terminated'), { ssr: true }),
  UnknownIcon: dynamic(() => import('../svg/delivery-status/unknown'), { ssr: true }),
  DownloadIconS: dynamic(() => import('../svg/download/download-s'), { ssr: true }),
  DownloadIconXs: dynamic(() => import('../svg/download/download-xs'), { ssr: true }),
  EAutoIconM: dynamic(() => import('../svg/e-auto/e-auto-m')),
  EcoPowerIconM: dynamic(() => import('../svg/eco-power/eco-power-m'), { ssr: true }),
  EcoPowerIconS: dynamic(() => import('../svg/eco-power/eco-power-s'), { ssr: true }),
  EcoPowerIconXl: dynamic(() => import('../svg/eco-power/eco-power-xl'), { ssr: true }),
  ElectricityIconL: dynamic(() => import('../svg/electricity/electricity-l'), { ssr: true }),
  ElectricityIconM: dynamic(() => import('../svg/electricity/electricity-m'), { ssr: true }),
  ElectricityIconS: dynamic(() => import('../svg/electricity/electricity-s'), { ssr: true }),
  ElectricityIconXl: dynamic(() => import('../svg/electricity/electricity-xl'), { ssr: true }),
  ElectricityIconXs: dynamic(() => import('../svg/electricity/electricity-xs'), { ssr: true }),
  ElectricityIconXxl: dynamic(() => import('../svg/electricity/electricity-xxl'), { ssr: true }),
  ElectricityGasIconL: dynamic(() => import('../svg/electricity-gas/electricity-gas-l'), { ssr: true }),
  ElectricityGasIconM: dynamic(() => import('../svg/electricity-gas/electricity-gas-m'), { ssr: true }),
  ElectricityGasIconXl: dynamic(() => import('../svg/electricity-gas/electricity-gas-xl'), { ssr: true }),
  ElectricityProviderChangeIconL: dynamic(
    () => import('../svg/electricity-provider-change/electricity-provider-change-l'),
    { ssr: true },
  ),
  EyeCloseIconS: dynamic(() => import('../svg/eye-close/eye-close-s'), { ssr: true }),
  EyeCloseIconXs: dynamic(() => import('../svg/eye-close/eye-close-xs'), { ssr: true }),
  EyeOpenIconS: dynamic(() => import('../svg/eye-open/eye-open-s'), { ssr: true }),
  EyeOpenIconXs: dynamic(() => import('../svg/eye-open/eye-open-xs'), { ssr: true }),
  GasIconL: dynamic(() => import('../svg/gas/gas-l'), { ssr: true }),
  GasIconM: dynamic(() => import('../svg/gas/gas-m'), { ssr: true }),
  GasIconS: dynamic(() => import('../svg/gas/gas-s'), { ssr: true }),
  GasIconXl: dynamic(() => import('../svg/gas/gas-xl'), { ssr: true }),
  GasIconXs: dynamic(() => import('../svg/gas/gas-xs'), { ssr: true }),
  GasIconXxl: dynamic(() => import('../svg/gas/gas-xxl'), { ssr: true }),
  GasProviderChangeIconL: dynamic(() => import('../svg/gas-provider-change/gas-provider-change-l'), { ssr: true }),
  GiftIconXl: dynamic(() => import('../svg/gift/gift-xl'), { ssr: true }),
  GridIconXs: dynamic(() => import('../svg/grid/grid-xs'), { ssr: true }),
  HandshakeIconXl: dynamic(() => import('../svg/handshake/handshake-xl'), { ssr: true }),
  HandshakeHeartIconXl: dynamic(() => import('../svg/handshake-heart/handshake-heart-xl'), { ssr: true }),
  HandshakeHeartIconXxl: dynamic(() => import('../svg/handshake-heart/handshake-heart-xxl'), { ssr: true }),
  HeartIconXs: dynamic(() => import('../svg/heart/heart-xs'), { ssr: true }),
  HeatPumpIconL: dynamic(() => import('../svg/heat-pump/heat-pump-l'), { ssr: true }),
  HeatPumpIconM: dynamic(() => import('../svg/heat-pump/heat-pump-m'), { ssr: true }),
  HeatPumpIconS: dynamic(() => import('../svg/heat-pump/heat-pump-s'), { ssr: true }),
  HeatPumpIconXl: dynamic(() => import('../svg/heat-pump/heat-pump-xl'), { ssr: true }),
  HeatPumpIconXs: dynamic(() => import('../svg/heat-pump/heat-pump-xs'), { ssr: true }),
  HeatingEnergyIconL: dynamic(() => import('../svg/heating-energy/heating-energy-l'), { ssr: true }),
  HeatingEnergyIconM: dynamic(() => import('../svg/heating-energy/heating-energy-m'), { ssr: true }),
  HeatingEnergyIconS: dynamic(() => import('../svg/heating-energy/heating-energy-s'), { ssr: true }),
  HeatingEnergyIconXl: dynamic(() => import('../svg/heating-energy/heating-energy-xl'), { ssr: true }),
  HeatingEnergyIconXs: dynamic(() => import('../svg/heating-energy/heating-energy-xs'), { ssr: true }),
  HeatingEnergyIconXxl: dynamic(() => import('../svg/heating-energy/heating-energy-xxl'), { ssr: true }),
  HeatingProviderChangeIconL: dynamic(() => import('../svg/heating-provider-change/heating-provider-change-l'), {
    ssr: true,
  }),
  HouseIconL: dynamic(() => import('../svg/house/house-l'), { ssr: true }),
  HouseIconM: dynamic(() => import('../svg/house/house-m'), { ssr: true }),
  HouseIconS: dynamic(() => import('../svg/house/house-s'), { ssr: true }),
  HouseIconXs: dynamic(() => import('../svg/house/house-xs'), { ssr: true }),
  HouseCarIconL: dynamic(() => import('../svg/house-car/house-car-l'), { ssr: true }),
  HouseCarIconM: dynamic(() => import('../svg/house-car/house-car-m'), { ssr: true }),
  HouseCarIconS: dynamic(() => import('../svg/house-car/house-car-s'), { ssr: true }),
  HouseCarIconXl: dynamic(() => import('../svg/house-car/house-car-xl'), { ssr: true }),
  HouseFilledIconS: dynamic(() => import('../svg/house-filled/house-filled-s'), { ssr: true }),
  HouseFilledIconXs: dynamic(() => import('../svg/house-filled/house-filled-xs'), { ssr: true }),
  InfoIconL: dynamic(() => import('../svg/info/info-l'), { ssr: true }),
  InfoIconS: dynamic(() => import('../svg/info/info-s'), { ssr: true }),
  InfoIconXl: dynamic(() => import('../svg/info/info-xl'), { ssr: true }),
  InfoIconXs: dynamic(() => import('../svg/info/info-xs'), { ssr: true }),
  InfoIconXxl: dynamic(() => import('../svg/info/info-xxl'), { ssr: true }),
  InfoFilledIconL: dynamic(() => import('../svg/info-filled/info-filled-l'), { ssr: true }),
  InvoiceIconL: dynamic(() => import('../svg/invoice/invoice-l'), { ssr: true }),
  InvoiceIconM: dynamic(() => import('../svg/invoice/invoice-m'), { ssr: true }),
  InvoiceIconS: dynamic(() => import('../svg/invoice/invoice-s'), { ssr: true }),
  InvoiceIconXl: dynamic(() => import('../svg/invoice/invoice-xl'), { ssr: true }),
  InvoiceIconXs: dynamic(() => import('../svg/invoice/invoice-xs'), { ssr: true }),
  InvoiceIconXxl: dynamic(() => import('../svg/invoice/invoice-xxl'), { ssr: true }),
  InvoiceFilledIconS: dynamic(() => import('../svg/invoice-filled/invoice-filled-s'), { ssr: true }),
  InvoiceFilledIconXs: dynamic(() => import('../svg/invoice-filled/invoice-filled-xs'), { ssr: true }),
  KeysIconL: dynamic(() => import('../svg/keys/keys-l'), { ssr: true }),
  KeysIconS: dynamic(() => import('../svg/keys/keys-s'), { ssr: true }),
  LadesaeuleIconXl: dynamic(() => import('../svg/ladesaeule/ladesaeule-xl'), { ssr: true }),
  LineGraphIconL: dynamic(() => import('../svg/line-graph/line-graph-l'), { ssr: true }),
  LineGraphIconS: dynamic(() => import('../svg/line-graph/line-graph-s'), { ssr: true }),
  LineGraphIconXl: dynamic(() => import('../svg/line-graph/line-graph-xl'), { ssr: true }),
  LineGraphIconXs: dynamic(() => import('../svg/line-graph/line-graph-xs'), { ssr: true }),
  LoadingIconXs: dynamic(() => import('../svg/loading/loading-xs'), { ssr: true }),
  LockIconS: dynamic(() => import('../svg/lock/lock-s'), { ssr: true }),
  IconLogo: dynamic(() => import('../svg/logo/logo'), { ssr: true }),
  LogoIconIconXs: dynamic(() => import('../svg/logo-icon/logo-icon-xs'), { ssr: true }),
  LogoutIconS: dynamic(() => import('../svg/logout/logout-s'), { ssr: true }),
  LogoutIconXs: dynamic(() => import('../svg/logout/logout-xs'), { ssr: true }),
  MailIconL: dynamic(() => import('../svg/mail/mail-l'), { ssr: true }),
  MailIconM: dynamic(() => import('../svg/mail/mail-m'), { ssr: true }),
  MailIconS: dynamic(() => import('../svg/mail/mail-s'), { ssr: true }),
  MailIconXs: dynamic(() => import('../svg/mail/mail-xs'), { ssr: true }),
  MaintenanceModeIconXxl: dynamic(() => import('../svg/maintenance-mode/maintenance-mode-xxl'), { ssr: true }),
  MapIconXl: dynamic(() => import('../svg/map/map-xl'), { ssr: true }),
  MoneyIconL: dynamic(() => import('../svg/money/money-l'), { ssr: true }),
  MoneyIconM: dynamic(() => import('../svg/money/money-m'), { ssr: true }),
  MoneyIconS: dynamic(() => import('../svg/money/money-s'), { ssr: true }),
  MoneyIconXl: dynamic(() => import('../svg/money/money-xl'), { ssr: true }),
  MoneyIconXxl: dynamic(() => import('../svg/money/money-xxl'), { ssr: true }),
  NoCarIconL: dynamic(() => import('../svg/no-car/no-car-l'), { ssr: true }),
  Number1IconM: dynamic(() => import('../svg/number-1/number-1-m'), { ssr: true }),
  Number1IconS: dynamic(() => import('../svg/number-1/number-1-s'), { ssr: true }),
  Number1IconXl: dynamic(() => import('../svg/number-1/number-1-xl'), { ssr: true }),
  Number1IconXs: dynamic(() => import('../svg/number-1/number-1-xs'), { ssr: true }),
  Number2IconM: dynamic(() => import('../svg/number-2/number-2-m'), { ssr: true }),
  Number2IconS: dynamic(() => import('../svg/number-2/number-2-s'), { ssr: true }),
  Number2IconXl: dynamic(() => import('../svg/number-2/number-2-xl'), { ssr: true }),
  Number2IconXs: dynamic(() => import('../svg/number-2/number-2-xs'), { ssr: true }),
  Number3IconM: dynamic(() => import('../svg/number-3/number-3-m'), { ssr: true }),
  Number3IconS: dynamic(() => import('../svg/number-3/number-3-s'), { ssr: true }),
  Number3IconXl: dynamic(() => import('../svg/number-3/number-3-xl'), { ssr: true }),
  Number3IconXs: dynamic(() => import('../svg/number-3/number-3-xs'), { ssr: true }),
  Number4IconS: dynamic(() => import('../svg/number-4/number-4-s'), { ssr: true }),
  Number4IconXl: dynamic(() => import('../svg/number-4/number-4-xl'), { ssr: true }),
  Number4IconXs: dynamic(() => import('../svg/number-4/number-4-xs'), { ssr: true }),
  Number5IconS: dynamic(() => import('../svg/number-5/number-5-s'), { ssr: true }),
  Number5IconXs: dynamic(() => import('../svg/number-5/number-5-xs'), { ssr: true }),
  Number6IconS: dynamic(() => import('../svg/number-6/number-6-s'), { ssr: true }),
  Number6IconXs: dynamic(() => import('../svg/number-6/number-6-xs'), { ssr: true }),
  Number7IconS: dynamic(() => import('../svg/number-7/number-7-s'), { ssr: true }),
  Number7IconXs: dynamic(() => import('../svg/number-7/number-7-xs'), { ssr: true }),
  Number8IconS: dynamic(() => import('../svg/number-8/number-8-s'), { ssr: true }),
  Number8IconXs: dynamic(() => import('../svg/number-8/number-8-xs'), { ssr: true }),
  Number9IconS: dynamic(() => import('../svg/number-9/number-9-s'), { ssr: true }),
  Number9IconXs: dynamic(() => import('../svg/number-9/number-9-xs'), { ssr: true }),
  OpenIconS: dynamic(() => import('../svg/open/open-s'), { ssr: true }),
  OpenIconXs: dynamic(() => import('../svg/open/open-xs'), { ssr: true }),
  PaymentAdjustIconL: dynamic(() => import('../svg/payment-adjust/payment-adjust-l'), { ssr: true }),
  PaymentAdjustIconS: dynamic(() => import('../svg/payment-adjust/payment-adjust-s'), { ssr: true }),
  PenIconM: dynamic(() => import('../svg/pen/pen-m'), { ssr: true }),
  PenIconS: dynamic(() => import('../svg/pen/pen-s'), { ssr: true }),
  PenIconXs: dynamic(() => import('../svg/pen/pen-xs'), { ssr: true }),
  PhoneIconS: dynamic(() => import('../svg/phone/phone-s'), { ssr: true }),
  PhoneIconXl: dynamic(() => import('../svg/phone/phone-xl'), { ssr: true }),
  PhoneIconXs: dynamic(() => import('../svg/phone/phone-xs'), { ssr: true }),
  PinIconL: dynamic(() => import('../svg/pin/pin-l'), { ssr: true }),
  PinIconS: dynamic(() => import('../svg/pin/pin-s'), { ssr: true }),
  PinIconXs: dynamic(() => import('../svg/pin/pin-xs'), { ssr: true }),
  PlaceholderIconXxl: dynamic(() => import('../svg/placeholder/placeholder-xxl'), { ssr: true }),
  PlusIconS: dynamic(() => import('../svg/plus/plus-s'), { ssr: true }),
  PlusIconXs: dynamic(() => import('../svg/plus/plus-xs'), { ssr: true }),
  PodcastIconXs: dynamic(() => import('../svg/podcast/podcast-xs'), { ssr: true }),
  PostIconL: dynamic(() => import('../svg/post/post-l'), { ssr: true }),
  PostIconS: dynamic(() => import('../svg/post/post-s'), { ssr: true }),
  PostIconXl: dynamic(() => import('../svg/post/post-xl'), { ssr: true }),
  PressIconXl: dynamic(() => import('../svg/press/press-xl'), { ssr: true }),
  PressIconXs: dynamic(() => import('../svg/press/press-xs'), { ssr: true }),
  PressIconXxl: dynamic(() => import('../svg/press/press-xxl'), { ssr: true }),
  PvSunIconXl: dynamic(() => import('../svg/pv-sun/pv-sun-xl'), { ssr: true }),
  RechnungIconS: dynamic(() => import('../svg/rechnung/rechnung-s'), { ssr: true }),
  RemoveDocumentIconL: dynamic(() => import('../svg/remove-document/remove-document-l'), { ssr: true }),
  RemoveDocumentIconM: dynamic(() => import('../svg/remove-document/remove-document-m'), { ssr: true }),
  RemoveDocumentIconS: dynamic(() => import('../svg/remove-document/remove-document-s'), { ssr: true }),
  ReturnIconS: dynamic(() => import('../svg/return/return-s'), { ssr: true }),
  EasyIconL: dynamic(() => import('../svg/roof-inclination/easy-l'), { ssr: true }),
  FlatIconL: dynamic(() => import('../svg/roof-inclination/flat-l'), { ssr: true }),
  HouseIconMarker: dynamic(() => import('../svg/roof-inclination/house-marker'), { ssr: true }),
  ModerateIconL: dynamic(() => import('../svg/roof-inclination/moderate-l'), { ssr: true }),
  NormalIconL: dynamic(() => import('../svg/roof-inclination/normal-l'), { ssr: true }),
  StrongIconL: dynamic(() => import('../svg/roof-inclination/strong-l'), { ssr: true }),
  VeryStrongIconL: dynamic(() => import('../svg/roof-inclination/very-strong-l'), { ssr: true }),
  RoofUnknownIconXl: dynamic(() => import('../svg/roof-unknown/roof-unknown-xl'), { ssr: true }),
  RoofNoIconXl: dynamic(() => import('../svg/roof-unsuitable/roof-no-xl'), { ssr: true }),
  SavingsHouseIconXl: dynamic(() => import('../svg/savings-house/savings-house-xl'), { ssr: true }),
  SearchIconXs: dynamic(() => import('../svg/search/search-xs'), { ssr: true }),
  SeedlingIconXs: dynamic(() => import('../svg/seedling/seedling-xs'), { ssr: true }),
  SepaDirectDebitIconL: dynamic(() => import('../svg/sepa-direct-debit/sepa-direct-debit-l'), { ssr: true }),
  SepaDirectDebitIconS: dynamic(() => import('../svg/sepa-direct-debit/sepa-direct-debit-s'), { ssr: true }),
  ServicesIconL: dynamic(() => import('../svg/services/services-l'), { ssr: true }),
  ServicesIconM: dynamic(() => import('../svg/services/services-m'), { ssr: true }),
  ServicesIconS: dynamic(() => import('../svg/services/services-s'), { ssr: true }),
  ServicesIconXl: dynamic(() => import('../svg/services/services-xl'), { ssr: true }),
  ServicesIconXs: dynamic(() => import('../svg/services/services-xs'), { ssr: true }),
  ServicesIconXxl: dynamic(() => import('../svg/services/services-xxl'), { ssr: true }),
  ServicesFilledIconS: dynamic(() => import('../svg/services-filled/services-filled-s'), { ssr: true }),
  ServicesFilledIconXs: dynamic(() => import('../svg/services-filled/services-filled-xs'), { ssr: true }),
  ShippingIconS: dynamic(() => import('../svg/shipping/shipping-s'), { ssr: true }),
  ShippingIconXs: dynamic(() => import('../svg/shipping/shipping-xs'), { ssr: true }),
  SocketIconXxl: dynamic(() => import('../svg/socket/socket-xxl'), { ssr: true }),
  SolarIconM: dynamic(() => import('../svg/solar/solar-m'), { ssr: true }),
  SolarIconXl: dynamic(() => import('../svg/solar/solar-xl'), { ssr: true }),
  SolarIconXs: dynamic(() => import('../svg/solar/solar-xs'), { ssr: true }),
  SolarModuleIconXl: dynamic(() => import('../svg/solar-module/solar-module-xl'), { ssr: true }),
  SortAscIconXs: dynamic(() => import('../svg/sort-asc/sort-asc-xs'), { ssr: true }),
  SortDescIconXs: dynamic(() => import('../svg/sort-desc/sort-desc-xs'), { ssr: true }),
  SortNoneIconXs: dynamic(() => import('../svg/sort-none/sort-none-xs'), { ssr: true }),
  SpeechBubbleIconS: dynamic(() => import('../svg/speech-bubble/speech-bubble-s'), { ssr: true }),
  SpeechBubbleIconXs: dynamic(() => import('../svg/speech-bubble/speech-bubble-xs'), { ssr: true }),
  SpeechBubbleFilledIconS: dynamic(() => import('../svg/speech-bubble-filled/speech-bubble-filled-s'), { ssr: true }),
  SpeechBubbleFilledIconXs: dynamic(() => import('../svg/speech-bubble-filled/speech-bubble-filled-xs'), { ssr: true }),
  StarEmptyIconXs: dynamic(() => import('../svg/star-full/star-empty-xs'), { ssr: true }),
  StarFullIconL: dynamic(() => import('../svg/star-full/star-full-l'), { ssr: true }),
  StarFullIconM: dynamic(() => import('../svg/star-full/star-full-m'), { ssr: true }),
  StarFullIconXs: dynamic(() => import('../svg/star-full/star-full-xs'), { ssr: true }),
  StarHalfIconL: dynamic(() => import('../svg/star-half/star-half-l'), { ssr: true }),
  StarHalfIconM: dynamic(() => import('../svg/star-half/star-half-m'), { ssr: true }),
  StarHalfIconXs: dynamic(() => import('../svg/star-half/star-half-xs'), { ssr: true }),
  StorageHeaterIconL: dynamic(() => import('../svg/storage-heater/storage-heater-l'), { ssr: true }),
  StorageHeaterIconM: dynamic(() => import('../svg/storage-heater/storage-heater-m'), { ssr: true }),
  StorageHeaterIconS: dynamic(() => import('../svg/storage-heater/storage-heater-s'), { ssr: true }),
  StorageHeaterIconXl: dynamic(() => import('../svg/storage-heater/storage-heater-xl'), { ssr: true }),
  StorageHeaterIconXs: dynamic(() => import('../svg/storage-heater/storage-heater-xs'), { ssr: true }),
  TariffChangeIconL: dynamic(() => import('../svg/tariff-change/tariff-change-l'), { ssr: true }),
  TariffChangeIconS: dynamic(() => import('../svg/tariff-change/tariff-change-s'), { ssr: true }),
  TimeIconS: dynamic(() => import('../svg/time/time-s'), { ssr: true }),
  TippIconXl: dynamic(() => import('../svg/tipp/tipp-xl'), { ssr: true }),
  TippIconXxl: dynamic(() => import('../svg/tipp/tipp-xxl'), { ssr: true }),
  TreeIconXl: dynamic(() => import('../svg/tree/tree-xl'), { ssr: true }),
  TreeLandscapeIconXs: dynamic(() => import('../svg/tree-landscape/tree-landscape-xs'), { ssr: true }),
  TreeSeedIconXs: dynamic(() => import('../svg/tree-seed/tree-seed-xs'), { ssr: true }),
  UploadIconS: dynamic(() => import('../svg/upload/upload-s'), { ssr: true }),
  UserIconL: dynamic(() => import('../svg/user/user-l'), { ssr: true }),
  UserIconM: dynamic(() => import('../svg/user/user-m'), { ssr: true }),
  UserIconS: dynamic(() => import('../svg/user/user-s'), { ssr: true }),
  UserIconXl: dynamic(() => import('../svg/user/user-xl'), { ssr: true }),
  UserIconXs: dynamic(() => import('../svg/user/user-xs'), { ssr: true }),
  IconUser: dynamic(() => import('../svg/user/user'), { ssr: true }),
  UserFilledIconM: dynamic(() => import('../svg/user-filled/user-filled-m'), { ssr: true }),
  UserFilledIconS: dynamic(() => import('../svg/user-filled/user-filled-s'), { ssr: true }),
  UserFilledIconXs: dynamic(() => import('../svg/user-filled/user-filled-xs'), { ssr: true }),
  UserIconFilled: dynamic(() => import('../svg/user-filled/user-filled'), { ssr: true }),
  WallboxIconM: dynamic(() => import('../svg/wallbox/wallbox-m'), { ssr: true }),
  WallboxIconS: dynamic(() => import('../svg/wallbox/wallbox-s'), { ssr: true }),
  WallboxIconXl: dynamic(() => import('../svg/wallbox/wallbox-xl'), { ssr: true }),
  WallboxIconXs: dynamic(() => import('../svg/wallbox/wallbox-xs'), { ssr: true }),
  WorldIconXs: dynamic(() => import('../svg/world/world-xs'), { ssr: true }),
} as const;

export type IconName = keyof typeof LazySvgMap;

export const capitalize = (word: string) => `${word.slice(0, 1).toUpperCase()}${word.slice(1)}`;

export const toIconName = (name: string) => {
  if (name in LazySvgMap) {
    return name as IconName;
  }

  const words = name.split(/\W+/);
  const sizeMarker = words[words.length - 1]?.match(/^(m|x*[sl])$/) && words.pop(); // Array.prototype.at is not compatible with safari < 15.4

  // insert "icon" at end (but before size marker)
  words.push('icon');
  words.push(sizeMarker ?? '');

  // pascalize
  return words.map(capitalize).join('') as IconName;
};

// passing shiftColor to svg based component is causing a console error
const SVGIcon: FC<IconProps & { name: IconName }> = ({ name, shiftColor: _shiftColor, ...props }) => {
  const Component = LazySvgMap[name];

  return (
    Component && (
      <Suspense>
        <Component {...props} />
      </Suspense>
    )
  );
};

export const StyledIcon = styled(SVGIcon)<PickTransient<IconProps, 'shiftColor'>>`
  /*
    if the icon doesn't have the color just by adding the $color prop,
    you need to style it like on MProductCardTeaser.styles.ts
  */

  path[class*='shift'] {
    fill: ${({ $shiftColor }) => $shiftColor};
  }
`;

export const Icon: FC<IconProps & { name: IconName }> = ({ shiftColor, ...props }) => (
  <StyledIcon {...props} $shiftColor={shiftColor} />
);
