import styled from '@lichtblick/styled';
import { MediaMediumAndAbove, Spacing } from '@lichtblick/theme';

export const TeaserList = styled.div`
  padding: ${Spacing.L} 0;

  ${MediaMediumAndAbove} {
    padding: ${Spacing.Xl} 0;
  }
`;

export const TeaserListItemWrapper = styled.ul`
  & > a:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const TextIntroWrapper = styled.div`
  margin-bottom: ${Spacing.S};

  ${MediaMediumAndAbove} {
    margin-bottom: ${Spacing.M};
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: ${Spacing.S};
  text-align: center;
`;
