import { Icon } from '@lichtblick/icons';
import styled, { css } from '@lichtblick/styled';
import { Colors, MediaBelowMedium, MediaDesktop, Spacing, alpha, placeholderElement } from '@lichtblick/theme';

import { AText } from '../AText';

export const StyledItem = styled.li<{ $hasTopLine?: boolean; $isBulletList?: boolean; $topLineColor?: Colors }>`
  align-items: flex-start;
  display: flex;
  flex-wrap: nowrap;
  list-style: none;

  ${({ $hasTopLine, $topLineColor }) =>
    $hasTopLine &&
    $topLineColor &&
    css`
      padding-top: ${Spacing.Xs};
      border-top: 1px solid ${alpha($topLineColor, 0.2)};
    `}
`;

export const StyledIcon = styled(Icon)<{ $color?: string }>`
  color: ${({ $color }) => $color};
  flex-shrink: 0;
  margin-right: ${Spacing.Xxs};

  ${MediaDesktop} {
    margin-top: 0.0625rem;
  }
`;

export const Title = styled(AText)`
  order: 0;
`;

export const Value = styled(AText)({
  order: 1,
  flexShrink: 0,
  maxWidth: '40%',
  alignSelf: 'flex-end',
});

export const ValuePrimary = styled(AText)({
  order: 1,
  flexShrink: 0,
  maxWidth: '50%',
  alignSelf: 'flex-end',
});

export const Wrapper = styled.div<{ $hasDottedLine: boolean; $textColor?: Colors }>`
  color: ${({ $textColor }) => $textColor};
  display: flex;
  justify-content: space-between;

  & > ${Title} {
    padding-right: ${Spacing['3Xs']};
    position: relative;
  }

  & > ${Value} {
    float: right;
    padding-left: ${Spacing['3Xs']};
    position: relative;
  }

  ${({ $hasDottedLine }) =>
    $hasDottedLine &&
    css`
      display: block;

      ${MediaBelowMedium} {
        display: flex;
        justify-content: space-between;
      }

      & > ${Title} {
        background-color: ${Colors.White};
        z-index: 1;
      }

      & > ${Value} {
        background-color: ${Colors.White};
        padding-left: 0;
        z-index: 1;
        text-align: right;
      }

      ${MediaBelowMedium} {
        & > ${Value} {
          padding-left: ${Spacing['3Xs']};
        }
      }

      &::before {
        content: '';
        position: absolute;
        background-image: linear-gradient(to right, ${Colors.Gray} 1px, ${Colors.White} 1px);
        background-position: 50% 0;
        background-size: 4px 1px;
        background-repeat: repeat-x;
        width: 100%;
        height: 1px;
        bottom: 4px;
      }
    `}

  position: relative;
  width: 100%;
`;

export const PlaceholderItem = styled.li`
  height: 1.7rem;
  list-style: none;
  ${placeholderElement()}
  width: 100%;
`;
