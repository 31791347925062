import Head from 'next/head';
import { FC } from 'react';

import 'iframe-resizer/js/iframeResizer.contentWindow';
import { componentMapper } from '../../helpers/componentMapper';
import { WithMetaData } from '../../helpers/templateMapper';
import { StoryblokStoryType, TFrameContentType } from '../../types/storyblok';
import { Layout } from '../Layout';
import { OFootnotes } from '../OFootnotes';

export const TFrameContent: FC<WithMetaData<StoryblokStoryType<TFrameContentType>>> = ({
  content: {
    breadcrumbTitle,
    footerCustomContactPhone,
    footerCustomContactSmallText,
    footerCustomContactText,
    footerCustomSecondaryNavigation,
    footerLayout,
    footnotes,
    partials,
    stage,
  },
  parentPages,
  slug,
}) => (
  <>
    <Head>
      <base target="_blank" />
    </Head>

    <Layout
      breadcrumbTitle={breadcrumbTitle}
      footerCustomContactPhone={footerCustomContactPhone}
      footerCustomContactSmallText={footerCustomContactSmallText}
      footerCustomContactText={footerCustomContactText}
      footerLayout={footerLayout as any}
      footerSecondaryNavigation={footerCustomSecondaryNavigation}
      hasNoHeader={true}
      parentPages={parentPages}
      slug={slug}
    >
      {stage?.[0] && componentMapper(stage[0])}
      {partials?.map((item) => item && componentMapper(item, item._uid))}
      <OFootnotes footnotes={footnotes} />
    </Layout>
  </>
);
