import { logger } from '@lichtblick/logger';

import { TRACKING_STATE_STORAGE_NAME } from './tracker.config';
import { TrackingState } from './tracker.types';

const parse = (json: string | null): any => {
  if (!json) {
    return undefined;
  }

  try {
    return JSON.parse(json);
  } catch (error: any) {
    logger.warn(`Could not parse the given json string: ${error.message}`);

    return undefined;
  }
};

export const getStateFromStorage = (): TrackingState | undefined => {
  try {
    const state = parse(localStorage.getItem(TRACKING_STATE_STORAGE_NAME));

    if (!state || !Object.keys(state).length) {
      return undefined;
    }

    return state;
  } catch (error: any) {
    // localStorage might not work in a cross origin iframe
    logger.warn(`Ignore error when reading tracking state from local storage: ${error.message}`);

    return undefined;
  }
};

export const setStateInStorage = (trackingState: TrackingState) => {
  try {
    localStorage.setItem(TRACKING_STATE_STORAGE_NAME, JSON.stringify(trackingState));
  } catch (error: any) {
    // localStorage might not work in a cross origin iframe
    logger.warn(`Ignore error when updating tracking state from local storage: ${error.message}`);
  }
};
