import { FC, useContext } from 'react';

import { componentMapper } from '../../helpers/componentMapper';
import { SettingsContext } from '../../helpers/settingsContext';
import { WithMetaData } from '../../helpers/templateMapper';
import { useTemplateTracker } from '../../hooks';
import { StoryblokStoryType, TArticleType } from '../../types/storyblok';
import { Layout } from '../Layout';
import { MBreadcrumb } from '../MBreadcrumb/MBreadcrumb';
import { OFootnotes } from '../OFootnotes';
import { OStageArticle } from '../OStageArticle';
import { OTeaserGridArticles, TeaserGridArticlesProps } from '../OTeaserGridArticles';

export type TArticleProps = TArticleType & {
  customRelatedArticlesGrid?: TeaserGridArticlesProps;
  relatedArticlesGrid?: TeaserGridArticlesProps;
};

export const TArticle: FC<WithMetaData<StoryblokStoryType<TArticleProps>>> = ({
  content: {
    _uid,
    breadcrumbTitle,
    customRelatedArticlesGrid,
    footnotes,
    metaTitle,
    partials,
    relatedArticlesGrid,
    stage,
    trackingPageArea,
  },
  parentPages,
  slug,
  uuid,
}) => {
  const { relatedArticlesTextIntro } = useContext(SettingsContext);

  useTemplateTracker({
    pageArea: trackingPageArea ?? '',
    pageType: 'article',
    pageTitle: metaTitle ?? '',
  });

  return (
    <Layout breadcrumbTitle={breadcrumbTitle} parentPages={parentPages} slug={slug}>
      {breadcrumbTitle && slug && Boolean(parentPages.length) && (
        <MBreadcrumb breadcrumbTitle={breadcrumbTitle} mode="content" parentPages={parentPages} slug={slug} />
      )}
      {stage?.length === 1 && <OStageArticle {...stage[0]} />}
      {partials?.map((item, index) => item && componentMapper(item, item._uid, undefined, index))}
      {customRelatedArticlesGrid?.articles?.length ? (
        <OTeaserGridArticles
          _uid={_uid}
          accent={customRelatedArticlesGrid?.accent ?? 'white'}
          articles={customRelatedArticlesGrid?.articles}
          component="o-teaser-grid-articles"
          currentPageId={_uid}
          limit={customRelatedArticlesGrid?.limit}
          tags={customRelatedArticlesGrid?.tags}
          textIntro={customRelatedArticlesGrid?.textIntro ?? relatedArticlesTextIntro}
          theme={customRelatedArticlesGrid?.theme ?? 'white'}
        />
      ) : relatedArticlesGrid?.articles?.length ? (
        <OTeaserGridArticles
          _uid={uuid}
          accent={'faded-gray'}
          articles={relatedArticlesGrid?.articles}
          component="o-teaser-grid-articles"
          currentPageId={_uid}
          limit="3"
          textIntro={relatedArticlesTextIntro}
          theme={'white'}
        />
      ) : null}
      {<OFootnotes footnotes={footnotes} />}
    </Layout>
  );
};
