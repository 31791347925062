import { Dispatch, SetStateAction } from 'react';

import styled from '@lichtblick/styled';
import { BelowSmall } from '@lichtblick/theme';
import { Text, Headline, Modal, TextCTA, StyledBoxCTA } from '@lichtblick/ui-components';
import { StyledBoxCTAText } from '@lichtblick/ui-components/atoms/BoxCTA/BoxCTA.styles';

import { StyledModalContent } from './CalculatorLayout.styles';

export const MAX_GAS_USAGE = 1499999;
export const MAX_ELECTRICITY_USAGE = 99999;

type UsageModalProps = {
  readonly isOpen: boolean;
  readonly route: '/gewerbestrom' | '/gewerbegas';
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const UsageModal = ({ isOpen, route, setIsOpen }: UsageModalProps) => {
  return (
    <Modal
      footerContent={
        <>
          <StyledResponsiveBoxCTA $variant="primary" as="a" href={route}>
            <StyledBoxCTAText>Gewerbekunde werden</StyledBoxCTAText>
          </StyledResponsiveBoxCTA>
          <TextCTA as="button" data-testid="button-modal-close" onClick={() => setIsOpen(false)}>
            Schließen
          </TextCTA>
        </>
      }
      isOpen={isOpen}
      newApi
      onPointerDownOutside={() => setIsOpen(false)}
    >
      <StyledModalContent>
        <Headline renderAs={'h2'} size="S">
          Für euch ist ein Gewerbetarif das Richtige
        </Headline>
        <Text renderAs={'p'}>
          Bei eurem Energieverbrauch ist Öko so wichtig wie der richtige Tarif: Ab{' '}
          {route === '/gewerbestrom' ? '100.000' : '1.500.000'} kWh im Jahr bietet LichtBlick deshalb Gewerbetarife.
          Dafür einfach auf den Button klicken, Formular ausfüllen und ein LichtBlick-Berater wird sich bald melden.
        </Text>
      </StyledModalContent>
    </Modal>
  );
};

const StyledResponsiveBoxCTA = styled(StyledBoxCTA)`
  @media ${BelowSmall} {
    width: 100%;
  }
`;
