import styled from '@lichtblick/styled';
import { Breakpoints, Colors, MediumAndAbove, Spacing } from '@lichtblick/theme';
import { focusRing } from '@lichtblick/ui-components/atoms/shared/styles';

/* stylelint-disable */
export const StyledBackToTopButtonContainer = styled.div<{ $isVisible: boolean }>`
  display: flex;
  align-items: center;
  justify-content: right;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: ${Breakpoints.Large}px;
  height: 10px;
  margin: 0 auto;
  padding: 0 ${Spacing.Xs};

  @media ${MediumAndAbove} {
    padding: 0 ${Spacing.Xl};
  }

  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transform: ${({ $isVisible }) => ($isVisible ? 'translate3d(0, 0, 0)' : 'translate3d(0, 20px, 0)')};
  transition-property: transform, opacity;
  transition-delay: 0.2s;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
  pointer-events: ${({ $isVisible }) => ($isVisible ? undefined : 'none')};
  z-index: 999;
`;

export const StyledBackToTopButton = styled.button`
  display: block;
  position: relative;
  bottom: ${Spacing.L};
  right: ${Spacing.Xxs};
  height: 48px;
  width: 48px;
  border-radius: 48px;
  border: 2px solid ${Colors.Black};
  background-color: ${Colors.White};
  transition-property: transform;
  transition-duration: 0.05s;
  transition-timing-function: ease-in-out;

  @media ${MediumAndAbove} {
    bottom: ${Spacing.Xxl};
  }

  &:focus-visible {
    ${focusRing}
  }

  &:hover {
    background-color: ${Colors.LightGray};
  }

  &:active {
    outline: none;
    transform: scale(0.96);
  }
`;
