import { FC, PropsWithChildren, useEffect, useState } from 'react';

import { Icon } from '@lichtblick/icons/components/Icon';
import { ArrowRightIconXs } from '@lichtblick/icons/svg/arrow-right/arrow-right-xs';
import { StyledTextCTAText, Text } from '@lichtblick/ui-components';

import {
  StyledFlyoutMenu,
  StyledOverlay,
  StyledStickyNav,
  StyledStickyNavContainer,
  StyledStickyNavTextCTA,
  StyledTextCTAIconWrapper,
  StyledToggle,
} from './StickyNav.styles';

import { MIndexItemType } from '../../types';

type StickyNavCTAProps = {
  href: string;
  icon: JSX.Element;
  onClick: () => void;
};

export const StickyNavCTA = ({ children, href, icon, onClick }: PropsWithChildren<StickyNavCTAProps>) => {
  return (
    <StyledStickyNavTextCTA $isSlim $variant="plain" href={href} onClick={onClick} tabIndex={-1}>
      {icon && <StyledTextCTAIconWrapper>{icon}</StyledTextCTAIconWrapper>}
      {children && <StyledTextCTAText>{children}</StyledTextCTAText>}
    </StyledStickyNavTextCTA>
  );
};

const useScrollLock = (isExpanded: boolean) => {
  useEffect(() => {
    const enableScrollLock = () => {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    };

    const disableScrollLock = () => {
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
    };

    if (isExpanded) {
      enableScrollLock();
    } else {
      disableScrollLock();
    }

    return () => {
      disableScrollLock();
    };
  }, [isExpanded]);
};

export const StickyNav: FC<{ isVisible: boolean; items: MIndexItemType[] }> = ({ isVisible, items }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useScrollLock(isExpanded);

  useEffect(() => {
    setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
  }, []);

  const handleMouseEnter = () => {
    if (isTouchDevice) {
      return;
    }

    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    if (isTouchDevice) {
      return;
    }

    setIsExpanded(false);
  };

  return (
    <>
      <StyledStickyNav $isVisible={isVisible} aria-hidden="true">
        <StyledStickyNavContainer>
          <StyledToggle
            onClick={() => {
              setIsExpanded((prev) => !prev);
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            tabIndex={-1}
          >
            <Text isBold={true} size="M">
              Inhaltsangabe
            </Text>
            <Icon name={isExpanded ? 'ChevronUpIconS' : 'ChevronDownIconS'} />
          </StyledToggle>
          <StyledFlyoutMenu $isExpanded={isExpanded} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {items.map((item) => (
              <li key={item._uid}>
                <StickyNavCTA
                  href={item.linkUrl}
                  icon={<ArrowRightIconXs />}
                  onClick={() => setIsExpanded((prev) => !prev)}
                >
                  <Text isBold={true} size="M">
                    {item.text}
                  </Text>
                </StickyNavCTA>
              </li>
            ))}
          </StyledFlyoutMenu>
        </StyledStickyNavContainer>
      </StyledStickyNav>
      {isVisible && (
        <StyledOverlay
          aria-hidden="true"
          isExpanded={isExpanded}
          onClick={() => setIsExpanded((prev) => !prev)}
          tabIndex={-1}
        />
      )}
    </>
  );
};
