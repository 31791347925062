import { Icon } from '@lichtblick/icons';
import styled from '@lichtblick/styled';
import { MediaMediumAndAbove, Spacing } from '@lichtblick/theme';

export const LinkItem = styled.li`
  align-items: flex-start;
  display: flex;
  gap: ${Spacing.Xs};
`;

export const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  margin-top: 0.3125rem;
  ${MediaMediumAndAbove} {
    margin-top: 0.32788rem;
  }
`;
