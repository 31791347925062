import styled, { css } from '@lichtblick/styled';
import { Colors, Spacing, alpha } from '@lichtblick/theme';
import { Box, LegacyLink, Text } from '@lichtblick/ui-components';

export const StyledBox = styled(Box)`
  border-top: 1px solid ${Colors.LightGray};
`;

export const StyledText = styled(Text)`
  ${({ size }) =>
    size == 'Xs'
      ? css`
          color: ${alpha(Colors.Black, 0.4)};
          margin-top: ${Spacing.Xxs};
        `
      : css`
          margin-bottom: ${Spacing.Xs};
        `}
`;

export const ContactLink = styled.div`
  align-items: center;
  display: inline-flex;
  margin-bottom: ${Spacing.Xs};
`;

export const StyledLink = styled(LegacyLink)`
  margin-left: ${Spacing.Xxs};
`;
