import { TrackingContextValue } from '@lichtblick/tracker';

import { extractErrorMessages, transformErrorMessage } from './errors';

export const allInOneCalculatorTrackingInitializer = (track: TrackingContextValue['trackCustomEvent']) => {
  const handlerCalculatorFocus = () => {
    track({ eventName: 'tariff_calculator_interaction' });
  };

  const handleCalculatorSubmit = (e: Event | CustomEvent) => {
    if ('detail' in e) {
      const { customProps, errors, touched } = e.detail;
      const errorMessage = transformErrorMessage(extractErrorMessages(errors, touched));

      if (!errorMessage) {
        track({ eventName: 'tariff_calculator_show_tariffs', customProps });
      } else {
        track({
          eventName: 'tariff_calculator_error_message',
          customProps: {
            product: customProps.product,
            error_message: errorMessage,
          },
        });
      }
    }
  };

  document.addEventListener('trackCalculatorOnFocus', handlerCalculatorFocus);
  document.addEventListener('trackCalculatorAfterSubmit', handleCalculatorSubmit);

  return () => {
    document.removeEventListener('trackCalculatorOnFocus', handlerCalculatorFocus);
    document.removeEventListener('trackCalculatorAfterSubmit', handleCalculatorSubmit);
  };
};
