import styled from '@lichtblick/styled';
import { BorderRadius, Colors, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';

import { AImage } from '../../AImage';
import { MTextIntro } from '../../MTextIntro';

export const CtaContentWrapper = styled.div<{ $backgroundColor: Colors }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${BorderRadius.S};
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${MediaSmallAndAbove} {
    flex-direction: row;
  }
`;

export const CtaImage = styled(AImage)`
  flex-basis: 50%;
  height: unset;
`;

export const CtaText = styled(MTextIntro)`
  align-items: start;
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  justify-content: center;
  padding: ${Spacing.S};

  ${MediaSmallAndAbove} {
    padding: ${Spacing.L};
  }
`;
