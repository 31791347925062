import styled from '@lichtblick/styled';

import { StyledTextCTAText } from '../../atoms/TextCTA/TextCTA.styles';

export const StyledPrimarySubNavigation = styled.li`
  list-style: none;

  ${StyledTextCTAText} {
    font-size: 1rem;
  }
`;
