import { FC } from 'react';

import { Meta, MetaValue, StyledHeadline, TeaserListItem } from './MTeaserListItem.styles';

export type MTeaserListItemProps = {
  _uid: string;
  component: 't-press-detail-list-item' | 't-job-detail-list-item' | 'm-teaser-list-item';
  isExternalLink?: boolean;
  linkUrl: string;
  meta?: string[];
  text: string;
};

export const MTeaserListItem: FC<MTeaserListItemProps> = ({ isExternalLink = false, linkUrl, meta = [], text }) => (
  <TeaserListItem href={linkUrl} target={isExternalLink ? '_blank' : '_parent'}>
    <StyledHeadline renderAs="div" size="S">
      {text}
    </StyledHeadline>
    {meta?.length > 0 && (
      <Meta $isExternalLink={isExternalLink}>
        {meta.map((item) => (
          <MetaValue key={item}>{item}</MetaValue>
        ))}
      </Meta>
    )}
  </TeaserListItem>
);
