export const texts = {
  contact: {
    links: {
      contactForm: {
        label: 'Zum Kontaktformular',
      },
    },
  },
  legal: {
    copyright: '© {{year}} LichtBlick',
  },
};
