import styled, { css } from '@lichtblick/styled';
import { Breakpoints, MediaMediumAndAbove, Spacing } from '@lichtblick/theme';

export const Figcaption = styled.figcaption<{ $layout?: string | null }>`
  margin: 0 auto;
  max-width: ${Breakpoints.Large}px;

  ${({ $layout }) =>
    $layout === 'large'
      ? css`
          padding: ${Spacing.Xxs} ${Spacing.Xs};

          ${MediaMediumAndAbove} {
            padding: ${Spacing.Xxs} ${Spacing.Xl};
          }
        `
      : css`
          padding: ${Spacing.Xxs} 0 0;
        `}
`;
