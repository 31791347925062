import { LegacyButtonVariant } from '@lichtblick/ui-components';

// contentful button variants need to be mapped to the button variants from the ui-components

const variantMap: Record<string, LegacyButtonVariant> = {
  outline: 'secondary',
  solid: 'primary',
  link: 'link',
};

export const getButtonVariant = (variant?: string | null) => (variant ? variantMap[variant] : undefined);
