// check readme in this folder for further informations
type ComponentsMapProps = {
  fileName: string;
  selector?: string;
};

const FILE_HOST = 'https://static.lichtblick.de/react-components';

export type ReactComponentNames =
  | 'AllInOneCalculator'
  | 'LeadForm'
  | 'ContractCancellationForm'
  | 'PermissionCenter'
  | 'ReliefCalculator'
  | 'CarCalculator'
  | 'WallboxConfigurator'
  | 'SuccessPageContent'
  | 'form'
  | 'vendor';

export const COMPONENTS_MAP: { [name in ReactComponentNames]: ComponentsMapProps } = {
  // calculators
  AllInOneCalculator: {
    selector: '[id^="all-in-one-calculator-"]',
    fileName: `${FILE_HOST}/allinonecalculator.4ea52df0f3250fa20495.js`,
  },
  // forms
  LeadForm: {
    selector: '[id^="form-"]',
    fileName: `${FILE_HOST}/leadform.c71281b3238131e31aed.js`,
  },
  ContractCancellationForm: {
    selector: '[id^="contract-cancellation-"]',
    fileName: `${FILE_HOST}/contractcancellationform.d764a68b45845ca215cc.js`,
  },
  PermissionCenter: {
    selector: '[id^="permission-center-"]',
    fileName: `${FILE_HOST}/permissioncenter.10f6abc8c62162b37d30.js`,
  },
  ReliefCalculator: {
    selector: '[id^="relief-calculator-"]',
    fileName: `${FILE_HOST}/reliefcalculator.819647d63d7db02f6420.js`,
  },
  // others
  CarCalculator: {
    selector: '[id^="car-calculator-"]',
    fileName: `${FILE_HOST}/carcalculator.8264763bc92b222b04a7.js`,
  },
  WallboxConfigurator: {
    selector: '[id^="wallbox-configurator-"]',
    fileName: `${FILE_HOST}/wallboxconfigurator.3871d4cb5b1044b55afe.js`,
  },
  SuccessPageContent: {
    selector: '[id="success-page-content"]',
    fileName: `${FILE_HOST}/successpagecontent.8044640292ee4558077c.js`,
  },
  // common scripts
  form: {
    fileName: `${FILE_HOST}/form.d2d34de5cf08744007a5.js`,
  },
  vendor: {
    fileName: `${FILE_HOST}/vendor.7c1f60373f9a22880b78.js`,
  },
} as const;

const CSS_SRC = `${FILE_HOST}/hugo-apps.2.css`;

type ReactComponentDomElementMap = { [name in ReactComponentNames]?: NodeListOf<HTMLElement> };

export const getComponentDomElements = (): ReactComponentDomElementMap =>
  Object.keys(COMPONENTS_MAP).reduce<ReactComponentDomElementMap>(
    (acc: ReactComponentDomElementMap, componentName: ReactComponentNames): ReactComponentDomElementMap => {
      const componentSelector = COMPONENTS_MAP[componentName].selector;

      if (!componentSelector) {
        return acc;
      }

      const domElements = document.querySelectorAll<HTMLElement>(componentSelector);

      if (domElements.length === 0) {
        return acc;
      }

      return {
        ...acc,
        [componentName]: domElements,
      };
    },
    {},
  );

const initComponent = async (
  componentName: ReactComponentNames,
  domElements: NodeListOf<HTMLElement>,
): Promise<void> => {
  await loadComponentScript(componentName);
  renderComponent(domElements, window[componentName as any], `${componentName} could not be found`);
};

const loadComponentScript = (componentName: ReactComponentNames): Promise<void> =>
  new Promise<void>((resolve) => {
    const componentScript = COMPONENTS_MAP[componentName].fileName;

    if (!componentScript) {
      throw Error(`Could not render ${componentName} cause component script is not available.`);
    }

    const scriptElement = document.createElement('script');

    scriptElement.async = true;
    scriptElement.src = COMPONENTS_MAP[componentName].fileName;

    scriptElement.onload = () => {
      resolve();
    };

    document.body.appendChild(scriptElement);
  });

const loadCss = (): Promise<void> =>
  new Promise<void>((resolve) => {
    const linkElement = document.createElement('link');

    linkElement.rel = 'stylesheet';
    linkElement.href = CSS_SRC;

    linkElement.onload = () => {
      resolve();
    };

    document.head.appendChild(linkElement);
  });

const parse = (json: string): any => {
  try {
    return json ? JSON.parse(json) : {};
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Could not parse the given json string', e);

    return {};
  }
};

const isEmpty = (obj?: object | null): boolean => !obj || !Object.keys(obj).length;

const getAppConfig = (domElement: HTMLElement): object | undefined => {
  const selector = domElement.dataset.configId;

  if (selector) {
    const appConfigString = document.getElementById(selector)?.textContent;
    const appConfig = parse(appConfigString ?? '');

    return isEmpty(appConfig) ? undefined : appConfig;
  }

  return undefined;
};

const renderComponent = (domElements: NodeListOf<HTMLElement>, component: any, fallbackMessage?: string): void => {
  const reactInstance = component?.React;
  const reactDOMInstance = component?.ReactDOM;

  if (!reactInstance || !reactDOMInstance) {
    throw Error('Could not render react components cause react is not available in this page.');
  }

  domElements.forEach((domElement) => {
    if (component) {
      const props = {
        ...domElement.dataset,
        appConfig: getAppConfig(domElement),
      };

      const root = reactDOMInstance.createRoot(domElement);

      root.render(reactInstance.createElement(component, props));
    } else {
      domElement.innerHTML = fallbackMessage ?? '';
    }
  });
};

const loadCommonScripts = (): Promise<any> =>
  Promise.all([loadComponentScript('vendor'), loadComponentScript('form'), loadCss()]);

export const initReactComponents = async (): Promise<void> => {
  const componentInDomElements = Object.entries(getComponentDomElements()) as [
    ReactComponentNames,
    NodeListOf<HTMLElement>,
  ][];

  if (componentInDomElements.length === 0) {
    return;
  }

  await loadCommonScripts();
  await Promise.all(
    componentInDomElements.map(([componentName, componentDomElements]) => {
      return initComponent(componentName, componentDomElements);
    }),
  );
};
