import { FC, MouseEventHandler, PropsWithChildren, ReactNode } from 'react';

import { Colors } from '@lichtblick/theme';
import { TrackingData, useTracker } from '@lichtblick/tracker';

import { Container, Content, StyledLink, Media } from './TeaserItem.styles';

import { Chip } from '../../atoms/Chip';
import { Headline } from '../../atoms/Headline';
import { Text } from '../../atoms/Text';

type LinkProps = {
  href?: string;
  isDownloadLink?: boolean;
  shouldOpenInNewTab?: boolean;
};

export type TeaserItemProps = PropsWithChildren<{
  backgroundColor?: Colors;
  children: ReactNode;
  chip?: string;
  className?: string;
  headline?: string;
  isTextOutside?: boolean;
  link?: LinkProps;
  media?: ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
  text?: string | ReactNode;
  tracking?: TrackingData;
}>;

export const TeaserItem: FC<TeaserItemProps> = ({
  backgroundColor = Colors.White,
  children,
  chip,
  className,
  headline,
  isTextOutside = false,
  link,
  media,
  onClick,
  text,
  tracking,
}) => {
  const { trackCustomEvent } = useTracker();

  const trackEvent = (trackingData?: TrackingData) => {
    trackCustomEvent(trackingData);

    if (link?.href?.includes('onelink')) {
      trackCustomEvent(
        {
          eventName: 'app_link',
          customProps: {
            link_type: 'Onelink',
          },
        },
        undefined,
        false,
      );
    }
  };

  const content = (
    <Content $isTextOutside={isTextOutside}>
      {chip && <Chip label={chip} />}
      {headline && <Headline size="S">{headline}</Headline>}
      {text && <Text>{text}</Text>}
      {!isTextOutside && children}
    </Content>
  );

  return (
    <StyledLink
      $backgroundColor={backgroundColor}
      $isTextOutside={isTextOutside}
      className={className}
      data-testid="teaser-item"
      download={link?.isDownloadLink}
      href={link?.href ?? '#'}
      onClick={(event) => {
        trackEvent(tracking);
        onClick?.(event);
      }}
      target={link?.shouldOpenInNewTab ? '_blank' : undefined}
    >
      <Container $backgroundColor={backgroundColor} $isTextOutside={isTextOutside}>
        {media && (
          <Media $backgroundColor={backgroundColor} $isTextOutside={isTextOutside}>
            {media}
          </Media>
        )}
        {!isTextOutside && content}
      </Container>
      {isTextOutside && content}
    </StyledLink>
  );
};
