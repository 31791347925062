/* eslint-disable import/no-default-export */

import styled from 'styled-components';
export {
  css,
  createGlobalStyle,
  keyframes,
  ServerStyleSheet,
  StyleSheetManager,
  ThemeProvider,
  type FlattenSimpleInterpolation,
  type StyledComponent,
  type Keyframes,
} from 'styled-components';

export default styled;

export type PickTransient<T, K extends keyof T> = {
  [P in K as P extends string ? `$${P}` : P]: T[P];
};

export const transient = <T extends object>(props: T) =>
  Object.entries(props).reduce((transientProps, [key, value]) => {
    transientProps[`$${key}`] = value;

    return transientProps;
  }, {} as any) as PickTransient<T, keyof T>;
