import styled, { css } from '@lichtblick/styled';
import { Colors, MediaMediumAndAbove, MediaSmallAndAbove, Spacing, ZIndex } from '@lichtblick/theme';

import { Container } from '../../atoms/Container/Container';
import { focusRing } from '../../atoms/shared/styles';
import { Link } from '../../helpers/Link';

export const Root = styled.header`
  position: relative;
  z-index: ${ZIndex.Header};

  // background (necessary to make the background visible above the overlay)
  &::after {
    background-color: ${Colors.White};
    content: '';
    inset: 0;
    position: absolute;
    z-index: -1;
  }
`;

export const Section = styled(Container)`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  ${MediaSmallAndAbove} {
    flex-wrap: nowrap;
  }
`;

export const HomeLink = styled(Link)`
  align-self: center;
  display: flex;
  min-height: 0;
  width: 7rem;

  ${MediaMediumAndAbove} {
    width: 10.625rem;
  }

  & > svg {
    width: 100%;
  }

  &:focus-visible {
    ${focusRing}
  }
`;

export const HeaderNavigation = styled.menu<{ $isTopNavigation?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: ${Spacing.Xs};
  justify-content: center;
  line-height: 1.7;
  pointer-events: none;
  position: relative;

  ${({ $isTopNavigation }) =>
    $isTopNavigation &&
    css`
      && {
        justify-content: space-between;
        min-width: 100%;
        order: 2;

        ${MediaSmallAndAbove} {
          min-width: 0;
          order: initial;
        }
      }
    `}

  ${MediaMediumAndAbove} {
    gap: ${Spacing.M};
  }
`;
