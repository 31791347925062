import styled from '@lichtblick/styled';

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 30rem;
  width: 100%;
`;
