import styled from '@lichtblick/styled';
import { BorderRadius, Breakpoints, Colors, MediumAndAbove, SmallAndAbove, Spacing, alpha } from '@lichtblick/theme';
import { StyledTextCTA } from '@lichtblick/ui-components';
import { StyledTextCTAIconWrapper as TextCTAIconWrapper } from '@lichtblick/ui-components/atoms/TextCTA/TextCTA.styles';

/* stylelint-disable */
export const StyledStickyNavTextCTA = styled(StyledTextCTA)`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: ${Spacing['3Xs']} 0;
  padding: 10px ${Spacing['Xxs']};
  border-radius: ${BorderRadius.S};

  &:hover {
    background-color: ${Colors.FadedGray};
    transition: background-color 0.05s ease-in-out;
  }
`;

export const StyledTextCTAIconWrapper = styled(TextCTAIconWrapper)`
  @media ${MediumAndAbove} {
    top: 3px;
  }
`;

export const StyledStickyNav = styled.div<{ $isVisible: boolean }>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${Colors.FadedGray};
  z-index: 999;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  transition: opacity 0.15s ease-in-out;
  pointer-events: ${({ $isVisible }) => ($isVisible ? 'auto' : 'none')};
`;

export const StyledStickyNavContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  position: relative;
  width: 100%;
  max-width: ${Breakpoints.Large}px;
  padding: 0 ${Spacing.Xs};

  @media ${MediumAndAbove} {
    padding: 0 ${Spacing.Xl};
  }
`;

export const StyledToggle = styled.button`
  color: ${Colors.Black};
  height: 100%;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  & > :first-child {
    margin-right: ${Spacing.Xxs};
  }
`;

export const StyledFlyoutMenu = styled.ul<{ $isExpanded: boolean }>`
  position: absolute;
  list-style-type: none;
  top: 40px;
  left: 0;
  width: 100%;
  background-color: ${Colors.White};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
  overflow: hidden;
  padding: 0 ${Spacing.S};
  max-height: ${(props) => (props.$isExpanded ? '85vh' : '0px')};
  max-height: ${(props) => (props.$isExpanded ? '85dvh' : '0px')};
  overflow-y: ${(props) => (props.$isExpanded ? 'auto' : 'hidden')};
  transition: max-height 0.15s ease-in-out;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  li:not(:last-child) {
    border-bottom: 1px solid ${Colors.FadedGray};
  }

  @media ${SmallAndAbove} {
    padding: 0 ${Spacing.Xs};
    margin: 0 ${Spacing.Xxs};
    width: 460px;
  }

  @media ${MediumAndAbove} {
    margin: 0 ${Spacing.L};
  }
`;

export const StyledOverlay = styled.div<{ isExpanded: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${alpha(Colors.Black, 0.8)};
  z-index: 998;
  opacity: ${(props) => (props.isExpanded ? 1 : 0)};
  transition: opacity 0.2s ease-in-out;
  pointer-events: ${(props) => (props.isExpanded ? '' : 'none')};
`;
