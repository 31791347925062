import styled, { PickTransient } from '@lichtblick/styled';
import { FontWeights, MediaDesktop } from '@lichtblick/theme';

import { AccentTextProps } from './AccentText';
import { AccentTextSizeClass } from './AccentText';

const responsiveFontSizes: {
  desktop: { [K in AccentTextSizeClass]: string };
  mobile: { [K in AccentTextSizeClass]: string };
} = {
  desktop: {
    M: '1.25rem',
    L: '1.75rem',
  },
  mobile: {
    M: '1.125rem',
    L: '1.5625rem',
  },
};

export const Wrapper = styled.span<PickTransient<AccentTextProps, 'size'>>`
  font-size: ${({ $size = 'M' }) => responsiveFontSizes.mobile[$size]};
  font-weight: ${FontWeights.Bold};
  line-height: 140%;

  ${MediaDesktop} {
    font-size: ${({ $size = 'M' }) => responsiveFontSizes.desktop[$size]};
  }
`;
