/* stylelint-disable no-descending-specificity */

import styled, { css } from '@lichtblick/styled';
import { alpha, Colors, MediaMediumAndAbove, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';

import { Container } from '../../atoms/Container/Container';
import { Headline } from '../../atoms/Headline/Headline';
import { Text } from '../../atoms/Text/Text';
import { StyledTextCTAText } from '../../atoms/TextCTA/TextCTA.styles';
import { Link } from '../../helpers/Link';

export const Root = styled.footer<{ $hasPaddingTop?: boolean }>`
  background-color: ${Colors.Black};
  border-top: 1px solid ${alpha(Colors.White, 0.2)};
  color: ${Colors.White};
  overflow: hidden;

  &:first-of-type {
    border: none;

    ${({ $hasPaddingTop }) =>
      $hasPaddingTop &&
      `
       ${MediaMediumAndAbove} {
        padding-top: 2rem;
      }
    `}
  }
`;

export const HomeLink = styled(Link)`
  align-self: center;
  min-height: 0;
  width: 7rem;

  ${MediaMediumAndAbove} {
    width: 7.8125rem;
  }
`;

export const FooterSection = styled(Container)<{ $isSecondary?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ $isSecondary }) =>
    $isSecondary &&
    css`
      font-size: 0.625rem;
    `}
  padding-bottom: 2rem;
  padding-inline: ${Spacing.Xxs};
  padding-top: 1rem;

  ${MediaSmallAndAbove} {
    flex-wrap: wrap;
    ${({ $isSecondary }) =>
      !$isSecondary &&
      css`
        flex-direction: row;
      `}
  }

  ${MediaMediumAndAbove} {
    margin: 0 auto;
    padding-inline: calc(${Spacing.Xl} - ${Spacing.Xxs});
  }
`;

export const footerCellStyles = css`
  box-sizing: border-box;
  margin: 2rem 0;
  padding: 0 0.5rem;
`;

export const FooterTeaser = styled.div`
  ${footerCellStyles};

  ${MediaSmallAndAbove} {
    order: 2;
    width: 50%;
  }

  ${MediaMediumAndAbove} {
    order: 1;
    width: 33%;
  }
`;

export const FooterHeadline = styled(Headline).attrs({ renderAs: 'div', size: 'S' })`
  margin-bottom: 1rem;

  ${MediaMediumAndAbove} {
    margin-bottom: 1.5rem;
  }
`;

export const Contact = styled.div`
  ${footerCellStyles};

  ${MediaSmallAndAbove} {
    order: 1;
    width: 50%;
  }

  ${MediaMediumAndAbove} {
    order: 2;
    width: 33.3333%;
  }
`;

export const ContactLink = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  margin-top: 1rem;

  svg {
    fill: currentColor;
  }
`;

export const ContactOpeningHours = styled(Text).attrs({ size: 'Xs' })`
  color: ${Colors.Gray};
  display: block;
  margin-top: 1rem;
`;

export const FooterNavigation = styled.menu<{ $isSecondary?: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  order: ${({ $isSecondary }) => ($isSecondary ? 'initial' : 3)};
  padding: ${({ $isSecondary }) => ($isSecondary ? '1rem 0' : 0)};

  ${MediaSmallAndAbove} {
    flex-direction: row;
  }
`;

export const FooterCopyright = styled(Text).attrs({ size: 'Xs' })`
  color: ${Colors.DarkGray};
  display: block;
  margin-top: 1rem;
  text-align: center;
`;

export const StyledSecondaryNavigation = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${Spacing.S};
  ${footerCellStyles};

  ${MediaSmallAndAbove} {
    gap: ${Spacing.M};
  }

  ${MediaSmallAndAbove} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  ${StyledTextCTAText} {
    font-size: 0.625rem;

    ${MediaMediumAndAbove} {
      font-size: 0.75rem;
    }
  }
`;
