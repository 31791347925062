import { LogoIcon } from '@lichtblick/icons/svg/logo/logo';
import styled from '@lichtblick/styled';
import { DurationAndEasing } from '@lichtblick/theme';

export const Logo = styled(LogoIcon)`
  [class*='logo-sun'] {
    transition: transform ${DurationAndEasing.SmallIntro};

    *:focus-visible > &,
    *:hover > & {
      transform: translate(0.25rem, -0.125rem);
    }

    *:active > & {
      transform: translate(0.625rem, -0.3125rem);
    }
  }
`;
