import { FC } from 'react';

import { Spacing } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

import { MediaFigure } from './MediaFigure';
import { Wrapper } from './OMedia.styles';

import { MTextIntroPrice } from '../..';
import { MTextIntroType, OMediaType } from '../../types/storyblok';
import { getColor, getSpacing } from '../../utils';
import { MTextIntro } from '../MTextIntro';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

const isMTextIntro = (
  textIntro: Exclude<OMediaType['textIntro'], undefined>[number] | undefined,
): textIntro is MTextIntroType => textIntro?.component === 'm-text-intro';

type OMediaProps = OMediaType & {
  index?: number;
  isCenteredDesktop?: boolean;
  isCenteredMobile?: boolean;
};

export const OMedia: FC<OMediaProps> = ({
  anchorId,
  caption,
  copyright,
  index,
  isCenteredDesktop = true,
  isCenteredMobile = true,
  layout,
  media,
  paddingBottomDesktop,
  paddingBottomMobile,
  paddingTopDesktop,
  paddingTopMobile,
  textIntro,
  theme,
}) => {
  const isNarrow = isMTextIntro(textIntro?.[0]) && isCenteredDesktop;

  return (
    <Box
      backgroundColor={getColor(theme)}
      data-testid="media"
      pbd={getSpacing(paddingBottomDesktop) ?? Spacing.Xl}
      pbm={getSpacing(paddingBottomMobile) ?? Spacing.L}
      ptd={getSpacing(paddingTopDesktop) ?? Spacing.Xl}
      ptm={getSpacing(paddingTopMobile) ?? Spacing.L}
    >
      {anchorId && <AnchorMark anchorId={anchorId} />}

      {textIntro?.length === 1 && (
        <GridContainer>
          <GridRow>
            <GridColumn $push={isNarrow ? 2 : undefined} $width={isNarrow ? 8 : 12}>
              <Wrapper>
                {isMTextIntro(textIntro?.[0]) ? (
                  <MTextIntro
                    {...textIntro[0]}
                    isCenteredDesktop={isCenteredDesktop}
                    isCenteredMobile={isCenteredMobile}
                  />
                ) : (
                  <MTextIntroPrice {...textIntro[0]} />
                )}
              </Wrapper>
            </GridColumn>
          </GridRow>
        </GridContainer>
      )}

      <MediaFigure caption={caption} copyright={copyright} index={index} layout={layout} media={media} />
    </Box>
  );
};
