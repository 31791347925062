import { AnimatePresence, motion } from 'framer-motion';
import { FC, ReactNode, useState } from 'react';

import { Colors, Duration, EasingValues, Spacing } from '@lichtblick/theme';

import { Root, TabListContainer, TabList, TabContainer, Tab, Background, MobileNavAlignment } from './Tabs.styles';

import { Box, BoxProps } from '../../atoms/Box/Box';
import { Text } from '../../atoms/Text/Text';

export type Tab = {
  contentSlot?: ReactNode;
  headline: string;
  subline?: string;
};

export type TabsType = [Tab, Tab] | [Tab, Tab, Tab];

export type TabsProps = {
  /** The background color of the inactive tab. defaults to `Colors.White` */
  backgroundColor?: Colors;
  className?: string;
  /** In case the alignment of the content needs to be adjusted. */
  customContentProps?: BoxProps;
  /** Spacing between Tab Controls and Content */
  gap?: Spacing;
  /**
   * If there is more than one tab menu, Framer Motion must determine
   * for which component to animate the active state indicator.
   */
  layoutID?: string;
  /** default is row */
  mobileNavAlignment?: MobileNavAlignment;
  onActiveTabChange?: (tab: number) => void;
  /** Min: 2, Max: 3 */
  tabs: TabsType;
};

export const Tabs: FC<TabsProps> = ({
  backgroundColor = Colors.White,
  className,
  customContentProps,
  gap,
  layoutID,
  mobileNavAlignment,
  onActiveTabChange,
  tabs,
}) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  /**
   * If there is more than one tab menu, Framer Motion must determine
   * for which component to animate the active state indicator.
   * If it is not passed directly, it is assumed (!) that the
   * combination of all tab names is unique.
   */
  const uniqueLayoutID = layoutID || tabs.map((tab) => tab.headline).join('-');

  const { contentSlot: activeContent, headline: activeHeadline } = tabs[activeTab];

  const onTabClicked = (index: number) => {
    setActiveTab(index);
    onActiveTabChange?.(index);
  };

  return (
    <Root $gap={gap} className={className}>
      <TabListContainer $aria-orientation="horizontal" role="tabpanel">
        <TabList $backgroundColor={backgroundColor} $mobileNavAlignment={mobileNavAlignment ?? 'row'}>
          {tabs.map(({ headline, subline }, index: number) => {
            const isActive = index === activeTab;

            const key = `${uniqueLayoutID}-${headline}-${index}`;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <TabContainer key={key}>
                <Tab
                  onClick={() => onTabClicked(index)}
                  {...{ 'aria-selected': isActive, 'aria-controls': headline, 'aria-label': headline }}
                  data-testid={`${uniqueLayoutID}-tab-${index}`}
                  isActive={isActive}
                  role="tab"
                  tabIndex={index + 1}
                >
                  <Text align="center" isBold size="S">
                    {headline}
                  </Text>

                  {subline ? (
                    <Text align="center" size="S">
                      {subline}
                    </Text>
                  ) : null}

                  {isActive && <Background layoutId={uniqueLayoutID} transition={{ ease: EasingValues.Outro }} />}
                </Tab>
              </TabContainer>
            );
          })}
        </TabList>
      </TabListContainer>
      <main {...{ 'aria-labelledby': activeHeadline }}>
        <Box {...customContentProps}>
          <AnimatePresence mode="wait">
            <motion.div
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              initial={{ y: 10, opacity: 0 }}
              key={activeTab}
              transition={{ ease: EasingValues.Outro, duration: Duration.SmallOutro / 1000 }}
            >
              {activeContent}
            </motion.div>
          </AnimatePresence>
        </Box>
      </main>
    </Root>
  );
};
