import { ComponentPropsWithoutRef, FC } from 'react';

import { Default, Plain, Thin } from './LegacyLink.styles';

type LinkVariant = 'plain' | 'thin' | 'default';

const variantMap: Record<LinkVariant, typeof Default> = {
  plain: Plain,
  thin: Thin,
  default: Default,
};

export type LegacyLinkProps = Omit<ComponentPropsWithoutRef<'a'>, 'as'> & {
  shouldOpenInNewTab?: boolean;
  variant?: LinkVariant;
};

/** @deprecated */
export const LegacyLink: FC<LegacyLinkProps> = ({ href, shouldOpenInNewTab, variant = 'default', ...props }) => {
  const Variant = variantMap[variant] ?? Default;

  return <Variant href={href} target={shouldOpenInNewTab ? '_blank' : undefined} {...props} />;
};
