import styled from '@lichtblick/styled';
import { Breakpoints, MediaMediumAndAbove, Spacing } from '@lichtblick/theme';
import { StyledSelectInput } from '@lichtblick/ui-components';

export const StyledSelectInputWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 ${Spacing.Xs};
  margin-left: auto;
  margin-right: auto;
  margin-top: ${Spacing.Xs};
  margin-bottom: -${Spacing.M};
  width: 100%;
  max-width: ${Breakpoints.Large}px;

  ${StyledSelectInput} {
    width: 100%;
  }

  ${MediaMediumAndAbove} {
    padding: 0 ${Spacing.Xl};
    margin-top: ${Spacing.S};

    ${StyledSelectInput} {
      width: auto;
      min-width: 160px;
    }
  }
`;
