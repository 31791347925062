import { FC } from 'react';

import { Iframe, Root } from './MIframe.styles';

import { MIframeType } from '../../types/storyblok';

export const MIframe: FC<MIframeType> = ({
  desktopHeight,
  desktopWidth,
  layout,
  mobileHeight,
  mobileWidth,
  src,
  title,
}) => (
  <Root
    $desktopHeight={desktopHeight ? Number(desktopHeight) : 900}
    $desktopWidth={desktopWidth ? Number(desktopWidth) : 1600}
    $layout={layout ?? 'responsive'}
    $mobileHeight={mobileHeight ? Number(mobileHeight) : 600}
    $mobileWidth={mobileWidth ? Number(mobileWidth) : 400}
    data-testid="iframe"
  >
    <Iframe allow="geolocation" height="100%" loading="lazy" src={src ?? ''} title={title ?? ''} width="100%" />
  </Root>
);
