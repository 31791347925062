import { FC } from 'react';

import { useTracker } from '@lichtblick/tracker';

import { StyledNavigationLink, StyledNavigationLinkText, StyledNavigationItemList } from './NavigationItemGroup.styles';
import { NavigationItemIcons } from './NavigationItemIcon';

import { NavigationItemProps } from '../../types';

export const NavigationItemGroup: FC<NavigationItemProps> = ({
  href,
  icon,
  isActive,
  items,
  setIsFlyoutActive,
  text,
  tracking,
}) => {
  const { trackCustomEvent } = useTracker();

  return (
    <>
      {Boolean(text) && (
        <StyledNavigationItemList>
          <StyledNavigationLink
            $isActive={!!isActive && !items?.some((item) => item.isActive)}
            aria-label={text}
            href={href}
            key={href}
            onClick={() => {
              trackCustomEvent(tracking);

              if (setIsFlyoutActive) {
                setIsFlyoutActive(false);
              }
            }}
          >
            {icon && <NavigationItemIcons name={icon} />}
            <StyledNavigationLinkText>{text}</StyledNavigationLinkText>
          </StyledNavigationLink>
          {Boolean(items?.length) && (
            <menu>
              {items?.map(
                ({ href, icon, isActive, text, tracking }) =>
                  Boolean(text) && (
                    <StyledNavigationLink
                      $isActive={!!isActive}
                      aria-label={text}
                      href={href}
                      key={href}
                      onClick={() => {
                        trackCustomEvent(tracking);

                        if (setIsFlyoutActive) {
                          setIsFlyoutActive(false);
                        }
                      }}
                    >
                      {icon && <NavigationItemIcons name={icon} />}
                      <StyledNavigationLinkText>{text}</StyledNavigationLinkText>
                    </StyledNavigationLink>
                  ),
              )}
            </menu>
          )}
        </StyledNavigationItemList>
      )}
    </>
  );
};
