import { ElementType, FC, PropsWithChildren } from 'react';

import { ThemeProvider, transient } from '@lichtblick/styled';
import { Colors, dark, darkColors, light } from '@lichtblick/theme';

import { Container } from './Box.styles';
import {
  BorderAttributes,
  DimensionAttributes,
  FlexAttributes,
  GridAttributes,
  PositionAttributes,
  Spacings,
} from './Box.types';

export type BoxProps = PropsWithChildren<
  {
    // `backgroundColor` will implicitly set color when it is a dark color
    backgroundColor?: string | Colors;
    boxSizing?: 'content-box' | 'border-box';
    className?: string;
    // if `color` is set, color will overwrite the implicit color defined by `backgroundColor`
    color?: string | Colors;
    'data-testid'?: string;
    display?: 'block' | 'inline-block' | 'flex' | 'inline-flex' | 'grid' | 'inline-grid';
    gap?: string;
    id?: string;
    textAlign?: 'left' | 'center' | 'right';
    zIndex?: number;
  } & Spacings &
    GridAttributes &
    FlexAttributes &
    PositionAttributes &
    DimensionAttributes &
    BorderAttributes & { as?: ElementType }
>;

/**
 * @param alignItems  Aligns flex items of the current flex container.
 * @param alignContent  Defines the default behaviour for how flex items are laid out along the cross axis on the current line.
 * @param alignSelf  Allows the default alignment (or the one specified by align-items) to be overridden for individual flex items.
 * @param backgroundColor  Background color of the box.
 * @param border  Sets all the border properties in one declaration.
 * @param borderColor  Sets the color of an element's four borders.
 * @param borderRadius  Defines the radius of the element's corners.
 * @param borderStyle  Sets the style of an element's four borders.
 * @param borderWidth  Sets the width of an element's four borders.
 * @param bottom  Specifies the bottom position of a positioned element.
 * @param children  The content of the box.
 * @param color  Color of the box.
 * @param display  Specifies the type of box used for an element.
 * @param flex  Specifies the length of the item, relative to the rest of the flexible items inside the same container.
 * @param flexDirection  Specifies the direction of the flexible items.
 * @param flexGrow  Specifies how much the item will grow relative to the rest of the flexible items inside the same container.
 * @param flexShrink  Specifies how the item will shrink relative to the rest of the flexible items inside the same container.
 * @param flexWrap  Specifies whether the flexible items should wrap or not.
 * @param gridArea  Specifies a name for the grid item, and how a grid item is placed within the grid by referring to the name.
 * @param gridAutoColumns  Specifies the size of the column tracks.
 * @param gridAutoFlow  Specifies how auto-placed items are inserted in the grid.
 * @param gridAutoRows  Specifies the size of the row tracks.
 * @param gridColumn  Specifies a grid item's size and location within the grid column by referring to specific grid lines.
 * @param gridColumnGap  Specifies the size of the gap between columns.
 * @param gridGap  Specifies the size of the gap between rows and columns.
 * @param gridRow  Specifies a grid item's size and location within the grid row by referring to specific grid lines.
 * @param gridRowGap  Specifies the size of the gap between rows.
 * @param gridTemplateAreas  Specifies how to display columns and rows, using named grid items.
 * @param gridTemplateColumns  Specifies the size of the column tracks.
 * @param gridTemplateRows  Specifies the size of the row tracks.
 * @param height  Sets the height of an element.
 * @param id  Id of the box.
 * @param justifyContent  Defines the alignment along the main axis.
 * @param justifyItems  Defines the default behaviour for how flex items are laid out along the main axis on the current line.
 * @param justifySelf  Allows the default alignment (or the one specified by justify-items) to be overridden for individual flex items.
 * @param left  Specifies the left position of a positioned element.
 * @param m  Sets all the margin properties in one declaration.
 * @param mb  Sets the bottom margin of an element.
 * @param ml  Sets the left margin of an element.
 * @param mr  Sets the right margin of an element.
 * @param mt  Sets the top margin of an element.
 * @param mx  Sets the left and right margin of an element.
 * @param my  Sets the top and bottom margin of an element.
 * @param mbm  Sets the bottom margin of an element for mobile
 * @param mlm  Sets the left margin of an element for mobile
 * @param mm  Sets all the margin properties in one declaration for mobile
 * @param mrm  Sets the right margin of an element for mobile
 * @param mtm  Sets the top margin of an element for mobile
 * @param mxm  Sets the left and right margin of an element for mobile
 * @param mym  Sets the top and bottom margin of an element for mobile
 * @param mbd  Sets the bottom margin of an element for desktop
 * @param md  Sets all the margin properties in one declaration for desktop
 * @param mld  Sets the left margin of an element for desktop
 * @param mrd  Sets the right margin of an element for desktop
 * @param mtd  Sets the top margin of an element for desktop
 * @param mxd  Sets the left and right margin of an element for desktop
 * @param myd  Sets the top and bottom margin of an element for desktop
 * @param p  Sets all the padding properties in one declaration.
 * @param pb  Sets the bottom padding of an element.
 * @param pl  Sets the left padding of an element.
 * @param pr  Sets the right padding of an element.
 * @param pt  Sets the top padding of an element.
 * @param px  Sets the left and right padding of an element.
 * @param py  Sets the top and bottom padding of an element.
 * @param pbm  Sets the bottom padding of an element for mobile
 * @param plm  Sets the left padding of an element for mobile
 * @param pm  Sets all the padding properties in one declaration for mobile
 * @param prm  Sets the right padding of an element for mobile
 * @param ptm  Sets the top padding of an element for mobile
 * @param pxm  Sets the left and right padding of an element for mobile
 * @param pym  Sets the top and bottom padding of an element for mobile
 * @param pbd  Sets the bottom padding of an element for desktop
 * @param pd  Sets all the padding properties in one declaration for desktop
 * @param pld  Sets the left padding of an element for desktop
 * @param prd  Sets the right padding of an element for desktop
 * @param ptd  Sets the top padding of an element for desktop
 * @param pxd  Sets the left and right padding of an element for desktop
 * @param pyd  Sets the top and bottom padding of an element for desktop
 * @param position  Specifies the type of positioning method used for an element (static, relative, absolute or fixed).
 * @param right  Specifies the right position of a positioned element.
 * @param top  Specifies the top position of a positioned element.
 * @param width  Sets the width of an element.
 * @param zIndex  Specifies the stack order of a positioned element.
 *
 * @returns
 */
export const Box: FC<BoxProps> = ({ backgroundColor, children, className, 'data-testid': testId, id, ...props }) => (
  <ThemeProvider theme={darkColors.includes(backgroundColor as Colors) ? dark : light}>
    <Container
      $backgroundColor={backgroundColor}
      className={className}
      data-testid={testId}
      id={id}
      {...transient(props)}
    >
      {children}
    </Container>
  </ThemeProvider>
);
