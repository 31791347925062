import styled from '@lichtblick/styled';
import { Spacing, Colors, MediaDesktop } from '@lichtblick/theme';

export const Steps = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${Spacing.S};
  list-style: none;
  margin-top: ${Spacing.S};
  position: relative;
  &::before {
    background: ${Colors.LightMoss};
    content: '';
    height: calc(100% - 2 * ${Spacing.S});
    left: calc(50% - 1px);
    position: absolute;
    top: ${Spacing.S};
    width: 2px;
    ${MediaDesktop} {
      display: block;
      height: 2px;
      left: ${Spacing.L};
      top: calc(50% - 1rem);
      width: calc(100% - 2 * ${Spacing.L});
    }
  }
  ${MediaDesktop} {
    flex-direction: row;
    flex-wrap: nowrap;
    gap: ${Spacing.Xxl};
  }
`;

export const Step = styled.li`
  display: flex;
  justify-content: center;
  width: 100%;
  /* stylelint-disable-next-line no-descending-specificity */
  ${MediaDesktop} {
    padding: ${Spacing.L} 0;
    width: 25%;
  }
`;

export const StepContent = styled.div<{ $backgroundColor: Colors }>`
  align-items: center;
  background: ${({ $backgroundColor }) => $backgroundColor};
  display: flex;
  flex-direction: column;
  gap: ${Spacing.Xxs};
  padding: ${Spacing.Xxs} 0;
  position: relative;
  text-align: center;
  width: 10rem;
`;
