import { FC } from 'react';

import { tableDataToReactComponents } from './MTable.helpers';
import { Root, Table } from './MTable.styles';

import { MTableType } from '../../types/storyblok';

export type MTableProps = MTableType & {
  className?: string;
};

export const MTable: FC<MTableProps> = ({ ariaLabel, className, tableData }) => (
  <Root className={className} data-testid="mtable">
    <Table aria-label={ariaLabel ?? undefined}>{Boolean(tableData) && tableDataToReactComponents(tableData)}</Table>
  </Root>
);
