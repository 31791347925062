import { FC } from 'react';

import { Box } from '@lichtblick/ui-components';

import { ButtonWrapper, List, ListContainer } from './OList.styles';

import { OListType } from '../../types/storyblok';
import { getColor } from '../../utils';
import { AButton } from '../AButton';
import { MList } from '../MList';
import { MTextIntro } from '../MTextIntro';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

export const OList: FC<OListType> = ({ _uid, accent, anchorId, button, items, textIntro, theme }) => {
  return (
    <Box backgroundColor={getColor(theme)} data-testid="list">
      {anchorId && <AnchorMark anchorId={anchorId} />}
      <ListContainer>
        <GridContainer>
          <GridRow>
            <GridColumn $width={6}>
              {textIntro?.[0] && <MTextIntro {...textIntro[0]} accentColor={getColor(accent)} buttonVariant="link" />}
            </GridColumn>
            <GridColumn $push={1} $width={5}>
              <List $theme={theme}>
                {Boolean(items?.length) && (
                  <MList _uid={_uid} colorOverride={accent} component="m-list" items={items} textSizeOverride="M" />
                )}
                {button?.[0] && (
                  <ButtonWrapper>
                    <AButton {...button[0]} variant="secondary" />
                  </ButtonWrapper>
                )}
              </List>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </ListContainer>
    </Box>
  );
};
