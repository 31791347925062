import { FC } from 'react';

import { StyledPrimaryNavigation, StyledPrimarySubNavigation } from './PrimaryNavigation.styles';
import { PrimarySubNavigation } from './PrimarySubNavigation';

import { Headline } from '../../atoms/Headline/Headline';
import { NavigationItemProps } from '../../types';

export const PrimaryNavigation: FC<NavigationItemProps> = ({ items, text }) => {
  return (
    <StyledPrimaryNavigation>
      <Headline renderAs="div" size="S">
        {text}
      </Headline>
      {Boolean(items?.length) && (
        <StyledPrimarySubNavigation>
          {items?.map((item) => <PrimarySubNavigation {...item} key={item.href} />)}
        </StyledPrimarySubNavigation>
      )}
    </StyledPrimaryNavigation>
  );
};
