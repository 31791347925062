import { StyledText, TableData, TableHeader, TableRow } from './MTable.styles';

type HeadCell = {
  value: string;
};

type RowCell = {
  _uid: string;
  component: '_table_col';
  value: string;
};

type Row = {
  _uid: string;
  body: RowCell[];
  component: '_table_row';
};

type TableData = {
  tbody?: Row[];
  thead?: HeadCell[];
};

export const tableDataToReactComponents = (tableData: TableData) => (
  <>
    {Boolean(tableData.thead?.some(({ value }) => Boolean(value))) && (
      <thead>
        <tr>
          {tableData.thead?.map(({ value }) => (
            <TableHeader key={value}>
              <StyledText isBold size="L">
                {value}
              </StyledText>
            </TableHeader>
          ))}
        </tr>
      </thead>
    )}
    <tbody>
      {tableData.tbody?.map((row) => (
        <TableRow key={row._uid}>
          {row.body.map(({ _uid, value }) => (
            <TableData key={_uid}>
              <StyledText size="L">{value}</StyledText>
            </TableData>
          ))}
        </TableRow>
      ))}
    </tbody>
  </>
);
