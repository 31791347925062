import styled, { PickTransient } from '@lichtblick/styled';
import { BorderRadius, Breakpoints, Colors, MediaMediumAndAbove, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';
import { StyledHeadline } from '@lichtblick/ui-components/atoms/Headline/Headline.styles';
import { StyledTextIntro } from '@lichtblick/ui-components/molecules/TextIntro/TextIntro.styles';

import { SolarStageExperimentProps } from './SolarStageExperiment';

import { Items as LogoItems, Item as LogoItem } from '../../../OLogos/OLogos.styles';

export const Root = styled.section`
  display: flex;
  flex-direction: column;
`;

export const StageContainer = styled.div<PickTransient<SolarStageExperimentProps, 'hasSoftFunnelEntry'>>`
  display: flex;
  flex-direction: column-reverse;
  /* stylelint-disable-next-line meowtec/no-px */
  ${MediaMediumAndAbove} {
    /* stylelint-disable-next-line meowtec/no-px */
    height: 600px;
    flex-direction: row;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: ${Colors.Moss};

  ${MediaMediumAndAbove} {
    width: 50%;
    justify-content: flex-end;
  }
`;

export const TextContainer = styled.div<PickTransient<SolarStageExperimentProps, 'hasSoftFunnelEntry'>>`
  width: 100%;
  max-width: var(--max-content-width, ${Breakpoints.Large}px);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${Spacing.M};
  padding: 2rem;
  padding-inline: ${Spacing.Xs};
  padding-bottom: ${({ $hasSoftFunnelEntry }) => ($hasSoftFunnelEntry ? '8rem' : '6.5rem')};

  ${MediaMediumAndAbove} {
    max-width: calc(var(--max-content-width, ${Breakpoints.Large}px) / 2);
    padding-inline: ${Spacing.Xl};
    padding-top: 4rem;
    justify-content: space-between;
  }

  ${StyledTextIntro} {
    color: ${Colors.White};
  }

  ${LogoItems} {
    display: none;
    justify-content: flex-start;
    flex-wrap: nowrap;

    ${MediaSmallAndAbove} {
      display: flex;
      max-width: 50%;
    }
    ${MediaMediumAndAbove} {
      max-width: 70%;
    }
  }

  ${LogoItem} {
    flex-basis: auto;
    flex-shrink: 1;
  }

  ${StyledHeadline} {
    /* stylelint-disable-next-line meowtec/no-px */
    font-size: 24px;
    ${MediaSmallAndAbove} {
      /* stylelint-disable-next-line meowtec/no-px */
      font-size: 40px;
    }
    ${MediaMediumAndAbove} {
      /* stylelint-disable-next-line meowtec/no-px */
      font-size: 42px;
    }
  }
`;

export const StyledListItem = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  color: white;
  gap: ${Spacing.Xs};
  width: 100%;
  br {
    display: none;
  }
`;

export const H1Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${Spacing.S};
`;

export const ImageContainer = styled.div`
  width: 100%;
  /* stylelint-disable-next-line meowtec/no-px */
  height: 180px;
  justify-content: center;
  align-items: center;

  ${MediaMediumAndAbove} {
    height: 100%;
    width: 50%;
    display: flex;
  }
`;

export const SellingPointList = styled.ul`
  background: ${Colors.White};
  border-radius: ${BorderRadius.S};
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding-block: ${Spacing.Xxs};
  transform: translateY(-50%);

  ${MediaMediumAndAbove} {
    flex-direction: row;
  }
`;
