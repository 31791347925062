import { ElementType, ReactNode, forwardRef } from 'react';

import { transient } from '@lichtblick/styled';

import { StyledText } from './Text.styles';

import type { Property } from 'csstype';

/**
 * Responsive size classes:
 * https://www.figma.com/file/A0m8tFZk3yHpbiCtmlCXfb/%5BPhoton%5D-Typography-(Web)?node-id=2%3A32&t=jtIHhBKNgXKstYE0-1
 */
export type TextSizeClass = 'Xs' | 'S' | 'M' | 'L';

export type TextProps = {
  align?: Property.TextAlign;
  children?: ReactNode;
  className?: string;
  hasSpacing?: boolean;
  id?: string;
  isBold?: boolean;
  isItalic?: boolean;
  renderAs?: ElementType | keyof JSX.IntrinsicElements;
  /**
   * XS = mobile 10px | desktop 12px;
   * S = mobile 12px | desktop 14px;
   * M = mobile 16px | desktop 16px;
   * L = mobile 16px | desktop 18px;
   */
  size?: TextSizeClass;
};

export const Text = forwardRef<HTMLElement, TextProps>(
  ({ align, hasSpacing, isBold, isItalic, renderAs, size, ...props }, ref) => (
    <StyledText {...props} {...transient({ align, hasSpacing, isBold, isItalic, size })} as={renderAs} ref={ref} />
  ),
);
