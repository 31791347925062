import { FC } from 'react';

import { componentMapper } from '../../helpers/componentMapper';
import { WithMetaData } from '../../helpers/templateMapper';
import { useTemplateTracker } from '../../hooks';
import { OTeaserGridArticlesType, StoryblokStoryType, TDetailType } from '../../types/storyblok';
import { Layout } from '../Layout';
import { MNavigationSecondary } from '../MNavigationSecondary';
import { OFootnotes } from '../OFootnotes';
import { TeaserGridArticlesProps } from '../OTeaserGridArticles';

type ReplaceOTeaserGridArticlesType<T> = T extends OTeaserGridArticlesType ? TeaserGridArticlesProps : T;

export type TDetailProps = Omit<TDetailType, 'partials'> & {
  partials?: ReplaceOTeaserGridArticlesType<NonNullable<TDetailType['partials']>[number]>[];
};

export const TDetail: FC<WithMetaData<StoryblokStoryType<TDetailProps>>> = ({
  content: {
    breadcrumbTitle,
    footnotes,
    metaTitle,
    partials,
    secondaryNavigation,
    secondaryNavigationButtonIntroOnDesktop: isButtonIntroOnDesktop,
    stage,
    trackingPageArea,
  },
  parentPages,
  slug,
}) => {
  useTemplateTracker({
    pageArea: trackingPageArea ?? '',
    pageType: 'detail',
    pageTitle: metaTitle ?? '',
  });

  return (
    <Layout breadcrumbTitle={breadcrumbTitle} parentPages={parentPages} slug={slug}>
      {secondaryNavigation?.[0] && (
        <MNavigationSecondary {...secondaryNavigation[0]} isButtonIntroOnDesktop={isButtonIntroOnDesktop ?? false} />
      )}
      {stage?.[0] && componentMapper(stage[0])}
      {partials?.map((item) => item && componentMapper(item, item._uid))}
      {<OFootnotes footnotes={footnotes} />}
    </Layout>
  );
};
