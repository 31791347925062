import { FC, useEffect, useState } from 'react';

import { ProductWithInformation, addProductInformation, addProductPricing } from './OProductComparison.helpers';
import { Card, CardsWrapper, Header, StickyNav, StyledHeadline } from './OProductComparison.styles';

import { getNonCommodityProducts } from '../../api';
import { OProductComparisonType } from '../../types/storyblok';
import { getColor } from '../../utils';
import { MProductComparisonCard } from '../MProductComparisonCard';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

// This component is currently not used. In order to use it again the products need to be dynamically loaded in the slug.
export const OProductComparison: FC<OProductComparisonType> = ({
  anchorId,
  cards,
  headline,
  technicalDetails,
  theme,
}) => {
  const cardsWithoutPricing = addProductInformation([], cards ?? []);
  const [products, setProducts] = useState<ProductWithInformation[]>(cardsWithoutPricing);

  useEffect(() => {
    const fetchProducts = async () => {
      const campaign = cardsWithoutPricing[0]?.campaign ?? '';
      const productCodes: string[] = [];

      cardsWithoutPricing.forEach((card) => {
        card.productCodes?.value?.forEach((code) => {
          if (!productCodes.includes(code)) {
            productCodes.push(code);
          }
        });
      });

      const dataProducts = await getNonCommodityProducts({ campaign: campaign, productCodes: productCodes });

      const cardsWithPricing = addProductPricing({
        products: cardsWithoutPricing,
        dataProducts: dataProducts.nonCommodityProductsByCampaign,
      });

      setProducts(cardsWithPricing);
    };

    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = ({ currentOrder, newValue }: { currentOrder: number; newValue: string }) => {
    setProducts((prevProducts) => {
      const updatedProducts = [...prevProducts];

      const newSelectedProduct = updatedProducts.findIndex((product) => product.value === newValue);
      const currentSelectedProduct = updatedProducts.findIndex((product) => product.order === currentOrder);

      if (newSelectedProduct === -1 || currentSelectedProduct === -1) {
        return prevProducts;
      }

      const temp = updatedProducts[currentSelectedProduct].order;

      updatedProducts[currentSelectedProduct].order = updatedProducts[newSelectedProduct].order;
      updatedProducts[newSelectedProduct].order = temp;

      return updatedProducts;
    });
  };

  return (
    <div data-testid="product-comparison">
      {anchorId && <AnchorMark anchorId={anchorId} />}
      {headline && (
        <Header $theme={getColor(theme)}>
          <GridContainer>
            <GridRow>
              <GridColumn $push={3} $width={6}>
                <StyledHeadline renderAs={'h1'} size="L">
                  {headline}
                </StyledHeadline>
              </GridColumn>
            </GridRow>
          </GridContainer>
        </Header>
      )}
      <StickyNav $theme={getColor(theme)} />
      <CardsWrapper>
        {products.map((card) => (
          <Card data-order={card.order} key={card.headline} order={card.order}>
            <MProductComparisonCard
              {...card}
              availableProducts={products}
              onChange={handleOnChange}
              technicalDetails={[...(card.technicalDetails ?? []), ...(technicalDetails ?? [])]}
              theme={theme ?? 'smoke'}
            />
          </Card>
        ))}
      </CardsWrapper>
    </div>
  );
};
