import styled, { css } from '@lichtblick/styled';
import { Spacing } from '@lichtblick/theme';

import {
  SharedInputRowProps,
  getSharedInputRowStyles,
  sharedInputStyles,
  sharedWrapperStyles,
} from '../Textfield/Textfield.styles';

export const StyledSelectInput = styled.div`
  ${sharedWrapperStyles}
`;

export const InputRow = styled.div<SharedInputRowProps>`
  ${getSharedInputRowStyles}
  position: relative;
`;

export const Select = styled.select<{ $paddingLeft?: number; $shouldShowPlaceholder?: boolean }>`
  appearance: none;
  cursor: ${({ disabled: isDisabled }) => (isDisabled ? 'default' : 'pointer')};
  height: 100%;
  ${sharedInputStyles.base}
  opacity: 0.5; // this will be the opacity used by the <select/> placeholder (which is a hidden <option />)
  padding-left: ${({ $paddingLeft }) => ($paddingLeft ? `${$paddingLeft}px` : Spacing.Xs)};
  width: 100%;

  // make the selected option full opacity
  &:has(option:checked:not([hidden])) {
    opacity: 1;
  }
`;

const iconOverlay = css`
  align-items: center;
  display: flex;
  height: 100%;
  pointer-events: none;
  position: absolute;
  z-index: 1;
`;

export const IconWrapper = styled.div`
  left: 0;
  ${iconOverlay};
  padding: 0 ${Spacing.Xxs};
`;

export const ArrowWrapper = styled.div`
  background: inherit;
  mask-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 1));
  ${iconOverlay};
  padding: 0 0.875rem;
  right: 0;
`;
