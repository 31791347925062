import { GetStaticPaths, GetStaticProps } from 'next';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ISbError, ISbStoriesParams } from 'storyblok-js-client';

import { ParsedUrlQuery } from 'querystring';

import {
  SettingsType,
  StoryBlokResponseDataType,
  StoryblokStoryType,
  TemplatesType,
  resolveRelationString,
  storyblokClient,
} from '@lichtblick/contentful';
import { extendTemplates, ExtendedTemplates, STORY_COMPONENTS } from '@lichtblick/contentful/components/templates';
import { mapSettings, Settings } from '@lichtblick/contentful/helpers/settingsContext';
import { ParentPage } from '@lichtblick/contentful/helpers/templateMapper';
import { ExternalPaths } from '@lichtblick/ui-components';
import { isExternalPath } from '@lichtblick/ui-components/helpers/isExternalPath';

import appRewrites from '../appRewrites.json';
import { ContentMapper } from '../helpers/contentMapper';
import { generateRobots } from '../helpers/generateRobots';
import { generateSiteMap } from '../helpers/generateSiteMap';

export type StaticProps = {
  isPreview: boolean;
  parentPages: ParentPage[];
  previewReleaseId: string | null;
  previewSecret: string | null;
  settings: Settings;
  slug: string;
  story: StoryblokStoryType<ExtendedTemplates>;
};

export const externalPaths = Object.values(appRewrites)
  .flatMap((redirect) => (typeof redirect === 'object' ? redirect.map(({ fromPath }) => fromPath) : []))
  .reduce<ExternalPaths>(
    (acc, path) => {
      if (path.includes(':path*')) {
        acc.catchAll.push(path.split(':path*')[0]);
      } else {
        acc.absolut.push(path);
      }

      return acc;
    },
    { catchAll: [], absolut: [] },
  );

const generatePaths = (stories: StoryblokStoryType<TemplatesType>[]) => {
  return stories
    .filter(({ full_slug }) => !isExternalPath(full_slug, externalPaths))
    .map(({ full_slug }) => ({ params: { slug: full_slug.replace(/\/$/g, '').split('/') } }));
};

const fetchStories = async (): Promise<StoryblokStoryType<TemplatesType>[]> => {
  const stories = await storyblokClient.getAll('cdn/stories', { version: 'published' });

  return stories.filter((story) => STORY_COMPONENTS.includes(story.content.component));
};

export const getStaticPaths: GetStaticPaths = async () => {
  const stories = await fetchStories();

  await Promise.all([generateSiteMap(stories), generateRobots(stories)]);

  return { paths: generatePaths(stories), fallback: 'blocking' };
};

export const getStaticProps: GetStaticProps<
  StaticProps,
  ParsedUrlQuery,
  { from_release?: string; previewSecret?: string }
> = async ({ draftMode, params, previewData, revalidateReason }) => {
  // during build caching SB responses is fine, with on demand ISR we want the latest data (almost threw my MacBook on the street)
  if (revalidateReason !== 'build') {
    await storyblokClient.flushCache();
  }

  const slug = (params?.slug ?? []) as string[];

  if (isExternalPath(slug.join('/'), externalPaths)) {
    return { notFound: true };
  }

  const storyblokClientParams: ISbStoriesParams = {
    from_release: previewData?.from_release,
    token: previewData ? process.env.STORYBLOK_CDA_PREVIEW_TOKEN : undefined,
    version: previewData ? 'draft' : 'published',
    resolve_relations: resolveRelationString,
  };

  let storyResponse;

  try {
    storyResponse = await storyblokClient.get(
      `cdn/stories/${slug.length === 0 ? 'home' : slug.join('/')}`,
      storyblokClientParams,
    );
  } catch (e) {
    // throw errors during build to prevent missing pages
    if (revalidateReason === 'build') {
      throw e;
    }

    // sb client throws in case of a 404. Catch error, set page to notFound
    if ((e as unknown as ISbError).status === 404) {
      return { notFound: true };
    }

    // no clue what happened. throw it
    throw e;
  }

  const storyData = storyResponse.data as StoryBlokResponseDataType<TemplatesType>;

  const parentStoryRequests = [];

  for (let i = 1; i < slug.length; i++) {
    parentStoryRequests.push(
      storyblokClient.get(`cdn/stories/${slug.slice(0, slug.length - i).join('/')}`, storyblokClientParams),
    );
  }

  const parentPages = (
    (await Promise.all(parentStoryRequests)).map((request) => request.data.story) as StoryblokStoryType<TemplatesType>[]
  )
    .map<ParentPage>((page) => ({
      slug: page.full_slug,
      breadcrumbTitle: 'breadcrumbTitle' in page.content ? page.content.breadcrumbTitle : null,
    }))
    .reverse();

  const settings = mapSettings(
    (await storyblokClient.get(`cdn/stories/settings`, storyblokClientParams)).data.story.content as SettingsType,
  );

  return {
    props: {
      isPreview: Boolean(draftMode),
      previewReleaseId: previewData?.from_release ?? null,
      previewSecret: previewData?.previewSecret ?? null,
      story: await extendTemplates(storyData.story, storyblokClientParams),
      slug: `/${storyData.story.full_slug}`,
      parentPages,
      settings,
    },
  };
};

export default ContentMapper;
