import { ElementType, FC, PropsWithChildren } from 'react';

import { AccentText, AccentTextSizeClass } from '../../atoms/AccentText/AccentText';
import { Text, TextSizeClass } from '../../atoms/Text/Text';

export type LabelProps = PropsWithChildren<
  {
    className?: string;
    htmlFor?: string;
    id?: string;
    isBold?: boolean;
    renderAs?: ElementType;
  } & (
    | {
        isAccentText: true;
        size?: AccentTextSizeClass;
      }
    | {
        isAccentText?: false;
        size?: TextSizeClass;
      }
  )
>;

export const Label: FC<LabelProps> = ({ children, isAccentText, isBold, renderAs, size, ...props }) => {
  const as = renderAs ? renderAs : 'label';

  return isAccentText ? (
    <AccentText renderAs={as} size={size} {...props}>
      {children}
    </AccentText>
  ) : (
    <Text isBold={isBold} renderAs={as} size={size} {...props}>
      {children}
    </Text>
  );
};
