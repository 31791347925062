import { FC } from 'react';

import { WithMetaData } from '../../helpers/templateMapper';
import { StoryblokStoryType, TServiceViewType } from '../../types/storyblok';
import { Layout } from '../Layout';

// TODO: dummy
export const TServiceView: FC<WithMetaData<StoryblokStoryType<TServiceViewType>>> = ({
  content: { layout },
  parentPages,
  slug,
}) => (
  <Layout parentPages={parentPages} slug={slug}>
    <dfn>{layout}</dfn>
  </Layout>
);
