import Head from 'next/head';
import { usePathname } from 'next/navigation';
import { FC } from 'react';

import { Spacing } from '@lichtblick/theme';
import { AccordionRoot, Box } from '@lichtblick/ui-components';

import { generateStructuredData } from './OAccordion.helpers';
import { TextIntroWrapper } from './OAccordion.styles';

import { OAccordionType } from '../../types/storyblok';
import { getButtonVariant, getColor } from '../../utils';
import { MAccordionItem } from '../MAccordionItem';
import { MTextIntro } from '../MTextIntro';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

export const OAccordion: FC<OAccordionType> = ({
  anchorId,
  faq: isFaq,
  fullWidthLayout: isFullWidth,
  items,
  textIntro,
  theme,
}) => {
  const pathname = usePathname();

  const textIntroComponent = textIntro?.[0] && (
    <TextIntroWrapper $isFullWidth={Boolean(isFullWidth)}>
      <MTextIntro {...textIntro[0]} buttonVariant={getButtonVariant('link')} />
    </TextIntroWrapper>
  );

  const accordionComponent = (
    <AccordionRoot type={'multiple'}>
      {items.map(
        (item) =>
          Boolean(item) && <MAccordionItem {...item} isThemeFadedGray={theme === 'faded-gray'} key={item._uid} />,
      )}
    </AccordionRoot>
  );

  return (
    <>
      {isFaq && (
        <Head>
          <script
            dangerouslySetInnerHTML={{
              __html: JSON.stringify(generateStructuredData(pathname, items)),
            }}
            type="application/ld+json"
          />
        </Head>
      )}

      <Box backgroundColor={getColor(theme)} data-testid="accordion" pyd={Spacing.Xl} pym={Spacing.M}>
        {anchorId && <AnchorMark anchorId={anchorId} />}
        <GridContainer>
          {isFullWidth && (
            <>
              <GridRow>
                <GridColumn $push={1} $width={10}>
                  {textIntroComponent}
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn $push={1} $width={10}>
                  {accordionComponent}
                </GridColumn>
              </GridRow>
            </>
          )}

          {!isFullWidth && (
            <GridRow>
              {textIntroComponent && <GridColumn $width={6}>{textIntroComponent}</GridColumn>}
              <GridColumn $push={textIntroComponent ? 1 : 7} $width={5}>
                {accordionComponent}
              </GridColumn>
            </GridRow>
          )}
        </GridContainer>
      </Box>
    </>
  );
};
