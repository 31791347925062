export const formatNumber = (value?: number | null, fractionDigits = 0): string => {
  if (value == null) {
    return '';
  }

  return value.toLocaleString('de-DE', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
};

/**
 * Formats the number in German style with the unit added (separated by a non breaking space).
 *
 * @param value The number value
 * @param unit The unit that is added after the price
 * @param fractionDigits The number of fraction digits (default 0)
 */
export const formatUnit = (value: number | undefined | null, unit: string, fractionDigits = 0): string => {
  if (value == null) {
    return '';
  }

  const formattedValue = formatNumber(value, fractionDigits);

  return `${formattedValue} ${unit}`.replace(/\s/g, '\xa0');
};

export const formatMeterReading = (value?: number | null): string => {
  if (value == null) {
    return '';
  }

  return formatNumber(value, 0);
};

/**
 * Same as `formatUnit` but with a default unit of '€' and a default fraction digits of 2.
 */
export const formatPrice = (value?: number | null, unit = '€', fractionDigits = 2): string =>
  formatUnit(value, unit, fractionDigits);

export const parseFormattedNumber = (formattedNumber?: string | null): number | undefined => {
  const toParse = formattedNumber?.replace(/[^0-9]/g, '');

  return toParse ? parseInt(toParse, 10) : undefined;
};

export const roundPrice = (value?: number | null): number | undefined | null => {
  // we only want to change the number if it has more than two decimal digits
  if (value?.toString()?.match(/\.\d{3,}/)) {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  return value;
};

export const roundPriceUp = (value?: number | null): number | undefined | null => {
  // we only want to change the number if it has more than two decimal digits
  if (value?.toString()?.match(/\.\d{3,}/)) {
    return Math.ceil(value * 100) / 100;
  }

  return value;
};

/* istanbul ignore next */
export const generateUuid = (): number => Math.floor(Math.random() * 1000000);

export const range = (start?: number | null, stop?: number | null, step = 1): number[] => {
  const first = start ?? Number.NaN;
  const last = stop ?? Number.NaN;
  const length = Math.ceil((last - first + 1) / step);

  return Array.from({ length }, (_, i) => first + i * step);
};

export const isValidNumber = (num?: any): num is number =>
  typeof num === 'number' && !Number.isNaN(num) && Number.isFinite(num);

export const isValidNumberAboveZero = (num: any): num is number => isValidNumber(num) && num > 0;
