import Image from 'next/image';

import styled, { css } from '@lichtblick/styled';

const backgroundStyles = css`
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

export const Img = styled(Image)<{ $isBackground?: boolean; $isFullWidth?: boolean }>`
  height: auto;
  max-width: 100%;
  ${({ $isBackground }) => $isBackground && backgroundStyles}
  vertical-align: middle;
  ${({ $isFullWidth }) =>
    $isFullWidth &&
    css`
      width: 100%;
    `}
`;
