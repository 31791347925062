import styled from '@lichtblick/styled';
import { Spacing } from '@lichtblick/theme';
import { Ul } from '@lichtblick/ui-components';
import { Li } from '@lichtblick/ui-components/atoms/List/Li';

export const StyledKWKForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.Xs};

  ${Ul} ${Li} {
    padding-bottom: 0;
  }
`;
