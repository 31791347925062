import { FC, ReactElement } from 'react';
import { Masonry } from 'react-plock';

import { Breakpoints } from '@lichtblick/theme';

import { StyledMasonryGridItem } from './MasonryGrid.styles';

type ItemProps = {
  children?: {
    props?: {
      headline?: string;
    };
  };
};

type Item = ReactElement<ItemProps>;

export type MasonryGridProps = {
  className?: string;
  columnCount: number;
  items: Item[];
};

export const MasonryGrid: FC<MasonryGridProps> = ({ className, columnCount, items }) => {
  return (
    <Masonry
      className={className}
      config={{
        columns: [1, columnCount],
        gap: 0,
        media: [Breakpoints.Small, Breakpoints.Small],
      }}
      items={items}
      render={(item, index) => (
        <StyledMasonryGridItem key={item?.props?.children?.props?.headline || index}>{item}</StyledMasonryGridItem>
      )}
    />
  );
};
