import styled from '@lichtblick/styled';
import { MediaSmallAndAbove, Spacing } from '@lichtblick/theme';
import { ContainerStyle } from '@lichtblick/ui-components';

export const Diagram = styled.div`
  display: flex;
  justify-content: center;

  > svg {
    height: 100%;
    width: 100%;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.M};
  ${ContainerStyle}

  ${MediaSmallAndAbove} {
    flex-direction: row;
  }
`;

export const ImageWrapper = styled.div`
  max-width: 20rem;
  margin-inline: auto;
`;

export const SvgWrapper = styled.div`
  width: 100%;
`;
