'use client';

import { useEffect, useContext, useMemo, useState, FC, PropsWithChildren, createContext } from 'react';

import { GOOGLE_ANALYTICS_NAME, useConsent } from '@lichtblick/consent';

import { getStateFromStorage } from './tracker.helpers';
import {
  TrackingEvent,
  PageMetaEventInput,
  PageMetaEvent,
  TrackingContextValue,
  EventSerializer,
  VirtPathEvent,
  CustomLegacyEvent,
  EcommerceEvent,
  OptimizelyDecisionEvent,
} from './tracker.types';

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    dataLayer: any[];
  }
}

type EventStore = 'virtPath' | 'Page Meta' | 'NewgaEvent' | 'gaEvent' | 'Ecommerce' | 'optimizely';

type TrackingParams = {
  event: TrackingEvent;
  eventStoreName: EventStore;
  getLastEvent?: (event: TrackingEvent) => TrackingEvent | undefined;
  serializer?: EventSerializer<TrackingEvent>;
  shouldAvoidDuplicate: boolean;
};

const ENVIRONMENTS = {
  prod: 'productive',
  test: 'staging',
  dev: 'development',
} as const;

const environment = ENVIRONMENTS[process.env.NEXT_PUBLIC_STAGE as keyof typeof ENVIRONMENTS] ?? 'development';

const localEventStores: { [key in EventStore]: any[] } = {
  virtPath: new Array<VirtPathEvent>(),
  'Page Meta': new Array<PageMetaEvent>(),
  NewgaEvent: new Array<CustomEvent>(),
  gaEvent: new Array<CustomLegacyEvent>(),
  Ecommerce: new Array<EcommerceEvent>(),
  optimizely: new Array<OptimizelyDecisionEvent>(),
};

const clearAll = () => Object.values(localEventStores).forEach((store) => (store.length = 0));

export const trackEvent = <TEvent extends TrackingEvent>(
  event: TEvent,
  eventStoreName: EventStore,
  shouldAvoidDuplicate: boolean,
  serializer?: EventSerializer<TEvent>,
) => {
  const eventStore = localEventStores[eventStoreName] as TEvent[];

  const serializedEvent = shouldAvoidDuplicate && serializer?.(event);

  if (serializedEvent && eventStore.some((last) => serializedEvent === serializer?.(last))) {
    return;
  }

  // virt path event indicates page load, clear all events
  if (event.event === 'virtPath') {
    clearAll();
  }

  window.dataLayer.push(event);
  eventStore.push(event);
};

export const createPageMetaEvent = ({
  customerTypeUser,
  loginStatus,
  pageArea,
  pageType,
}: PageMetaEventInput): PageMetaEvent => {
  const trafficType =
    typeof document === 'undefined' || environment !== 'productive' || document.cookie.includes('trafficType=internal')
      ? 'internal'
      : 'external';

  return {
    event: 'Page Meta',
    environment,
    // GA3
    pageArea,
    pageType,
    pageEnvironment: 'new frontend-design',
    trafficType,
    customerTypeUser,
    loginStatus,
    // GA4
    page_area: pageArea,
    page_type: pageType,
    traffic_type: trafficType,
    customer_type: customerTypeUser,
    login_status: loginStatus,
  } as const;
};

const flushQueue = (trackingParams: TrackingParams[]) => {
  trackingParams.forEach(({ event, eventStoreName, serializer, shouldAvoidDuplicate }) =>
    trackEvent(event, eventStoreName, shouldAvoidDuplicate, serializer),
  );

  return [];
};

const useSetupTracker = () => {
  const consent = useConsent(GOOGLE_ANALYTICS_NAME);
  const [, setTrackingQueue] = useState<TrackingParams[]>([]);
  const isTrackingEnabled = consent;
  const [isVirtPathTracked, setVirtPathTracked] = useState(false);

  const trackOrQueueEvent = useMemo<typeof trackEvent>(
    () =>
      isTrackingEnabled
        ? trackEvent
        : (event, eventStoreName, shouldAvoidDuplicate, serializer) =>
            setTrackingQueue((queue) => [
              ...queue,
              { event, eventStoreName, shouldAvoidDuplicate, serializer } as TrackingParams,
            ]),
    [isTrackingEnabled],
  );

  useEffect(() => {
    if (isTrackingEnabled) {
      setTrackingQueue(flushQueue);
    }
  }, [isTrackingEnabled]);

  return useMemo<TrackingContextValue>(
    () => ({
      isTrackingEnabled,
      trackEcommerceEvent: (event) => {
        const serializer = (event: TrackingEvent) => JSON.stringify({ ...event, 'gtm.uniqueEventId': undefined });

        if (event) {
          trackOrQueueEvent({ ...event }, 'Ecommerce', true, serializer);
        }
      },

      trackCustomEvent: (tracking, legacyTracking, shouldAvoidDuplicate = true) => {
        if (tracking) {
          const serializer = (event: TrackingEvent) => JSON.stringify({ ...event, 'gtm.uniqueEventId': undefined });

          // GA4
          trackOrQueueEvent(
            {
              event: 'NewgaEvent',
              event_name: tracking.eventName,
              [tracking.eventName]: tracking.customProps,
            },
            'NewgaEvent',
            shouldAvoidDuplicate,
            serializer,
          );

          // GA3
          if (legacyTracking) {
            trackOrQueueEvent({ event: 'gaEvent', ...legacyTracking }, 'gaEvent', shouldAvoidDuplicate, serializer);
          }
        }
      },

      trackPageMetaEvent: ({ pageArea, pageType }) => {
        const { customerTypeUser = 'new customer', loginState = 'logged out' } = getStateFromStorage() ?? {};

        trackOrQueueEvent(
          createPageMetaEvent({ customerTypeUser, loginStatus: loginState, pageArea, pageType }),
          'Page Meta',
          true,
          ({ event }) => event,
        );
      },

      /** see e.g. https://docs.google.com/presentation/d/11AEsa5noYLepG_XwfP7TzBMyVgsEB-CwR371ujo0aGs/edit#slide=id.g1224e2b2ed8_0_27 */
      trackVirtPathEvent: (virtPathInput) => {
        trackOrQueueEvent(
          { ...virtPathInput, event: 'virtPath' },
          'virtPath',
          true,
          ({ virtPagePath }) => virtPagePath,
        );
        setVirtPathTracked(localEventStores.virtPath.length > 0);
      },

      trackOptimizelyDecisionEvent: ({ flagKey, ruleKey, variationKey }) => {
        trackOrQueueEvent(
          {
            event: 'optimizely-decision-fx',
            'optimizely-flagKey': flagKey,
            'optimizely-ruleKey': ruleKey,
            'optimizely-variationKey': variationKey,
          },
          'optimizely',
          false,
        );
      },

      isVirtPathTracked: isVirtPathTracked,
    }),
    [isTrackingEnabled, isVirtPathTracked, trackOrQueueEvent],
  );
};

const TrackingContext = createContext<TrackingContextValue>({
  isTrackingEnabled: false,
  trackEcommerceEvent: () => {
    // noop
  },
  trackCustomEvent: () => {
    // noop
  },
  trackVirtPathEvent: () => {
    // noop
  },
  trackPageMetaEvent: () => {
    // noop
  },
  trackOptimizelyDecisionEvent: () => {
    // noop
  },
  isVirtPathTracked: false,
});

export const TrackerProvider: FC<PropsWithChildren> = ({ children }) => {
  const trackingState = useSetupTracker();

  return <TrackingContext.Provider value={trackingState}>{children}</TrackingContext.Provider>;
};

export const useTracker = () => useContext(TrackingContext);

// Expose to allow setting meta information across all apps
export { setStateInStorage } from './tracker.helpers';

export * from './tracker.types';
export * from './TrackingScript';
