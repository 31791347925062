import { FC } from 'react';

import {
  Content,
  Price,
  StyledAccentText,
  StyledChip,
  StyledHeadline,
  StyledText,
  Wrapper,
} from './MTextIntroPrice.styles';

import { MTextIntroPriceType } from '../../types/storyblok';
import { getButtonVariant } from '../../utils';
import { AButton } from '../AButton';

export const MTextIntroPrice: FC<MTextIntroPriceType> = ({
  button,
  buttonType,
  chip,
  headline,
  priceSmallText,
  priceValue,
  subheadline,
}) => (
  <div data-testid="text-intro-price">
    {chip && <StyledChip label={chip} />}

    <Wrapper>
      <Content>
        {headline && (
          <StyledHeadline renderAs="h2" size="M">
            {headline}
          </StyledHeadline>
        )}

        {subheadline && <StyledText size="L">{subheadline}</StyledText>}
      </Content>
      <Price>
        {priceValue && <StyledAccentText size="L">{priceValue}</StyledAccentText>}
        {priceSmallText && <StyledText size="Xs">{priceSmallText}</StyledText>}
        {button?.[0] && <AButton {...button[0]} variant={getButtonVariant(buttonType)} />}
      </Price>
    </Wrapper>
  </div>
);
