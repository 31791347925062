import styled from '@lichtblick/styled';
import { Spacing } from '@lichtblick/theme';

import { DropDown } from '../../atoms/DropDown/DropDown';
import { sharedIconWrapperStyles } from '../FilterSelect/FilterSelect.styles';
import {
  SharedInputRowProps,
  getSharedInputRowStyles,
  sharedInputStyles,
  sharedLabelWrapperStyles,
  sharedSlotStyles,
  sharedWrapperStyles,
} from '../Textfield/Textfield.styles';

export const Wrapper = styled.div`
  ${sharedWrapperStyles}
  position: relative;
`;

export const LabelWrapper = styled.div`
  ${sharedLabelWrapperStyles}
`;

export const InputRow = styled(DropDown)<SharedInputRowProps>`
  ${getSharedInputRowStyles}
`;

export const Slot = styled.div`
  ${sharedSlotStyles}
`;

export const Input = styled.input`
  ${sharedInputStyles.base}
  ${sharedInputStyles.hasRightSlot}
`;

// using `<div>` instead of `<button>` to avoid changing `:focus-within` unless `tabIndex` is explicitly set
export const IconWrapper = styled.div.attrs({ 'aria-hidden': true, type: 'button' })`
  ${sharedIconWrapperStyles}
`;

export const OptionContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${Spacing.Xs};
  padding: 0 ${Spacing.Xxs};
`;

export const OptionLabel = styled.div`
  flex: 1;
  min-width: 0;
`;
