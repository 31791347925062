export const extractErrorMessages = (
  errors: Record<string, any>,
  touched: Record<string, boolean>,
): Record<string, any> =>
  Object.keys(errors)
    .filter((name) => touched[name] || name.startsWith('FINAL_FORM/'))
    .reduce((errorMessages, name) => ({ ...errorMessages, [name]: errors[name] }), {});

export const transformErrorMessage = (errors: Record<string, any>): string => {
  const valueMap: Record<string, string[]> = {};

  Object.entries(errors).forEach(([key, value]) => {
    valueMap[value] ??= [];
    valueMap[value].push(key);
  });

  return Object.entries(valueMap)
    .map(([value, keys]) => `> ${value}: ${keys.join(', ')}`)
    .join('  ');
};
