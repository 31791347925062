import { FC, useState } from 'react';

import { useTracker } from '@lichtblick/tracker';

import { NavigationItemGroup } from './NavigationItemGroup';
import { StyledHeaderNavigationButton, StyledFlyOut, StyledHeaderNavigationList } from './TopNavigationItem.styles';

import { Text } from '../../atoms/Text/Text';
import { NavigationItemProps } from '../../types';

export const HeaderTopNavigationItem: FC<NavigationItemProps> = ({ isActive, items, text, tracking }) => {
  const { trackCustomEvent } = useTracker();

  const [isFlyoutActive, setIsFlyoutActive] = useState(false);

  return (
    <StyledHeaderNavigationList
      $isFlyoutActive={isFlyoutActive}
      onFocus={() => setIsFlyoutActive(true)}
      onMouseEnter={() => setIsFlyoutActive(true)}
      onMouseLeave={() => setIsFlyoutActive(false)}
    >
      <StyledHeaderNavigationButton
        $isActive={!!isActive}
        aria-label={text}
        onClick={() => {
          trackCustomEvent(tracking);
          setIsFlyoutActive(true);
        }}
      >
        <Text isBold={isActive} size="L">
          {text}
        </Text>
      </StyledHeaderNavigationButton>
      {Boolean(items?.length) && (
        <StyledFlyOut
          $isFlyoutActive={isFlyoutActive}
          onBlur={(e) => {
            if (e.currentTarget.contains(e.relatedTarget)) {
              return;
            }

            setIsFlyoutActive(false);
          }}
        >
          {items?.map((item) => (
            <NavigationItemGroup {...item} key={item.href} setIsFlyoutActive={setIsFlyoutActive} />
          ))}
        </StyledFlyOut>
      )}
    </StyledHeaderNavigationList>
  );
};
