import styled, { css } from '@lichtblick/styled';

import { DropDown } from '../../atoms/DropDown/DropDown';
import {
  SharedInputRowProps,
  getSharedInputRowStyles,
  sharedInputStyles,
  sharedLabelWrapperStyles,
  sharedSlotStyles,
  sharedWrapperStyles,
} from '../Textfield/Textfield.styles';

export const Wrapper = styled.div`
  ${sharedWrapperStyles}
  position: relative;
`;

export const LabelWrapper = styled.div`
  ${sharedLabelWrapperStyles}
`;

export const InputRow = styled(DropDown).attrs({ variant: 'input' })<SharedInputRowProps>`
  ${getSharedInputRowStyles};

  &[data-open]:focus-within {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const Slot = styled.div`
  ${sharedSlotStyles}
`;

export const Input = styled.input`
  ${sharedInputStyles.base}
  ${sharedInputStyles.hasRightSlot}
`;

export const sharedIconWrapperStyles = css`
  align-items: center;
  appearance: none;
  background: none;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 1.75rem;
  z-index: 1;
`;

// using `<div>` instead of `<button>` to avoid changing `:focus-within` unless `tabIndex` is explicitly set
export const IconWrapper = styled.div.attrs({ 'aria-hidden': true, type: 'button' })`
  ${sharedIconWrapperStyles}
`;
