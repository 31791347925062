import styled from '@lichtblick/styled';
import { Colors, MediaSmallAndAbove } from '@lichtblick/theme';

import { StyledText } from '../../atoms/Text/Text.styles';
import { Link } from '../../helpers/Link';

export const StyledNavigationItem = styled.li`
  background: ${Colors.White};
  color: ${Colors.Black};
  list-style: none;
  padding: 0.5rem 1rem;
  pointer-events: all;

  &:last-child {
    padding-right: 0;
  }

  ${MediaSmallAndAbove} {
    background: ${Colors.Black};
    color: ${Colors.White};
  }
`;

export const StyledNavigationLink = styled(Link)`
  display: inline-block;

  &:hover {
    opacity: 0.6;
  }

  transition: opacity 0.25s ease-out;

  &:focus-visible {
    outline: dotted 1px ${Colors.DarkOrange};
    outline-offset: 1px;
  }

  ${StyledText} {
    font-size: 0.875rem;

    ${MediaSmallAndAbove} {
      font-size: 0.75rem;
    }
  }
`;
