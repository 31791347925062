import styled from '@lichtblick/styled';
import { Colors, MediumAndAbove, Spacing } from '@lichtblick/theme';

export const StyledORoadToZeroWrapper = styled.div`
  position: relative;

  html:has(&) {
    scroll-behavior: smooth;
  }
`;

const HALF_COUNTER_SMALL = '2.2rem';
const HALF_COUNTER = '5rem';

export const StyledORoadToZeroBar = styled.div<{ $colorSteps: string }>`
  position: absolute;
  width: 0.5rem;
  border-radius: 0.25rem;
  background: linear-gradient(180deg, ${({ $colorSteps }) => $colorSteps});
  right: calc(${Spacing.S} + ${HALF_COUNTER_SMALL} - 0.25rem);
  top: 7rem;
  bottom: 7rem;

  @media ${MediumAndAbove} {
    left: calc(50% - 0.65rem);
    top: 50vh;
    top: 50dvh;
    bottom: 50vh;
    bottom: 50dvh;
    width: 1.3rem;
    border-radius: 0.65rem;
  }
`;

export const StyledORoadToZeroCounterWrapper = styled.div`
  position: absolute;
  right: ${Spacing.S};
  pointer-events: none;

  top: 7rem;
  bottom: 7rem;

  @media ${MediumAndAbove} {
    top: calc(50vh - ${HALF_COUNTER});
    top: calc(50dvh - ${HALF_COUNTER});
    bottom: calc(50vh - ${HALF_COUNTER});
    bottom: calc(50dvh - ${HALF_COUNTER});
    left: 0;
    right: 0;
  }
`;

export const StyledORoadToZeroCounter = styled.div`
  position: sticky;
  width: calc(2 * ${HALF_COUNTER_SMALL});
  height: calc(2 * ${HALF_COUNTER_SMALL});
  border-radius: ${HALF_COUNTER_SMALL};
  right: 2rem;
  bottom: calc(50vh - ${HALF_COUNTER});
  top: calc(50dvh - ${HALF_COUNTER_SMALL});

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${Colors.DarkSmoke};
  color: ${Colors.White};
  box-shadow:
    0 0 0 0.6rem rgba(255, 255, 255, 0.5),
    0 1rem 1rem rgba(0, 0, 0, 0.2);

  span:first-child {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  span:last-child {
    font-size: 0.6rem;
  }

  @media ${MediumAndAbove} {
    width: calc(2 * ${HALF_COUNTER});
    height: calc(2 * ${HALF_COUNTER});
    border-radius: ${HALF_COUNTER};
    left: calc(50% - ${HALF_COUNTER});
    top: calc(50vh - ${HALF_COUNTER});
    top: calc(50dvh - ${HALF_COUNTER});
    right: unset;

    box-shadow:
      0 0 0 1rem rgba(255, 255, 255, 0.5),
      0 1rem 1rem rgba(0, 0, 0, 0.2);

    span:first-child {
      font-size: 3.5rem;
      line-height: 3.5rem;
    }
    span:last-child {
      font-size: 1rem;
    }
  }
`;
