import { FC } from 'react';

import { SecondaryNavigation } from '@lichtblick/ui-components';

import { useNavigationItems } from '../../hooks';
import { MNavigationSecondaryType } from '../../types/storyblok';
import { AButton } from '../AButton/AButton';

type SecondaryNavigationProps = Partial<MNavigationSecondaryType> & {
  isButtonIntroOnDesktop?: boolean;
};

export const MNavigationSecondary: FC<SecondaryNavigationProps> = ({ button, isButtonIntroOnDesktop, list, title }) => {
  const navigation = useNavigationItems(list ?? []);

  return (
    <SecondaryNavigation
      isButtonIntroOnDesktop={isButtonIntroOnDesktop}
      secondaryNavigation={navigation}
      title={title ?? ''}
    >
      {button?.length === 1 && <AButton {...button[0]} isSlim isWide />}
    </SecondaryNavigation>
  );
};
