import { FC } from 'react';

import { Colors, darkColors } from '@lichtblick/theme';
import { HeadlineProps, LegacyButtonVariant, TextIntro, TextProps } from '@lichtblick/ui-components';

import { buildSubline } from './MTextIntro.helpers';

import { MTextIntroType } from '../../types/storyblok';
import { AButton } from '../AButton';

export type MTextIntroProps = MTextIntroType & {
  accentColor?: Colors;
  buttonVariant?: LegacyButtonVariant;
  className?: string;
  headlineProps?: HeadlineProps;
  isCenteredDesktop?: boolean;
  isCenteredMobile?: boolean;
  textProps?: TextProps & { $isOStageSplit?: boolean };
};

export const MTextIntro: FC<MTextIntroProps> = ({
  accentColor = Colors.Black,
  button,
  buttonVariant = 'link',
  chip,
  className,
  headline,
  headlineProps,
  isCenteredDesktop,
  isCenteredMobile,
  richText,
  textProps,
}) => (
  <TextIntro
    chipProps={
      chip
        ? {
            label: chip,
            backgroundColor: accentColor,
            textColor: darkColors.includes(accentColor) ? Colors.White : Colors.Black,
          }
        : undefined
    }
    className={className}
    data-testid="text-intro"
    headline={headline || undefined}
    headlineProps={headlineProps}
    isCenteredDesktop={isCenteredDesktop}
    isCenteredMobile={isCenteredMobile}
    text={richText && buildSubline(richText)}
    textProps={textProps}
  >
    {button?.[0] && <AButton {...button[0]} variant={buttonVariant} />}
  </TextIntro>
);
