import { FC, useCallback, useEffect, useState } from 'react';

import { Icon } from '@lichtblick/icons';
import { useTracker } from '@lichtblick/tracker';

import { isDayOfService, isTimeOfService } from './OToast.helpers';
import { CloseButton, StyledHeadline, StyledImage, Root, Wrapper } from './OToast.styles';

import { OToastType, RichTextDocType } from '../../types/storyblok';
import { AButton } from '../AButton';

export const OToast: FC<OToastType> = ({
  button,
  icon,
  office_hour_end,
  office_hour_start,
  out_of_office,
  time_to_show,
  title,
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const { trackCustomEvent } = useTracker();

  const trackEvent = useCallback(() => {
    trackCustomEvent({
      eventName: 'hotline_teaser_impression',
    });
  }, [trackCustomEvent]);

  useEffect(() => {
    const toastTimeout = (time_to_show ? Number(time_to_show) : 120) * 1000;

    const shouldShowToast =
      isDayOfService(out_of_office?.value ?? []) &&
      isTimeOfService(
        office_hour_start ? Number(office_hour_start) : 8,
        office_hour_end ? Number(office_hour_end) : 16,
      );

    if (shouldShowToast) {
      setTimeout(() => {
        setIsHidden(false);
        trackEvent();
      }, toastTimeout);
    }
  }, [office_hour_end, office_hour_start, out_of_office, time_to_show, trackEvent]);

  return (
    <Root $isHidden={isHidden} data-testid="toast">
      <Wrapper>
        <CloseButton aria-label="Schließen" onClick={() => setIsHidden(true)} type="button">
          <Icon name="CloseIconS" />
        </CloseButton>

        {icon?.length === 1 && <StyledImage {...icon[0]} />}

        {title && (
          <StyledHeadline renderAs={'h3'} size="S">
            {title}
          </StyledHeadline>
        )}

        {button?.length === 1 && (
          <AButton
            {...button[0]}
            hasNoIcon
            isWide
            linkDialog={[
              {
                component: 'o-info-dialog',
                _uid: 'toast-dialog',
                ariaLabel: 'Hotline Dialog',
                buttonText: 'Schließen',
                isTextCentered: true,
                richText: {
                  type: 'doc',
                  content: [
                    {
                      content: [
                        {
                          text: 'Wir beraten dich gerne und nehmen deine Bestellung über unsere Hotline entgegen.',
                          type: 'text',
                        },
                      ],
                      type: 'paragraph',
                    },
                    {
                      content: [
                        {
                          text: '040 6360 1718',
                          marks: [{ type: 'link', attrs: { href: 'tel:+494063601718' } }],
                          type: 'text',
                        },
                      ],
                      type: 'paragraph',
                    },
                    {
                      content: [
                        {
                          marks: [],
                          text: 'Montags bis freitags von 8:00 bis 18:00 Uhr.',
                          type: 'text',
                        },
                      ],
                      attrs: { level: 6 },
                      type: 'heading',
                    },
                  ],
                } satisfies RichTextDocType,
              },
            ]}
            variant="primary"
          />
        )}
      </Wrapper>
    </Root>
  );
};
