import { FC } from 'react';

import { StyledIcon, toIconName } from '@lichtblick/icons';
import { Modal } from '@lichtblick/ui-components';

import { ContentWrapper, StyledH6Headline, StyledHeadline, StyledText } from './OInfoDialog.styles';

import { componentMapper, richTextMapper } from '../../helpers';
import { OInfoDialogType } from '../../types/storyblok';
import { getColor } from '../../utils';

export type OInfoDialogProps = OInfoDialogType & {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  onSecondaryButtonClick: () => void;
};

export const OInfoDialog: FC<OInfoDialogProps> = ({
  ariaLabel,
  buttonText,
  headline,
  headlineIcon,
  headlineIconColor: shiftColor,
  isOpen,
  isTextCentered,
  media,
  onOpenChange,
  onSecondaryButtonClick,
  richText,
}) => (
  <Modal
    aria-label={ariaLabel}
    isOpen={isOpen}
    onOpenChange={onOpenChange}
    onSecondaryButtonClick={onSecondaryButtonClick}
    secondaryButtonLabel={buttonText ?? undefined}
  >
    <ContentWrapper isTextCentered={isTextCentered}>
      {Boolean(headline) && (
        <StyledHeadline renderAs={'div'} size="S">
          {headline}
          {headlineIcon && <StyledIcon name={toIconName(headlineIcon)} shiftColor={getColor(shiftColor)} />}
        </StyledHeadline>
      )}
      {Boolean(richText) && (
        <div>
          {richTextMapper(richText, {
            h3: ({ children }) => (
              <StyledHeadline renderAs={'div'} size="S">
                {children}
              </StyledHeadline>
            ),
            p: ({ children }) => <StyledText>{children}</StyledText>,
            h6: ({ children }) => <StyledH6Headline renderAs={'div'}>{children}</StyledH6Headline>,
          })}
        </div>
      )}
      {media?.length === 1 && <div>{componentMapper(media[0])}</div>}
    </ContentWrapper>
  </Modal>
);
