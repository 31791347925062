import { FC } from 'react';

import { ArrowUpIconS } from '@lichtblick/icons/svg/arrow-up/arrow-up-s';

import { StyledBackToTopButton, StyledBackToTopButtonContainer } from './BackToTopButton.styles';

export const BackToTopButton: FC<{ isVisible: boolean }> = ({ isVisible }) => {
  return (
    <StyledBackToTopButtonContainer $isVisible={isVisible} aria-hidden="true">
      <StyledBackToTopButton
        onClick={() => {
          scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }}
      >
        <ArrowUpIconS />
      </StyledBackToTopButton>
    </StyledBackToTopButtonContainer>
  );
};
