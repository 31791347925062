// Convenience helpers for **simple** animations.
// Use `Small` to animate small elements like icons or buttons
// Use `Large` to animate bigger content blocks like modals, accordions or form pages
// Interesting resource: https://uxdesign.cc/the-ultimate-guide-to-proper-use-of-animation-in-ux-10bd98614fa9

type AnimationType = 'Intro' | 'Outro';

type Bezier = [number, number, number, number];

export const EasingValues: Record<AnimationType, Bezier> = {
  Intro: [0.4, 0, 0.2, 1],
  Outro: [0, 0.4, 0.2, 1],
};

export const Easing: Record<AnimationType, string> = {
  Intro: `cubic-bezier(${EasingValues.Intro.join(',')})`,
  Outro: `cubic-bezier(${EasingValues.Outro.join(',')})`,
};

/** in milliseconds */
export const Duration = {
  /** in milliseconds */
  LargeIntro: 350,
  /** in milliseconds */
  LargeOutro: 250,
  /** in milliseconds */
  SmallIntro: 200,
  /** in milliseconds */
  SmallOutro: 150,
};

export const DurationAndEasing: Record<keyof typeof Duration, string> = {
  LargeIntro: `${Duration.LargeIntro}ms ${Easing.Intro}`,
  LargeOutro: `${Duration.LargeOutro}ms ${Easing.Outro}`,
  SmallIntro: `${Duration.SmallIntro}ms ${Easing.Intro}`,
  SmallOutro: `${Duration.SmallOutro}ms ${Easing.Outro}`,
};
