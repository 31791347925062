/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';

import { Spacing } from '@lichtblick/theme';
import { Box, Container } from '@lichtblick/ui-components';

import { Item, Items, TextIntroWrapper } from './OLogos.styles';

import { componentMapper } from '../../helpers/componentMapper';
import { OLogosType } from '../../types/storyblok';
import { getColor, getSpacing } from '../../utils';
import { MTextIntro } from '../MTextIntro';

export const OLogos: FC<OLogosType> = ({
  anchorId,
  centerVertically,
  logos,
  paddingBottomDesktop,
  paddingBottomMobile,
  paddingTopDesktop,
  paddingTopMobile,
  textIntro,
  theme,
}) => (
  <Box
    backgroundColor={getColor(theme)}
    data-testid="logos"
    id={anchorId ?? undefined}
    pbd={getSpacing(paddingBottomDesktop) ?? Spacing.Xl}
    pbm={getSpacing(paddingBottomMobile) ?? Spacing.M}
    ptd={getSpacing(paddingTopDesktop) ?? Spacing.Xl}
    ptm={getSpacing(paddingTopMobile) ?? Spacing.M}
    textAlign="center"
  >
    <Container>
      {textIntro?.length === 1 && (
        <TextIntroWrapper>
          <MTextIntro {...textIntro[0]} />
        </TextIntroWrapper>
      )}
      {logos.length > 0 && (
        <Items $isVerticallyCentered={centerVertically}>
          {logos.map((item) => (
            <Item $length={logos.length} key={item._uid}>
              {componentMapper(item)}
            </Item>
          ))}
        </Items>
      )}
    </Container>
  </Box>
);
