import styled from '@lichtblick/styled';
import { MediaMediumAndAbove, Spacing } from '@lichtblick/theme';
import { Headline } from '@lichtblick/ui-components';

import { getColumnWidth } from '../shared';

export const StyledHeadline = styled(Headline)`
  ${MediaMediumAndAbove} {
    max-width: calc(${getColumnWidth(11)} - 2rem);
  }

  &:not(:last-child) {
    margin-bottom: ${Spacing.Xxs};
  }
`;
