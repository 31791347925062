import Head from 'next/head';
import Script from 'next/script';
import { FC } from 'react';

import { CloseIconS } from '@lichtblick/icons/svg/close/close-s';
import { Colors } from '@lichtblick/theme';
import { Box, LegacyLink, Text } from '@lichtblick/ui-components';

import { Footer, GlobalStyle, Header, StyledLink } from './TStory.styles';

import { buildUrl } from '../../helpers';
import { useTemplateTracker } from '../../hooks';
import { StoryblokStoryType, TStoryType } from '../../types/storyblok';
import { OStoryPage } from '../OStoryPage';

const IMPRINT_LINK_URL = 'https://www.lichtblick.de/impressum/';
const PUBLISHER_LOGO_SRC = 'https://www.lichtblick.de/amp-publisher-logo.png';
const PUBLISHER = 'LichtBlick';

export const TStory: FC<StoryblokStoryType<TStoryType>> = ({
  content: {
    closeButtonLinkEntry,
    closeButtonTitle,
    closeButtonUrl,
    disableAutoplay: isAutoplayDisabled,
    metaTitle,
    pages,
    posterPortrait,
    title,
    trackingPageArea,
  },
}) => {
  useTemplateTracker({
    pageArea: trackingPageArea ?? '',
    pageType: 'story',
    pageTitle: metaTitle ?? '',
  });

  return (
    <>
      <GlobalStyle />

      <Head>
        {/* eslint-disable-next-line react/no-unknown-property */}
        <style amp-boilerplate={true}>
          {`body{-webkit-animation:-amp-start 8s steps(1,end) 0s 1 normal both;-moz-animation:-amp-start 8s steps(1,end) 0s 1 normal both;-ms-animation:-amp-start 8s steps(1,end) 0s 1 normal both;animation:-amp-start 8s steps(1,end) 0s 1 normal both}@-webkit-keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}@-moz-keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}@-ms-keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}@-o-keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}@keyframes -amp-start{from{visibility:hidden}to{visibility:visible}}</style><noscript><style amp-boilerplate>body{-webkit-animation:none;-moz-animation:none;-ms-animation:none;animation:none}</style></noscript>`}
        </style>
      </Head>

      {/* AMP libs */}
      <Script async src="https://cdn.ampproject.org/v0.js" />
      <Script async custom-element="amp-video" src="https://cdn.ampproject.org/v0/amp-video-0.1.js" />
      <Script async custom-element="amp-story" src="https://cdn.ampproject.org/v0/amp-story-1.0.js" />

      <Box backgroundColor={Colors.Black}>
        <amp-story
          poster-portrait-src={`${posterPortrait?.filename}/m/640x853`}
          publisher={PUBLISHER}
          publisher-logo-src={PUBLISHER_LOGO_SRC}
          standalone
          title={title}
        >
          <Header>
            <StyledLink
              href={(closeButtonLinkEntry ? buildUrl(closeButtonLinkEntry) : closeButtonUrl) ?? '#'}
              title={closeButtonTitle ?? ''}
            >
              <CloseIconS height="2rem" width="2rem" />
            </StyledLink>
          </Header>

          {pages?.map((storyPage) => (
            <OStoryPage key={storyPage?._uid} {...storyPage} shouldAutoPlay={!isAutoplayDisabled} />
          ))}
        </amp-story>

        <Footer>
          <Text size="Xs">
            <LegacyLink href={IMPRINT_LINK_URL}>Impressum</LegacyLink>
          </Text>
        </Footer>
      </Box>
    </>
  );
};
