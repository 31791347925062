import styled from '@lichtblick/styled';
import { BorderRadius, Colors, MediaBelowMedium, MediaMediumAndAbove, Spacing } from '@lichtblick/theme';
import { Headline } from '@lichtblick/ui-components';

import { AImage } from '../AImage';

export const Root = styled.div<{ $isHidden: boolean }>`
  bottom: 0;
  display: ${({ $isHidden }) => ($isHidden ? 'none' : 'block')};
  position: sticky;
  z-index: 10;
`;

export const Wrapper = styled.div`
  background-color: ${Colors.White};
  outline: 1px solid ${Colors.LightGray};
  padding: ${Spacing.S};
  width: 100%;

  ${MediaMediumAndAbove} {
    border-radius: ${BorderRadius.S};
    bottom: ${Spacing.S};
    margin-right: ${Spacing.S};
    position: absolute;
    right: 0;
    width: 17.5rem;
  }
`;

export const CloseButton = styled.button`
  background: none;
  padding: ${Spacing.S};
  position: absolute;
  right: 0;
  top: 0;
  color: ${Colors.Black};
`;

export const StyledImage = styled(AImage)`
  ${MediaBelowMedium} {
    display: none;
  }

  ${MediaMediumAndAbove} {
    display: block;
    margin-bottom: ${Spacing.Xs};
  }
`;

export const StyledHeadline = styled(Headline)`
  margin: 0 ${Spacing.S} ${Spacing.Xs} 0;

  &:last-child {
    margin-bottom: 0;
  }
`;
