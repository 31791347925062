import { ComponentPropsWithRef, forwardRef } from 'react';

import { Wrapper, RadioButton, RadioLabel } from './RadioButton.styles';

import { Text } from '../../atoms/Text';
import { FieldFooter } from '../FieldFooter';
import { MediaCard, IconOrImageData } from '../MediaCard';

export type RadioCardProps = Omit<ComponentPropsWithRef<'input'>, 'value' | 'name' | 'defautlValue' | 'disabled'> & {
  // ! Replaces :has selector, which is not avilable for FireFox yet. See: https://caniuse.com/?search=%3Ahas
  activeValue?: string;
  hasError?: boolean;
  label: string;
  mediaProperties: IconOrImageData;
  name: string;
  statusLabel?: string;
  text?: string;
  value: string;
};

export const RadioCard = forwardRef<HTMLInputElement, RadioCardProps>(
  ({ activeValue, hasError, label, mediaProperties, name, statusLabel, text, value, ...props }, ref) => (
    <MediaCard htmlFor={`${name}-${value}`} isActive={activeValue === value} mediaProperties={mediaProperties}>
      <Wrapper>
        <RadioButton
          id={`${name}-${value}`}
          name={name}
          type="radio"
          value={value}
          {...props}
          $state={hasError ? 'error' : 'default'}
          ref={ref}
        />
        <RadioLabel isBold renderAs={'span'}>
          {label}
          <FieldFooter fieldFooterLabel={statusLabel} />
        </RadioLabel>
      </Wrapper>
      {text && <Text size="S">{text}</Text>}
    </MediaCard>
  ),
);
