import { FC } from 'react';

import { Spacing } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

import { MStageArticleInfoLine } from './MStageArticleInfoLine';
import { Hr } from './OStageArticle.styles';

import { OStageArticleType } from '../../types/storyblok';
import { getColor } from '../../utils';
import { MTextIntro } from '../MTextIntro';
import { GridColumn, GridContainer, GridRow } from '../shared';

export const OStageArticle: FC<OStageArticleType> = ({
  author: authorStories,
  publishDate,
  readingTime,
  textIntro,
  theme,
  updateDate,
}) => (
  <Box backgroundColor={getColor(theme)} data-testid="stage-article" pyd={Spacing.Xl} pym={Spacing.S}>
    <GridContainer>
      <GridRow>
        <GridColumn $push={2} $width={8}>
          <MTextIntro
            {...textIntro[0]}
            headlineProps={{ renderAs: 'h1', size: 'L' }}
            isCenteredDesktop
            isCenteredMobile
          />
        </GridColumn>
      </GridRow>
      <GridRow>
        <GridColumn $push={3} $width={6}>
          <Hr />
          <MStageArticleInfoLine
            author={authorStories?.[0]?.content}
            publishDate={publishDate}
            readingTime={readingTime}
            updateDate={updateDate}
          />
        </GridColumn>
      </GridRow>
    </GridContainer>
  </Box>
);
