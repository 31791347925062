import styled from '@lichtblick/styled';
import { FontWeights, MediaSmallAndAbove } from '@lichtblick/theme';

export const Li = styled.li`
  padding: 0.375rem 0 0.375rem 1.125rem;
  position: relative;

  &::marker {
    font-weight: ${FontWeights.Bold};
  }

  &::before {
    display: inline-block;
    font-size: 1rem;
    position: absolute;
    right: calc(100% - 0.625rem);
    top: 0.5em;
  }

  ${MediaSmallAndAbove} {
    padding: 0.5rem 0 0.5rem 1.5rem;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &:only-child {
    margin-top: -1rem;
  }
`;
