import { LichtBlickTypes } from '../../types';
import { MProductComparisonCardType, ProductType } from '../../types/storyblok';

export type ProductWithInformation = MProductComparisonCardType & {
  colors: { color: string; image: string | null | undefined; textContent: string }[];
  label: string;
  order: number;
  price: number | null;
  value: string;
};

type JoinedProduct = ProductType & {
  productCodes: string[];
  productColors: { color: string; image: string | null | undefined; textContent: string }[];
};

// Join all products from contentful based on model because there's no other way to connect them
export const joinAllProductsByModel = (allProducts: ProductType[]) => {
  const allJoinedProducts: JoinedProduct[] = [];

  allProducts.forEach((product) => {
    if (product.productCode && product.color?.length === 1) {
      const productWithModelAlreadyInArray = allJoinedProducts.find(
        (joinedProduct) => joinedProduct.model === product.model,
      );

      //if model is already in array add product code and color if color is missing so no duplicate colors
      if (productWithModelAlreadyInArray) {
        productWithModelAlreadyInArray.productCodes.push(product.productCode);

        if (
          productWithModelAlreadyInArray.productColors.filter(
            (color) => product.color?.length === 1 && color.color === product.color[0].hex,
          ).length === 0
        ) {
          productWithModelAlreadyInArray.productColors.push({
            color: product.color[0].hex,
            textContent: product.color[0].label,
            image: product.image?.filename,
          });
        }
      } else {
        allJoinedProducts.push({
          ...product,
          productCodes: [product.productCode],
          productColors: [
            {
              color: product.color[0].hex,
              textContent: product.color[0].label,
              image: product.image?.filename,
            },
          ],
        });
      }
    }
  });

  return allJoinedProducts;
};

export const addProductInformation = (products: ProductType[], cards: MProductComparisonCardType[]) => {
  const cardProductsWithInformation: ProductWithInformation[] = [];

  const allProductsByModel = joinAllProductsByModel(products);

  cards.forEach((card, index) => {
    const product: ProductWithInformation = {
      ...card,
      label: card.headline ?? '',
      value: card.headline ?? '',
      colors: [],
      order: index,
      price: null,
    };

    allProductsByModel.forEach((model) => {
      // if product codes match based on model.productCodes add colors and related image
      if (model.productCodes.some((productCode) => card.productCodes.value.includes(productCode))) {
        // if model has color which is not yet in the product add it based on model.productColors
        model.productColors.forEach((color) => {
          if (product.colors.filter((productColor) => productColor.color === color.color).length === 0 && color.color) {
            product.colors.push(color);
          }
        });
      }
    });

    cardProductsWithInformation.push(product);
  });

  return cardProductsWithInformation;
};

export const addProductPricing = ({
  dataProducts,
  products,
}: {
  dataProducts: LichtBlickTypes.GetNonCommodityProductsQuery['nonCommodityProductsByCampaign'];
  products: ProductWithInformation[];
}) => {
  const cardProductsWithPrice: ProductWithInformation[] = [];

  products.forEach((cardProduct) => {
    dataProducts.forEach((dataProduct) => {
      if (cardProduct?.productCodes.value.includes(dataProduct.productCode)) {
        const lowestPrice = Math.min(...dataProduct.prices.map((price) => price.salesPriceGross));

        cardProductsWithPrice.push({
          ...cardProduct,
          price: lowestPrice,
        });
      }
    });
  });

  return cardProductsWithPrice;
};
