import { FC } from 'react';

import { componentMapper } from '../../helpers/componentMapper';
import { WithMetaData } from '../../helpers/templateMapper';
import { useTemplateTracker } from '../../hooks';
import { StoryblokStoryType, TOverviewType } from '../../types/storyblok';
import { Layout } from '../Layout';
import { MNavigationSecondary } from '../MNavigationSecondary';
import { OFootnotes } from '../OFootnotes';

export const TOverview: FC<WithMetaData<StoryblokStoryType<TOverviewType>>> = ({
  content: { breadcrumbTitle, footnotes, metaTitle, partials, secondaryNavigation, stage, trackingPageArea },
  parentPages,
  slug,
}) => {
  useTemplateTracker({
    pageArea: trackingPageArea ?? '',
    pageType: 'overview',
    pageTitle: metaTitle ?? '',
  });

  return (
    <Layout breadcrumbTitle={breadcrumbTitle} parentPages={parentPages} slug={slug}>
      {secondaryNavigation?.length === 1 && <MNavigationSecondary {...secondaryNavigation[0]} />}
      {componentMapper(stage[0])}
      {partials?.map((item) => componentMapper(item, item._uid))}
      {<OFootnotes footnotes={footnotes} />}
    </Layout>
  );
};
