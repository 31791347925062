import { FC } from 'react';

import { useTracker } from '@lichtblick/tracker';

import { StyledNavigationItem, StyledNavigationLink } from './NavigationItem.styles';

import { Text } from '../../atoms/Text/Text';
import { NavigationItemProps } from '../../types';

export const NavigationItem: FC<NavigationItemProps> = ({ href, isActive, text, tracking }) => {
  const { trackCustomEvent } = useTracker();

  return (
    <StyledNavigationItem>
      <StyledNavigationLink
        href={href}
        onClick={() => {
          trackCustomEvent(tracking);
        }}
        tabIndex={href ? undefined : 0}
      >
        <Text isBold={isActive} size="Xs">
          {text}
        </Text>
      </StyledNavigationLink>
    </StyledNavigationItem>
  );
};
