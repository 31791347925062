import { FC } from 'react';

import { Breakpoints } from '@lichtblick/theme';
import { Text } from '@lichtblick/ui-components';

import { Figcaption } from './MediaFigure.styles';

import { AImage, componentMapper } from '../..';
import { AImageType, OMediaType } from '../../types/storyblok';
import { GridContainer, GridRow, GridColumn } from '../shared';

export type MediaFigureProps = Pick<OMediaType, 'media' | 'layout'> & {
  caption?: string | null;
  copyright?: string | null;
  index?: number;
};

const getMediaMaxWidth = (layout?: string | null) => {
  switch (layout) {
    case 'large':
      return Breakpoints.Max;
    case 'medium':
      return Breakpoints.Large;
    default:
      return;
  }
};

const isIAImage = (media: MediaFigureProps['media'][0]): media is AImageType => media.component === 'a-image';

export const MediaFigure: FC<MediaFigureProps> = ({ caption, copyright, index, layout, media }) => {
  const figure = (
    <figure>
      {media?.[0] &&
        (isIAImage(media[0]) ? (
          <AImage {...media[0]} loading={index === 0 ? 'eager' : undefined} maxWidth={getMediaMaxWidth(layout)} />
        ) : (
          componentMapper(media[0], media[0]._uid)
        ))}

      {(caption || copyright) && (
        <Figcaption $layout={layout}>
          {caption && <Text>{caption}</Text>}
          {copyright && <Text size="Xs">{copyright}</Text>}
        </Figcaption>
      )}
    </figure>
  );

  if (layout === 'large') {
    return figure;
  } else if (layout === 'medium') {
    return (
      <GridContainer>
        <GridRow>
          <GridColumn $width={12}>{figure}</GridColumn>
        </GridRow>
      </GridContainer>
    );
  } else {
    return (
      <GridContainer>
        <GridRow>
          <GridColumn $push={2} $width={8}>
            {figure}
          </GridColumn>
        </GridRow>
      </GridContainer>
    );
  }
};
