import { useState, useCallback, FormEventHandler, ChangeEventHandler } from 'react';

import { logger } from '@lichtblick/logger';

import { isSolarPartnerAvailable } from '../../../../../api/lichtblick/queries/isSolarPartnerAvailable/isSolarPartnerAvailable';

const DEFAULT_REQUIRED_ERROR_MESSAGE = 'Hier fehlt noch eine Angabe';
const DEFAULT_INVALID_ERROR_MESSAGE = 'Bitte überprüfe deine Angabe';
const isPostalCodeValid = (postalCode: string) => /^\d{5}$/.test(postalCode);

enum CheckedState {
  InProgress,
  PartnerAvailable,
  NoPartner,
}

export const usePostalCodeForm = () => {
  const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState<string | undefined>(undefined);
  const [currentPostalCode, setCurrentPostalCode] = useState<string | undefined>(undefined);
  const [postalCodeCache, setPostalCodeCache] = useState<Record<string, CheckedState>>({});

  const hasNoPartnerAvailable = postalCodeCache[currentPostalCode ?? ''] === CheckedState.NoPartner;

  const onSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (event) => {
      event.preventDefault();

      const postalCode = new FormData(event.currentTarget).get('postalCode')?.toString();

      if (!postalCode) {
        setPostalCodeErrorMessage(DEFAULT_REQUIRED_ERROR_MESSAGE);

        return;
      }

      if (!isPostalCodeValid(postalCode)) {
        setPostalCodeErrorMessage(DEFAULT_INVALID_ERROR_MESSAGE);

        return;
      }

      const page = hasNoPartnerAvailable ? 'ServiceNotAvailablePage' : 'HouseTypePage';

      location.assign(`/zuhause-checkout?buildingPostalCode=${postalCode}&page=${page}`);
    },
    [hasNoPartnerAvailable],
  );

  const onChangeHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ target: { value } }) => {
      setPostalCodeErrorMessage(value ? undefined : DEFAULT_REQUIRED_ERROR_MESSAGE);
      setCurrentPostalCode(value);

      if (!isPostalCodeValid(value) || postalCodeCache[value] !== undefined) {
        return;
      }

      setPostalCodeCache((postalCodeCache) => ({ ...postalCodeCache, [value]: CheckedState.InProgress }));
      const updatepostalCodeCache = (isAvailable: boolean) =>
        setPostalCodeCache((postalCodeCache) => ({
          ...postalCodeCache,
          [value]: isAvailable ? CheckedState.PartnerAvailable : CheckedState.NoPartner,
        }));

      const logAndResetPostalCode = (error: any) => {
        logger.error(`Error in Soft-Funnel-Entry (for postalCode ${value}):`, error);
        setPostalCodeCache(({ [value]: _removed, ...rest }) => rest);
      };

      isSolarPartnerAvailable(value).then(updatepostalCodeCache).catch(logAndResetPostalCode);
    },
    [postalCodeCache],
  );
  const isLoading = Boolean(currentPostalCode) && postalCodeCache[currentPostalCode] === CheckedState.InProgress;

  return { isLoading, onChangeHandler, onSubmit, postalCodeErrorMessage, hasNoPartnerAvailable };
};
