import { useTrackEvent } from '@optimizely/react-sdk';

import { OPTIMIZELY_NAME } from '@lichtblick/consent/ConsentManager/ConsentManager.types';
import { useConsent } from '@lichtblick/consent/hooks/useConsent';

export const useOptimizelyTrack = () => {
  const [track] = useTrackEvent();
  const optimizelyConsentState = useConsent(OPTIMIZELY_NAME);

  if (optimizelyConsentState) {
    return () => {
      // no-op
    };
  }

  return (event: string) => track(event);
};
