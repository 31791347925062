import { MBreadcrumbProps } from './MBreadcrumb';

export const generateLinks = ({ breadcrumbTitle, parentPages, slug }: Omit<MBreadcrumbProps, 'mode'>) => {
  const parentLinks = parentPages.map((page) => ({
    title: page.breadcrumbTitle || '',
    url: `/${page.slug}`,
  }));

  const currentLink = { url: `${slug}`, title: breadcrumbTitle };

  return [...parentLinks, currentLink];
};
