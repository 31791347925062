import { FC, PropsWithChildren } from 'react';

import { OStageFull } from '@lichtblick/contentful/components/OStageFull';
import { CheckIconS } from '@lichtblick/icons/svg/check/check-s';

import { CalloutBadge } from './components/CalloutBadge';
import {
  Root,
  SellingPointsContainer,
  SellingPointList,
  SellingPointItem as SellingPointItemImpl,
  SellingPointsSection,
} from './SolarStage.styles';

import { richTextMapper } from '../../../../helpers';
import { SolarStageType } from '../../../../types';
import { getColor } from '../../../../utils';

const SellingPointItem: FC<PropsWithChildren> = ({ children }) => (
  <SellingPointItemImpl>
    <CheckIconS />
    {children}
  </SellingPointItemImpl>
);

export const SolarStage: FC<SolarStageType> = ({
  calloutBadge,
  followingSectionTheme,
  sellingPoints,
  textIntro,
  ...rest
}) => (
  <Root data-testid="solar-stage">
    <CalloutBadge calloutBadge={calloutBadge} />
    <OStageFull textIntro={textIntro} {...rest} component="o-stage-full" />
    <SellingPointsSection $backgroundColor={getColor(followingSectionTheme)}>
      <SellingPointsContainer>
        {sellingPoints &&
          richTextMapper(sellingPoints, {
            ul: SellingPointList,
            li: SellingPointItem,
          })}
      </SellingPointsContainer>
    </SellingPointsSection>
  </Root>
);
