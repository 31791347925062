import styled from '@lichtblick/styled';
import { Colors, MediaDesktop, Spacing } from '@lichtblick/theme';

export const OFootnotesRoot = styled.div<{ $theme?: 'light' | 'dark' }>`
  background-color: ${({ $theme }) => ($theme === 'dark' ? Colors.BackgroundGray : Colors.DarkGray)};

  padding-bottom: ${Spacing.Xs};
  padding-top: ${Spacing.Xs};

  ${MediaDesktop} {
    padding-bottom: ${Spacing.S};
    padding-top: ${Spacing.S};
  }
`;
