import { FC, ReactNode } from 'react';

import { Header, Label, Row, RowHeadline, Rows, Titles, Value, Wrapper } from './ProductSummary.styles';

import { Text } from '../../atoms/Text/Text';

export type ProductSummaryRow = {
  headline?: ReactNode;
  label?: ReactNode;
  value?: ReactNode;
};

export type ProductSummaryProps = {
  className?: string;
  'data-testid'?: string;
  headline?: string;
  rows: Array<ProductSummaryRow>;
  subline?: string;
};

export const ProductSummary: FC<ProductSummaryProps> = ({
  className,
  'data-testid': testId,
  headline,
  rows,
  subline,
}) => (
  <Wrapper className={className} data-testid={testId}>
    <Header>
      <Titles>
        {Boolean(headline) && <Text isBold>{headline}</Text>}
        {Boolean(subline) && <Text size="S">{subline}</Text>}
      </Titles>
    </Header>
    <Rows>
      {rows?.map(({ headline: rowHeadline, label, value }) => (
        <Row $isHeadline={Boolean(rowHeadline)} key={`${rowHeadline}-${label}-${value}`}>
          {Boolean(rowHeadline) && <RowHeadline>{rowHeadline}</RowHeadline>}
          {Boolean(label) && <Label>{label}</Label>}
          {Boolean(value) && <Value>{value}</Value>}
        </Row>
      ))}
    </Rows>
  </Wrapper>
);
