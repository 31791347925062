type SpacingInput = {
  bottom?: string | number;
  full?: string | number;
  left?: string | number;
  right?: string | number;
  top?: string | number;
  x?: string | number;
  y?: string | number;
};

export const spacing = (values: SpacingInput) => {
  const { bottom, full, left, right, top, x, y } = values;

  return [[full], [y, x], [top, right, bottom, left]]
    .find((values) => values.some((value) => value || value === 0))
    ?.map((value) => value || 0)
    .join(' ');
};
