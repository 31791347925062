import Image from 'next/image';
import { FC } from 'react';

import { Icon } from '@lichtblick/icons';
import { Text } from '@lichtblick/ui-components';

import { ImageInfoLine, ImageWrapper, InfoLine, InfoLineContainer, InfoLines } from './MStageArticleInfoLine.styles';

import { AuthorType, OStageArticleType } from '../../types/storyblok';

const formatDate = (dateString: string) =>
  new Date(dateString.split(' ')[0]).toLocaleDateString('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });

const getDate = (publishDate: string | undefined, updateDate: string | undefined) =>
  updateDate
    ? { text: 'aktualisiert am', date: formatDate(updateDate) }
    : publishDate
      ? { text: 'veröffentlicht am', date: formatDate(publishDate) }
      : null;

type MStageArticleInfoLineProps = Pick<OStageArticleType, 'readingTime' | 'publishDate' | 'updateDate'> & {
  author: AuthorType | undefined;
};

export const MStageArticleInfoLine: FC<MStageArticleInfoLineProps> = ({
  author,
  publishDate,
  readingTime,
  updateDate,
}) => {
  const dateDetails = getDate(publishDate, updateDate);

  return (
    <InfoLineContainer>
      <InfoLines>
        {dateDetails && (
          <InfoLine>
            <Icon name="CalendarIconS"></Icon>
            <Text size="S">{dateDetails.text}</Text>
            <Text isBold size="S">
              {dateDetails.date}
            </Text>
          </InfoLine>
        )}

        {author && (
          <ImageInfoLine>
            <ImageWrapper>
              <Image
                alt="Picture of the author"
                fill
                src={author.image?.filename || ''}
                style={{ objectFit: 'cover' }}
              />
            </ImageWrapper>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text isBold size="S">
                {author.name}
              </Text>
              <Text size="S">{author.position}</Text>
            </div>
          </ImageInfoLine>
        )}

        {Number(readingTime) > 0 && (
          <InfoLine>
            <Icon name="TimeIconS"></Icon>
            <Text isBold size="S">{`${readingTime} Minute${Number(readingTime) > 1 ? 'n' : ''}`}</Text>
            <Text size="S">Lesezeit</Text>
          </InfoLine>
        )}
      </InfoLines>
    </InfoLineContainer>
  );
};
