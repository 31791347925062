import styled from '@lichtblick/styled';
import { Spacing } from '@lichtblick/theme';

import {
  SharedInputRowProps,
  getSharedInputRowStyles,
  sharedInputStyles,
  sharedWrapperStyles,
} from '../Textfield/Textfield.styles';

export const Wrapper = styled.div`
  ${sharedWrapperStyles}
`;

export const InputRow = styled.div<SharedInputRowProps>`
  ${getSharedInputRowStyles}
  height: auto;
`;

export const Input = styled.textarea`
  ${sharedInputStyles.base}
  padding-bottom: ${Spacing.Xs};
  padding-top: ${Spacing.Xs};
`;
