import React, { FC } from 'react';

import { Colors } from '@lichtblick/theme';

import { Root } from './Footnote.styles';

export type FootnoteProps = {
  children: React.ReactNode;
  className?: string;
  color?: Colors;
  index: string;
};

export const Footnote: FC<FootnoteProps> = ({ children, className, color, index }) => (
  <Root $color={color} className={className} renderAs={'div'} size="Xs">
    <sup>{index}</sup>
    {children}
  </Root>
);
