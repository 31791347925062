import { FC } from 'react';

import { Colors } from '@lichtblick/theme';
import { Footnote, Text } from '@lichtblick/ui-components';

import { richTextMapper } from '../../helpers/richTextMapper';
import { useOptimizelyFeatureFlag } from '../../hooks/use-optimizely-decision';
import { MFootnoteType } from '../../types/storyblok';

export type MFootnoteProps = MFootnoteType & {
  index: number;
  theme?: 'light' | 'dark';
};

export const MFootnote: FC<MFootnoteProps> = ({ featureFlag, index, text, theme }) => {
  const footNote = useOptimizelyFeatureFlag(featureFlag);

  if (featureFlag && !footNote) {
    return;
  }

  return (
    <Footnote color={theme === 'light' ? Colors.DarkGray : Colors.White} index={index.toString()}>
      {richTextMapper(text, {
        p: ({ children }) => (
          <Text renderAs="p" size="Xs">
            {children}
          </Text>
        ),
      })}
    </Footnote>
  );
};
