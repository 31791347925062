import styled from '@lichtblick/styled';
import { MediaSmallAndAbove, MediaBelowSmall, Spacing } from '@lichtblick/theme';

export const Slider = styled.div`
  margin-top: ${Spacing.L};
  position: relative;
`;

export const Cards = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 100%;

  ${MediaBelowSmall} {
    overflow-x: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    // Hides scrollbar in firefox
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  ${MediaSmallAndAbove} {
    align-items: center;
    justify-content: center;
  }
`;

export const Footer = styled.div`
  margin-left: 0;
  margin-top: ${Spacing.S};

  ${MediaSmallAndAbove} {
    margin: ${Spacing.L} auto 0;
  }
`;
