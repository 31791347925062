import { ComponentPropsWithRef, ElementType, forwardRef } from 'react';

import { Input, InputRow, Wrapper } from './Textarea.styles';

import { FieldFooter, FieldFooterType } from '../FieldFooter';
import { Label } from '../Label';

export type TextareaElementProps = {
  isBold?: boolean;
};

export type TextareaProps = Omit<ComponentPropsWithRef<'textarea'>, 'defaultValue' | 'disabled'> &
  TextareaElementProps & {
    className?: string;
    icon?: ElementType;
    isDisabled?: boolean;
    label?: string;
    statusLabel?: string;
    statusType?: FieldFooterType;
  };

/**
 * Ref needs to be forwarded to be compatible with form packages like react-hook-form.
 */
export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, icon, isDisabled, label, name, statusLabel, statusType, ...props }, ref) => (
    <Wrapper className={className}>
      {Boolean(label) && (
        <Label htmlFor={name ?? ''} isBold>
          {label}
        </Label>
      )}
      <InputRow $isDisabled={isDisabled} $statusType={statusType}>
        <Input {...props} aria-invalid={statusType === 'error'} disabled={isDisabled} name={name} ref={ref} />
      </InputRow>
      {Boolean(statusLabel) && <FieldFooter fieldFooterLabel={statusLabel} fieldFooterType={statusType} icon={icon} />}
    </Wrapper>
  ),
);
