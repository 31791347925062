import { FC } from 'react';

import { StyledMList } from './MList.styles';

import { MListType } from '../../types/storyblok';
import { ColorKey } from '../../utils';
import { AListItem } from '../AListItem';

type MListProps = MListType & { colorOverride?: ColorKey; textSizeOverride?: 'M' | 'S' };

export const MList: FC<MListProps> = ({ colorOverride, items, textSizeOverride }) => (
  <StyledMList data-testid="list">
    {items?.map((item) => (
      <AListItem {...item} colorOverride={colorOverride} key={item._uid} textSizeOverride={textSizeOverride} />
    ))}
  </StyledMList>
);
