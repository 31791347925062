import { useContext } from 'react';

import { SettingsContext } from '../../helpers/settingsContext';
import { MTextIntroType, SettingsType } from '../../types/storyblok';

export const FormType = {
  ContractCancellation: 'contract-cancellation',
  ReliefCalculator: 'relief-calculator',
  PermissionCenter: 'permission-center',
  B2BElectricity: 'B2BElectricity',
  B2BGas: 'B2BGas',
  EnergyManger: 'energieManager',
};

type AllInMaintenance = {
  scope: 'all';
  textIntro: MTextIntroType | null | undefined;
};

type IndividualInMaintenance = {
  scope: 'individual';
  textIntro: MTextIntroType | null | undefined;
};

type NoMaintenance = {
  scope: 'none';
  textIntro: null;
};

type MaintenanceMode = AllInMaintenance | IndividualInMaintenance | NoMaintenance;

type MaintenanceProduct = NonNullable<SettingsType['maintenanceProducts']>[number];

type Item = NonNullable<NonNullable<NonNullable<MaintenanceProduct>['maintenanceContent']>[number]['partials']>[number];

// Maintenance Mode is not yet implemented for the nulled forms.
const formTypeToMaintenanceProductMap: Record<string, string | null> = {
  B2BElectricity: 'form-b2b-electricity',
  B2BGas: 'form-b2b-gas',
  'contract-cancellation': null,
  energieManager: null,
  'relief-calculator': null,
  'permission-center': null,
};

const getMaintenanceProductTextIntro = (maintenanceProduct: MaintenanceProduct) =>
  maintenanceProduct?.maintenanceContent?.[0]?.partials.find(
    (item: Item): item is MTextIntroType => item?.component === 'm-text-intro',
  );

export const useMaintenanceMode = (formType: string | null | undefined): MaintenanceMode => {
  const { maintenanceProducts } = useContext(SettingsContext);

  const allFormsInMaintenanceProduct = maintenanceProducts?.find(
    (item) => item.product === 'all' || item.product === 'forms',
  );

  if (allFormsInMaintenanceProduct) {
    return {
      scope: 'all',
      textIntro: getMaintenanceProductTextIntro(allFormsInMaintenanceProduct),
    };
  }

  const maintenanceProductName = formTypeToMaintenanceProductMap[formType ?? ''];

  const maintenanceProduct = maintenanceProducts?.find((item) => item.product === maintenanceProductName);

  if (maintenanceProduct) {
    return {
      scope: 'individual',
      textIntro: getMaintenanceProductTextIntro(maintenanceProduct),
    };
  }

  return {
    scope: 'none',
    textIntro: null,
  };
};

export const getIdFromFormType = (formType: string | undefined | null, sysId: string) => {
  switch (formType) {
    case FormType.ContractCancellation:
      return `contract-cancellation-${sysId}`;
    case FormType.ReliefCalculator:
      return `relief-calculator-${sysId}`;
    case FormType.PermissionCenter:
      return `permission-center-${sysId}`;
    case FormType.B2BElectricity:
      return `form-${sysId}`;
    case FormType.B2BGas:
      return `form-${sysId}`;
    case FormType.EnergyManger:
      return `form-${sysId}`;
    default:
      return sysId;
  }
};
