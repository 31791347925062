import styled, { css } from '@lichtblick/styled';
import { BorderRadius, Colors, darkColors, DurationAndEasing, Spacing } from '@lichtblick/theme';
import { AccentText } from '@lichtblick/ui-components';

const cover = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export const PlusIcon = styled.div`
  display: flex;
  border-radius: 50%;
  position: absolute;
  top: ${Spacing.Xxs};
  right: ${Spacing.Xxs};
  background-color: color-mix(in srgb, ${Colors.White} 33%, transparent);
  padding: ${Spacing.Xs};
  color: ${Colors.Black};
`;

export const CloseIcon = styled.div`
  padding: ${Spacing.Xs};
  position: absolute;
  right: ${Spacing.Xxs};
  top: ${Spacing.Xxs};
  display: block;

  @media (hover: hover) {
    display: none;
  }
`;

export const Card = styled.div<{ $backgroundColor: Colors; $isToggled: boolean }>`
  display: inline-block;
  position: relative;
  margin-bottom: ${Spacing.Xs};
  color: ${({ $backgroundColor }) => (darkColors.includes($backgroundColor) ? Colors.White : Colors.Black)};

  @media (hover: none) {
    &:hover ${PlusIcon} {
      opacity: ${({ $isToggled }) => ($isToggled ? 0 : 1)};
    }
  }

  @media (hover: hover) {
    &:hover ${PlusIcon} {
      opacity: 0;
    }
  }
`;

export const Media = styled.div<{ $isToggled: boolean }>`
  border-radius: ${BorderRadius.S};
  overflow: hidden;
`;

export const Description = styled.div<{ $backgroundColor: Colors; $isToggled: boolean }>`
  ${cover};
  display: flex;
  align-items: center;
  background: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${BorderRadius.S};
  opacity: ${({ $isToggled }) => ($isToggled ? 1 : 0)};
  padding: ${Spacing.M};
  transition: opacity ${DurationAndEasing.LargeOutro};

  @media (hover: hover) {
    opacity: 0;
    ${Card}:hover > & {
      opacity: 1;
    }
  }
`;

export const Toggle = styled.div`
  ${cover};
  margin: 0;
  opacity: 0;
  z-index: 2;

  @media (hover: hover) {
    display: none;
  }
`;

export const Caption = styled(AccentText)`
  display: block;
`;
