import { TrackingContextValue } from '@lichtblick/tracker';

export const permissionCenterTrackingInitializer = (track: TrackingContextValue['trackCustomEvent']) => {
  const handlerPermissionCenterFormOpen = () => {
    track({ eventName: 'form_open', customProps: { form_name: 'kontakteinstellungen' } });
  };

  const handlerPermissionCenterFormSubmit = (e: Event | CustomEvent) => {
    if ('detail' in e) {
      const { customProps } = e.detail;

      track({
        eventName: 'form_submit_kontakteinstellungen',
        customProps: { form_name: 'kontakteinstellungen', opt_out: customProps.opt_out },
      });
    }
  };

  const handlerPermissionCenterFormFailed = (e: Event | CustomEvent) => {
    if ('detail' in e) {
      const { errors } = e.detail;
      const errorMessage = errors.replace(/\s'\d+'/g, '').trim();

      track({
        eventName: 'form_failed',
        customProps: { form_name: 'kontakteinstellungen', error_message: errorMessage },
      });
    }
  };

  const handlerPermissionCenterErrorPage = () => {
    track({ eventName: 'error_screen', customProps: { form_name: 'kontakteinstellungen' } });
  };

  document.addEventListener('trackPermissionCenterFormOpen', handlerPermissionCenterFormOpen);
  document.addEventListener('trackPermissionCenterFormSubmit', handlerPermissionCenterFormSubmit);
  document.addEventListener('trackPermissionCenterFormFailed', handlerPermissionCenterFormFailed);
  document.addEventListener('trackPermissionCenterLoadingError', handlerPermissionCenterErrorPage);

  return () => {
    document.removeEventListener('trackPermissionCenterFormOpen', handlerPermissionCenterFormOpen);
    document.removeEventListener('trackPermissionCenterFormSubmit', handlerPermissionCenterFormSubmit);
    document.removeEventListener('trackPermissionCenterFormFailed', handlerPermissionCenterFormFailed);
    document.removeEventListener('trackPermissionCenterLoadingError', handlerPermissionCenterErrorPage);
  };
};
