import styled from '@lichtblick/styled';
import {
  BorderRadius,
  Breakpoints,
  MediaMediumAndAbove,
  Colors,
  FontWeights,
  Spacing,
  themeColors,
} from '@lichtblick/theme';
import { StyledFieldFooter } from '@lichtblick/ui-components';

import { GridColumn, GridRow } from '../../shared';

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: ${Spacing.M};
  gap: ${Spacing.S};
  & > ${StyledFieldFooter} {
    background-color: ${Colors.FadedRed};
    border-radius: ${BorderRadius.S};
    font-size: 0.75rem;
    font-weight: ${FontWeights.Bold};
    margin-top: ${Spacing.M};
    padding: ${Spacing.Xxs};
  }
`;

export const StyledGridContainer = styled.div<{ $backgroundColor?: Colors }>`
  margin: 0 auto;
  max-width: ${Breakpoints.Large}px;
  width: 100%;

  & > ${GridRow} {
    background: ${({ $backgroundColor }) => $backgroundColor};
    color: ${({ $backgroundColor }) => $backgroundColor && themeColors($backgroundColor).primary};
    & > ${GridColumn}:first-child {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
    & > ${GridColumn}:last-child {
      padding: ${Spacing.S};
      ${MediaMediumAndAbove} {
        padding: ${Spacing.L};
      }
    }
  }
`;
