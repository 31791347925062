import { FC } from 'react';

import { Spacing } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

import { ColumnItems, StyledTextIntro } from './OColumns.styles';

import { OColumnsType } from '../../types/storyblok';
import { getColor, getTheme } from '../../utils';
import { MColumnItem } from '../MColumnItem';
import { AnchorMark, GridColumn, GridContainer, GridRow, getContainerStyle } from '../shared';

export const OColumns: FC<OColumnsType> = ({
  accent,
  anchorId,
  columnAlignment,
  columnWidth,
  containerStyle,
  items,
  textIntro,
  theme,
}) => {
  const chipBackground = containerStyle === 'full-boxed' ? getTheme(accent).primary : getColor(accent);

  return (
    <Box backgroundColor={getColor(theme)} data-testid="columns" pyd={Spacing.Xl} pym={Spacing.L}>
      {anchorId && <AnchorMark anchorId={anchorId} />}
      <GridContainer $backgroundColor={getColor(accent)} $containerStyle={getContainerStyle(containerStyle)}>
        {textIntro?.[0] && (
          <GridRow>
            <GridColumn $push={2} $width={8}>
              <StyledTextIntro
                {...textIntro[0]}
                accentColor={chipBackground}
                buttonVariant="link"
                isCenteredDesktop
                isCenteredMobile
              />
            </GridColumn>
          </GridRow>
        )}
        <GridRow>
          <GridColumn $width={12}>
            <ColumnItems>
              {items
                ?.filter(Boolean)
                .map((columnItem) => (
                  <MColumnItem
                    {...columnItem}
                    columnAlignment={columnAlignment}
                    columnWidth={columnWidth}
                    key={columnItem._uid}
                  />
                ))}
            </ColumnItems>
          </GridColumn>
        </GridRow>
      </GridContainer>
    </Box>
  );
};
