import { ComponentProps, forwardRef } from 'react';

import { Label, StyledChip } from './Chip.styles';

export type ChipProps = Omit<ComponentProps<'span'>, 'ref'> & {
  backgroundColor?: string;
  label: string;
  textColor?: string;
};

export const Chip = forwardRef<HTMLSpanElement, ChipProps>(({ backgroundColor, label, textColor, ...props }, ref) => (
  <StyledChip $background={backgroundColor} ref={ref} {...props}>
    <Label $color={textColor}>{label}</Label>
  </StyledChip>
));
