import { InfoIconXs } from '@lichtblick/icons/svg/info/info-xs';
import { BoxCTA, StatusMessage, Textfield } from '@lichtblick/ui-components';
import { Text } from '@lichtblick/ui-components/atoms/Text';

import { usePostalCodeForm } from './SoftFunnel.helpers';
import { Root, FunnelForm, Controls, StyledSearchIcon, ButtonWrapper, StageCard } from './SoftFunnel.styles';

const message = `Leider kein Installationspartner in deiner Nähe gefunden. Werde benachrichtigt, sobald das SolarPaket \
in deiner Region verfügbar ist.`;

export const SoftFunnel = () => {
  const { hasNoPartnerAvailable, isLoading, onChangeHandler, onSubmit, postalCodeErrorMessage } = usePostalCodeForm();
  const buttonText = hasNoPartnerAvailable ? 'Benachrichtigt mich' : 'SolarAnlage berechnen';

  return (
    <Root>
      <StageCard>
        <FunnelForm onSubmit={onSubmit}>
          <Text isBold>Wo soll Deine SolarAnlage installiert werden?</Text>
          <Controls>
            <Textfield
              leftSlot={<StyledSearchIcon />}
              name="postalCode"
              onChange={onChangeHandler}
              placeholder="Postleitzahl eingeben"
              required
              statusLabel={postalCodeErrorMessage}
              statusType={postalCodeErrorMessage ? 'error' : 'default'}
              type="text"
            />
            <ButtonWrapper>
              <BoxCTA
                as="button"
                isLoading={isLoading}
                isWide
                variant={hasNoPartnerAvailable ? 'secondary' : 'primary'}
              >
                {buttonText}
              </BoxCTA>
            </ButtonWrapper>
          </Controls>
          {hasNoPartnerAvailable && (
            <StatusMessage icon={<InfoIconXs />} message={message} status="action" type="outline" />
          )}
        </FunnelForm>
      </StageCard>
    </Root>
  );
};
