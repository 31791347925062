import { FC } from 'react';

import { WithMetaData } from '../../helpers/templateMapper';
import { useTemplateTracker } from '../../hooks';
import { StoryblokStoryType, TJobDetailType } from '../../types/storyblok';
import { Layout } from '../Layout';
import { MNavigationSecondary } from '../MNavigationSecondary';
import { OEditorial } from '../OEditorial';
import { OMedia } from '../OMedia';
import { OStageNarrow } from '../OStageNarrow';
import { OStandoutText } from '../OStandoutText';
import { OText } from '../OText';

export const TJobDetail: FC<WithMetaData<StoryblokStoryType<TJobDetailType>>> = ({
  content: {
    areaOfWork,
    breadcrumbTitle,
    button,
    contact,
    jobTitle,
    metaTitle,
    partials,
    secondaryNavigation,
    secondaryNavigationButtonIntroOnDesktop: isButtonIntroOnDesktop,
    stageNarrowTheme,
    standoutText,
    trackingPageArea,
  },
  parentPages,
  slug,
}) => {
  useTemplateTracker({
    pageArea: trackingPageArea ?? '',
    pageType: 'job-detail',
    pageTitle: metaTitle ?? '',
  });

  return (
    <Layout breadcrumbTitle={breadcrumbTitle} parentPages={parentPages} slug={slug}>
      {secondaryNavigation?.length === 1 && (
        <MNavigationSecondary {...secondaryNavigation[0]} isButtonIntroOnDesktop={isButtonIntroOnDesktop ?? false} />
      )}
      <OStageNarrow
        _uid=""
        component="o-stage-narrow"
        defineSubheadlineAsH1={true}
        textIntro={[
          {
            _uid: '8a986250',
            component: 'm-text-intro',
            headline: jobTitle,
            chip: areaOfWork,
            button,
          },
        ]}
        theme={stageNarrowTheme}
      />
      {partials?.map((item) =>
        item.component === 'o-media' ? <OMedia {...item} key={item._uid} /> : <OText {...item} key={item._uid} />,
      )}

      {contact?.length === 1 && (
        <OEditorial
          _uid=""
          button={[
            { _uid: 'd485311b', component: 'a-button', linkUrl: `mailto:${contact[0].email}`, text: contact[0].email },
          ]}
          component="o-editorial"
          headline={contact[0].name ?? ''}
          media={contact[0].image}
          paddingBottomDesktop="xl"
          paddingBottomMobile="m"
          paddingTopDesktop="xl"
          paddingTopMobile="m"
          tagline={contact[0].tagline}
          text={contact[0].description ?? ''}
          theme="faded-gray"
        />
      )}

      <OStandoutText {...standoutText[0]} />
    </Layout>
  );
};
