import styled from '@lichtblick/styled';
import { Spacing } from '@lichtblick/theme';

export const StyledBulletItem = styled.li`
  display: flex;
  &::before {
    content: '\\2022';
    margin-right: ${Spacing.Xxs};
  }
`;
