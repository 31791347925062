type GetNonCommodityProductsParams = {
  campaign: string;
  productCodes: string[];
};

export const getNonCommodityProducts = async ({ campaign, productCodes }: GetNonCommodityProductsParams) => {
  const { sdk } = await import('../../graphqlClient');

  const response = await sdk.getNonCommodityProducts({
    campaign,
    productCodes,
  });

  return response;
};
