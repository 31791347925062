import styled from '@lichtblick/styled';
import { BorderRadius, Colors, MediaSmallAndAbove, Spacing } from '@lichtblick/theme';
import { Headline } from '@lichtblick/ui-components';

export const TechnicalDetails = styled.div`
  margin-bottom: ${Spacing.Xxl};
  margin-top: ${Spacing.Xxl};

  ${MediaSmallAndAbove} {
    margin-bottom: ${Spacing['3Xl']};
    margin-top: ${Spacing['3Xl']};
  }
`;

export const StyledHeadline = styled(Headline)`
  background-color: ${Colors.White};
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
`;

export const ItemsList = styled.div`
  border: 2px solid ${Colors.LightGray};
  border-radius: ${BorderRadius.S};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: ${Spacing.M} ${Spacing.Xs};
  position: relative;
  top: 1.875rem;
`;
