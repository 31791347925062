import { FC } from 'react';

import { Spacing } from '@lichtblick/theme';
import { AppBadges, Box } from '@lichtblick/ui-components';

import { componentMapper } from '../../helpers/componentMapper';
import { OStageSplitType } from '../../types/storyblok';
import { getColor, getTheme } from '../../utils';
import { MTextIntro } from '../MTextIntro';
import { GridColumn, GridContainer, GridRow } from '../shared';

export const OStageSplit: FC<OStageSplitType> = ({
  appStoreUrl,
  defineSubheadlineAsH1: hasSubheadlineAsH1,
  media,
  playStoreUrl,
  textIntro,
  theme,
}) => (
  <Box
    alignItems="center"
    backgroundColor={getColor(theme)}
    data-testid="stage-full"
    display="flex"
    gap={Spacing.Xs}
    pxd={Spacing.Xl}
    pyd={Spacing.Xl}
    pym={Spacing.S}
  >
    <GridContainer>
      <GridRow $isAlignedCenter={true} $isReversed={true}>
        {media?.[0] && <GridColumn $width={4}>{componentMapper(media[0])}</GridColumn>}

        <GridColumn $width={8}>
          {textIntro?.[0] && (
            <MTextIntro
              {...textIntro[0]}
              accentColor={getTheme(theme).primary}
              buttonVariant="primary"
              headlineProps={{ renderAs: hasSubheadlineAsH1 ? 'div' : 'h1', size: 'L' }}
              textProps={{ size: 'S', $isOStageSplit: true }}
            />
          )}

          {(appStoreUrl || playStoreUrl) && (
            <AppBadges appStoreUrl={appStoreUrl ?? undefined} playStoreUrl={playStoreUrl ?? undefined} />
          )}
        </GridColumn>
      </GridRow>
    </GridContainer>
  </Box>
);
