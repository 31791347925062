import styled, { css } from '@lichtblick/styled';
import {
  BorderRadius,
  Colors,
  MediaMediumAndAbove,
  MediaSmallAndAbove,
  Spacing,
  placeholderElement,
} from '@lichtblick/theme';
import { Headline, Image, focusRing } from '@lichtblick/ui-components';

import { ColorKey, getColor } from '../../utils';
import { MBulletList } from '../MBulletList';

export const ProductComparisonCard = styled.div`
  background-color: transparent;

  ${({ theme }) =>
    theme === 'white' &&
    css`
      background-color: ${Colors.FadedGray};
    `}
  padding: 0 ${Spacing.Xxs};
  text-align: center;
  width: 100%;

  ${MediaSmallAndAbove} {
    width: 18rem;
  }
`;

export const Curtain = styled.div`
  background-color: transparent;
  color: ${Colors.White};
  margin-top: -6.25rem;
  padding: ${Spacing.Xs} 0 calc(3 / 4 * 20% + ${Spacing.Xxs});
  position: sticky;
  top: 0;
  z-index: 10;

  ${MediaSmallAndAbove} {
    padding: ${Spacing.M} 0 calc(3 / 4 * 5% + ${Spacing.S});
  }
`;

export const Dropdown = styled.div<{ $theme?: ColorKey | null }>`
  &::before {
    background-image: linear-gradient(270deg, ${({ $theme }) => getColor($theme)} 30%, rgba(255, 255, 255, 0) 100%);
    content: '';
    height: ${Spacing.S};
    pointer-events: none;
    position: absolute;
    right: 2rem;
    top: ${Spacing.Xxs};
    width: ${Spacing.S};
  }

  & > select {
    appearance: none;
    background: ${({ $theme }) => getColor($theme)};
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 6'><path stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' color='red' fill='none' d='M1 1.82837L3.82843 4.6568L6.65685 1.82837'/></svg>");
    background-position: right 0.75rem bottom ${Spacing.Xs};
    background-repeat: no-repeat;
    background-size: ${Spacing.Xxs};
    border: 2px solid ${Colors.White};
    border-radius: ${BorderRadius.S};
    color: ${Colors.White};
    font-size: ${Spacing.Xs};
    height: 2.688rem;
    padding: ${Spacing.Xxs} 1.875rem ${Spacing.Xxs} ${Spacing.Xs};
    width: 100%;

    &:focus {
      ${focusRing}
    }
  }

  display: block;
  margin: 0 auto;
  position: relative;
  width: 65%;

  ${MediaSmallAndAbove} {
    display: none;
  }
`;

export const HeadlineWrapper = styled.div`
  display: none;
  position: relative;

  ${MediaSmallAndAbove} {
    display: block;
  }
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: 0;
`;

export const ImageWrapper = styled.div`
  height: 0;
  margin-top: calc(-3 / 4 * -5%);
  padding-top: calc(3 / 4 * 100%);
  position: relative;
  width: 100%;
`;

export const ImageContent = styled.div`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

export const StyledImage = styled(Image)<{ isSelected: boolean }>`
  align-items: center;
  display: flex;
  height: ${({ isSelected }) => (isSelected ? '100%' : '0')};
  justify-content: center;
  width: 100%;

  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
  }
`;

export const ColorSelectorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${Spacing.Xs};

  ${MediaSmallAndAbove} {
    margin-top: ${Spacing.S};
  }
`;

export const Price = styled.div<{ $hasPrice: boolean }>`
  margin-top: ${Spacing.Xs};

  ${({ $hasPrice }) =>
    !$hasPrice &&
    css`
      ${placeholderElement()}
    `}

  ${MediaSmallAndAbove} {
    margin-top: ${Spacing.S};
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: ${Spacing.Xs};
  margin-top: ${Spacing.S};
  min-height: 2.699rem;

  ${MediaMediumAndAbove} {
    min-height: 2.912rem;
  }
`;

export const ListHeadlineWrapper = styled.div`
  background-color: ${Colors.White};
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
`;

export const ListSubHeadlineWrapper = styled.div`
  background-color: ${Colors.White};
  color: ${Colors.DarkGray};
  left: 50%;
  margin-top: ${Spacing.M};
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
`;

export const StyledList = styled(MBulletList)`
  border: 2px solid ${Colors.LightGray};
  border-radius: ${BorderRadius.S};
  padding: ${Spacing.M} ${Spacing.Xs};
  position: relative;
  text-align: left;
  top: 4.375rem;
`;
