import { FC } from 'react';

import { transient } from '@lichtblick/styled';

import { Root } from './Interferer.styles';

import { richTextMapper } from '../../../../helpers';
import { RichTextDocType } from '../../../../types';

export type InterfererProps = {
  text: RichTextDocType;
};

export const Interferer: FC<InterfererProps> = ({ text, ...rest }) => (
  <Root {...transient(rest)}>{richTextMapper(text)}</Root>
);
