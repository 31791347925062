import { FC, useCallback } from 'react';

import { useTracker } from '@lichtblick/tracker';
import { Text, TextCTA } from '@lichtblick/ui-components';

import { ColumnItemContainer, StyledHeadline } from './MColumnItem.styles';

import { buildUrl } from '../../helpers';
import { componentMapper } from '../../helpers/componentMapper';
import { MColumnItemType } from '../../types/storyblok';

export type ColumnItemProps = MColumnItemType & {
  columnAlignment?: string | null;
  columnWidth?: string | null;
};

export const MColumnItem: FC<ColumnItemProps> = ({
  columnAlignment,
  columnWidth,
  headline,
  link,
  linkLabel,
  media,
  text,
  textFirst = false,
}) => {
  const { trackCustomEvent } = useTracker();

  const linkClickHandler = useCallback(() => {
    trackCustomEvent({
      customProps: { navigation_area: 'Body', navigation_element: linkLabel },
      eventName: 'navigation',
    });
  }, [linkLabel, trackCustomEvent]);

  const href = link && buildUrl(link);

  return (
    <ColumnItemContainer $alignment={columnAlignment ?? ''} $width={columnWidth ?? ''} data-testid="column-item">
      {!textFirst && media?.[0] && componentMapper(media[0])}
      {headline && (
        <StyledHeadline renderAs="h3" size="S">
          {headline}
        </StyledHeadline>
      )}
      {text && <Text>{text}</Text>}
      {Boolean(textFirst) && media?.[0] && componentMapper(media[0])}
      {Boolean(href) && Boolean(linkLabel) && (
        <TextCTA as="a" href={href} onClick={linkClickHandler}>
          {linkLabel}
        </TextCTA>
      )}
    </ColumnItemContainer>
  );
};
