// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { FC } from 'react';

import { AButton } from './AButton';
import { AImage } from './AImage';
import { AListItem } from './AListItem';
import { ATechnicalDetailsItem } from './ATechnicalDetailsItem';
import { AToggle } from './AToggle';
import { AVideo } from './AVideo';
import { BenefitCards } from './eaas/BenefitCards';
import { CasesSlider } from './eaas/CasesSlider/CasesSlider';
import { CaseStudy } from './eaas/CaseStudy';
import { CompressedProductBundle } from './eaas/CompressedProductBundle';
import { FourSteps } from './eaas/FourSteps';
import { LeadForm } from './eaas/LeadForm';
import { Promo } from './eaas/Promo';
import { SolarCheckoutTeaser } from './eaas/SolarCheckoutTeaser';
import { SolarProductBundle } from './eaas/SolarProductBundle';
import { SolarSlider } from './eaas/SolarSlider/SolarSlider';
import { SolarStage } from './eaas/SolarStage';
import { StromWalletTextIntro } from './eaas/StromWalletTextIntro';
import { TabsWithTextIntros, TextIntroWithTabs } from './eaas/TabsWithTextIntros';
import { KWKForm } from './KWKForm/KWKForm';
import { MAccordionItem } from './MAccordionItem';
import { MAnimatedIllustration } from './MAnimatedIllustration';
import { MBreadcrumb } from './MBreadcrumb';
import { MColumnItem } from './MColumnItem';
import { MDownloadItem } from './MDownloadItem';
import { MEAutoTariffCard } from './MEAutoTariffCard';
import { MFactItem } from './MFactItem';
import { MFootnote } from './MFootnote';
import { MIframe } from './MIframe';
import { MIndexItem } from './MIndexItem';
import { MList } from './MList';
import { MLogoItem } from './MLogoItem';
import { MNavigationSecondary } from './MNavigationSecondary';
import { MProductCardTeaser } from './MProductCardTeaser';
import { MProductComparisonCard } from './MProductComparisonCard';
import { MProductOverviewCard } from './MProductOverviewCard';
import { MRoadToZeroItem } from './MRoadToZeroItem/MRoadToZeroItem';
import { MTable } from './MTable';
import { MTariffCard } from './MTariffCard';
import { MTeaserItem } from './MTeaserItem';
import { MTeaserListItem } from './MTeaserListItem';
import { MTechnicalDetails } from './MTechnicalDetails';
import { MTextIntro } from './MTextIntro';
import { MTextIntroPrice } from './MTextIntroPrice';
import { MYoutube } from './MYoutube';
import { OAccordion } from './OAccordion';
import { OAllInOneCalculator } from './OAllInOneCalculator';
import { OCarCalculator } from './OCarCalculator';
import { OCarousel } from './OCarousel';
import { OColumns } from './OColumns';
import { ODownload } from './ODownload';
import { OEAutoTariffComparison } from './OEAutoTariffComparison';
import { OEditorial } from './OEditorial';
import { OFactGrid } from './OFactGrid';
import { OFootnotes } from './OFootnotes';
import { OForm } from './OForm';
import { OHeyFlowForm } from './OHeyFlowForm';
import { OHubSpotForm } from './OHubSpotForm';
import { OIndex } from './OIndex';
import { OInfoteaser } from './OInfoteaser';
import { OList } from './OList';
import { OLogos } from './OLogos';
import { OMedia } from './OMedia';
import { OProductComparison } from './OProductComparison';
import { OProductOverview } from './OProductOverview';
import { ORoadToZero } from './ORoadToZero/ORoadToZero';
import { OSnowflake } from './OSnowflake';
import { OStageArticle } from './OStageArticle';
import { OStageFull } from './OStageFull';
import { OStageNarrow } from './OStageNarrow';
import { OStageSplit } from './OStageSplit';
import { OStandoutText } from './OStandoutText';
import { OStoryPage } from './OStoryPage';
import { OSuccessPageContent } from './OSuccessPageContent';
import { OSuccessRating } from './OSuccessRating';
import { OTable } from './OTable';
import { OTariffComparison } from './OTariffComparison';
import { OTeaserGrid } from './OTeaserGrid';
import { OTeaserGridArticles } from './OTeaserGridArticles';
import { OTeaserList } from './OTeaserList';
import { OText } from './OText';
import { OToast } from './OToast';
import { OWallboxConfigurator } from './OWallboxConfigurator';
import { SolarTeaser } from './SolarTeaser';
import { SvgIcon } from './SvgIcon/SvgIcon';

import { AllComponentNamesType, AllComponentsType } from '../types/storyblok';

export type ComponentMap = {
  [K in AllComponentNamesType]: FC<Extract<AllComponentsType, { component: K }>>;
};

export const COMPONENTS_MAP: ComponentMap = {
  'a-button': AButton,
  'a-image': AImage,
  'a-list-item': AListItem,
  'a-technical-details-item': ATechnicalDetailsItem,
  'a-toggle': AToggle,
  'a-video': AVideo,
  'm-accordion-item': MAccordionItem,
  'm-animated-illustration': MAnimatedIllustration,
  'm-breadcrumb': MBreadcrumb,
  'm-column-item': MColumnItem,
  'm-download-item': MDownloadItem,
  'm-eauto-tariff-card': MEAutoTariffCard,
  'm-fact-item': MFactItem,
  'm-footnote': MFootnote,
  'm-iframe': MIframe,
  'm-index-item': MIndexItem,
  'm-list': MList,
  'm-logo-item': MLogoItem,
  'm-navigation-secondary': MNavigationSecondary,
  'm-product-card-teaser': MProductCardTeaser,
  'm-product-comparison-card': MProductComparisonCard,
  'm-product-overview-card': MProductOverviewCard,
  'm-road-to-zero-item': MRoadToZeroItem,
  'm-table': MTable,
  'm-tariff-card': MTariffCard,
  'm-teaser-item': MTeaserItem,
  'm-teaser-list-item': MTeaserListItem,
  'm-technical-details': MTechnicalDetails,
  'm-text-intro-price': MTextIntroPrice,
  'm-text-intro': MTextIntro,
  'm-youtube': MYoutube,
  'o-accordion': OAccordion,
  'o-all-in-one-calculator': OAllInOneCalculator,
  'o-car-calculator': OCarCalculator,
  'o-carousel': OCarousel,
  'o-columns': OColumns,
  'o-download': ODownload,
  'o-eauto-tariff-comparison': OEAutoTariffComparison,
  'o-editorial': OEditorial,
  'o-fact-grid': OFactGrid,
  'o-footnotes': OFootnotes,
  'o-form': OForm,
  'o-heyflow-form': OHeyFlowForm,
  'o-hubspot-form': OHubSpotForm,
  'o-index': OIndex,
  'o-infoteaser': OInfoteaser,
  'o-list': OList,
  'o-logos': OLogos,
  'o-media': OMedia,
  'o-product-comparison': OProductComparison,
  'o-product-overview': OProductOverview,
  'o-road-to-zero': ORoadToZero,
  'o-snowflake': OSnowflake,
  'o-stage-article': OStageArticle,
  'o-stage-full': OStageFull,
  'o-stage-narrow': OStageNarrow,
  'o-stage-split': OStageSplit,
  'o-standout-text': OStandoutText,
  'o-story-page': OStoryPage,
  'o-success-page-content': OSuccessPageContent,
  'o-success-rating': OSuccessRating,
  'o-table': OTable,
  'o-tariff-comparison': OTariffComparison,
  'o-teaser-grid-articles': OTeaserGridArticles,
  'o-teaser-grid': OTeaserGrid,
  'o-teaser-list': OTeaserList,
  'o-text': OText,
  'o-toast': OToast,
  'o-wallbox-configurator': OWallboxConfigurator,
  'svg-icon': SvgIcon,

  'benefit-cards': BenefitCards,
  'case-study': CaseStudy,
  'cases-slider': CasesSlider,
  'compressed-product-bundle': CompressedProductBundle,
  'four-steps': FourSteps,
  'installion-promo': Promo,
  'kwk-form': KWKForm,
  'solar-checkout-teaser': SolarCheckoutTeaser,
  'solar-product-bundle': SolarProductBundle,
  'solar-slider': SolarSlider,
  'solar-stage': SolarStage,
  'solar-teaser': SolarTeaser,
  'stromwallet-text-intro': StromWalletTextIntro,
  'tabs-with-text-intros': TabsWithTextIntros,
  'text-intro-with-tabs': TextIntroWithTabs,
  'lead-form': LeadForm,
} as const;

export type ContentfulComponentProps = React.ComponentPropsWithoutRef<
  (typeof COMPONENTS_MAP)[keyof typeof COMPONENTS_MAP]
>;
