import styled, { css } from '@lichtblick/styled';
import {
  Breakpoints,
  Colors,
  DurationAndEasing,
  MediaLargeAndAbove,
  MediaMediumAndAbove,
  Opacities,
  Spacing,
  light,
} from '@lichtblick/theme';
import { LegacyLink, focusRing } from '@lichtblick/ui-components';

import { MTeaserItem } from '../MTeaserItem';
import { MTextIntro } from '../MTextIntro';

const iconStyles = css`
  background-image: ${({ theme = light }) =>
    `linear-gradient(to right, ${Colors.Orange}, ${Colors.Orange} 50%, ${theme.primary} 50%)`};
  background-size: 200% 100%;
  height: 1.5rem;
  transition:
    background-position ${DurationAndEasing.LargeOutro},
    transform ${DurationAndEasing.LargeOutro};
  width: 1.5rem;
  will-change: background-position, transform;
`;

export const Slider = styled.div`
  overflow: hidden;
  position: relative;
`;

export const SkipLink = styled(LegacyLink)`
  height: 1px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;

  &:focus {
    align-items: center;
    background-color: ${Colors.LightGray};
    color: ${Colors.Black};
    display: flex;
    height: 100%;
    justify-content: center;
    ${focusRing}
    opacity: ${Opacities.Overlay};
    width: 100%;
    z-index: 10;
  }
`;

export const Controls = styled.div`
  display: none;

  ${MediaMediumAndAbove} {
    display: flex;
    gap: ${Spacing.Xxs};
    justify-content: flex-start;
    margin-left: -${Spacing.Xxs};
    padding: ${Spacing.Xxs} 0;
    width: 100%;
  }
`;

export const Control = styled.div`
  cursor: pointer;
  overflow: visible;

  &:focus-visible {
    ${focusRing}
  }

  padding: ${Spacing.Xxs};

  &[aria-disabled='true'] {
    cursor: default;
    opacity: ${Opacities.Light};
  }
`;

export const PreviousIcon = styled.div`
  background-position: -100%;
  clip-path: path(
    'M7.41266 10.9999H19C19.5523 10.9999 20 11.4477 20 11.9999C20 12.5522 19.5523 12.9999 19 12.9999H7.4126L12.0713 17.6587C12.4618 18.0492 12.4618 18.6824 12.0713 19.0729C11.6808 19.4634 11.0476 19.4634 10.6571 19.0729L4.29314 12.7089C4.14206 12.5578 4.04943 12.3704 4.01524 12.1748C4.00522 12.118 4 12.0596 4 11.9999C4 11.9581 4.00257 11.9169 4.00756 11.8764C4.03367 11.6625 4.12883 11.4553 4.29304 11.2911L10.657 4.92717C11.0475 4.53665 11.6807 4.53665 12.0712 4.92717C12.4617 5.31769 12.4617 5.95086 12.0712 6.34138L7.41266 10.9999Z'
  );

  ${iconStyles}

  ${Control}:not([aria-disabled='true']):hover & {
    background-position: 0;
    transform: translate3d(-0.5rem, 0, 0);
  }
`;

export const NextIcon = styled.div`
  background-position: 100%;
  clip-path: path(
    'M16.5873 10.9999H5C4.44771 10.9999 4 11.4477 4 11.9999C4 12.5522 4.44771 12.9999 5 12.9999H16.5874L11.9287 17.6587C11.5382 18.0492 11.5382 18.6824 11.9287 19.0729C12.3192 19.4634 12.9524 19.4634 13.3429 19.0729L19.7069 12.7089C19.8579 12.5578 19.9506 12.3704 19.9848 12.1748C19.9948 12.118 20 12.0596 20 11.9999C20 11.9581 19.9974 11.9169 19.9924 11.8764C19.9663 11.6625 19.8712 11.4553 19.707 11.2911L13.343 4.92717C12.9525 4.53665 12.3193 4.53665 11.9288 4.92717C11.5383 5.31769 11.5383 5.95086 11.9288 6.34138L16.5873 10.9999Z'
  );

  ${iconStyles}

  ${Control}:not([aria-disabled='true']):hover & {
    background-position: 0;
    transform: translate3d(0.5rem, 0, 0);
  }
`;

export const StyledTextIntro = styled(MTextIntro)`
  margin-bottom: ${Spacing.M};

  ${MediaMediumAndAbove} {
    margin-bottom: ${Spacing.L};
  }
`;

export const StyledTeaserItem = styled(MTeaserItem)`
  margin: ${Spacing['Xxs']} 0 ${Spacing.M};
`;

export const Slides = styled.div`
  display: flex;
  margin: 0 -${Spacing.S};
  overflow-x: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  // Hides scrollbar in firefox
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::before,
  &::after {
    content: '';
    flex-basis: 1.5rem;
    flex-shrink: 0;
    scroll-snap-align: start;

    ${MediaMediumAndAbove} {
      flex-basis: 4.5rem;
    }

    ${MediaLargeAndAbove} {
      flex-basis: calc(((100% - ${Breakpoints.Large}px) / 2) + 3rem);
    }
  }
`;

export const SlideItem = styled.div`
  flex-shrink: 0;
  margin: 0 ${Spacing.Xs};
  /* stylelint-disable-next-line meowtec/no-px */
  max-width: 250px;
  /* stylelint-disable-next-line meowtec/no-px */
  min-width: 200px;
  scroll-snap-align: center;
  width: 15vw;
`;
