import styled from '@lichtblick/styled';
import { MediaMediumAndAbove, MediaBelowMedium, Spacing } from '@lichtblick/theme';
import { Loader } from '@lichtblick/ui-components';

import { MTextIntro } from '../MTextIntro';

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${Spacing.M};
`;

export const StyledLoader = styled(Loader)`
  ${MediaBelowMedium} {
    width: 4rem;
  }
`;

export const StyledTextIntro = styled(MTextIntro)`
  margin-bottom: ${Spacing.M};

  ${MediaMediumAndAbove} {
    margin-bottom: ${Spacing.L};
  }
`;
