import { Icon } from '@lichtblick/icons';
import styled from '@lichtblick/styled';
import { Colors, DurationAndEasing, FontWeights, MediaMediumAndAbove, Spacing, alpha } from '@lichtblick/theme';

import { AText } from '../AText';

export const Container = styled.nav`
  overflow: hidden;
  position: relative;

  &:before {
    background: linear-gradient(270deg, ${Colors.Black} 0%, ${alpha(Colors.Black, 0)} 100%);
    content: '';
    height: 100%;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 5rem;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Item = styled.li`
  align-items: center;
  display: flex;

  &:last-child {
    padding-right: 5rem;
  }
`;

export const Crumb = styled.a.attrs({ target: '_self' })`
  color: inherit;
  padding: ${Spacing.Xs} 0;
  font-size: 0.625rem;

  ${MediaMediumAndAbove} {
    padding: ${Spacing.S} 0;
    font-size: 0.75rem;
  }

  &:hover {
    opacity: 0.6;
    transition: ${DurationAndEasing.LargeOutro};
  }
`;

export const CrumbLabel = styled(AText).attrs({ renderAs: 'span', size: 'Xs' })`
  ${Item}:last-child & {
    font-weight: ${FontWeights.Bold};
  }
  white-space: nowrap;
`;

export const StyledIcon = styled(Icon).attrs({ name: 'ChevronRightIconXs' })`
  flex-shrink: 0;
  color: ${Colors.DarkGray};
  margin: 0 0.25rem;

  ${Item}:last-child > & {
    display: none;
  }
`;

export const StyledMBreadcrumbFooter = styled.div`
  background-color: ${Colors.Black};
  color: ${Colors.White};
  border-bottom: 1px solid ${alpha(Colors.White, 0.2)};
`;

export const StyledMBreadcrumbTArticle = styled.div`
  background-color: ${Colors.White};
  color: ${Colors.Black};
  border-bottom: 1px solid ${alpha(Colors.Gray, 0.2)};

  ${Container} {
    &:before {
      content: none;
    }
  }

  ${Crumb} {
    padding: ${Spacing.S} 0;
    overflow: hidden;
  }

  ${CrumbLabel} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.75rem;
  }

  ${Item}:last-child {
    padding-right: 0;
    overflow: hidden;
  }
`;
