import { FC, useContext } from 'react';

import { componentMapper } from '../../helpers/componentMapper';
import { SettingsContext } from '../../helpers/settingsContext';
import { WithMetaData } from '../../helpers/templateMapper';
import { useTemplateTracker } from '../../hooks';
import { OStageNarrowType, StoryblokStoryType, TPressDetailType } from '../../types/storyblok';
import { Layout } from '../Layout';
import { MNavigationSecondary } from '../MNavigationSecondary';
import { MTeaserListItemProps } from '../MTeaserListItem/MTeaserListItem';
import { OEditorial } from '../OEditorial';
import { OStageNarrow } from '../OStageNarrow';
import { OStandoutText } from '../OStandoutText';
import { OTeaserList } from '../OTeaserList';

export type TPressDetailProps = TPressDetailType & {
  listItems?: MTeaserListItemProps[];
};

export const TPressDetail: FC<WithMetaData<StoryblokStoryType<TPressDetailProps>>> = ({
  content: {
    _uid,
    articleTitle,
    author,
    breadcrumbTitle,
    category,
    download,
    listItems,
    metaTitle,
    partials,
    secondaryNavigation,
    secondaryNavigationButtonIntroOnDesktop: isButtonIntroOnDesktop,
    trackingPageArea,
  },
  parentPages,
  slug,
  uuid,
}) => {
  const oStageNarrowTextIntro: OStageNarrowType['textIntro'][number] = {
    headline: articleTitle,
    _uid: '32049b66',
    component: 'm-text-intro',
    chip: category,
  };

  useTemplateTracker({
    pageArea: trackingPageArea ?? '',
    pageType: 'press-detail',
    pageTitle: metaTitle ?? '',
  });

  const { pressDownloadButtonText, pressDownloadHeadline, pressRelatedArticlesHeadline, pressTeaser } =
    useContext(SettingsContext);

  return (
    <Layout breadcrumbTitle={breadcrumbTitle} parentPages={parentPages} slug={slug}>
      {secondaryNavigation?.length === 1 && (
        <MNavigationSecondary {...secondaryNavigation[0]} isButtonIntroOnDesktop={isButtonIntroOnDesktop} />
      )}
      <OStageNarrow _uid="" component="o-stage-narrow" textIntro={[oStageNarrowTextIntro]} theme="white" />
      {partials?.map((item) => componentMapper(item, _uid))}
      {download && (
        <OStandoutText
          _uid=""
          button={[
            {
              _uid: '5650ac9b',
              component: 'a-button',
              linkUrl: download,
              openAsDownload: true,
              text: pressDownloadButtonText,
            },
          ]}
          component="o-standout-text"
          headline={pressDownloadHeadline}
          theme="light-gray"
        />
      )}
      {author?.length === 1 && (
        <OEditorial
          _uid=""
          button={[
            {
              _uid: '5feb55e7',
              component: 'a-button',
              linkUrl: `mailto:${author?.[0].email}`,
              text: author?.[0].email,
            },
          ]}
          component="o-editorial"
          headline={author?.[0].name ?? ''}
          media={author?.[0].image}
          paddingBottomDesktop="xl"
          paddingBottomMobile="m"
          paddingTopDesktop="xl"
          paddingTopMobile="m"
          tagline={author?.[0].tagline}
          text={author?.[0].description ?? ''}
          theme="white"
        />
      )}
      <OTeaserList
        _uid={_uid}
        component="o-teaser-list"
        contentType="t-press-detail"
        excluded={[uuid]}
        filterByCategory={category}
        listItems={listItems}
        textIntro={[
          {
            _uid: '2db0f5bb',
            component: 'm-text-intro',
            headline: pressRelatedArticlesHeadline ?? '',
          },
        ]}
        theme="white"
      />
      {pressTeaser?.[0] && <OStandoutText {...pressTeaser[0]} />}
    </Layout>
  );
};
