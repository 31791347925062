import { replaceDiacritics } from '@lichtblick/localization';

export const cleanSearchString = (value: string): string =>
  replaceDiacritics(value.toLocaleLowerCase().trim())
    // remove whitespace around dashes
    .replace(/\s*-\s*/g, '-')
    // remove all characters that are not letters, digits, spaces or dashes
    .replace(/[^\w\s-]+/g, '')
    // join multiple white spaces to one
    .replace(/\s+/g, ' ');

export const doesValueMatchSearch = (value: string, search = ''): boolean => {
  let pattern = cleanSearchString(search);

  if (!pattern) {
    return true;
  }

  pattern = pattern.replace(/[ -]/g, (match) => (match === ' ' ? '[ -]' : '[ -]?'));
  pattern = pattern.replace(/(\w)(?=\w)/g, '$1-?');

  return new RegExp(pattern).test(cleanSearchString(value));
};
