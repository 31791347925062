import { ComponentPropsWithRef, ElementType, forwardRef, ReactNode } from 'react';

import { transient } from '@lichtblick/styled';

import {
  InfoIcon,
  Input,
  InputRow,
  LabelWrapper,
  LeftSlot,
  RightSlot,
  StyledSubLabel,
  SuffixText,
  Wrapper,
} from './Textfield.styles';

import { Tooltip } from '../../organisms/Tooltip';
import { FieldFooter, FieldFooterType } from '../FieldFooter/FieldFooter';
import { Label } from '../Label/Label';

export type InputElementProps = {
  isBold?: boolean;
  isCentered?: boolean;
};

export type TextfieldProps = Omit<ComponentPropsWithRef<'input'>, 'disabled'> &
  InputElementProps & {
    className?: string;
    'data-testid'?: string;
    icon?: ElementType;
    isDisabled?: boolean;
    label?: string;
    leftSlot?: ReactNode;
    onTooltipClick?: () => void;
    rightSlot?: ReactNode;
    statusLabel?: string;
    statusType?: FieldFooterType;
    subLabel?: string;
    suffixText?: string;
    tooltipContent?: ReactNode;
  };

/**
 * Generic text input component with some basic setup to be used as foundation for all kinds of text input types.
 * Ref needs to be forwarded to be compatible with form packages like react-hook-form.
 */
export const Textfield = forwardRef<HTMLInputElement, TextfieldProps>(
  (
    {
      className,
      'data-testid': testId,
      icon,
      id,
      isBold,
      isCentered,
      isDisabled,
      label,
      leftSlot,
      name,
      onTooltipClick,
      rightSlot,
      statusLabel,
      statusType,
      subLabel,
      suffixText,
      tooltipContent,
      ...props
    },
    ref,
  ) => {
    const inputId = id || name;
    const labelId = inputId && `${inputId}_label`;
    const statusId = inputId && `${inputId}_validationMessage`;

    return (
      <Wrapper className={className}>
        {Boolean(label || onTooltipClick || tooltipContent) && (
          <LabelWrapper>
            {Boolean(label) && (
              <Label htmlFor={inputId} id={labelId} isBold>
                {label}
                {Boolean(subLabel) && <StyledSubLabel size="Xs">{subLabel}</StyledSubLabel>}
              </Label>
            )}
            {(Boolean(onTooltipClick) || Boolean(tooltipContent)) && (
              <Tooltip content={tooltipContent}>
                <InfoIcon onClick={onTooltipClick} />
              </Tooltip>
            )}
          </LabelWrapper>
        )}
        <InputRow $isDisabled={isDisabled} $statusType={statusType}>
          {leftSlot && <LeftSlot>{leftSlot}</LeftSlot>}
          <Input
            {...props}
            {...transient({ isBold, isCentered })}
            $hasLeftSlot={Boolean(leftSlot)}
            $hasRightSlot={Boolean(rightSlot)}
            aria-describedby={statusId}
            aria-invalid={statusType === 'error'}
            aria-labelledby={labelId}
            data-testid={testId || inputId}
            disabled={isDisabled}
            id={inputId}
            name={name}
            ref={ref}
          />
          {Boolean(rightSlot || suffixText) && (
            <RightSlot>
              {Boolean(suffixText) && <SuffixText isBold>{suffixText}</SuffixText>}
              {rightSlot}
            </RightSlot>
          )}
        </InputRow>
        {Boolean(statusLabel) && (
          <FieldFooter fieldFooterLabel={statusLabel} fieldFooterType={statusType} icon={icon} id={statusId} />
        )}
      </Wrapper>
    );
  },
);
