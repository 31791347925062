import styled, { PickTransient } from '@lichtblick/styled';
import { Colors, MediaMediumAndAbove, darkColors } from '@lichtblick/theme';

import { BoxProps } from './Box';
import { spacing } from './Box.helpers';

export const Container = styled.div<PickTransient<BoxProps, keyof BoxProps>>`
  align-content: ${({ $alignContent }) => $alignContent};
  align-items: ${({ $alignItems }) => $alignItems};
  align-self: ${({ $alignSelf }) => $alignSelf};

  background-color: ${({ $backgroundColor }) => $backgroundColor};

  border: ${({ $border }) => $border};
  border-color: ${({ $borderColor }) => $borderColor};
  border-radius: ${({ $borderRadius }) => $borderRadius};
  border-style: ${({ $borderStyle }) => $borderStyle};
  border-width: ${({ $borderWidth }) => $borderWidth};

  bottom: ${({ $bottom }) => $bottom};

  color: ${({ $backgroundColor, $color }) =>
    $color || ($backgroundColor && darkColors.includes($backgroundColor as Colors)) ? Colors.White : 'inherit'};

  display: ${({ $display }) => $display};

  flex: ${({ $flex }) => $flex};
  flex-direction: ${({ $flexDirection }) => $flexDirection};
  flex-grow: ${({ $flexGrow }) => $flexGrow};
  flex-shrink: ${({ $flexShrink }) => $flexShrink};
  flex-wrap: ${({ $flexWrap }) => $flexWrap};

  gap: ${({ $gap }) => $gap};

  grid-area: ${({ $gridArea }) => $gridArea};
  grid-auto-columns: ${({ $gridAutoColumns }) => $gridAutoColumns};
  grid-auto-flow: ${({ $gridAutoFlow }) => $gridAutoFlow};
  grid-auto-rows: ${({ $gridAutoRows }) => $gridAutoRows};
  grid-column: ${({ $gridColumn }) => $gridColumn};
  grid-column-gap: ${({ $gridColumnGap }) => $gridColumnGap};
  grid-row: ${({ $gridRow }) => $gridRow};
  grid-row-gap: ${({ $gridRowGap }) => $gridRowGap};
  grid-template-areas: ${({ $gridTemplateAreas }) => $gridTemplateAreas};
  grid-template-columns: ${({ $gridTemplateColumns }) => $gridTemplateColumns};
  grid-template-rows: ${({ $gridTemplateRows }) => $gridTemplateRows};

  height: ${({ $height }) => $height};

  justify-content: ${({ $justifyContent }) => $justifyContent};
  justify-items: ${({ $justifyItems }) => $justifyItems};
  justify-self: ${({ $justifySelf }) => $justifySelf};

  left: ${({ $left }) => $left};

  /* default margin gets overwritten by mobile if defined */
  margin: ${({ $m, $mb, $mbm, $ml, $mlm, $mm, $mr, $mrm, $mt, $mtm, $mx, $mxm, $my, $mym }) =>
    spacing({ full: $mm, x: $mxm, y: $mym, bottom: $mbm, left: $mlm, right: $mrm, top: $mtm }) ??
    spacing({ full: $m, x: $mx, y: $my, bottom: $mb, left: $ml, right: $mr, top: $mt })};

  /* default padding gets overwritten by mobile if defined */
  padding: ${({ $p, $pb, $pbm, $pl, $plm, $pm, $pr, $prm, $pt, $ptm, $px, $pxm, $py, $pym }) =>
    spacing({ full: $pm, x: $pxm, y: $pym, bottom: $pbm, left: $plm, right: $prm, top: $ptm }) ??
    spacing({ full: $p, x: $px, y: $py, bottom: $pb, left: $pl, right: $pr, top: $pt })};

  position: ${({ $position }) => $position};
  right: ${({ $right }) => $right};
  text-align: ${({ $textAlign }) => $textAlign};
  top: ${({ $top }) => $top};

  width: ${({ $width }) => $width};
  z-index: ${({ $zIndex }) => $zIndex};

  ${MediaMediumAndAbove} {
    margin: ${({ $mbd, $md, $mld, $mrd, $mtd, $mxd, $myd }) =>
      spacing({ full: $md, x: $mxd, y: $myd, bottom: $mbd, left: $mld, right: $mrd, top: $mtd })};
    padding: ${({ $pbd, $pd, $pld, $prd, $ptd, $pxd, $pyd }) =>
      spacing({ full: $pd, x: $pxd, y: $pyd, bottom: $pbd, left: $pld, right: $prd, top: $ptd })};
  }
`;
