import { FC } from 'react';

import { Spacing } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

import { Grid, GridItem, StyledOFactGrid } from './OFactGrid.styles';

import { OFactGridType } from '../../types/storyblok';
import { getColor } from '../../utils';
import { MFactItem } from '../MFactItem';
import { MTextIntro } from '../MTextIntro';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

export const OFactGrid: FC<OFactGridType> = ({ accent, anchorId, items, textIntro, theme }) => {
  /* The fact grid always contains 3 items. To ensure correct item order in the grid, swap the 2nd and 3rd elements. */
  const orderedItems = items.length === 3 ? [items[0], items[2], items[1]] : items;

  return (
    <StyledOFactGrid>
      <Box backgroundColor={getColor(theme)} data-testid="fact-grid" pbd={Spacing.Xxs} ptd={Spacing.Xl} ptm={Spacing.L}>
        {anchorId && <AnchorMark anchorId={anchorId} />}

        <GridContainer>
          {textIntro?.[0] && (
            <GridRow>
              <GridColumn $push={2} $width={8}>
                <MTextIntro {...textIntro[0]} buttonVariant="link" isCenteredDesktop={true} isCenteredMobile={true} />
              </GridColumn>
            </GridRow>
          )}

          <GridRow>
            <GridColumn $width={12}>
              {
                <Grid>
                  {orderedItems.map((item, index) => {
                    return (
                      <GridItem $index={index} key={item._uid}>
                        {/* TODO: check if that fallback is what we want */}
                        <MFactItem {...item} backgroundColor={accent ?? 'white'} />
                      </GridItem>
                    );
                  })}
                </Grid>
              }
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Box>
    </StyledOFactGrid>
  );
};
