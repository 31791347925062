import { FC, useState } from 'react';

import { LegacyButton } from '@lichtblick/ui-components';

import { ButtonWrapper, Content, Root } from './Expander.styles';

import { componentMapper } from '../../helpers';
import { OIndexType } from '../../types/storyblok';

export type ExpanderProps = Pick<OIndexType, 'items'>;

export const Expander: FC<ExpanderProps> = ({ items }) => {
  const [isExpanderOpen, setIsExpanderOpen] = useState(false);

  return (
    <Root>
      <Content $isOpen={isExpanderOpen}>
        <ul>{items.filter(Boolean).map((item) => item && componentMapper(item, item._uid))}</ul>
      </Content>

      <ButtonWrapper>
        <LegacyButton onClick={() => setIsExpanderOpen(!isExpanderOpen)} variant="link">
          {isExpanderOpen ? 'Weniger anzeigen' : 'Mehr anzeigen'}
        </LegacyButton>
      </ButtonWrapper>
    </Root>
  );
};
