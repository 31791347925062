import { RefObject, useCallback, useEffect, useState } from 'react';

import { InfoIconXs } from '@lichtblick/icons/svg/info/info-xs';
import { useTracker } from '@lichtblick/tracker';
import { Headline, Modal, Stepper, Text, TextCTA } from '@lichtblick/ui-components';

import { StyledModalContent } from './CalculatorLayout.styles';
import { StyledCalculatorInputRow, StyledCalculatorUsageInput } from './CalculatorUsageInput.styles';

type ChargingUsageInputProps = {
  onChange: (value: string, mileage: number) => void;
  usageRef: RefObject<HTMLInputElement>;
};

export const ChargingUsageInput: React.FC<ChargingUsageInputProps> = ({ onChange, usageRef }) => {
  const { trackCustomEvent } = useTracker();
  const [mileage, setMileage] = useState<number>(13000);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleStepperChange = useCallback(
    (mileage: number | undefined) => {
      if (mileage) {
        setMileage(mileage);

        if (usageRef.current) {
          usageRef.current.value = (Math.round((0.25 * 0.85 * mileage) / 100) * 100).toString();
          onChange(usageRef.current.value, mileage);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setMileage],
  );

  useEffect(() => {
    handleStepperChange(13000);
  }, [handleStepperChange]);

  return (
    <div>
      <Stepper
        label="Fahrleistung in km/Jahr"
        min={1}
        name="mileage"
        onChange={handleStepperChange}
        onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
        step={100}
        value={mileage}
      />
      <StyledCalculatorInputRow>
        <Text size="Xs">
          Dein Verbrauch{' '}
          <StyledCalculatorUsageInput
            $hasError={usageRef.current?.checkValidity() === false}
            aria-invalid={usageRef.current?.checkValidity() === false}
            aria-label="Verbrauch in kWh/Jahr"
            name="carUsage"
            onChange={(event) => onChange(event.target.value, mileage)}
            onClick={() => trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)}
            onInvalid={(e) => e.preventDefault()}
            ref={usageRef}
            required
          />{' '}
          kWh/Jahr
        </Text>
        <TextCTA
          ariaLabel="Informationen zur Berechnung des Autoverbrauchs zeigen"
          as="button"
          icon={<InfoIconXs />}
          onClick={() => setIsInfoModalOpen(true)}
          type="button"
        />
      </StyledCalculatorInputRow>

      <Modal
        footerContent={
          <TextCTA as="button" data-testid="button-modal-close" onClick={() => setIsInfoModalOpen(false)}>
            Ok, verstanden
          </TextCTA>
        }
        isOpen={isInfoModalOpen}
        newApi
        onPointerDownOutside={() => setIsInfoModalOpen(false)}
      >
        <StyledModalContent>
          <Headline renderAs={'h2'} size="S">
            So berechnet LichtBlick den Verbrauchsanteil für euer E-Auto
          </Headline>
          <Text renderAs={'p'}>
            Solltet ihr euren Jahresverbruach für das Laden des E-Autos zu Hause kennen, könnt ihr es direkt eintragen.
            Besitzt ihr noch keine längeren Erfahrungswerte, schätzen wir den Verbrauch für euch: Bis zu 85 % der
            E-Auto-Ladevorgänge finden im privaten Umfeld statt, beispielsweise am eigenen Stellplatz. Für den
            durchschnittlichen Stromverbrauch gehen wir von 0,25 kWh/km aus. Der tatsächliche Verbrauch hängt – neben
            dem Automodell – auch von der Fahrweise und den Wetterbedingungen ab. Daraus ergibt sich die Rechnung:
          </Text>
          <Text renderAs={'p'}>Verbrauch = Fahrleistung pro Jahr x 85 % zu Hause Laden x 0,25 kWh/km.</Text>
          <Text renderAs={'p'}>Dieser Wert wird verwendet, wenn ihr die Jahreskilometer für das E-Auto angebt.</Text>
        </StyledModalContent>
      </Modal>
    </div>
  );
};
