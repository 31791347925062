import styled, { css } from '@lichtblick/styled';
import { MediaMediumAndAbove, Spacing } from '@lichtblick/theme';
import { Headline, StyledTextCTA } from '@lichtblick/ui-components';

import { getColumnWidth } from '../shared/GridContainer/GridContainer';

export const ColumnItemContainer = styled.div<{ $alignment?: string; $width?: string }>`
  flex: 0 0 100%;
  margin: 0 0 ${Spacing.M};

  ${({ $alignment }) =>
    $alignment === 'center' &&
    css`
      text-align: center;
    `}

  ${MediaMediumAndAbove} {
    margin: 0 ${Spacing.Xxs} ${Spacing.M};

    ${({ $width }) =>
      $width === 'wide' &&
      css`
        flex-basis: calc(${getColumnWidth(5)} - 1rem);
      `}

    ${({ $width }) =>
      $width === 'narrow' &&
      css`
        flex-basis: calc(${getColumnWidth(4)} - 1rem);
      `}
  }

  ${StyledTextCTA} {
    margin-top: ${Spacing.Xxs};
  }
`;

export const StyledHeadline = styled(Headline)`
  margin: ${Spacing.Xs} 0 ${Spacing.Xxs};

  &:first-child {
    margin-top: 0;
  }
`;
