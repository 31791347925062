import { useEffect } from 'react';

import { initTracking } from '@lichtblick/contentful';
import { useTracker } from '@lichtblick/tracker';

export const ReactComponentTracking: React.FC<{ path: string }> = ({ path }) => {
  const { trackCustomEvent } = useTracker();

  useEffect(() => {
    return initTracking(trackCustomEvent);
  }, [path, trackCustomEvent]);

  return null;
};
