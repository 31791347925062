import styled from '@lichtblick/styled';
import { MediaMediumAndAbove, Spacing } from '@lichtblick/theme';

export const Wrapper = styled.div`
  margin-bottom: ${Spacing.M};

  ${MediaMediumAndAbove} {
    margin-bottom: ${Spacing.L};
  }
`;
