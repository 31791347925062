import { TrackingContextValue } from '@lichtblick/tracker';

import { extractErrorMessages, transformErrorMessage } from './errors';

export const formTrackingInitializer = (track: TrackingContextValue['trackCustomEvent']) => {
  const handlerFormFocus = (e: Event | CustomEvent) => {
    if ('detail' in e) {
      const { category } = e.detail;

      track({ eventName: 'form_open', customProps: { form_name: getFormName(category) } });
    }
  };

  const handleFormSubmit = (e: Event | CustomEvent) => {
    if ('detail' in e) {
      const { category, errors, touched, values } = e.detail;
      const errorMessage = transformErrorMessage(extractErrorMessages(errors, touched));

      if (errorMessage) {
        track({
          eventName: 'form_failed',
          customProps: {
            form_name: getFormName(category),
            error_message: errorMessage,
          },
        });
      } else {
        const product = getFormProduct(category, values);

        if (product) {
          track({ eventName: `form_submit_B2B_${product}`, customProps: { unique_id: undefined } });
        } else if (category === 'cancellation') {
          track({ eventName: 'form_submit_cancel_contract' });
        }
      }
    }
  };

  const getFormName = (formType: string): string => {
    switch (formType) {
      case 'B2B':
        return 'B2B_Power_Gas';
      case 'energieManager':
        return 'B2B_EnergieManager';
      case 'cancellation':
        return 'Cancel contract';
      default:
        return formType;
    }
  };

  const getFormProduct = (category: string, values: Record<string, any>): string => {
    const { requestElectro, requestGas } = values;

    switch (category) {
      case 'B2BGas': {
        if (requestElectro?.length) {
          return 'Power_Gas';
        }

        return 'Gas';
      }
      case 'B2BElectricity': {
        if (requestGas?.length) {
          return 'Power_Gas';
        }

        return 'Power';
      }
      case 'B2B': {
        if (requestElectro?.length && requestGas?.length) {
          return 'Power_Gas';
        }

        if (requestElectro?.length) {
          return 'Power';
        }

        if (requestGas?.length) {
          return 'Gas';
        }

        return 'No_Products';
      }
      case 'energieManager':
        return 'EnergieManager';
      default:
        return '';
    }
  };

  document.addEventListener('trackFormOnFocus', handlerFormFocus);
  document.addEventListener('trackFormAfterSubmit', handleFormSubmit);

  return () => {
    document.removeEventListener('trackFormOnFocus', handlerFormFocus);
    document.removeEventListener('trackFormAfterSubmit', handleFormSubmit);
  };
};
