import { FC } from 'react';

import { MNavigationSecondary } from '../../components/MNavigationSecondary';
import { componentMapper } from '../../helpers/componentMapper';
import { WithMetaData } from '../../helpers/templateMapper';
import { useTemplateTracker } from '../../hooks';
import { StoryblokStoryType, TProductType } from '../../types/storyblok';
import { Layout } from '../Layout';
import { OFootnotes } from '../OFootnotes';

export const TProduct: FC<WithMetaData<StoryblokStoryType<TProductType>>> = ({
  content: {
    breadcrumbTitle,
    footnotes,
    metaTitle,
    partials,
    secondaryNavigation,
    secondaryNavigationButtonIntroOnDesktop: isButtonIntroOnDesktop,
    stage,
    trackingPageArea,
  },
  parentPages,
  slug,
}) => {
  useTemplateTracker({
    pageArea: trackingPageArea ?? '',
    pageType: 'product',
    pageTitle: metaTitle ?? '',
  });

  return (
    <Layout breadcrumbTitle={breadcrumbTitle} parentPages={parentPages} slug={slug}>
      {secondaryNavigation?.length === 1 && (
        <MNavigationSecondary {...secondaryNavigation[0]} isButtonIntroOnDesktop={isButtonIntroOnDesktop} />
      )}
      {stage?.length === 1 && componentMapper(stage[0])}

      {partials?.map((item) => {
        if (!item) {
          return null;
        }

        return componentMapper(item, item._uid);
      })}
      {Boolean(footnotes?.length) && <OFootnotes footnotes={footnotes} />}
    </Layout>
  );
};
