import { LegacyLink } from '@lichtblick/ui-components';

import { LinkItem, StyledIcon } from './ALinkItem.styles';

type ALinkItemProps = {
  className?: string;
  linkUrl: string;
  title: string;
};

export const ALinkItem = ({ className, linkUrl, title }: ALinkItemProps) => (
  <LinkItem className={className}>
    <StyledIcon name="ArrowRightIconXs" />
    <div>
      <LegacyLink href={linkUrl} variant="thin">
        {title}
      </LegacyLink>
    </div>
  </LinkItem>
);
