import { FC } from 'react';

import { useTracker } from '@lichtblick/tracker';

import { StyledPrimarySubNavigation } from './PrimarySubNavigation.styles';

import { TextCTA } from '../../atoms/TextCTA/TextCTA';
import { NavigationItemProps } from '../../types';

export const PrimarySubNavigation: FC<NavigationItemProps> = ({ href, shouldOpenInNewTab, text, tracking }) => {
  const { trackCustomEvent } = useTracker();

  return (
    <StyledPrimarySubNavigation>
      <TextCTA
        as="a"
        href={href}
        onClick={() => trackCustomEvent(tracking)}
        tabIndex={href ? undefined : 0}
        target={shouldOpenInNewTab ? '_blank' : ''}
      >
        {text}
      </TextCTA>
    </StyledPrimarySubNavigation>
  );
};
