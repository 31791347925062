import { FormEventHandler, useCallback, useEffect, useRef, useState } from 'react';

import { logger } from '@lichtblick/logger';
import { Spacing } from '@lichtblick/theme';
import { Box, BoxCTA, CheckboxInput, Text } from '@lichtblick/ui-components';

import { StyledKWKForm } from './KWKForm.styles';
import { Textfield } from './Textfield';

import { richTextMapper } from '../../helpers';
import { ElementMappers } from '../../helpers/richTextMapper';
import { KwkFormType } from '../../types';
import { CustomerContactFormStatus } from '../../types/generated.lichtblick';
import { OInfoteaser } from '../OInfoteaser';
import { GridContainer, GridRow, GridColumn } from '../shared';

const richTextMapperOverride: Partial<ElementMappers> = {
  p: ({ children }) => (
    <Text hasSpacing renderAs="p" size="M">
      {children}
    </Text>
  ),
} as const;

export const KWKForm: React.FC<KwkFormType> = ({
  aknowledgement,
  infoTeaser,
  intro,
  optin,
  privacyNote,
  successTeaser,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [contractId, setContractId] = useState<string>('');
  const [meterId, setMeterId] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [missingOptin, setMissingOptin] = useState(false);

  const [majaId, setMajaId] = useState<string | undefined | null>(undefined);
  const [williId, setWilliId] = useState<string | undefined | null>(undefined);

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    setContractId(urlParams.get('contractId') ?? '');
    setMeterId(urlParams.get('meterId') ?? '');
    setEmail(urlParams.get('email') ?? '');

    import('../../api/lichtblick/graphqlClient').then(({ sdk }) =>
      sdk.getContactFormReasons({ token: Date.now().toString() }).then((res) => {
        setMajaId(res.contactFormReasons.majaId);
        setWilliId(res.contactFormReasons.williId);
      }),
    );
  }, [setContractId, setMeterId, setEmail, setMajaId, setWilliId]);

  const submit = useCallback<FormEventHandler<HTMLFormElement>>(
    async (e) => {
      e.preventDefault();
      setLoading(true);

      const formData = new FormData(e.currentTarget);

      const email = formData.get('email') as string;
      const meterId = formData.get('meterId') as string;
      const contractId = Number(formData.get('contractId'));

      const { sdk } = await import('../../api/lichtblick/graphqlClient');

      const response = await sdk.submitCategorizedContactForm({
        contactFormInput: {
          applicableToWebsiteForLead: false,
          categoryKey: 'OFFSHORE_LEVY_APPLICATION',
          contractId,
          description: `Optin:${optin}`,
          email,
          lastName: 'Unknown',
          firstName: 'Unknown',
          isB2C: true,
          isServiceView: false,
          majaId,
          meterId,
          title: 'Antrag für den Bezug der KWK/Offshore-Umlage',
          williId,
        },
      });

      if (response.categorizedContactForm.status === CustomerContactFormStatus.Success) {
        setSubmitted(true);

        logger.info('KWKForm: Successful submit', response);

        return;
      }

      setLoading(false);
      logger.warn('KWKForm: Failed to submit', response);
    },
    [setSubmitted, setLoading, optin, majaId, williId],
  );

  useEffect(() => {
    if (!submitted) {
      return;
    }

    containerRef.current?.scrollIntoView({ block: 'center' });
  }, [submitted, containerRef]);

  return submitted ? (
    <div ref={containerRef}>
      <OInfoteaser {...successTeaser[0]} />
    </div>
  ) : (
    <>
      {infoTeaser?.length === 1 && <OInfoteaser {...infoTeaser[0]} />}
      <Box pbd={Spacing.L} pbm={Spacing.M}>
        <GridContainer>
          <GridRow>
            <GridColumn $push={2} $width={8}>
              <StyledKWKForm onSubmit={submit}>
                {richTextMapper(intro, richTextMapperOverride)}

                <Textfield
                  initialValue={contractId}
                  label="Vertragsnummer"
                  maxLength={8}
                  name="contractId"
                  pattern="\d{7,8}"
                  required
                />
                <Textfield
                  initialValue={meterId}
                  label="Zählernummer"
                  maxLength={40}
                  name="meterId"
                  required
                  type="text"
                />
                <Textfield initialValue={email} label="E-Mail-Adresse" name="email" required type="email" />

                <div>{richTextMapper(aknowledgement, richTextMapperOverride)}</div>

                <CheckboxInput
                  hasError={missingOptin}
                  label={optin}
                  name="optin"
                  onChange={(e) => {
                    setMissingOptin(!e.currentTarget.validity.valid);
                  }}
                  onInvalid={(e) => {
                    e.preventDefault();
                    setMissingOptin(!e.currentTarget.validity.valid);
                  }}
                  required
                  statusLabel={missingOptin ? 'Hier fehlt noch eine Angabe' : undefined}
                />

                <BoxCTA as="button" isDisabled={!majaId || !williId} isLoading={loading} type="submit">
                  Absenden
                </BoxCTA>
                {richTextMapper(privacyNote)}
              </StyledKWKForm>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Box>
    </>
  );
};
