import { FC } from 'react';

import { Colors } from '@lichtblick/theme';

import { ContainerColumn, OptionalComponentsWrapper } from './SolarProductBundle.styles';

import { SolarProductBundleType } from '../../../types';
import { getColor } from '../../../utils';
import { MTextIntro } from '../../MTextIntro';
import { MProductItemList, MProductCardOptional } from '../shared/Card';
import { Section } from '../shared/Section';

export const SolarProductBundle: FC<SolarProductBundleType> = ({
  cta,
  productListItem1,
  productListItem2,
  productListItem3,
  productListItem4,
  productListItemOptional1,
  productListItemOptional2,
  textIntro,
  theme = 'faded-gray',
}) => {
  const backgroundColor = theme === 'white' ? Colors.FadedGray : Colors.White;

  return (
    <Section backgroundColor={getColor(theme)}>
      <ContainerColumn>
        {textIntro && <MTextIntro {...textIntro?.[0]} />}
        <MProductItemList
          backgroundColor={backgroundColor}
          cta={cta}
          href="/zuhause-checkout/"
          items={[productListItem1?.[0], productListItem2?.[0], productListItem3?.[0], productListItem4?.[0]]}
        />
        <OptionalComponentsWrapper>
          {productListItemOptional1?.[0] && (
            <MProductCardOptional backgroundColor={backgroundColor} item={productListItemOptional1?.[0]} />
          )}
          {productListItemOptional2?.[0] && (
            <MProductCardOptional backgroundColor={backgroundColor} item={productListItemOptional2?.[0]} />
          )}
        </OptionalComponentsWrapper>
      </ContainerColumn>
    </Section>
  );
};
