import Script from 'next/script';
import { useState } from 'react';

import { Container, Loader } from '@lichtblick/ui-components';

import { FormContainer, LoaderContainer } from './OHeyFlowForm.styles';

export const OHeyFlowForm = () => {
  const [scriptLoaded, setScriptLoaded] = useState(false);

  return (
    <>
      <Script
        async
        defer
        onReady={() => setScriptLoaded(true)}
        src="https://assets.prd.heyflow.com/builder/widget/latest/webview.js"
        type="text/javascript"
      />

      <Container>
        {scriptLoaded ? (
          <FormContainer>
            <div
              dangerouslySetInnerHTML={{
                __html: `
                  <heyflow-wrapper
                  flow-id="geig-konfigurator"
                  dynamic-height
                  scroll-up-on-navigation
                  style-config='{"width": "100%"}'
                  ></heyflow-wrapper>
                `,
              }}
            />
          </FormContainer>
        ) : (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
      </Container>
    </>
  );
};
