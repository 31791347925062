import { PropsWithChildren, ReactNode, useEffect, useState } from 'react';

import { Colors } from '@lichtblick/theme';
import { useTracker } from '@lichtblick/tracker';
import { Chip } from '@lichtblick/ui-components/atoms/Chip/Chip';
import { Text } from '@lichtblick/ui-components/atoms/Text/Text';
import { RadioButton } from '@lichtblick/ui-components/molecules/Radio/RadioButton.styles';
import {
  AccordionContent,
  AccordionHeader,
  AccordionTrigger,
} from '@lichtblick/ui-components/organisms/Accordion/Accordion.styles';

import { StyledCalculatorAccordionItem } from './CalculatorLayout.styles';

export type CalculatorLayout = PropsWithChildren & {
  chip?: string;
  headerColor: Colors;
  headline?: string;
  icon: ReactNode;
  id: string;
  isActionTriggerDisabled?: boolean;
};

export const CalculatorLayout: React.FC<CalculatorLayout> = ({
  children,
  chip,
  headerColor,
  headline,
  icon,
  id,
  isActionTriggerDisabled,
}) => {
  const { trackCustomEvent } = useTracker();
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  return (
    <StyledCalculatorAccordionItem $headerColor={headerColor} value={id}>
      <AccordionHeader asChild data-testid="accordion-header">
        <AccordionTrigger
          as={isActionTriggerDisabled ? 'div' : undefined}
          onClick={() =>
            !isActionTriggerDisabled &&
            trackCustomEvent({ eventName: 'tariff_calculator_interaction' }, undefined, true)
          }
        >
          {!isActionTriggerDisabled && <RadioButton $state="default" as="div" />}
          <Text isBold size="L">
            {headline}
          </Text>
          {Boolean(chip) && <Chip backgroundColor={Colors.White} label={chip} textColor={Colors.Black} />}
          {icon}
        </AccordionTrigger>
      </AccordionHeader>
      <AccordionContent $isAnimationDisabled={firstRender}>{children}</AccordionContent>
    </StyledCalculatorAccordionItem>
  );
};
