import { FC, ReactNode } from 'react';

import {
  TextIntroChip as Chip,
  TextIntroHeadline as Headline,
  TextIntroText as Text,
  StyledTextIntro,
} from './TextIntro.styles';

import { ChipProps } from '../../atoms/Chip/Chip';
import { HeadlineProps } from '../../atoms/Headline/Headline';
import { TextProps } from '../../atoms/Text/Text';

export type TextIntroProps = {
  children?: ReactNode;
  chipProps?: ChipProps;
  className?: string;
  headline?: string;
  headlineProps?: HeadlineProps;
  isCenteredDesktop?: boolean;
  isCenteredMobile?: boolean;
  text?: ReactNode;
  textProps?: TextProps & { $isOStageSplit?: boolean };
};

export const TextIntro: FC<TextIntroProps> = ({
  children,
  chipProps,
  className,
  headline,
  headlineProps,
  isCenteredDesktop,
  isCenteredMobile,
  text,
  textProps,
}) => (
  <StyledTextIntro $isCenteredDesktop={isCenteredDesktop} $isCenteredMobile={isCenteredMobile} className={className}>
    {chipProps && <Chip {...chipProps} />}
    {Boolean(headline) && (
      <Headline renderAs="h2" {...headlineProps}>
        {headline}
      </Headline>
    )}
    {text && <Text {...textProps}>{text}</Text>}
    {children}
  </StyledTextIntro>
);
