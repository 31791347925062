import { FC } from 'react';

import { ItemsList, StyledHeadline, TechnicalDetails } from './MTechnicalDetails.styles';

import { MTechnicalDetailsType } from '../../types/storyblok';
import { ATechnicalDetailsItem } from '../ATechnicalDetailsItem';

export const MTechnicalDetails: FC<MTechnicalDetailsType> = ({ _uid, headline, items }) => {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <TechnicalDetails key={_uid}>
      {headline && (
        <StyledHeadline renderAs={'h3'} size="S">
          {headline}
        </StyledHeadline>
      )}
      <ItemsList>
        {items.map((item) => (
          <ATechnicalDetailsItem {...item} key={_uid} />
        ))}
      </ItemsList>
    </TechnicalDetails>
  );
};
