import { FC } from 'react';

import { Colors } from '@lichtblick/theme';
import { Container } from '@lichtblick/ui-components';

import { CaseStudyRoot, ImageWrapper } from './CaseStudy.styles';

import { CaseStudyType } from '../../../types';
import { getColor } from '../../../utils';
import { AImage } from '../../AImage';
import { MTextIntro } from '../../MTextIntro';
import { Listing, Interferer, HalfSizedColumn, Row } from '../shared';

export const CaseStudy: FC<CaseStudyType> = ({ details, image, interferer, text, theme = 'white' }) => (
  <CaseStudyRoot $backgroundColor={getColor(theme) || Colors.White}>
    <Container as={'div'}>
      <ImageWrapper>
        {image?.[0] && <AImage {...image[0]} />}
        {interferer && <Interferer text={interferer} />}
      </ImageWrapper>
      <Row>
        <HalfSizedColumn>{text?.[0] && <MTextIntro {...text[0]} />}</HalfSizedColumn>
        <HalfSizedColumn>
          <Listing
            backgroundColor={theme === 'white' ? Colors.FadedMoss : Colors.White}
            data-testid="CaseStudy-Listing"
            rows={details}
          />
        </HalfSizedColumn>
      </Row>
    </Container>
  </CaseStudyRoot>
);
