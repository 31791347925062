import { FC } from 'react';

import { useTracker } from '@lichtblick/tracker';

import { TextCTA } from '../../atoms/TextCTA/TextCTA';
import { NavigationItemProps } from '../../types';

export const SecondaryNavigation: FC<NavigationItemProps> = ({ href, onClick, text, tracking }) => {
  const { trackCustomEvent } = useTracker();

  return (
    <TextCTA
      as="a"
      href={href}
      onClick={(event) => {
        trackCustomEvent(tracking);
        onClick?.(event);
      }}
      tabIndex={href ? undefined : 0}
    >
      {text}
    </TextCTA>
  );
};
