import { FC, ReactNode } from 'react';

import { TeaserItem } from '@lichtblick/ui-components';

import { getHref } from '../../helpers';
import { componentMapper } from '../../helpers/componentMapper';
import { MTeaserItemType } from '../../types/storyblok';
import { ColorKey, colorMap, getButtonVariant } from '../../utils';
import { AButton } from '../AButton';

export type MTeaserItemProps = Omit<MTeaserItemType, 'text'> & {
  accentColor?: ColorKey;
  className?: string;
  isTextOutside?: boolean;
  text?: MTeaserItemType['text'] | ReactNode;
};

export const MTeaserItem: FC<MTeaserItemProps> = ({
  accentColor,
  button,
  buttonType,
  chip,
  className,
  headline,
  isTextOutside = false,
  media,
  text,
  trackingIdentifier,
}) => {
  const buttonData = button[0];
  const href = getHref(buttonData);
  const tracking = trackingIdentifier ? { eventName: trackingIdentifier } : undefined;

  return (
    <TeaserItem
      backgroundColor={accentColor ? colorMap[accentColor] : undefined}
      chip={chip}
      className={className}
      headline={headline}
      isTextOutside={isTextOutside}
      link={{
        href,
        isDownloadLink: buttonData.isDownloadLink ?? false,
        shouldOpenInNewTab: buttonData.openInNewTab ?? false,
      }}
      media={media?.length ? componentMapper(media[0]) : undefined}
      text={text}
      tracking={tracking}
    >
      {buttonData.text && (
        // TODO: this should be a visual component only since the MTeaserItem is a link already
        <AButton
          {...buttonData}
          // AButton cannot be link since this would result in invalid markup (link in link)
          linkAsset={undefined}
          linkDialog={undefined}
          linkEntry={undefined}
          linkUrl={undefined}
          tabIndex={-1}
          variant={getButtonVariant(buttonType) ?? 'link'}
        />
      )}
    </TeaserItem>
  );
};
