import { Icon } from '@lichtblick/icons';
import styled, { css } from '@lichtblick/styled';
import { MediaMediumAndAbove, Spacing, Colors, BorderRadius, MediaSmallAndAbove } from '@lichtblick/theme';
import { focusRing, Headline, linkUnderlineBlack, linkUnderlineHover } from '@lichtblick/ui-components';

import { AText } from '../AText';

export const StyledHeadline = styled(Headline)`
  margin-bottom: ${Spacing['3Xs']};

  span {
    ${linkUnderlineBlack};
  }
`;

export const TextWrapper = styled.div`
  align-items: center;
  display: flex;
  padding: ${Spacing.Xs} ${Spacing.S};
  position: relative;

  ${MediaSmallAndAbove} {
    padding: ${Spacing.S};
  }
`;

export const StyledText = styled.div`
  padding-right: ${Spacing.S};

  ${MediaSmallAndAbove} {
    padding-right: ${Spacing.S};
  }
`;

export const ImageContainer = styled.div`
  border-top-left-radius: ${BorderRadius.S};
  border-top-right-radius: ${BorderRadius.S};
  height: 9.4375rem;
  overflow: hidden;
  position: relative;

  // set fixed height to prevent item w/ image to exceed the height of 2 items w/o image to match exactly the design
  ${MediaSmallAndAbove} {
    height: 14.5625rem;
  }

  ${MediaMediumAndAbove} {
    height: 15.6875rem;
  }

  &::after {
    content: ' ';
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.1s;
    background: color-mix(in srgb, ${Colors.Black} 11%, transparent);
  }
`;

export const DownloadItem = styled.a`
  border-radius: ${BorderRadius.S};
  display: block;
  position: relative;
  border: 1px solid ${Colors.Gray};
  transition: background-color 0.1s;

  &:focus-visible {
    ${focusRing}
  }

  &:hover {
    ${StyledHeadline} {
      span {
        ${linkUnderlineHover}
      }
    }

    background: color-mix(in srgb, ${Colors.Black} 11%, transparent);

    ${ImageContainer}::after {
      opacity: 1;
    }
  }
`;

export const Image = styled.div<{ previewAlignment?: string | null }>`
  height: 100%;
  overflow: hidden;
  position: relative;

  img {
    height: auto;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  ${({ previewAlignment }) => {
    if (previewAlignment === 'top') {
      return css`
        img {
          top: 0;
          transform: translate(-50%);
        }
      `;
    }

    if (previewAlignment === 'bottom') {
      return css`
        img {
          top: auto;
          bottom: 0;
          transform: translate(-50%);
        }
      `;
    }
  }}
`;

export const MetaCollection = styled.div`
  display: flex;
`;

export const MetaItem = styled(AText)`
  color: ${Colors.DarkGray};
  margin-bottom: 0;

  &:not(:first-child) {
    &::before {
      content: '|';
      padding-left: ${Spacing.Xs};
      padding-right: ${Spacing.Xs};
    }
  }
`;

export const StyledIcon = styled(Icon)`
  flex-shrink: 0;
  margin-left: auto;
`;
