export const isDayOfService = (outOfOfficeDates: (string | null)[]): boolean => {
  const today = new Date();
  const month = today.getMonth() + 1;
  const day = today.getDay();
  const dateString = `${today.getDate()}.${month}.`;

  // Saturday and Sunday are not days of service
  if (day === 6 || day === 0) {
    return false;
  }

  if (outOfOfficeDates.includes(dateString)) {
    return false;
  }

  return true;
};

export const isTimeOfService = (startHour: number, endHour: number): boolean => {
  const hours = new Date().getHours();

  return hours >= startHour && hours <= endHour;
};
