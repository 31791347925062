import { FC } from 'react';

import { toIconName } from '@lichtblick/icons';
import { Spacing } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

import { StyledGridColumn, StyledHeadline, StyledIcon, StyledText } from './OInfoteaser.styles';

import { OInfoteaserType } from '../../types/storyblok';
import { getColor, getTheme } from '../../utils';
import { AButton } from '../AButton';
import { AnchorMark, GridColumn, GridContainer, GridRow, getContainerStyle } from '../shared';

export const OInfoteaser: FC<OInfoteaserType> = ({
  accent,
  anchorId,
  button,
  containerStyle,
  headline,
  icon,
  iconColor: shiftColor,
  text,
  theme,
}) => {
  const gridContainerStyle = getContainerStyle(containerStyle);

  let iconColor = getTheme(theme).primary;

  if (gridContainerStyle === 'full-boxed' && accent) {
    iconColor = getTheme(accent).primary;
  }

  return (
    <Box backgroundColor={getColor(theme)} data-testid="info-teaser" pyd={Spacing.Xl} pym={Spacing.M}>
      {anchorId && <AnchorMark anchorId={anchorId} />}
      <GridContainer $backgroundColor={getColor(accent)} $containerStyle={gridContainerStyle}>
        <GridRow>
          <StyledGridColumn $isAlignedMiddle $width={2}>
            {icon && <StyledIcon $color={iconColor} name={toIconName(icon)} shiftColor={getColor(shiftColor)} />}
          </StyledGridColumn>
          <GridColumn $width={10}>
            <StyledHeadline renderAs="h4" size="S">
              {headline}
            </StyledHeadline>
            <StyledText>{text}</StyledText>
            {button?.length === 1 && <AButton {...button[0]} variant="link" />}
          </GridColumn>
        </GridRow>
      </GridContainer>
    </Box>
  );
};
