import styled, { css } from '@lichtblick/styled';
import { MediaMediumAndAbove, Spacing } from '@lichtblick/theme';

export const TextIntroWrapper = styled.div`
  margin-bottom: ${Spacing.M};
  ${MediaMediumAndAbove} {
    margin: 0 auto ${Spacing.L};
    width: ${(100 / 12) * 8}%;
  }
`;

export const Items = styled.div<{ $isVerticallyCentered?: boolean }>`
  display: flex;
  align-items: ${({ $isVerticallyCentered }) => ($isVerticallyCentered ? 'center' : 'normal')};
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -${Spacing.Xxs};
`;

export const Item = styled.div<{ $length: number }>`
  flex: 0 0 calc(50% - ${Spacing.Xs});
  margin: 0 ${Spacing.Xxs};

  ${({ $length }) =>
    // TODO: what does this magic do? Force wrap after certain amount of items? Can't we improve this with e.g. grid?
    [5, 6, 9].includes($length) &&
    css`
      ${MediaMediumAndAbove} {
        &:nth-child(3) {
          margin-right: calc(${Spacing.Xxs} + 1px);
        }
        &:nth-child(1) {
          margin-left: calc(${Spacing.Xxs} + 1px);
        }
      }
    `}

  ${({ $length }) =>
    $length === 9 &&
    css`
      ${MediaMediumAndAbove} {
        &:nth-child(6) {
          margin-right: calc(${Spacing.Xxs} + 1px);
        }
        &:nth-child(4) {
          margin-left: calc(${Spacing.Xxs} + 1px);
        }
      }
    `}
    
  ${MediaMediumAndAbove} {
    flex-basis: calc(25% - ${Spacing.Xs});
  }
`;
