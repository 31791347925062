import styled from '@lichtblick/styled';
import { Breakpoints, Colors, LargeAndAbove, MediumAndAbove, Spacing } from '@lichtblick/theme';

export const StyledMRoadToZeroItem = styled.div<{ $background: Colors }>`
  box-sizing: border-box;
  display: flex;
  background: ${({ $background }) => $background};
  min-height: 100vh;
  min-height: 100dvh;
  align-items: flex-start;

  @media ${MediumAndAbove} {
    align-items: center;
    justify-content: center;
  }

  @media ${LargeAndAbove} {
    padding: ${Spacing['3Xl']} 0;
  }
`;

export const StyledMRoadToZeroItemWrapper = styled.div<{ $reversed: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${Spacing.S};
  max-width: ${Breakpoints.Large}px;
  padding: calc(${Spacing.S} + 2rem) 7rem ${Spacing.S} ${Spacing.S};
  @media ${MediumAndAbove} {
    gap: 20rem;
    grid-template-columns: 1fr 1fr;
    padding: 0 3.5rem;

    > :last-child {
      ${({ $reversed }) => !$reversed && 'order: -1;'}
    }
  }
`;

export const StyledMRoadToZeroContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: ${Spacing.S};
`;
