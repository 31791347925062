import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

import { AKLAMIO_VP_CODE, sessionStorageName } from '../../constants';

export type VpCodes = {
  uvp?: string; // Direct sales deputy id
  vp?: string; // Direct sales partner id
};

/**
 * Will compute which vp codes should be used.
 * Will look for vp codes in the following order:
 *  - parameter
 *  - configuration
 *  - session storage
 * @param configuredVpCodes
 * @param storeConfiguredVpCodes If true the configured vp codes are also stored in the session
 *   storage when used. The vp codes from the parameters are always stored in the session when used.
 */
export const useVpCodes = () => {
  const [aidSessionStorage, setAidSessionStorage] = useState<string | null>(null);
  const [vpCodeSessionStorage, setVpCodeSessionStorage] = useState<string | null>(null);
  const [uvpCodeSessionStorage, setUvpCodeSessionStorage] = useState<string | null>(null);

  const searchParams = useSearchParams();
  const aid = searchParams.get('aid'); // Aklamio referral id
  const utmSource = searchParams.get('utm_source'); // Affiliate tracking
  const utmTerm = searchParams.get('utm_term'); // Affiliate tracking
  const vpCode = searchParams.get('vp'); // Direct sales deputy id
  const uvpCode = searchParams.get('uvp'); // Direct sales partner id

  useEffect(() => {
    setAidSessionStorage(sessionStorage.getItem(sessionStorageName['aklamioId']));
    setVpCodeSessionStorage(sessionStorage.getItem(sessionStorageName['vpCode']));
    setUvpCodeSessionStorage(sessionStorage.getItem(sessionStorageName['uvpCode']));
  }, []);

  const computeVpCodes = (configuredVpCode?: string, storeConfiguredVpCodes?: boolean): VpCodes => {
    const vpCodes: VpCodes = {};

    // Referral has highest priority. Thereafter affiliate parameters that are stored like direct sales partner codes.
    // Otherwise uvp is only allowed when vp is set
    if (aid || aidSessionStorage) {
      vpCodes.vp = AKLAMIO_VP_CODE;
      vpCodes.uvp = aid ?? aidSessionStorage ?? '';
    } else if (utmSource === 'awin' && utmTerm) {
      vpCodes.vp = 'awin';
      vpCodes.uvp = utmTerm;
    } else if (vpCode) {
      vpCodes.vp = vpCode;
      vpCodes.uvp = uvpCode ?? '';
      storeVpCodes(vpCodes);
    } else if (configuredVpCode) {
      vpCodes.vp = configuredVpCode;

      if (storeConfiguredVpCodes) {
        storeVpCodes(vpCodes);
      }
    } else if (vpCodeSessionStorage) {
      vpCodes.vp = vpCodeSessionStorage;
      vpCodes.uvp = uvpCodeSessionStorage ?? '';
    }

    return vpCodes;
  };

  return {
    computeVpCodes,
  };
};

const storeVpCodes = (vpCodes: VpCodes): void => {
  if (vpCodes.vp) {
    sessionStorage.setItem(sessionStorageName['vpCode'], vpCodes.vp);
  }

  if (vpCodes.uvp) {
    sessionStorage.setItem(sessionStorageName['uvpCode'], vpCodes.uvp);
  }
};
