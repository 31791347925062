import styled from '@lichtblick/styled';
import { Spacing } from '@lichtblick/theme';
import { Headline } from '@lichtblick/ui-components';

import { AText } from '../AText';

export const Privacy = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 75vh;
  padding: ${Spacing.Xs} ${Spacing.Xxs};
  text-align: center;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 75vh;
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: 1rem;
`;

export const StyledText = styled(AText)`
  margin-bottom: 1rem;
`;
