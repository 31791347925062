'use client';

import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import { FC, PropsWithChildren } from 'react';

import { OPTIMIZELY_NAME } from '@lichtblick/consent/ConsentManager/ConsentManager.types';
import { useConsent } from '@lichtblick/consent/hooks/useConsent';
import { useUsercentricsControllerId } from '@lichtblick/consent/hooks/useUsercentricsControllerId';

export const OptimizelyWrapper: FC<PropsWithChildren> = ({ children }) => {
  const ucControllerId = useUsercentricsControllerId() || null;
  const optimizelyConsentState = useConsent(OPTIMIZELY_NAME);

  const optimizelyInstance = createInstance({
    sdkKey: process.env.NEXT_PUBLIC_OPTIMIZELY_SDK_KEY,
    logLevel: process.env.NODE_ENV !== 'development' ? 'error' : 'info',
    odpOptions: { disabled: true },
  });

  return (
    <OptimizelyProvider optimizely={optimizelyInstance} user={{ id: optimizelyConsentState ? ucControllerId : null }}>
      {children}
    </OptimizelyProvider>
  );
};
