import styled, { PickTransient } from '@lichtblick/styled';
import { Colors, Spacing, StateColors } from '@lichtblick/theme';

import { FieldFooterProps, FieldFooterType } from './FieldFooter';

import { Text } from '../../atoms/Text/Text';

const colorMap: Record<FieldFooterType, string> = {
  default: Colors.DarkGray,
  error: StateColors.Error,
  success: StateColors.Success,
};

export const StyledFieldFooter = styled(Text).attrs({ size: 'Xs' })<PickTransient<FieldFooterProps, 'fieldFooterType'>>`
  align-items: center;
  color: ${({ $fieldFooterType = 'default' }) => colorMap[$fieldFooterType]};
  display: flex;
  line-height: 170%;
`;

export const IconWrapper = styled.span`
  margin-right: ${Spacing.Xxs};
  position: relative;

  & > * {
    vertical-align: middle;
  }
`;
