import styled from '@lichtblick/styled';
import { MediaMediumAndAbove, Spacing } from '@lichtblick/theme';
import { AppBadges, Headline } from '@lichtblick/ui-components';

import { AText } from '../AText';

export const StyledHeadline = styled(Headline)`
  &:not(:last-child) {
    margin-bottom: ${Spacing.Xs};

    ${MediaMediumAndAbove} {
      margin-bottom: ${Spacing.Xxs};
    }
  }
  &&:has(+ a) {
    ${MediaMediumAndAbove} {
      margin-bottom: ${Spacing.S};
    }
  }
`;

export const StyledText = styled(AText)`
  &:not(:last-child) {
    margin-bottom: ${Spacing.Xs};
    ${MediaMediumAndAbove} {
      margin-bottom: ${Spacing.S};
    }
  }
`;

export const StyledAppBadges = styled(AppBadges)`
  justify-content: center;
`;

export const ImageWrapper = styled.div`
  margin-bottom: ${Spacing.Xs};
  margin-left: auto;
  margin-right: auto;
  max-width: 4rem;
`;
