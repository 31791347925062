import { FC, useEffect, useRef, useState } from 'react';

import { Colors, Spacing } from '@lichtblick/theme';
import { Box } from '@lichtblick/ui-components';

import { BackToTopButton } from './BackToTopButton';
import { Expander } from './Expander';
import { StyledGridRow, StyledHeadline } from './OIndex.styles';
import { StickyNav } from './StickyNav';

import { OIndexType } from '../../types/storyblok';
import { getColor } from '../../utils';
import { MIndexItem } from '../MIndexItem';
import { AnchorMark, GridColumn, GridContainer, GridRow } from '../shared';

export const OIndex: FC<OIndexType> = ({ anchorId, items, theme, visibleItemsCount }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isStickyNavVisible, setIsStickyNavVisible] = useState(false);
  const [isBackToTopButtonVisible, setIsBackToTopButtonVisible] = useState(false);
  const tickRef = useRef<boolean>();
  const visibleItems = visibleItemsCount ? Number(visibleItemsCount) : items.length;
  const isExpanderVisible = visibleItems < items.length;

  useEffect(() => {
    const divRef = ref.current;

    if (!divRef) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsStickyNavVisible(entry.boundingClientRect.top < -entry.boundingClientRect.height);
      },
      {
        root: null,
        threshold: 0,
      },
    );

    observer.observe(divRef);

    return () => observer.unobserve(divRef);
  }, []);

  useEffect(() => {
    let lastScrollTop = window.scrollY || document.documentElement.scrollTop;

    const setScrollButtonVisibility = () => {
      if (tickRef.current) {
        return;
      }

      tickRef.current = true;

      window.requestAnimationFrame(() => {
        if (!isStickyNavVisible) {
          setIsBackToTopButtonVisible(false);
        } else {
          const scrollTopPosition = window.scrollY || document.documentElement.scrollTop;

          setIsBackToTopButtonVisible(scrollTopPosition <= lastScrollTop);
          lastScrollTop = Math.max(scrollTopPosition, 0);
        }

        tickRef.current = false;
      });
    };

    window.addEventListener('scroll', setScrollButtonVisibility);

    return () => window.removeEventListener('scroll', setScrollButtonVisibility);
  }, [isStickyNavVisible]);

  return (
    <>
      <Box backgroundColor={getColor(theme)} pyd={Spacing.Xl} pym={Spacing.M}>
        {anchorId && <AnchorMark anchorId={anchorId} />}
        <GridContainer>
          <GridRow>
            <GridColumn $push={1} $width={10}>
              <GridContainer
                $backgroundColor={theme === 'faded-gray' ? Colors.White : Colors.FadedGray}
                $containerStyle="full-boxed"
                ref={ref}
              >
                <StyledGridRow>
                  <GridColumn $push={1} $width={10}>
                    <StyledHeadline renderAs="div" size="S">
                      Inhaltsangabe:
                    </StyledHeadline>

                    {Boolean(items.length) && (
                      <ul>
                        {items.slice(0, visibleItems).map((item) => (
                          <MIndexItem {...item} key={item._uid} />
                        ))}

                        {isExpanderVisible && <Expander items={items.slice(visibleItems, items.length)} />}
                      </ul>
                    )}
                  </GridColumn>
                </StyledGridRow>
              </GridContainer>
            </GridColumn>
          </GridRow>
        </GridContainer>
      </Box>

      <StickyNav isVisible={isStickyNavVisible} items={items} />
      <BackToTopButton isVisible={isBackToTopButtonVisible} />
    </>
  );
};
