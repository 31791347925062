import { ComponentPropsWithRef } from 'react';

import styled from '@lichtblick/styled';
import {
  alpha,
  BorderRadius,
  BorderWidth,
  Colors,
  DurationAndEasing,
  Opacities,
  Spacing,
  StateColors,
} from '@lichtblick/theme';

export type CheckboxState = 'default' | 'error' | 'disabled';

export type CheckboxElementProps = Omit<ComponentPropsWithRef<'input'>, 'type'>;

export type CheckboxProps = CheckboxElementProps & {
  $state?: CheckboxState;
};

export const stateColorMap: Record<CheckboxState, string> = {
  default: Colors.Black,
  error: StateColors.Error,
  disabled: alpha(Colors.Black, Opacities.Disabled),
};

export const Checkbox = styled.input.attrs({ type: 'checkbox' })<CheckboxProps>`
  align-items: center;
  appearance: none;
  border: ${BorderWidth.Input} solid ${({ $state = 'default' }) => stateColorMap[$state]};
  border-radius: ${BorderRadius.Xxs};
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  display: flex;
  flex-shrink: 0;
  height: ${Spacing.Xs};
  justify-content: center;
  outline: none;
  position: relative;
  width: ${Spacing.Xs};
  z-index: 0;

  /* Focus indicator */
  &::before {
    border: 0.375rem solid ${alpha(Colors.Orange, Opacities.Light)};
    border-radius: ${BorderRadius.Xs};
    content: '';
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: opacity ${DurationAndEasing.SmallIntro};
    width: 100%;
    z-index: -1;
  }

  /* The checkmark */
  &::after {
    background-image: url("data:image/svg+xml;utf8,<svg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.47339 4.85233L4.09485 6.86554L8.57571 1.13208' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
    background-position: center;
    background-repeat: no-repeat;
    content: '';
    height: 100%;
    opacity: 0;
    transition: opacity ${DurationAndEasing.SmallIntro};
    width: 100%;
  }

  &:focus-visible::before {
    opacity: 1;
  }

  &:checked {
    background: ${({ $state }) => ($state === 'disabled' ? 'transparent' : Colors.Orange)};

    &::after {
      opacity: ${({ $state }) => ($state === 'disabled' ? Opacities.Disabled : 1)};
    }
  }
`;
