import styled from '@lichtblick/styled';
import { Colors, Spacing } from '@lichtblick/theme';
import { LegacyLink, Text } from '@lichtblick/ui-components';

export const StyledText = styled(Text)`
  color: ${Colors.Gray};

  &:not(:last-child) {
    margin-bottom: ${Spacing.Xxs};
  }
`;

export const StyledLink = styled(LegacyLink)`
  color: ${Colors.White};
`;
