import styled from '@lichtblick/styled';
import { MediaDesktop, Spacing } from '@lichtblick/theme';
import { Loader } from '@lichtblick/ui-components';

export const CarCalculatorApp = styled.div`
  width: 100%;
`;

export const LoaderContainer = styled.div`
  padding-bottom: ${Spacing.Xs};
  padding-top: ${Spacing.Xs};
  text-align: center;

  ${MediaDesktop} {
    padding-bottom: ${Spacing.M};
    padding-top: ${Spacing.M};
  }
`;

export const StyledLoader = styled(Loader)`
  width: 5rem;

  ${MediaDesktop} {
    width: 8rem;
  }
`;
