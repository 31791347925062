import styled from '@lichtblick/styled';
import { BorderRadius, Colors, DurationAndEasing, SmallAndAbove, Spacing } from '@lichtblick/theme';
import { AccordionContent, AccordionItem, AccordionTrigger, Label, StyledTextCTA } from '@lichtblick/ui-components';
import { StyledChip } from '@lichtblick/ui-components/atoms/Chip/Chip.styles';
import { RadioButton } from '@lichtblick/ui-components/molecules/Radio/RadioButton.styles';

export const StyledCalculatorAccordionItem = styled(AccordionItem)<{ $headerColor: Colors }>`
  border-radius: ${BorderRadius.Xs};
  background: ${Colors.White};
  margin-top: ${Spacing.Xxs};
  position: relative;

  &[data-state='closed'] {
    overflow: hidden;
  }

  ${AccordionTrigger} {
    background: ${Colors.White};
    align-items: center;
    transition: background-color ${DurationAndEasing.LargeIntro};
    border-radius: ${BorderRadius.Xs} ${BorderRadius.Xs} 0 0;
    padding: ${Spacing.Xxs} ${Spacing.Xs};
    display: flex;
    width: 100%;
    gap: ${Spacing.Xxs};

    @media ${SmallAndAbove} {
      padding: ${Spacing.Xs} ${Spacing.S};
      gap: ${Spacing.Xs};
    }

    ${AccordionContent} {
      padding: ${Spacing.Xs};
    }

    ${RadioButton} {
      margin: 0;
    }

    ${StyledChip} {
      cursor: unset;
    }

    & > :last-child {
      margin-left: auto;
      flex-shrink: 0;
    }

    &[data-state='closed'] {
      &:focus-visible {
        border-radius: ${BorderRadius.Xs};
      }

      ${StyledChip} {
        background: ${Colors.FadedGray};
      }
    }

    &[data-state='open'] {
      transition: background-color ${DurationAndEasing.LargeOutro};
      background: ${({ $headerColor }) => $headerColor};

      ${RadioButton} {
        background: ${Colors.White};

        &::after {
          opacity: 1;
        }
      }
    }
  }
`;

export const StyledCalculatorContent = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.Xs};
  width: 100%;
  padding: ${Spacing.Xs} ${Spacing.Xs} ${Spacing.S};

  @media ${SmallAndAbove} {
    padding: ${Spacing.Xs} ${Spacing.S} ${Spacing.S};
    gap: ${Spacing.S};
  }

  ${StyledTextCTA} {
    line-height: 0;
  }
`;

export const StyledCalculatorInputRow = styled.div`
  display: grid;
  gap: ${Spacing.Xs};

  @media ${SmallAndAbove} {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
  }
`;

export const StyledRadioInputWrapper = styled.label`
  cursor: pointer;
  border: solid 2px ${Colors.Black};
  border-radius: ${Spacing.Xxs};
  padding: ${Spacing.Xxs};
`;

export const StyledLabel = styled(Label)`
  display: flex;
  align-items: center;
  gap: ${Spacing.Xs};
`;

export const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.Xs};
`;
